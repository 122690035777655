import React, { useState, useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import { db } from "./BudgetPage";

function RadarChart(props) {
  const canvasRef = useRef(null);
  const [chartData, setChartData] = useState({});

  useEffect(() => {
    const purchasesRef = db.ref(`users/${props.userId}/purchases`);
    purchasesRef.once("value", (snapshot) => {
      const purchaseData = snapshot.val();
      if (purchaseData) {
        const categories = {};
        Object.keys(purchaseData).forEach((key) => {
          const category = purchaseData[key].category;
          if (categories[category]) {
            categories[category] += purchaseData[key].amount;
          } else {
            categories[category] = purchaseData[key].amount;
          }
        });

        if (Object.keys(categories).length >= 3) {
          // Get budget data
          const categoriesRef = db.ref(`users/${props.userId}/categories`);
          categoriesRef.once("value", (snapshot) => {
            const budgetData = snapshot.val();
            if (budgetData) {
              // Map categories in budget data to categories in purchase data
              const budgetCategories = Object.keys(budgetData);
              const purchaseCategories = Object.keys(categories);
              const categoryMap = {};
              purchaseCategories.forEach((category) => {
                const matchingCategory = budgetCategories.find(
                  (budgetCategory) =>
                    budgetCategory.toLowerCase() === category.toLowerCase()
                );
                if (matchingCategory) {
                  categoryMap[category] = matchingCategory;
                }
              });

              // Get total budget for each category in the purchase data
              const budgetValues = purchaseCategories.map((category) => {
                const budgetCategory = categoryMap[category];
                if (budgetCategory) {
                  return budgetData[budgetCategory];
                } else {
                  return 0;
                }
              });

              setChartData({
                labels: purchaseCategories,
                datasets: [
                  {
                    label: "Spending",
                    data: Object.values(categories),
                    backgroundColor: "rgb(10, 100, 90, 0.6)",
                    borderColor: "#707070",
                    borderWidth: 1,
                  },
                  {
                    label: "Budget",
                    data: budgetValues,
                    backgroundColor: "#cacaca8c",
                    borderColor: "#707070",
                    borderWidth: 0,
                  },
                ],
              });
            } else {
              setChartData({
                labels: Object.keys(categories),
                datasets: [
                  {
                    label: "Spending",
                    data: Object.values(categories),
                    backgroundColor: "#e9c4769c",
                    borderColor: "#707070",
                    borderWidth: 1,
                  },
                ],
              });
            }
          });
        } else {
          setChartData({});
        }
      } else {
        setChartData({});
      }
    });
  }, [props.userId]);
  
    useEffect(() => {
      if (canvasRef.current && Object.keys(chartData).length > 0) {
        const chart = new Chart(canvasRef.current, {
          type: "radar",
          data: chartData,
          options: {
            aspectRatio: false,
            // responsive: true,
            elements: {
                point: {
                    radius: 1},
                },
            scales: {
              r: {
                ticks: {
                  major: true,
                  showLabelBackdrop: false,
                  offset: true,
                  display: true,
                  color: '#555555',
                  font: {
                    size: 10,
                  },
                  callback: function (value, index, values) {
                    return props.currencySymbol + value;
                  },
                },
                angleLines: {
                  color: "#707070",
                },
                pointLabels: {
                  borderRadius: 10,
                  color: '#555555',
                  font: {
                    size: 10,
                  },
                },
                grid: {
                  color: "#a9a9a9",
                },
              },
            },
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                enabled: false,
                callbacks: {
                    label: function(context) {
                        const label = context.dataset.label;
                        const value = context.parsed?.y;
                        const category = context.label;
                        return (
                          category + ": " + props.currencySymbol + (value ? value.toFixed(2) : '')
                        );
                      }
                  },
                },
            },
          },
        });
  
        return () => {
          chart.destroy();
        };
      }
  
  }, [chartData]);

  return (
    <div className="chartcanvas">
    {Object.keys(chartData).length > 0 ? (
      <canvas ref={canvasRef} />
    ) : (
      <div className="emptychartstate">Not enough radar chart data to display!<br></br>Add purchases among at least three categories to see this chart.</div>
    )}
  </div>
  );
}

export default RadarChart;