import React, { useState, useEffect } from 'react';
import { FaSpinner } from 'react-icons/fa';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import './LightMode.css';

const db = firebase.database();

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "budget-app-d568a.firebaseapp.com",
  databaseURL: "https://budget-app-d568a-default-rtdb.firebaseio.com",
  projectId: "budget-app-d568a",
  storageBucket: "budget-app-d568a.appspot.com",
  messagingSenderId: "219321405159",
  appId: "1:219321405159:web:c4a51c20c9d13376629c42",
  measurementId: "G-6P5Y0TGT7T"
};
firebase.initializeApp(firebaseConfig);

const EmojiModal = ({
  showModal,
  emojis,
  emojiCategories, 
  selectedEmoji,
  setSelectedEmoji,
  purchaseId,
  closeModal,
  selectedGroup,
}) => {
  const [updatingEmoji, setUpdatingEmoji] = useState(false);

  const handleEmojiClick = async (emoji) => {
    setUpdatingEmoji(true);
    try {
      await updateEmojiInDatabase(purchaseId, emoji);
      setSelectedEmoji(emoji);
      closeModal();
    } catch (error) {
      console.error(error);
      // Handle the error, e.g., show an error message to the user
    } finally {
      setUpdatingEmoji(false);
    }
  };

  const updateEmojiInDatabase = async (purchaseId, emoji) => {
    const userId = firebase.auth().currentUser.uid;
    const userPurchasesRef = db.ref(`users/${userId}/purchases/${purchaseId}`);
  
    // Update the icon for the user's purchase
    await userPurchasesRef.update({ icon: emoji });
  
    // Retrieve all groups
    const groupsRef = db.ref('groups');
    groupsRef.once('value', async (snapshot) => {
      const allGroups = snapshot.val();
  
      // For each group, check if the user is a member and if so, update the purchases
      for (let groupId in allGroups) {
        const group = allGroups[groupId];
        const members = group.members || {};
        const groupPurchases = group.purchases || {};
  
        if (members[userId]) {  // If the user is a member of this group
          // Iterate over the purchases of the group
          for (let groupPurchaseId in groupPurchases) {
            const groupPurchase = groupPurchases[groupPurchaseId];
            if (groupPurchase.id === purchaseId) {  // If the group's purchase ID matches with the input purchaseId
              // Update the icon for the purchase in this group
              await db.ref(`groups/${groupId}/purchases/${groupPurchaseId}`).update({ icon: emoji });
            }
          }
        }
      }
    });
  };

  if (!showModal) {
    return null;
  }

 
  return (
    <div className="resetconfirm2" style={{padding: "0px"}}>
      <div className="emoji-modal-content">
      {Object.keys(emojiCategories).map(category => (
          <div key={category}>
            <h2 className="emoji-modal-list-header">{category}</h2>
            <div className="emoji-list">
              {/* Iterate over each emoji in the current category */}
              {emojiCategories[category].map((emoji, index) => (
                <button
                  key={index}
                  className={`emoji-item ${selectedEmoji === emoji ? 'selected' : ''}`}
                  onClick={() => handleEmojiClick(emoji)}
                >
                  {emoji}
                </button>
              ))}
            </div></div>
        ))}
        <div className="loader-container">
          {updatingEmoji && (
            <div className="loader">
              <FaSpinner className="spinner-icon" />
              <span>Updating Emoji...</span>
            </div>
          )}
        </div>
      </div>
      <button className="emojimodal-close-button" onClick={closeModal}>
        Cancel
      </button>
    </div>
  );
};

export default React.memo(EmojiModal);

