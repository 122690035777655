import React, { useEffect, useState, useRef } from 'react';
import budgetlogo from "./images/lillogo.svg";
import billimage from "./images/bill.svg";
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import IncomeBudgetFormAdvanced from './IncomeBudgetFormAdvanced';
import './ExpenseReminders.css';
import { endOfMonth, getDay, getDate } from 'date-fns'; 
import { FcCheckmark } from "react-icons/fc";



const ExpenseReminders = ({ handleUnhideInputWidget, handleHideInputWidget, lastPaidExpense, setLastPaidExpense, hasPayableUpcomingExpenses, setHasPayableUpcomingExpenses, handleScrollToStart, setActiveComponent, setShowExpenseReminders, showExpenseReminders, setShowIncomeBudgetForm, setHasUpcomingExpenses, currencySymbol, handleCloseBillReminders, handleShowExpenseReminders, setShowInputWidget, showBillReminders, setShowBillReminders, setEditMode}) => {
  const [expenses, setExpenses] = useState([]);
  const [upcomingExpenses, setUpcomingExpenses] = useState([]);
const [farExpenses, setFarExpenses] = useState([]);
const [noDueDateExpenses, setNoDueDateExpenses] = useState([]);
const [overdueExpenses, setOverdueExpenses] = useState([]);
const [showMainContent, setShowMainContent] = useState(true);
const expensesTopAnchorRef = useRef(null);

  const userId = firebase.auth().currentUser.uid;

  const today = new Date();
  const daysInMonth = getDate(endOfMonth(today)); 

  // Event Handlers
 
const handlePaidClick = (expenseId, paidStatus) => {
  const expenseRef = firebase.database().ref(`users/${userId}/expenses/${expenseId}`);
  if (!paidStatus) {
    expenseRef.update({ paid: true, paidDate: firebase.database.ServerValue.TIMESTAMP });
  } else {
    expenseRef.update({ paid: false });
  }
  setLastPaidExpense(expenseId);
};

  const closeExpenseReminders = () => {
    const paydayContainer = document.querySelector(".paydaycontainer2");
    
    if (paydayContainer) {
      paydayContainer.classList.add("animateaway");
      setTimeout(() => {
        handleCloseBillReminders();
      }, 200);
    }
  };

  useEffect(() => {
    if(expensesTopAnchorRef.current) {
        expensesTopAnchorRef.current.scrollIntoView({ top: 0, behavior: 'smooth' });
   }
     }, []);

const openExpenseReminders = () => {

setShowMainContent(false);
setShowExpenseReminders(true);

  };

  const getOrdinalSuffix = (i) => {
    var j = i % 10,
        k = i % 100;
    if (j == 1 && k != 11) {
        return "st";
    }
    if (j == 2 && k != 12) {
        return "nd";
    }
    if (j == 3 && k != 13) {
        return "rd";
    }
    if (i == undefined | i == "") {
        return "";
    }
    return "th";
  }
  
 // Fetch and sort expenses
 useEffect(() => {
  
    const expenseRef = firebase.database().ref(`users/${userId}/expenses`);
    expenseRef.on('value', (snapshot) => {
      let updatedExpenses = snapshot.val();
     
      updatedExpenses = Object.entries(updatedExpenses || {}).map(([key, value]) => ({
        
        ...value,
        
        dueDate: value.dueDate === 'End' ? daysInMonth : parseInt(value.dueDate, 10) || 'No due date set',
        key,
      }));
     
      updatedExpenses.sort((a, b) => {
        
        if (typeof a.dueDate !== 'number' || typeof b.dueDate !== 'number') {
          return 0;
        }
      
        const daysUntilDueA = a.dueDate - today.getDate() >= 0 ? a.dueDate - today.getDate() : daysInMonth + a.dueDate - today.getDate();
        const daysUntilDueB = b.dueDate - today.getDate() >= 0 ? b.dueDate - today.getDate() : daysInMonth + b.dueDate - today.getDate();

        return daysUntilDueA - daysUntilDueB;
      });
    
  
      let upcoming = [];
      let far = [];
      let noDueDate = [];
      let overdue = [];
      let hasUpcomingExpenses = false;

      for (let expense of updatedExpenses) {
        if (expense.dueDate === 'No due date set') {
          noDueDate.push(expense);
        } else if (!expense.paid && !expense.autopay) {
            const daysSinceDue = expense.dueDate <= today.getDate() ? today.getDate() - expense.dueDate : today.getDate() + daysInMonth - expense.dueDate;
            if (daysSinceDue <= 14 && daysSinceDue > 0) {
                overdue.push(expense);
                continue;
            }
        }
  
        const daysUntilDue = expense.dueDate >= today.getDate() ? expense.dueDate - today.getDate() : expense.dueDate + daysInMonth - today.getDate();
        if (daysUntilDue > 15) {
            far.push(expense);
        } else if (expense.dueDate !== 'No due date set') {
            upcoming.push(expense);
            if (daysUntilDue <= 2 && !expense.paid) {
              hasUpcomingExpenses = true;
              if (!expense.autopay) {
                hasPayableUpcomingExpenses = true;
              }
            }
          }
        }
      
        setHasUpcomingExpenses(hasUpcomingExpenses);
        setHasPayableUpcomingExpenses(hasPayableUpcomingExpenses);
      setUpcomingExpenses(upcoming);
      setFarExpenses(far);
      setNoDueDateExpenses(noDueDate);
      setOverdueExpenses(overdue);
    });
  
    return () => {
      expenseRef.off();
    };
}, [userId, lastPaidExpense]);

  

useEffect(() => {
  // This code fetches the expenses when the component mounts
  const fetchExpenses = async () => {
    try {
      const snapshot = await firebase.database().ref(`users/${userId}/expenses`).once('value');
      const expensesData = snapshot.val();
      // assuming you have a setExpenses function to update your expenses state
      setExpenses(expensesData || []);
    } catch (error) {
      console.error(error);
    }
  };

  fetchExpenses();
}, [userId]);

// useEffect(() => {
//   // Update expense paid status for overdue expenses
//   const updatePaidStatus = async () => {
//     const twoWeeksAgo = new Date();
//     twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 15);

//     console.log(`Two weeks ago timestamp: ${twoWeeksAgo.getTime()}`);
//     console.log(`Expenses before filtering: ${JSON.stringify(expenses, null, 2)}`);

//     const expensesToUpdate = expenses.filter((expense) => {
//       if (expense.paidDate) {
//         const paidDate = new Date(expense.paidDate);
//         const daysSincePaid = Math.floor((today.getTime() - paidDate.getTime()) / (1000 * 60 * 60 * 24));
    
//         return daysSincePaid >= 14 && expense.paid;
//       }
//       return false;
//     });

//     for (const [index, expense] of expensesToUpdate.entries()) {
//       const expenseRef = firebase.database().ref(`users/${userId}/expenses/${index}`);
//       await expenseRef.update({ paid: false });
//       console.log(`Updated expense: ${index}`);
//     }
    
//   };

//   updatePaidStatus();
// }, [expenses, userId]);
  

const renderExpense = (expense, listType) => {
    const daysUntilDue = expense.dueDate >= today.getDate() ? expense.dueDate - today.getDate() : daysInMonth + expense.dueDate - today.getDate();
    const daysSinceDue = expense.dueDate <= today.getDate() ? today.getDate() - expense.dueDate : daysInMonth - expense.dueDate + today.getDate();
    
  
    let listItemClassName = 'expense-reminders__list-item';
    let isDueSoonOrLater = false;
    if (daysUntilDue <= 3) {
      listItemClassName += ' expense-reminders__list-item--due-soon';
      isDueSoonOrLater = true;
    } else if (daysUntilDue <= 14) {
      listItemClassName += ' expense-reminders__list-item--due-later';
      isDueSoonOrLater = true;
    } else if (daysSinceDue <= 14 && !expense.paid && !expense.autopay) {
        listItemClassName += ' expense-reminders__list-item--overdue';
        isDueSoonOrLater = false;
    } else {
      listItemClassName += ' expense-reminders__list-item--due-far-away';
      isDueSoonOrLater = false;
    }
    return (
      <li key={expense.key} className={listItemClassName}>
         <div className="expense-reminders-expense-container">
        <div className="expense-reminders-liflex">
<div className="expense-reminders-liflex1">

         <div className="expense-reminders-name">{expense.name}</div><div className="expense-reminders-autopay-indicator">{expense.autopay ? <div className="e-r-a-auto">Autopay</div> : expense.paid && isDueSoonOrLater && !expense.autopay ? <div className="e-r-a-paid">Paid {expense.paid && <FcCheckmark />}</div>: <div className="e-r-a-manual">Manual Pay</div>}</div></div>
         <div className="expense-reminders-liflex2">
         {(listType === 'far' || listType === 'upcoming' || listType === 'overdue') && 
  <div>{currencySymbol}{expense.amount} due on the {expense.dueDate}{getOrdinalSuffix(expense.dueDate)}</div>
}
{listType === 'noDueDate' && 
  <div>{currencySymbol}{expense.amount}</div>
}

        
  
       <div>
        
       {!expense.autopay && (isDueSoonOrLater || daysSinceDue <= 14) && listType !== 'far' && (
          expense.paid ? (
            <button className="expense-reminders-undo-paid-button" onClick={() => handlePaidClick(expense.key, expense.paid)}>Undo</button>
          ) : (
            <button className="expense-reminders-paid-button" onClick={() => handlePaidClick(expense.key, expense.paid)}>Paid this bill</button>
          )
        )}

</div></div>
  
        {/* {expense.paid && isDueSoonOrLater && !expense.autopay &&
        <span>Paid</span>} */}
        
        </div></div>


      </li>
    );
  };
  

  // Render
  return (
    <div className="paydaycontainer2">
        {!showExpenseReminders && showMainContent && 
      <div className="expense-reminders"><div ref={expensesTopAnchorRef} className="expense-reminders-topanchor" />
      <h2 className="expense-reminders-header1">Upcoming Fixed Expenses</h2>
      <div className="expense-reminders-bill-image-container">
      <img
                        className="expense-reminders-billimage"
                        src={billimage}
                        alt="Bill image"
                    /></div>
        <p className="expense-reminders-header2">{today.toDateString()}</p>

        {upcomingExpenses.length !== 0 ? (<div className="expense-reminders-present-container">
        <h3 className="expense-reminders-header3">Bills due soon:</h3>
        {overdueExpenses.length > 0 && (
          <div className="expense-reminders-overdue">
            {/* <h3>Overdue bills</h3> */}
            {overdueExpenses.map(expense => renderExpense(expense, 'overdue'))}
          </div>
        )}
  
        {upcomingExpenses.length > 0 && (
          <div className="expense-reminders-upcoming">
            {/* <h3>Upcoming expenses</h3> */}
            {upcomingExpenses.map(expense => renderExpense(expense, 'upcoming'))}
          </div>
        )}
  
        {farExpenses.length > 0 && (
          <div className="expense-reminders-faraway">
            {/* <h3>Far away expenses</h3> */}
            {farExpenses.map(expense => renderExpense(expense, 'far'))}
          </div>
        )}

{noDueDateExpenses.length > 0 && (
        <div className="expense-reminders-noduedate">
          <h3 className="expense-reminders-header3">No due date set:</h3>
          {noDueDateExpenses.map(expense => renderExpense(expense, 'noDueDate'))}
        </div>
      )}
  </div>) : (<div className="emptystate">Expense reminders not set up!</div>)}
        <button className="expense-reminders-edit-reminders-button" onClick={openExpenseReminders}>Edit Expense Reminders</button>
    <button className="expense-reminders-close-button" onClick={closeExpenseReminders}>Close</button>
       
  
       
        <div className="budgetlogo">
                    <img
                        className="budgetlogoimg2"
                        src={budgetlogo}
                        alt="Budgetlogo"
                    />
                </div>
                <div className="bottomanchor"/>
      </div>}
      {showExpenseReminders && 
      <IncomeBudgetFormAdvanced handleUnhideInputWidget={handleUnhideInputWidget} handleHideInputWidget={handleHideInputWidget} handleScrollToStart={handleScrollToStart} setActiveComponent={setActiveComponent} setShowMainContent={setShowMainContent} setShowInputWidget={setShowInputWidget} setShowIncomeBudgetForm={setShowIncomeBudgetForm} setShowExpenseReminders={setShowExpenseReminders} showExpenseReminders={showExpenseReminders} currencySymbol={currencySymbol} userId={userId} expenses={expenses} setExpenses={setExpenses} setShowBillReminders={setShowBillReminders} setEditMode={setEditMode}/>}
    
    </div>
  );
};

export default ExpenseReminders;
