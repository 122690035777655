import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";

function GroupSpendingChart({ members, isUserAhead, userEmail, currencySymbol }) {
  const canvasRef = useRef(null);

  useEffect(() => {
   
    let memberEmails = Object.keys(members);
    let memberTotals = Object.values(members).map((total) => parseFloat(total));

  
    const userIndex = memberEmails.indexOf(userEmail);
    if (userIndex !== -1) {
      memberEmails = [userEmail].concat(memberEmails.filter(email => email !== userEmail));
      memberTotals = [memberTotals[userIndex]].concat(memberTotals.filter((total, index) => index !== userIndex));
    }


    const backgroundColors = memberEmails.map((email) => {
      if (email === userEmail) {
        return isUserAhead ? '#ffbd2b' : 'rgb(6, 59, 53)';
      }
      return 'grey';
    });



    const chartData = {
      labels: memberEmails,
      datasets: [
        {
          label: 'Total Spent',
          data: memberTotals,
          backgroundColor: backgroundColors,
          borderWidth: 0,
        },
      ],
    };

    if (canvasRef.current) {
      const chart = new Chart(canvasRef.current, {
        type: "bar",
        data: chartData,
        options: {
           
          borderRadius:  [5, 5, 5, 5],
          borderRadiusOnAllStackedSeries: true,
          legend: {align: 'start',},
          layout: {
            padding: {
              bottom: 0, 
            },
          },
          aspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
          },
        scales: {
          x: {
            display: false,
            grid: {
              display: false
            },
            // stacked: true,
          },
          y: 
          {
            position: 'right',
            grid: {
              display: false,
              color: '#E9e9e9'
            },
            ticks: {
              callback: function (value, index, values) {
                if (value >= 1000) {
                  return currencySymbol + (value/1000).toFixed(0) + 'k';
                } else {
                  return currencySymbol + value.toFixed(0);
                }
              },
              color: isUserAhead ? '#dddddd' : '#2c2c2c',
              maxTicksLimit: 3,
              stepSize: 50,
              font: 
          { 
            size: 8,
            
          }},
          
            },
            // stacked: true,
          
          },

        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            mode: "nearest",
            intersect: false,
            label: false,
          },
        },
      },
      });

      // Clean up the effect to avoid memory leaks
      return () => {
        chart.destroy();
      };
    }
  }, [members]);

  return (
    <div className="bar-chart-container">
      {Object.keys(members).length > 0 ? (
        <canvas className="barchartcanvas" id="groupchart" ref={canvasRef} />
      ) : (
        <div>No group data to display!<br></br>Add some purchases to see the chart.</div>
      )}
    </div>
  );
}

export default GroupSpendingChart;