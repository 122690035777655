import React, { useEffect, useRef } from 'react';
import './AllocatedChart.css';
import Chart from 'chart.js/auto';

const AllocatedChart = ({ totalBudget, discretionaryIncome, currencySymbol }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const ctx = chartRef.current.getContext('2d');
    const spentPercentage = (totalBudget / discretionaryIncome) || 0;
    const spentPercentageRounded = Math.round(spentPercentage * 100);
    const colorArray = [
      'rgb(10, 100, 90)',
      'rgb(15, 100, 90)',
      'rgb(20, 100, 80)',
      'rgb(25, 100, 70)',
      'rgb(30, 100, 60)',
      'rgb(35, 100, 50)',
      'rgb(40, 100, 40)',
      'rgb(45, 100, 30)',
      'rgb(50, 100, 20)',
      'rgb(55, 100, 10)',
      'rgb(60, 100, 0)',
      'rgb(65, 100, 0)',
      'rgb(70, 100, 0)',
      'rgb(75, 100, 0)',
      'rgb(80, 100, 0)',
      'rgb(85, 100, 0)',
      'rgb(90, 100, 0)',
      'rgb(95, 100, 0)',
      'rgb(100, 100, 0)',
      'rgb(105, 100, 0)'
    ];
    const spentPercentageColor = colorArray[Math.floor(spentPercentageRounded / (100 / colorArray.length))];
    const chart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: ['Discretionary Income'],
        display: false,
        datasets:  [
            {
                label: 'Remaining Budget',
                data: [totalBudget - discretionaryIncome],
                backgroundColor: spentPercentageColor,
                borderWidth: 0,
                stack: 'budget',
                borderRadius: Number.MAX_VALUE,
            borderSkipped: false,
              },
            {
              label: 'Total Budget',
              data: [totalBudget],
              backgroundColor: '#d8d8d8',
              borderWidth: 0,
               stack: 'budget',
               borderRadius: Number.MAX_VALUE,
               borderSkipped: false,
            },
          
          ],
      },
      options: {
      
        borderRadius:  [5, 5, 5, 5],
            borderRadiusOnAllStackedSeries: true,
       
            aspectRatio: false,
    
        indexAxis: 'y',
        responsive: true,
        scales: {
          x: {
            display: false,
            grid: {
                display: false
            },
            ticks: {
                stepSize: 1,
              display: false,
              beginAtZero: true,
              
              max: discretionaryIncome,
            },
          },
          y: {
            display: false,
            grid: {
                display: false
            },
            title: {
                
              display: false,
              
            },
            ticks: {
                display: false,
                stepSize: 1,
              beginAtZero: true,
              max: discretionaryIncome,
            },
          },
        },
        plugins: {
            tooltip: {
                enabled: false
            },
          legend: {
            display: false,
          },
        },
      },
    });

    return () => {
      chart.destroy();
    };
  }, [totalBudget, discretionaryIncome, currencySymbol]);

  return (
    <div className="allocatedchartcontainer">
      <canvas className="allocatedcanvas" ref={chartRef} />
    </div>
  );
};

export default AllocatedChart;