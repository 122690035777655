

function Terms(props) {

 

  const handleCloseClick = () => {
    props.setShowTerms(false);
  };

  return (
    <div className="termsandconditions"><div className="tncbody">
    <h1 className="tncheader">Terms and Conditions</h1>
Welcome to lilBudget, owned and operated by lilBudget, a Vermont LLC. These terms and conditions (the "Agreement") govern your use of lilBudget, and any services or features provided therein. By using lilBudget, you agree to be bound by this Agreement. If you do not agree to be bound by this Agreement, you may not use the app.

<h2 className="tncsubheader">Use of the App</h2>
You may use lilBudget only for lawful purposes and in accordance with this Agreement. You may not use lilBudget:
In any way that violates any applicable federal, state, local or international law or regulation
To transmit, or procure the sending of, any advertising or promotional material, including any "junk mail", "chain letter" or "spam" or any other similar solicitation
To impersonate or attempt to impersonate our company, a company employee, another user or any other person or entity
To engage in any other conduct that restricts or inhibits anyone's use or enjoyment of lilBudget, or which, as determined by us, may harm our company or users of lilBudget or expose them to liability
Registration
To use certain features of lilBudget, you may be required to register with us and provide certain information about yourself. You represent and warrant that all information you provide to us is truthful, accurate, and complete.

<h2 className="tncsubheader">Payment</h2>
Some features of lilBudget may be available for purchase. If you choose to purchase any of these features, you agree to pay all fees associated with your purchase.
<h2 className="tncsubheader">Ownership</h2>
Our app, including all intellectual property rights therein, belongs to us. You may not use lilBudget for any commercial purpose without our prior written consent.
<h2 className="tncsubheader">Privacy</h2>
Your use of lilBudget is subject to our Privacy Policy, which is incorporated into this Agreement by reference.
<h2 className="tncsubheader">Disclaimer of Warranties</h2>
Our app is provided "as is" and we make no warranty or representation regarding its use or performance. We expressly disclaim any warranties or representations, express or implied, including, but not limited to, any implied warranties of merchantability, fitness for a particular purpose, or non-infringement.
<h2 className="tncsubheader">Limitation of Liability</h2>
In no event shall we be liable to you or any third party for any damages arising out of or in connection with your use of lilBudget.
<h2 className="tncsubheader">Indemnification</h2>
You agree to indemnify, defend and hold us harmless from and against any and all claims, damages, losses, liabilities, costs or expenses (including reasonable attorneys' fees) arising out of or in connection with your use of lilBudget.
<h2 className="tncsubheader">Termination</h2>
We may terminate this Agreement at any time for any reason without notice. Upon termination, you must cease all use of lilBudget.
<h2 className="tncsubheader">Governing Law and Jurisdiction</h2>
This Agreement shall be governed by and construed in accordance with the laws of the state of Vermont. Any legal action or proceeding arising out of or relating to this Agreement shall be brought exclusively in the courts of Vermont.
<h2 className="tncsubheader">Changes to this Agreement</h2>
We reserve the right to modify this Agreement at any time, in our sole discretion. Your continued use of lilBudget following any such modification constitutes your agreement to be bound by the modified Agreement.

<h2 className="tncsubheader">Contact Us</h2>
If you have any questions about this Agreement or lilBudget, please contact us at info@lilbudget.me. 



    
    <h1 className="tncheader">Privacy Policy</h1>

    <h3 className="tncbody">This Privacy Policy explains how we, at lilBudget, collect, use, and protect your personal information.</h3>

    <h3 className="tncsubheader">Information we collect:</h3>
    <p>We collect only your email address when you create an account with us. The income, expense, and purchase information you enter into lilBudget are stored for your use and not used for any other purposes.</p>

    <h3 className="tncsubheader">How we use your information:</h3>
    <p>We use your email address to send you a link to reset your password if it is forgotten. Your income, expense, and purchase information are used solely to provide you with personalized budgeting information and to improve our services.</p>

    <h3 className="tncsubheader">How we protect your information:</h3>
    <p>We take the security and privacy of your personal information seriously. We use industry-standard security measures to protect your information from unauthorized access, use, or disclosure. We do not collect any other personal data nor do we sell, trade, or rent your personal information to third parties.</p>

    <h3 className="tncsubheader">Your choices:</h3>
    <p>You have the right to access, update, and correct the personal information you've provided to us. You can delete your account at any time by using the 'Delete My Account' tool in the 'Account' page. You can also request that your account be deleted by contacting info@lilbudget.me.</p>

    <h3 className="tncsubheader">Data Retention:</h3>
    <p>We retain your personal data for as long as your account is active or as necessary to provide you services. If you choose to delete your account, all your personal data is erased.</p>

    <h3 className="tncsubheader">Updates to this policy:</h3>
    <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting a notice in our app.</p>

    <h3 className="tncsubheader">Contact us:</h3>
    <p>If you have any questions or concerns about this Privacy Policy, please contact us at info@lilbudget.me.</p>

</div>








      
      <button className="tncclose" onClick={handleCloseClick}>Close</button>
    </div>
  );
}

export default Terms;