import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './DatePicker.css';

    function DatePicker({ handleDateChange }) {
        const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        const monthsOfYear = [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December',
        ];
        const [selectedDate, setSelectedDate] = useState(new Date());
      
        const handleChildDateChange = (date) => {
            if (!isFutureDate(date)) {
              setSelectedDate(date);
              handleDateChange(date);
            }
          };
      
        const monthDays = () => {
            const days = [];
            const lastDay = new Date(
              selectedDate.getFullYear(),
              selectedDate.getMonth() + 1,
              0
            ).getDate();
          
            for (let i = 1; i <= lastDay; i++) {
              days.push(i);
            }
          
            const firstDayIndex = new Date(
              selectedDate.getFullYear(),
              selectedDate.getMonth(),
              1
            ).getDay();
          
            for (let i = 0; i < firstDayIndex; i++) {
              days.unshift(null);
            }
          
            return days;
          };
          

          const isFutureDate = (date) => {
            const currentDate = new Date();
            return date.getTime() > currentDate.getTime();
          }
      
          return (
            <div className="custom-date-picker">
              <div className="custom-date-picker-header">
                <button
                  onClick={() =>
                    setSelectedDate(
                      new Date(selectedDate.getFullYear(), selectedDate.getMonth() - 1)
                    )
                  }
                >
                  &lt;
                </button>
                <h1>
                  {monthsOfYear[selectedDate.getMonth()]} {selectedDate.getFullYear()}
                </h1>
                <button
                  onClick={() =>
                    setSelectedDate(
                      new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1)
                    )
                  }
                >
                  &gt;
                </button>
              </div>
              <div className="custom-date-picker-days-of-week">
                {daysOfWeek.map((day) => (
                  <div key={day}>{day}</div>
                ))}
              </div>
              <div className="custom-date-picker-days">
                {monthDays().map((day, index) => (
                  <div
                    key={index}
                    onClick={() =>
                      day ? handleChildDateChange(new Date(selectedDate.getFullYear(), selectedDate.getMonth(), day)) : null
                    }
                    className={`
                    custom-date-picker-day
                    ${day && new Date(selectedDate.getFullYear(), selectedDate.getMonth(), day).toDateString() === selectedDate.toDateString() ? 'selected' : ''}
                    ${day === null ? 'blank' : ''}
                    ${day && isFutureDate(new Date(selectedDate.getFullYear(), selectedDate.getMonth(), day)) ? 'disabled' : ''}
                  `}
                  >
                    {day}
                  </div>
                ))}
              </div>
            </div>
          );
      }
      

            export default DatePicker;