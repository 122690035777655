import React, { useEffect, useRef, useState } from 'react';
import TinderCard from 'react-tinder-card';
import EmojiModal from "./EmojiModal";
import firebase from "firebase/compat/app";
import "firebase/compat/database";
import "firebase/compat/storage";
import {  NavLink, useNavigate, useLocation  } from 'react-router-dom';
import { FcConferenceCall  } from "react-icons/fc";
import { TbArrowBigRightFilled, TbArrowBigLeftFilled  } from "react-icons/tb";

const TinderLikeReview = React.memo(({ emojiCategories, groups, setSuccessMessage, setErrorMessage, currencySymbol, onSwipe, setShowSplashPage, fromExtension}) => {
  const [purchases, setPurchases] = useState([]);
  const [showSwipeText, setShowSwipeText] = useState(false);
  const [swipeText, setSwipeText] = useState('');
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const [swipedCard, setSwipedCard] = useState(null);
  const [swipedCards, setSwipedCards] = useState([]);
  const [worthItValue, setWorthItValue] = useState(null);
  const [showReviewedPurchases, setShowReviewedPurchases] = useState(false);
  const [reviewedPurchaseIds, setReviewedPurchaseIds] = useState([]);
  const [selectedEmoji, setSelectedEmoji] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPurchase, setSelectedPurchase] = useState(null);
  const [selectedPurchaseId, setSelectedPurchaseId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [selectedGroupName, setSelectedGroupName] = useState(null);
  const [currentGroup, setCurrentGroup] = useState(null);
  const [showGroupPurchaseModal, setShowGroupPurchaseModal] = useState(false);
  const [availableGroups, setAvailableGroups] = useState([]);
  
  const db = firebase.database();
  const navigate = useNavigate();
  const newNoteRef = useRef(null);

  const openModal = () => {
    setShowModal(true);
};

const closeModal = () => {
    setShowModal(false);
};

useEffect(() => {
  if (newNoteRef.current) {
    newNoteRef.current.focus();
  }
}, [showForm]);

const handleGroupSelect = (groupId) => {
  const groupRef = firebase.database().ref(`groups/${groupId}`);

  groupRef.once('value', snapshot => {
    const groupData = snapshot.val() || {};
    const groupName = groupData.name;

    if (groupName) {
      setSelectedGroup(groupId);
      setSelectedGroupName(groupName);
    } else {
      console.error('Group name not found.');
    }
  });
};

const handleGroupChange = (event) => {
  setCurrentGroup(event.target.value);
};

const handleCloseGroupModal = (event) => {
  setSelectedPurchaseId(null);
  setAvailableGroups([]);
  setShowGroupPurchaseModal(false);
};

const handleChangePurchaseIcon = (purchaseId) => {
  // console.log("Selected purchase ID:", purchaseId); // Added console log
  setIsLoading(true);
  const userId = firebase.auth().currentUser.uid;
  const purchasesRef = db.ref(`users/${userId}/purchases`);

  const selectedPurchase = purchases.find((purchase) => purchase.id === purchaseId);
  setSelectedPurchase(selectedPurchase);
  setSelectedPurchaseId(purchaseId);
  openModal();

  setIsLoading(false);
};
 

  const handleUpdateGroupPurchase = (purchaseId, newNote) => {
    const userId = firebase.auth().currentUser.uid;
    const groupsRef = db.ref('groups');
    groupsRef.once('value', async (snapshot) => {
      const allGroups = snapshot.val();
  
      // For each group, check if the user is a member and if so, update the purchases
      for (let groupId in allGroups) {
        const group = allGroups[groupId];
        const members = group.members || {};
        const groupPurchases = group.purchases || {};
  
        if (members[userId]) {  // If the user is a member of this group
          // Iterate over the purchases of the group
          for (let groupPurchaseId in groupPurchases) {
            const groupPurchase = groupPurchases[groupPurchaseId];
            if (groupPurchase.id === purchaseId) {  // If the group's purchase ID matches with the input purchaseId
              // Update the note for the purchase in this group
              await db.ref(`groups/${groupId}/purchases/${groupPurchaseId}`).update({ note: newNote });
            }
          }
        }
      }
    });
  };

  const handleSaveChanges = (event, purchaseId) => {
    setIsLoading(true);
    event.preventDefault();
    let newEmoji = event.target.elements.emoji.value;
    const userId = firebase.auth().currentUser.uid;
    const purchasesRef = db.ref(`users/${userId}/purchases`);
  
    if (newEmoji === "") {
      newEmoji = "No note added";
    }

    // console.log("Selected purchase ID in handleSaveChanges:", purchaseId); // Added console log
  
    purchasesRef
      .child(purchaseId)
      .update({ note: newEmoji })
      .then(() => {
        setSelectedPurchaseId(null);
        setShowForm(false);
  
        // Call handleUpdateGroupPurchase here
        handleUpdateGroupPurchase(purchaseId, newEmoji);
  
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  const handleEditNoteClick = (note, purchase) => { // Receive the whole purchase object
    setIsLoading(true);
    setSelectedPurchase(purchase); // Store the whole purchase object
    setSelectedPurchaseId(purchase.id); // Store the purchase ID
    // console.log("purchase ID in handleEditNoteClick:", purchase.id); 
    // console.log("Selected purchase ID in handleEditNoteClick:", selectedPurchaseId); 
    // Check if the current note is "📝 Add a purchase note"
    if (purchase.note === "📝 Add a purchase note") {
      // Replace the current note with an empty string
      purchase.note = "";
    }
    setShowForm(true);
    setIsLoading(false); 
  };

  const handleAddToSpecificGroup = async (purchaseId, groupId) => {
    const userId = firebase.auth().currentUser.uid;
    const userEmail = firebase.auth().currentUser.email;
    const userRef = firebase.database().ref(`users/${userId}`);
    const userSnapshot = await userRef.once('value');
    const userPurchasesRef = firebase.database().ref(`users/${userId}/purchases`);
    const selectedGroupRef = firebase.database().ref(`groups/${groupId}`);
    const selectedGroupSnapshot = await selectedGroupRef.once('value');
    const selectedGroupName = selectedGroupSnapshot.child('name').val();
  
    // Retrieve the purchase data
    const purchaseSnapshot = await userPurchasesRef.child(purchaseId).once('value');
    const purchaseData = purchaseSnapshot.val();
  
    // Prepare the purchase object
    const purchase = {
      id: purchaseId,
      category: purchaseData.category,
      icon: purchaseData.icon,
      note: purchaseData.note === '📝 Add a purchase note' ? 'No note added' : purchaseData.note,
      amount: purchaseData.amount,
      addedBy: userEmail,
      date: purchaseData.date,
      creature: userSnapshot.child('profilePicIndex').val(),
      alias: userSnapshot.child('alias').val(),
      group: selectedGroupName,
      groupId: selectedGroup // Set the group property to the selected group's name
    };
  
    // Only include worthit property if it exists
    if (purchaseData.worthit !== undefined) {
      purchase.worthit = purchaseData.worthit;
    }

    try {
      setIsLoading(true); // Show loading indicator
  
      // Add the purchase to the selected group
      await selectedGroupRef.child('purchases').push(purchase);
      await userPurchasesRef.child(purchaseId).update({ group: selectedGroupName, groupId: selectedGroup });
  
      setIsLoading(false); // Hide loading indicator
      setSuccessMessage('Purchase added to group successfully');
      setTimeout(() => {
        setSuccessMessage(null);
      }, 3000); // Show success message for 3 seconds
    } catch (error) {
      setIsLoading(false); // Hide loading indicator
      setErrorMessage('Error processing purchase: ' + error.message);
      setTimeout(() => {
        setErrorMessage(false);
      }, 3000); // Show error message for 3 seconds
      console.error('Error processing purchase:', error);
    }
  
    // Close the modal
    setSelectedPurchaseId(null);
    setAvailableGroups([]);
    setShowGroupPurchaseModal(false);
  };

  const handleAddToGroup = async (purchaseId, category, icon, note = 'No note added', amount, date, worthit) => {

    // console.log("handleAddToGroup called with purchaseId: ", purchaseId);
    // Retrieve the necessary data for the purchase
    const userId = firebase.auth().currentUser.uid;
    // console.log('Current User ID: ', userId);
    const userEmail = firebase.auth().currentUser.email;
    // console.log('Current User Email: ', userEmail);
    const userRef = firebase.database().ref(`users/${userId}`);
    const userSnapshot = await userRef.once('value');
    const userPurchasesRef = firebase.database().ref(`users/${userId}/purchases`);
    const groupsRef = firebase.database().ref('groups');
    const userAlias = userSnapshot.child('alias').val();
          const userProPic = userSnapshot.child('profilePicIndex').val();
  
    // Get the list of groups
    const snapshot = await groupsRef.once('value');
    const groups = snapshot.val();
    const groupIds = Object.keys(groups);
  
    const userGroups = Object.entries(groups).filter(([groupId, groupData]) => groupData.members && groupData.members[userId]);
    // console.log('User\'s groups: ', userGroups);
    
    // If there's only one group, proceed as before
    if (userGroups.length === 1) {
      const selectedGroup = userGroups[0][0]; // get the groupId from the first item in userGroups
      // console.log('Only one group found: ', selectedGroup);
      const selectedGroupRef = firebase.database().ref(`groups/${selectedGroup}`);
      const selectedGroupName = groups[selectedGroup].name; // Get the name of the selected group
    
      // console.log('Selected group name: ', selectedGroupName);
    
      // Check if the purchase already exists in the group
      const snapshot = await selectedGroupRef.child('purchases').once('value');
      const purchases = snapshot.val();
      const doesPurchaseExist = purchases && Object.values(purchases).some(p => p.id === purchaseId);
    
      // console.log('Does purchase exist in the group? ', doesPurchaseExist);
    
      if (doesPurchaseExist) {
        await handleRemoveFromGroup(purchaseId, selectedGroupRef); // Call the existing function to remove the purchase from the group
        // console.log('Purchase removed from the group');
        return; // Return after removing the purchase to prevent further execution
      }
    
      // Prepare the purchase object
      const purchase = {
        id: purchaseId,
        category: category,
        icon: icon,
        note: note === '📝 Add a purchase note' ? 'No note added' : note,
        amount: amount,
        addedBy: userEmail,
        date: date,
        creature: userProPic,
        alias: userAlias, 
        group: selectedGroupName,
        groupId: selectedGroup,
         
      };
               // Only include worthit property if it exists
               if (worthit !== undefined) {
                purchase.worthit = worthit;
              }

      // console.log('Prepared Purchase object: ', purchase);
    
      try {
        setIsLoading(true); // Show loading indicator
        // console.log('Loading...');
    
        // Add the purchase to the selected group
        await selectedGroupRef.child('purchases').push(purchase);
        // console.log('Purchase added to the group');
        await userPurchasesRef.child(purchaseId).update({ group: selectedGroupName, groupId: selectedGroup  });
    
        setIsLoading(false); // Hide loading indicator
        setSuccessMessage('Purchase added to group successfully');
        setTimeout(() => {
          setSuccessMessage(null);
        }, 3000); // Show success message for 3 seconds
      } catch (error) {
        setIsLoading(false); // Hide loading indicator
        setErrorMessage('Error processing purchase: ' + error.message);
        setTimeout(() => {
          setErrorMessage(false);
        }, 3000); // Show error message for 3 seconds
        console.error('Error processing purchase:', error);
      }
    } else {
      // Handle the case where there are multiple groups
      // console.log('Multiple groups found');
   
      const selectedGroupRefs = userGroups.map(([groupId]) => firebase.database().ref(`groups/${groupId}`));
      let isPurchaseInAnyGroup = false;
  
      for (const selectedGroupRef of selectedGroupRefs) {
        const snapshot = await selectedGroupRef.child('purchases').once('value');
        const purchases = snapshot.val();
        const doesPurchaseExist = purchases && Object.values(purchases).some(p => p.id === purchaseId);
        if (doesPurchaseExist) {
          isPurchaseInAnyGroup = true;
          await handleRemoveFromGroup(purchaseId, selectedGroupRef); // Call function to remove the purchase from the group
        }
      }
    
      if (!isPurchaseInAnyGroup) {
        setSelectedPurchaseId(purchaseId);
        setAvailableGroups(userGroups);
        setShowGroupPurchaseModal(true); // Show a modal (or a div) with a list of groups the user can add the purchase to
      }
    }
  };
  

  const handleRemoveFromGroup = async (purchaseId) => {
    // console.log("handleRemoveFromGroup called with purchaseId: ", purchaseId);
    const userId = firebase.auth().currentUser.uid;
    // console.log("Current User ID: ", userId);
  
    const userPurchasesRef = firebase.database().ref(`users/${userId}/purchases`);
    const groupsRef = firebase.database().ref('groups');
    const userRef = firebase.database().ref(`users/${userId}`);
    const userSnapshot = await userRef.once('value');
    const snapshot = await groupsRef.once('value');
    const groups = snapshot.val();
    const userGroups = Object.entries(groups).filter(([groupId, groupData]) => groupData.members && groupData.members[userId]);
    
    // Instead of selecting the first group in groupIds, get the first group from userGroups
    if (userGroups.length === 1) {
      const selectedGroup = userGroups[0][0];
      const selectedGroupRef = firebase.database().ref(`groups/${selectedGroup}`);
      // console.log("Selected group: ", selectedGroup);
  
      try {
        setIsLoading(true); 
        // console.log("Setting loading state to true...");
  
        const snapshot = await selectedGroupRef.child('purchases').once('value');
        const purchases = snapshot.val();
        // console.log("Purchases: ", purchases);
        
        if (purchases) {
          const keysToRemove = Object.keys(purchases).filter(key => purchases[key].id === purchaseId);
          // console.log("Keys to remove: ", keysToRemove);
  
          for (const key of keysToRemove) {
            await selectedGroupRef.child('purchases').child(key).remove();
            // console.log(`Removed key: ${key} from purchases...`);
          }
  
          await userPurchasesRef.child(purchaseId).update({ group: null,  groupId: null });
          // console.log(`Updated purchase ${purchaseId} group to null in userPurchasesRef...`);
  
          const updatedSnapshot = await selectedGroupRef.child('purchases').once('value');
          const updatedPurchases = updatedSnapshot.val();
          // console.log("Updated purchases: ", updatedPurchases);
  
          if (!updatedPurchases || !Object.values(updatedPurchases).find(p => p.id === purchaseId)) {
            setIsLoading(false); 
            // console.log("Setting loading state to false...");
            setSuccessMessage('Purchase removed from group successfully');
            setTimeout(() => {
              setSuccessMessage(null);
            }, 3000); 
          } else {
            throw new Error('Failed to remove purchase from group');
          }
        }
      } catch (error) {
        setIsLoading(false); 
        // console.log("Setting loading state to false...");
        setErrorMessage('Error processing purchase: ' + error.message);
        setTimeout(() => {
          setErrorMessage(false);
        }, 3000); 
        console.error('Error processing purchase:', error);
      }
    } else {
      // console.log("Multiple groups found");
      setIsLoading(true);

      const groupRefs = userGroups.map(([groupId]) => firebase.database().ref(`groups/${groupId}`));
      let removedFromAnyGroup = false;
    
      for (const groupRef of groupRefs) {
        const snapshot = await groupRef.child('purchases').once('value');
        const purchases = snapshot.val();
    
        if (purchases) {
          const keysToRemove = Object.keys(purchases).filter(key => purchases[key].id === purchaseId);
    
          for (const key of keysToRemove) {
            await groupRef.child('purchases').child(key).remove();
            removedFromAnyGroup = true;
          }
        }
      }
    
      if (removedFromAnyGroup) {
        await userPurchasesRef.child(purchaseId).update({ group: null });
        setIsLoading(false);
        setSuccessMessage('Purchase removed from group successfully');
        setTimeout(() => {
          setSuccessMessage(null);
        }, 3000);
      } else {
        setIsLoading(false);
        // console.log('Purchase not found in any group');
      }
    }
  };


  useEffect(() => {
    const auth = firebase.auth();
    const userId = auth.currentUser.uid;
    const purchasesRef = db.ref(`users/${userId}/purchases`);
    const groupsRef = db.ref('groups');
  
    const handleValueChange = (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const purchasesArray = Object.keys(data).map((key) => ({
            id: key,
            ...data[key],
          }));
        setPurchases(purchasesArray);
      }
    };
    
    const handleGroupChange = (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const groupKeys = Object.keys(data);
        setCurrentGroup(groupKeys[0]);
      }
    };
  
    purchasesRef.on('value', handleValueChange);
    groupsRef.on('value', handleGroupChange);
    
    return () => {
      purchasesRef.off('value', handleValueChange);
      groupsRef.off('value', handleGroupChange);
    };
  }, []);

// Calculate the remaining unswiped purchases
const unswipedPurchases = purchases.filter((purchase) => !swipedCards.includes(purchase.id));
// Check if there are remaining unswiped purchases
const hasUnswipedPurchases = unswipedPurchases.length > 0;
  const handleShowReviewedPurchases = () => {
    setShowReviewedPurchases(true);
  };
  const handleShowReviewedPurchasesOff = () => {
    setShowReviewedPurchases(false);
  };
  const handleSwipe = (purchase, direction) => {
    const uid = firebase.auth().currentUser.uid;
    const purchaseRef = db.ref(`users/${uid}/purchases/${purchase.id}/`);

    // Update the 'worthit' attribute based on the swipe direction
    const worthit = direction === 'right';
    purchaseRef.update({ worthit });

    // Update the current card index
    setCurrentCardIndex((prevIndex) => prevIndex + 1);

    // Set the swiped card
    setSwipedCard(purchase);
    setSwipedCards([...swipedCards, purchase.id]);

    let worthItValue;
    if (direction === 'right') {
      worthItValue = true;
      setSwipeText('👍 Worth it');
    } else if (direction === 'left') {
      worthItValue = false;
      setSwipeText('👎 Not Worth It');
    }

    const groupPurchasesRef = db.ref('groups');
    groupPurchasesRef.once('value', (snapshot) => {
      const allGroups = snapshot.val();
  
      for (let groupId in allGroups) {
        const group = allGroups[groupId];
        const groupPurchases = group.purchases || {};
  
        for (let groupPurchaseId in groupPurchases) {
          const groupPurchase = groupPurchases[groupPurchaseId];
          if (groupPurchase.id === purchase.id) {
            db.ref(`groups/${groupId}/purchases/${groupPurchaseId}`).update({ worthit });
          }
        }
      }
    });

    setShowSwipeText(true);
    setTimeout(() => {
      setShowSwipeText(false);
    }, 2000);

    // Add the swiped purchase ID to the reviewedPurchaseIds state
    setReviewedPurchaseIds((prevIds) => [...prevIds, purchase.id]);
  };
  
  
  

  return (
    <div className="tinder-like-review"> <div className="tinderswipeexperimental">*Experimental Feature*</div>
  {(purchases.some((purchase) => purchase.worthit === null || purchase.worthit === undefined) || showReviewedPurchases) && (
  
  <div className="tinderheader">
   
    <div className="tinderswipeleft"><TbArrowBigLeftFilled /> <br />Swipe left if the purchase wasn't worth it.<br></br>👎</div>
    <div className="tinderswiperight"><TbArrowBigRightFilled /><br />Swipe right if the purchase was worth it.<br></br>👍</div>
  </div>
)}




{/* <select className="tindergroupselect" value={currentGroup} onChange={handleGroupChange}>
  {Object.keys(groups).map((groupId) => (
    <option key={groupId} value={groupId}>
      {groups[groupId].name}
    </option>
  ))}
</select> */}
  
      {showSwipeText && (
  <div className="swipe-text-container pressable">
    {worthItValue ? (
      <div className="worthittrue pressable">{swipeText}</div>
    ) : (
      <div className="worthitfalse2 pressable">{swipeText}</div>
    )}
  </div>
)}

{showModal && (
                    <div className="emojimodalcontainer pressable">
                        <div className="confirmation-message pressable">
                            <EmojiModal
                                showModal={showModal}
                                emojiCategories={emojiCategories}
                                selectedEmoji={selectedEmoji}
                                setSelectedEmoji={setSelectedEmoji}
                                purchaseId={selectedPurchaseId}
                                closeModal={closeModal}
                             
                            />
                        </div>
                    </div>
                )}

{showForm && (
  <div className="emojimodal pressable">
    <div className="resetconfirmbottom pressable">
      <div className="divaccent4 pressable"></div>
      <form
        className="newemojiform pressable"
        onSubmit={(event) => handleSaveChanges(event, selectedPurchaseId)} // Pass the purchaseId directly
      >
        <p className="emojiheader pressable">Add Note</p>
        <textarea
        ref={newNoteRef}
          className="newemoji pressable"
          type="textarea"
          name="emoji"
          defaultValue={selectedPurchase ? selectedPurchase.note : ""}
          maxLength={65}
        />
        <div className="emojibuttons pressable">
          <button className="confirmation-okay-dark pressable" type="submit pressable">
            Save & Close
          </button>
        </div>
      </form>
    </div>
  </div>
)}
{showReviewedPurchases && hasUnswipedPurchases && purchases.filter((purchase) => purchase.worthit !== null && purchase.worthit !== undefined).length > 0 ? (
        <div className="reviewed-purchases pressable">
        
          {purchases
            .filter((purchase) => purchase.worthit !== null && purchase.worthit !== undefined)
            .map((purchase) => (
              <TinderCard
              key={purchase.id}
              className="tinder-card pressable"
              onSwipe={(dir) => handleSwipe(purchase, dir)}
              onCardLeftScreen={() => {}}
            >
              {!showGroupPurchaseModal && <div className="tinder-card-content pressable">
                <h2 className="tinder-card-title pressable">{purchase.category}</h2>
                <h2 className="tinder-card-icon pressable" onClick={() => handleChangePurchaseIcon(purchase.id)}>{purchase.icon}  {purchase.worthit !== undefined && (
                  <div>
                    {purchase.worthit ? (
                      <h2 className="tinder-card-worthit worthittrue">👍</h2>
                    ) : (
                      <h2 className="tinder-card-worthit worthitfalse">👎</h2>
                    )}
                  </div>
                )}</h2>
                <h3 className="tinder-card-price pressable">
                  {currencySymbol}
                  {purchase.amount}
                </h3>
                <h2 className="tinder-card-note pressable" onClick={() => {
                  // Set the selectedPurchaseId first
                  handleEditNoteClick(purchase.note, purchase); // Then call handleEditNoteClick
                }}>{purchase.note}</h2>
              
              {groups && groups.length > 0 ? (<button
  className="tinderaddtogroup pressable"
  onClick={() => handleAddToGroup(purchase.id, purchase.category, purchase.icon, purchase.note, purchase.amount, purchase.date, purchase.worthit)}
  disabled={isLoading}
>
  {isLoading 
    ? 'Working...' 
    : (groups.find((group) => group.name === purchase.group) 
        ? (
          <>
            <div className="addtogroupcontainer pressable"><FcConferenceCall style={{fontSize: "2.2rem", lineHeight: "1rem"}} /> {purchase.group}</div>
          </>
        )
        : (
          <>
             <div className="addtogroupcontainer pressable"><FcConferenceCall  style={{fontSize: "2.2rem", lineHeight: "1rem"}}/> Add to Group</div>
          </>
        )
      )
  }
</button>) : null}
              </div>}
              {showGroupPurchaseModal && <div className="grouppurchasebuttonmodal pressable" id="modal"><div className="grouppurchasebuttonmodalheader">Add purchase to which group?</div>
    {availableGroups.map(([groupId, groupData]) => 
      <button className="selectgroupbutton pressable" key={groupId} onClick={() => handleAddToSpecificGroup(selectedPurchaseId, groupId)}>
        {groupData.name}
      </button>
    )}
  </div>}
            </TinderCard>
            ))}
        </div> ) : (
  <div className="empty-state">
    {showReviewedPurchases && <p className="tinderreviewemptystate pressable">No more purchases to review!</p>}
  </div>
)}  
      {!showReviewedPurchases && purchases.length && (
        purchases
          .filter((purchase) => purchase.worthit === null || purchase.worthit === undefined)
          .map((purchase) => (
            <TinderCard
              key={purchase.id}
              className="tinder-card pressable"
              onSwipe={(dir) => handleSwipe(purchase, dir)}
              onCardLeftScreen={() => {}}
            >
              {!showGroupPurchaseModal && <div className="tinder-card-content pressable">
                <h2 className="tinder-card-title pressable">{purchase.category}</h2>
                <h2 className="tinder-card-icon pressable" onClick={() => handleChangePurchaseIcon(purchase.id)}>{purchase.icon}  {purchase.worthit !== undefined && (
                  <div>
                    {purchase.worthit ? (
                      <h2 className="tinder-card-worthit worthittrue pressable">👍</h2>
                    ) : (
                      <h2 className="tinder-card-worthit worthitfalse pressable">👎</h2>
                    )}
                  </div>
                )}</h2>
                <h3 className="tinder-card-price pressable ">
                  {currencySymbol}
                  {purchase.amount}
                </h3>
                <h2 className="tinder-card-note pressable" onClick={() =>  handleEditNoteClick(purchase.note, purchase)}>{purchase.note}</h2>
                {purchase.worthit !== undefined && (
                  <div>
                    {purchase.worthit ? (
                      <h2 className="tinder-card-worthit worthittrue">👍</h2>
                    ) : (
                      <h2 className="tinder-card-worthit worthitfalse">👎</h2>
                    )}
             
                  </div>
                )}
                 {groups && groups.length > 0 ? (<button
  className="tinderaddtogroup pressable"
  onClick={() => handleAddToGroup(purchase.id, purchase.category, purchase.icon, purchase.note, purchase.amount, purchase.date, purchase.worthit)}
  disabled={isLoading}
>
  {isLoading 
    ? 'Working...' 
    : (groups.find((group) => group.name === purchase.group) 
        ? (
          <>
            <div className="addtogroupcontainer pressable"><FcConferenceCall className="pressable" style={{fontSize: "2.2rem", lineHeight: "1rem"}} /> {purchase.group}</div>
          </>
        )
        : (
          <>
             <div className="addtogroupcontainer pressable"><FcConferenceCall  className="pressable" style={{fontSize: "2.2rem", lineHeight: "1rem"}}/> Add to Group</div>
          </>
        )
      )
  }
</button>) : null}
              </div>}
              {showGroupPurchaseModal && <div className="grouppurchasebuttonmodal pressable" id="modal"><div className="grouppurchasebuttonmodalheader">Add purchase to which group?</div>
    {availableGroups.map(([groupId, groupData]) => 
      <button className="selectgroupbutton pressable" key={groupId} onClick={() => handleAddToSpecificGroup(selectedPurchaseId, groupId)}>
        <FcConferenceCall  style={{fontSize: "1.2rem", lineHeight: "1.2rem", marginRight: "5px"}}/>{groupData.name}
      </button>
    )}
  <button className="confirmation-no pressable" onClick={() => handleCloseGroupModal()}>Cancel</button></div>}
            </TinderCard>
          ))
      )}

      <div className="tinderheader2"><div
              className="closetinderbutton2"
              onClick={() => {
                navigate('/', { state: { fromExtension: true } });
              }}
            >
              <NavLink to="/" className="tinder-close-profile-nav">
                Done Reviewing
              </NavLink>
            </div></div>
      {!showReviewedPurchases && purchases.every((purchase) => purchase.worthit !== null && purchase.worthit !== undefined) && ( <div className="tindernounreviewed">All purchases have been reviewed!
  <button className="emptystatebutton" onClick={handleShowReviewedPurchases}>View previously reviewed purchases</button>
            </div>
)}
      {showReviewedPurchases && <div className="tinderfooter"><button className="hidereviewed" onClick={handleShowReviewedPurchasesOff}>Hide Reviewed Purchases</button> 
            </div>}
    </div>
  );
});

export default TinderLikeReview;
