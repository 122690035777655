import React, { useEffect, useRef, useState } from 'react';
import firebase from "firebase/compat/app";
import "firebase/compat/database";
import "firebase/compat/storage";
import "./SavingsGoals.css"
import "./PaydayReview.css"
import GoalProgress from "./GoalProgress";
import GoalEmojiModal from "./GoalEmojiModal";
import { IoIosArrowBack, IoIosClose } from "react-icons/io";

const SavingsGoals = ({emojiCategories, sortedBudgets, setShowInputWidget, currencySymbol, containerRef, triggerRefetch}) => {
    const [income, setIncome] = useState(0);
    const [expenses, setExpenses] = useState(0);
    const [totalSpent, setTotalSpent] = useState(0);
    const [remainingIncome, setRemainingIncome] = useState(0);
    const [originalSavings, setOriginalSavings] = useState(0);
    const [savings, setSavings] = useState(0);
    const [totalAllowances, setTotalAllowances] = useState(0);
    const [savingsGoals, setSavingsGoals] = useState([]);
    const [selectedGoal, setSelectedGoal] = useState(null);
const [updatedPercentage, setUpdatedPercentage] = useState(0);
const [showAddGoalForm, setShowAddGoalForm] = useState(false);
const [newGoalName, setNewGoalName] = useState("");
const [newGoalTarget, setNewGoalTarget] = useState("");
const [newGoalIcon, setNewGoalIcon] = useState('💰');
const [showPercentSlider, setShowPercentSlider] = useState(false);
const [showEmojiModal, setShowEmojiModal] = useState(false);
const [showAddnDeleteGoal, setShowAddnDeleteGoal] = useState(false);
const [savingsGoalsExist, setSavingsGoalsExist] = useState(false);
const [showPercentSliderIndexes, setShowPercentSliderIndexes] = useState([]);
const [totalAmountAllocated, setTotalAmountAllocated] = useState(0);
const [prevSpent, setPrevSpent] = useState(null);
const [prevAllowances, setPrevAllowances] = useState(null);
const [editGoal, setEditGoal] = useState(null);
const [confirmDelete, setConfirmDelete] = useState(false);
const [isGoalAchieved, setIsGoalAchieved] = useState(false);
const [showAchievedGoalModal, setShowAchievedGoalModal] = useState(false);
const [selectedAchievedGoal, setSelectedAchievedGoal] = useState(null);
const [achievedGoals, setAchievedGoals] = useState([]);
const [deletingGoalId, setDeletingGoalId] = useState(null);



const tileRef = useRef();
const newGoalRef = useRef();
const goalRef = useRef();
const goalDeleteRef = useRef();
const goalEditRef = useRef();
const editGoalRef = useRef();
const achievedGoalRef = useRef();





useEffect(() => {
  if (selectedGoal) {
    setNewGoalName(selectedGoal.name);
    setNewGoalTarget(selectedGoal.target);
    setNewGoalIcon(selectedGoal.icon);
  }
}, [selectedGoal]);

useEffect(() => {
    const isAnyTileOpen = showPercentSliderIndexes.length > 0;
  
    if (isAnyTileOpen) {
      containerRef.current.classList.add('no-scroll');
    } else {
      containerRef.current.classList.remove('no-scroll');
    }
  
    return () => {
      // Cleanup code (if needed)
    };
  }, [showPercentSliderIndexes]);

//   useEffect(() => {
//     const timer = setTimeout(() => {
//         setShowPercentSliderIndexes([]);
//         fetchData();
//     }, 200);

//     // Cleanup: clear the timer if the component unmounts before the timer ends
//     return () => clearTimeout(timer);
// }, []);

const handleUpdateSavingsGoal = async (id, name, target) => {
  const userId = firebase.auth().currentUser.uid;
  const goalRef = firebase.database().ref(`users/${userId}/savingsGoals/${id}`);

  // Assuming that percent of the savings allocated to the goal remains the same.
  const updatedAmountAllocated = (selectedGoal.percent / 100) * savings;
  const progress = selectedGoal.startingAmount + updatedAmountAllocated;

  const updatedGoal = {
    name,
    target,
    percent: selectedGoal.percent,
    startingAmount: selectedGoal.startingAmount,
    amountAllocated: updatedAmountAllocated,
    progress,
    icon: newGoalIcon || selectedGoal.icon, // Assume the icon stays the same.
    createdDate: selectedGoal.createdDate, // Assume the created date stays the same.
  };

  await goalRef.update(updatedGoal);

  const updatedSavingsGoals = savingsGoals.map((goal) => {
    if (goal.id === id) {
      return { ...goal, ...updatedGoal };
    }
    return goal;
  });

  setSavingsGoals(updatedSavingsGoals);

  const remainingSavings = savings - updatedAmountAllocated;
  setSavings(remainingSavings);
};

const handleEditGoalFormSubmit = (event) => {
  event.preventDefault();
  handleUpdateSavingsGoal(selectedGoal.id, newGoalName, newGoalTarget);
  setShowAddGoalForm(false);
  setShowAddnDeleteGoal(false);
  setNewGoalName("");
  setNewGoalTarget("");
  setEditGoal(null);
};



  useEffect(() => {
    // Initialize the showPercentSliderIndexes array with all indexes initially
    const initialShowIndexes = savingsGoals.map(() => false);
    setShowPercentSliderIndexes(initialShowIndexes);
    setShowPercentSliderIndexes([]);
  }, [savingsGoals]);

  const calculateMaxAllowablePercentage = () => {
    let totalUsedPercentage = savingsGoals.reduce((total, goal) => total + goal.percent * 100, 0); // multiply by 100

    if (selectedGoal) {
        totalUsedPercentage -= selectedGoal.percent * 100; // multiply by 100
    }

    let maxAllowablePercentage = Math.max(0, 10000 - totalUsedPercentage); // max 10000 considering factor of 100
    let maxAllowablePercentageForCalculation = parseFloat((maxAllowablePercentage / 100).toFixed(2));  // divide by 100 and keep upto 2 decimal places

    if (selectedGoal) {
        let targetAchievementPercentage = (selectedGoal.percent / selectedGoal.target) * 100;
        let remainingGoal = selectedGoal.target - selectedGoal.startingAmount;
        let progressPercentage = (selectedGoal.progress / originalSavings) * 100;
        let remainingGoalPercentage = (remainingGoal / originalSavings) * 10000; // multiply by 100

        if (remainingGoalPercentage < maxAllowablePercentage) {
            maxAllowablePercentage = Math.min(10000, remainingGoalPercentage);  // add 1 (100 considering factor of 100), but don't exceed 10000
            maxAllowablePercentageForCalculation = parseFloat((maxAllowablePercentage / 100).toFixed(2)); // divide by 100 and keep upto 2 decimal places
        }

        // console.log(`The goal progress is equal to the remaining goal amount at ${remainingGoalPercentage}% of the original savings.`);
        // console.log(`The selected goal target is ${selectedGoal.target}`);
        // console.log(`The selected goal percent is ${selectedGoal.percent}`);
        // console.log(`The selected goal startingamount is ${selectedGoal.startingAmount}`);
        // console.log(`The selected goal progress is ${selectedGoal.progress}`);
        // console.log(`The target achievement percentage for the selected goal is ${targetAchievementPercentage}%`);
    }
    return {maxAllowablePercentageForCalculation, maxAllowablePercentage};  // return both the values
};

const handleSliderChange = (event, newValue) => {
  let maxValues = calculateMaxAllowablePercentage();
  let maxPercentage = maxValues.maxAllowablePercentageForCalculation;

  // Check if newValue is within 1% of the maxPercentage
  if (Math.abs(newValue - maxPercentage) <= 1) {
      // If it is, use the full number to two decimals
      setUpdatedPercentage(parseFloat(newValue.toFixed(2)));
  } else {
      // If it's not, round to the nearest integer
      setUpdatedPercentage(Math.round(newValue));
  }
};

const handleToggleEmojiModal = () => {
  setShowEmojiModal(!showEmojiModal);
};

const handleConfirmDeleteGoal = (goalId) => {
  setDeletingGoalId(goalId);
};

const handleCancelConfirmDeleteGoal = () => {
  setDeletingGoalId(null);
};

const handleShowAchievedGoalModal = (goalId) => {
  const achievedGoal = savingsGoals.find(goal => goal.id === goalId);
  setSelectedAchievedGoal(achievedGoal);
  setShowAchievedGoalModal(true);
};

const handleGoalIconUpdate = (icon) => {
  setNewGoalIcon(icon); // Update the selected icon
};

const handleToggleAddnRemove = () => {
    setShowAddnDeleteGoal(!showAddnDeleteGoal);
};

const handleSliderCompletion = async () => {
    const userId = firebase.auth().currentUser.uid;
    const goalRef = firebase.database().ref(`users/${userId}/savingsGoals/${selectedGoal.id}`);

    const oldAmountAllocated = selectedGoal.amountAllocated;
    const newAmountAllocated = (updatedPercentage / 100) * originalSavings;
    const difference = newAmountAllocated - oldAmountAllocated;

    const updatedGoal = {
        ...selectedGoal,
        percent: updatedPercentage,
        amountAllocated: newAmountAllocated,
        progress: selectedGoal.startingAmount + newAmountAllocated,
    };

    await goalRef.set(updatedGoal);

    const updatedGoals = savingsGoals.map((goal) =>
        goal.id === updatedGoal.id ? updatedGoal : goal
    );
    setSavingsGoals(updatedGoals);

    // update savings
    const newSavings = originalSavings - difference;
    setSavings(newSavings);

    // clear selectedGoal and updatedPercentage
    setSelectedGoal(null);
    setUpdatedPercentage(null);
    setShowPercentSlider(false);
    setShowPercentSliderIndexes([]);
};

const fetchData = async () => {
    const userId = firebase.auth().currentUser.uid;

    // Fetch all necessary data
    const incomeAndExpenses = await fetchIncomeAndExpenses(userId);
    const totalSpent = await fetchAndCalculateTotalSpent(userId);
    const totalAllowances = await fetchAndCalculateTotalAllowances(userId);
    
    // Perform calculations with fetched data
    const remainingIncome = incomeAndExpenses.income - incomeAndExpenses.totalExpenses;
    const toSubtract = Math.max(totalSpent, totalAllowances);
    const calculatedOriginalSavings = remainingIncome - toSubtract;

    // Set states
    setIncome(incomeAndExpenses.income);
    setExpenses(incomeAndExpenses.totalExpenses);
    setRemainingIncome(remainingIncome);
    setOriginalSavings(calculatedOriginalSavings);
    setTotalSpent(totalSpent);
    setTotalAllowances(totalAllowances);
    
    // Fetch savings goals after all the data has been set
    fetchSavingsGoals(userId);
};

useEffect(() => {
  fetchData();
}, [triggerRefetch]);
    
const fetchIncomeAndExpenses = async (userId) => {
  const userRef = firebase.database().ref(`users/${userId}`);
  const snapshot = await userRef.once('value');
  const user = snapshot.val();

  setIncome(user.income);

  let totalExpenses = 0;
  if (user.expenses && Array.isArray(user.expenses)) {
      for (let expense of user.expenses) {
          totalExpenses += parseFloat(expense.amount);
      }
  } else {
      // console.log(`No expenses found for user ${userId}`);
  }

  return { income: user.income, totalExpenses };
};
  
      const fetchAndCalculateTotalSpent = async (userId)  => {
        // const userId = firebase.auth().currentUser.uid;
        const userRef = firebase.database().ref(`users/${userId}/purchases`);
        const snapshot = await userRef.once('value');
        const purchases = snapshot.val();
        let total = 0;
    for (let purchaseId in purchases) {
        total += parseFloat(purchases[purchaseId].amount);
    }
    return total;
};

      const fetchAndCalculateTotalAllowances = async (userId)  => {
        // const userId = firebase.auth().currentUser.uid;
        const userRef = firebase.database().ref(`users/${userId}/categories`);
        const snapshot = await userRef.once('value');
        const categories = snapshot.val();
        let total = 0;
        for (let category in categories) {
            total += parseFloat(categories[category]);
        }
        return total;
    };

    const handleRemoveSavingsGoal = async (goalId) => {
      const userId = firebase.auth().currentUser.uid;
      const userRef = firebase.database().ref(`users/${userId}/savingsGoals/${goalId}`);
    
      const snapshot = await userRef.once('value');
      const goalToRemove = snapshot.val();
    
      await userRef.remove();
    
      let remainingSavings = savings + goalToRemove.amountAllocated; // Add the allocated amount back to savings
      setSavings(remainingSavings);
    
      const isGoalAchieved = savingsGoals.find((goal) => goal.id === goalId)?.isGoalAchieved;
    
      if (isGoalAchieved) {
        const achievedGoalsRef = firebase.database().ref(`users/${userId}/achievedGoals`);
        const newAchievedGoalRef = achievedGoalsRef.push(); // creates a new achieved goal node
    
        const newAchievedGoal = {
          ...goalToRemove,
          id: newAchievedGoalRef.key, // assign a new key for the achieved goal
          dateAdded: new Date().toLocaleDateString(), // add the current date
        };
    
        newAchievedGoalRef.set(newAchievedGoal);
        // console.log("Achieved goal moved to 'achievedGoals' node");
      } else {
        // console.log("Goal removed");
      }
    
      const updatedGoals = savingsGoals.filter((goal) => goal.id !== goalId);
      setDeletingGoalId(null);
      setSavingsGoals(updatedGoals);
    };
    

      useEffect(() => {
        const userId = firebase.auth().currentUser.uid; // Get the current user's ID
        const savingsGoalsRef = firebase.database().ref(`users/${userId}/savingsGoals`);
      
        const fetchSavingsGoals = (snapshot) => {
          const savingsGoals = snapshot.val();
          // console.log('savingsGoals:', savingsGoals); // Check the value of savingsGoals
      
          if (savingsGoals && Object.keys(savingsGoals).length > 0) {
            setSavingsGoalsExist(true);
          } else {
            setSavingsGoalsExist(false);
          }
        };
      
        savingsGoalsRef.on('value', fetchSavingsGoals); // Set up listener
      
        // Clean up the listener when the component is unmounted
        return () => {
          savingsGoalsRef.off('value', fetchSavingsGoals);
        };
      }, []);

      const fetchSavingsGoals = async (userId) => {
        const goalsRef = firebase.database().ref(`users/${userId}/savingsGoals`);
        const snapshot = await goalsRef.once('value');
        const goals = snapshot.val();
        
        if (goals) {
          const goalsArray = Object.entries(goals).map(async ([id, value]) => {
            // Check if startingAmount is within half of 1 integer away from the target
            const isGoalAchieved = Math.abs(value.startingAmount - value.target) <= 0.5;
            
            if (isGoalAchieved) {
              value.percent = 0;
              value.amountAllocated = 0;
      
              // Update values in the Firebase database
              await goalsRef.child(id).update({
                percent: 0,
                amountAllocated: 0
              });
            }
            
            return {
              id,
              ...value,
              isGoalAchieved, // store this property within each goal
            };
          });
      
          // We are using Promise.all to make sure all updates are done before updating the state
          const updatedGoalsArray = await Promise.all(goalsArray);
          setSavingsGoals(updatedGoalsArray);
          
          let totalAllocated = 0;
          for (let goal of updatedGoalsArray) {
            totalAllocated += goal.amountAllocated;
          }
          
          let newSavings = originalSavings - totalAllocated;
          setSavings(newSavings);
          setTotalAmountAllocated(totalAllocated); // Update the total amountAllocated state
        } else {
          // No goals found, set savings to originalSavings
          setSavings(originalSavings);
          setTotalAmountAllocated(0); // Set total amountAllocated to 0
        }
      };


      useEffect(() => {
        // Create a new array to store the updated goals
        let updatedGoals = [...savingsGoals];
      
        // Track the new total amount allocated
        let newTotalAmountAllocated = 0;
      
        // Loop through each goal
        for (let goal of updatedGoals) {
          // Recalculate amountAllocated based on the new originalSavings
          goal.amountAllocated = (goal.percent / 100) * originalSavings;
          newTotalAmountAllocated += goal.amountAllocated;
      
          // Recalculate progress and ensure it never goes below zero
          let newProgress = goal.startingAmount + goal.amountAllocated;
          goal.progress = newProgress >= 0 ? newProgress : 0;
      
          // Update the goal in the database
          firebase
            .database()
            .ref(`users/${firebase.auth().currentUser.uid}/savingsGoals/${goal.id}`)
            .update({ amountAllocated: goal.amountAllocated, progress: goal.progress });
        }
      
        // Update the goals in state
        setSavingsGoals(updatedGoals);
      
        // Update the total amount allocated
        setTotalAmountAllocated(newTotalAmountAllocated);
      
        // Recalculate savings
        let newSavings = originalSavings - newTotalAmountAllocated;
        setSavings(newSavings);
    }, [originalSavings, totalSpent, totalAllowances]); // Listen for changes to originalSavings
    
      

      
      useEffect(() => {
        const calculateSavings = () => {
          const toSubtract = Math.max(totalSpent, totalAllowances);
          const updatedSavings = remainingIncome - toSubtract;
          setSavings(updatedSavings);
        };
        calculateSavings();
      }, [remainingIncome, totalSpent, totalAllowances]);

      const handleAddSavingsGoal = async (name, target, percent) => {
        const userId = firebase.auth().currentUser.uid;
        const userRef = firebase.database().ref(`users/${userId}/savingsGoals`);
        
      
        const startingAmount = 0;
        const amountAllocated = (percent / 100) * originalSavings;
      
        const progress = startingAmount + amountAllocated;
        const icon = newGoalIcon; // use newGoalIcon instead of hardcoding the icon
        const createdDate = new Date().toISOString();
      
        const newGoal = { 
            name, 
            target, 
            percent, 
            startingAmount, 
            amountAllocated, 
            progress, 
            icon, 
            createdDate 
        };
      
        const goalRef = await userRef.push(newGoal);
      
        newGoal.id = goalRef.key;
        setSavingsGoals([...savingsGoals, newGoal]);
      
        let remainingSavings = savings - amountAllocated;
        setSavings(remainingSavings);
    };

    const handleAddGoalFormSubmit = (event) => {
        event.preventDefault();
        handleAddSavingsGoal(newGoalName, newGoalTarget, ""); // Initialize the percentage to 5
        setShowAddGoalForm(false);
        setShowAddnDeleteGoal(false);
        setNewGoalName("");
        setNewGoalTarget("");
      };

      const handleCloseGoalForm = (event) => {// Initialize the percentage to 5
        setShowAddGoalForm(false);
        setShowAddnDeleteGoal(false);
      };


      const handleClickOutsideAchievedGoal = (event) => {
        if (achievedGoalRef.current && !achievedGoalRef.current.contains(event.target)) {
          setShowAchievedGoalModal(false);
        }
    };

      const handleClickOutside = (event) => {
        if (tileRef.current && !tileRef.current.contains(event.target)) {
            setShowPercentSliderIndexes([]);
        }
    };

    const handleClickOutside2 = (event) => {
      if (goalRef.current && !goalRef.current.contains(event.target) &&
      goalDeleteRef.current && !goalDeleteRef.current.contains(event.target) &&
      editGoalRef.current && !editGoalRef.current.contains(event.target) &&
      goalEditRef.current && !goalEditRef.current.contains(event.target)  ) {
        setShowAddnDeleteGoal(false);
      }
  };
;

useEffect(() => {
  document.addEventListener("mousedown", handleClickOutside2);
  return () => {
      document.removeEventListener("mousedown", handleClickOutside2);
  };
}, []);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);


    useEffect(() => {
      document.addEventListener("mousedown", handleClickOutsideAchievedGoal);
      return () => {
          document.removeEventListener("mousedown", handleClickOutsideAchievedGoal);
      };
  }, []);

      const handleTogglePercentSlider = (index) => {
        if (showPercentSliderIndexes.includes(index)) {
          setShowPercentSliderIndexes([]);
        } else {
          setShowPercentSliderIndexes([index]);
        }
      };

      useEffect(() => {
        if (newGoalRef.current) {
          newGoalRef.current.focus();
        }
      }, [showAddGoalForm]);


      useEffect(() => {
        const calculateTotalAmountAllocated = () => {
          let totalAllocated = 0;
          for (let goal of savingsGoals) {
            totalAllocated += goal.amountAllocated;
          }
          setTotalAmountAllocated(totalAllocated);
        };
      
        calculateTotalAmountAllocated();
      }, [savingsGoals]);

      // console.log('showAddnDeleteGoal:', showAddnDeleteGoal);
      // console.log('savingsGoalsExist:', savingsGoalsExist);
      // console.log('originalSavings:', originalSavings);


       
useEffect(() => {
  const auth = firebase.auth();
  const userId = auth.currentUser.uid;
  const achievedGoalsRef = firebase.database().ref(`users/${userId}/achievedGoals`); // Added this line



 

  achievedGoalsRef.once('value', (snapshot) => {
    if (snapshot.exists()) {
      const achievedGoalsData = snapshot.val();
      const achievedGoals = Object.keys(achievedGoalsData).map((key) => ({
        ...achievedGoalsData[key],
        id: key,
      }));
      setAchievedGoals(achievedGoals); // Assumes you have a useState for achievedGoals
    } else {
      setAchievedGoals([]);
    }
  });


  


  const achievedGoalsListener = achievedGoalsRef.on('value', (snapshot) => {
    if (snapshot.exists()) {
      const achievedGoalsData = snapshot.val();
      const achievedGoals = Object.keys(achievedGoalsData).map((key) => ({
        ...achievedGoalsData[key],
        id: key,
      }));
      setAchievedGoals(achievedGoals);
    } else {
      setAchievedGoals([]);
    }
  });


  // Clean up the listeners when the component is unmounted
  return () => {
    achievedGoalsRef.off('value', achievedGoalsListener);
  };
}, []);

    return (
        <div  className="savingsgoalscontainer">




{editGoal && selectedGoal && (
  <form onSubmit={handleEditGoalFormSubmit}>
    <div ref={editGoalRef} className="confirmation-message">
      <div className="resetconfirmbottom">
        <div className="divaccent4"></div>
        <GoalEmojiModal
          showModal={showEmojiModal}
          emojiCategories={emojiCategories}
          selectedEmoji={selectedGoal.icon} 
          handleEmojiClick={handleGoalIconUpdate} 
          closeModal={() => setShowEmojiModal(false)}
        />
        <div className="createnewgroupformheader1">Edit Savings Goal</div>
        <div className="buttons-container">
          <div className="inputandiconcontainer">
          <div className="savingsicon" onClick={handleToggleEmojiModal}>
  {newGoalIcon || selectedGoal.icon}
</div>
            <input
              ref={newGoalRef}
              type="text"
              value={newGoalName}
              onChange={(event) => setNewGoalName(event.target.value)}
              placeholder="What are you saving for?"
              maxLength={40}
              className="group-input3"
            />
          </div>
          <div className="goaltargetinputcontainer">
            <div className="goaltargetlabel">Goal Target:</div>
            <div className="goaltargetamount">
              <input
                type="number"
                value={newGoalTarget}
                onChange={(event) => setNewGoalTarget(Number(event.target.value))}
                placeholder={currencySymbol}
                className="group-input2"
              />
            </div>
          </div>
          {/* <div className="boxoballs2">
            <div className="ball1"></div>
            <div className="ball2"></div>
            <div className="ball3"></div>
            <div className="ball4"></div>
          </div> */}
          <div className="yesno">
            <button
              type="submit"
              className={`confirmation-okay-dark ${
                selectedGoal.name.trim().length === 0 || String(selectedGoal.target).length === 0
                  ? 'disabled'
                  : ''
              }`}
              onClick={() => setShowInputWidget(true)}
              disabled={selectedGoal.name.trim().length === 0 || String(selectedGoal.target).length === 0}
            >
              Edit Savings Goal
            </button>
            <button
              className="confirmation-no-dark"
              onClick={() => {setEditGoal(false); setShowInputWidget(true);} }
            >
              <IoIosClose style={{fontSize: "1.5rem"}}/>
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
)}










{showAddGoalForm &&
 <div className="confirmation-message">
<div className="resetconfirmbottom">
    <div className="divaccent4"></div>
    <GoalEmojiModal
          showModal={showEmojiModal}
          emojiCategories={emojiCategories}
          selectedEmoji={newGoalIcon} // Pass the selected icon to the modal
          handleEmojiClick={handleGoalIconUpdate} // Pass the update function as prop
          closeModal={() => setShowEmojiModal(false)}
        />
   <div className="createnewgroupformheader1">Create a Savings Goal</div>
    <div className="buttons-container">
    {/* {newGroupName.trim().length === 0 && (
      <p className="error-message-invite-dark">Name cannot be blank</p>
    )} */}
    <form onSubmit={handleAddGoalFormSubmit}>
        <div className="inputandiconcontainer">
        <div className="savingsicon" onClick={handleToggleEmojiModal}>{newGoalIcon}</div>
       <input
       ref={newGoalRef}
        type="text"
        value={newGoalName}
        onChange={(event) => setNewGoalName(event.target.value)}
        placeholder="What are you saving for?"
        maxLength={40}
        className="group-input3"
      /></div>
      <div className="goaltargetinputcontainer">
      <div className="goaltargetlabel">Goal Target:</div>
      <div className="goaltargetamount">
      <input
        type="number"
        value={newGoalTarget}
        onChange={(event) => setNewGoalTarget(Number(event.target.value))}
        placeholder={currencySymbol}
        className="group-input2"
      /></div></div>
        {/* <div className="boxoballs2">
            <div className="ball1"></div>
            <div className="ball2"></div>
            <div className="ball3"></div>
            <div className="ball4"></div>
        </div> */}
      <div className="yesno">
      <button
    type="submit"
    className={`confirmation-okay-dark ${
      newGoalName.trim().length === 0 || String(newGoalTarget).length === 0
        ? 'disabled'
        : ''
    }`}
    onClick={() => setShowInputWidget(true)}
    disabled={newGoalName.trim().length === 0 || String(newGoalTarget).length === 0}
  >
    Create Savings Goal
  </button>
        
            <button
            
                className="confirmation-no-dark"
                onClick={() => {setShowAddGoalForm(false); setShowInputWidget(true);} }
            >
                <IoIosClose style={{fontSize: "1.5rem"}}/>
            </button>
        </div>
        </form>
    </div>
</div>
</div>}



{showAchievedGoalModal && selectedAchievedGoal &&
<div className="savingsachievedbackground">
<div ref={achievedGoalRef} className="savingsachievedmodal">
  <h2 style={{fontSize: "1.4rem"}}>Goal Achieved!</h2>
  <p className="achievedgoallineitem" style={{color: "rgb(6, 59, 53)", fontWeight: "700", fontSize: "1.2rem"}}>{selectedAchievedGoal.name}</p>
  <p className="achievedgoallineitem" style={{fontSize: "2rem"}}>{selectedAchievedGoal.icon}</p>
  <p className="achievedgoallineitem" style={{color: "rgb(6, 59, 53)", fontWeight: "700", fontSize: "1.2rem"}}>{currencySymbol}{selectedAchievedGoal.target} / {currencySymbol}{selectedAchievedGoal.target}</p>
  <p className="achievedgoallineitem">
  <br></br> You created this goal on {new Date(selectedAchievedGoal.createdDate).toLocaleDateString()}</p> 
    <p className="achievedgoallineitem"> <br></br>It took {Math.floor((Date.now() - new Date(selectedAchievedGoal.createdDate)) / (1000 * 60 * 60 * 24))} days to achieve this goal
  </p>
  <p className="achievedgoallineitem"> <br></br>Nice job!</p>
  <button className="confirmation-okay" onClick={() => {setShowAchievedGoalModal(false); handleRemoveSavingsGoal(selectedAchievedGoal.id, isGoalAchieved)}}>Archive Goal</button>
  <button className="confirmation-no" onClick={() => setShowAchievedGoalModal(false)}>Close</button>
  
</div></div>
}



            {/* <p>Income: {income}</p>
            <p>Expenses: {expenses}</p>
            <p>Remaining Income: {remainingIncome}</p>
            <p>Total Spent: {totalSpent}</p>
            <p>Total Allowances: {totalAllowances}</p> */}



            
{!showAchievedGoalModal && <ul className="goalslist" ref={tileRef}>
            { savingsGoals.map((goal, index) => (
  <li style={{ listStyleType: "none" }} key={goal.id}>
    <div
      className="goal-info"
      // ref={goalRef}
      onClick={() => {
        if (showAddnDeleteGoal) {
          setSelectedGoal(goal);
          // ;
        } else 
        if (goal.isGoalAchieved) {
          handleShowAchievedGoalModal(goal.id);
        } else {
          setSelectedGoal(goal);
          setUpdatedPercentage(goal.percent);
          handleTogglePercentSlider(index);
        }
      }}
    >



        
{!showPercentSliderIndexes.includes(index) && !goal.isGoalAchieved && deletingGoalId !== goal.id && (
      <div className="goaldetails">
        <div className="goaliconcontainer">{goal.icon}</div>
{/* <div className="currentpercentage">{goal.percent}%</div>    */}
        <div ref={goalEditRef} onClick={() => {
        if (showAddnDeleteGoal) {
          setEditGoal(goal)
        } else {
          // Logic when showAddnDeleteGoal is false
        }}} className="goalnameandpercentagecontainer">
          <div className="goalname2">{goal.name}</div>
          <div className="modifypercentgoalcontainer">

          <div className="forecastedtotal">
  {goal.percent === "" ? (
    <span className="usingthispercent">Assign some of your leftover cash to this goal!</span>
  ) : (
    <span className="usingthispercent">{goal.percent}% of leftover cash assigned</span>
  )}

</div>
          </div>
        </div>
      </div>
      )}

{goal.isGoalAchieved && (
     <div className="achievedgoal">
     <div className="goaliconcontainer">{goal.icon}</div>
{/* <div className="currentpercentage">{goal.percent}%</div>    */}

    
      <div className="goalnameandpercentagecontainer">
       <div className="goalname3">{goal.name}</div></div>
       <div className="chartandstatuscontainer">
       <div className="goalachievedtext">🏆</div></div>
     </div>

  // <button className="achievedgoal" onClick={() =>
  //   ""
  // //  handleRemoveSavingsGoal(goal.id)
  //  }>
  //   Goal Achieved!
  // </button>
)}

{!showPercentSliderIndexes.includes(index) && !goal.isGoalAchieved && deletingGoalId !== goal.id && (
        <div className="chartandstatuscontainer">
      <div className="actualtotal">
  {currencySymbol}{goal.progress.toFixed(0)} / {currencySymbol}{goal.target}
</div>
{/* {goal.startingAmount} */}
          <div className="goalchart">
            <GoalProgress goal={goal} />
          </div>
          <div className={`actualtotal ${goal.progress > goal.target ? 'over-target' : ''}`}>
  {((goal.progress / goal.target) * 100).toFixed(0)}% Funded<br></br>
  {/* {((goal.startingAmount / goal.target) * 100).toFixed(0)}% Realized */}
</div>
        </div>
      )}

{!showAddnDeleteGoal && showPercentSliderIndexes.includes(index) && deletingGoalId !== goal.id && (
    <div className="goaldetails">
        
        <div className="goaliconcontainer">{Math.round(updatedPercentage)}%</div>

        <div className="sliderandtext">
        <div className="sliderconfig">
          <div className="goalname">{goal.name}</div>
          <div className="percentslidercontainer">
          <input
    className="percentageslider"
    type="range"
    min="0"
    max={calculateMaxAllowablePercentage().maxAllowablePercentage} // Use the integer value here
    value={updatedPercentage * 100} // Multiply by 100
    step="1" // Allow steps of 1 (which will be 0.01 when divided by 100)
    onChange={(event) =>
        handleSliderChange(event, parseFloat(event.target.value) / 100) // Divide by 100 here
    }
    onMouseUp={handleSliderCompletion}
    onTouchEnd={handleSliderCompletion}
/>
          {/* <p>Current percent: </p> */}
        </div></div>
        
            <div className="forecastedtotal">
             Move the slider to contribute a different percentage of your available income
            </div>
          </div>
        
      </div>
 )}


{showAddnDeleteGoal && !goal.isGoalAchieved && deletingGoalId === goal.id && 
  <div className="goaldetails">
    <button ref={goalDeleteRef} className="confirmremovegoal" onClick={() => handleRemoveSavingsGoal(goal.id)}>Confirm Delete</button>
    <div className="confirmremovegoaltextcontainer">
    <div className="confirmremovegoalname">{goal.name}</div>
    <div className="confirmremovegoalheader">Do you really want to delete this goal?<br></br>This is irreversible.</div>
    <button ref={goalDeleteRef} className="cancelremovegoal" onClick={handleCancelConfirmDeleteGoal}>Cancel</button></div>
     </div>
}




 
{showAddnDeleteGoal && !goal.isGoalAchieved && deletingGoalId !== goal.id && <button ref={goalDeleteRef} className="removegoal" onClick={() => handleConfirmDeleteGoal(goal.id)}>Delete Goal</button>}

    </div>






  </li>
))}
            </ul>
}
            
          
{showAddnDeleteGoal && savingsGoals.length === 0 && 
<button ref={goalRef} className="goal-info buttonbutton" onClick={() => { setNewGoalName("");
    setNewGoalTarget("");  setNewGoalIcon("💸"); setShowAddGoalForm(true); setShowInputWidget(false); }}>
 Add a New Savings Goal
</button>}

{showAddnDeleteGoal && savingsGoals.length > 0 && 
<button ref={goalRef} className="goal-info buttonbutton2" onClick={() => { setNewGoalName("");
    setNewGoalTarget("");  setNewGoalIcon("💸"); setShowAddGoalForm(true); setShowInputWidget(false); }}>
 Add a New Savings Goal
</button>}



{showAddnDeleteGoal && 
<button disabled={deletingGoalId !== null} 
        className={`${showAddnDeleteGoal ? 'doneeditinggoalsbutton' : 'editgoalsbutton'} ${deletingGoalId !== null ? 'disabled' : ''}`} 
        onClick={() => handleToggleAddnRemove()}>
  {showAddnDeleteGoal ? 'Done Editing Savings Goals' : 'Edit Savings Goals'}
</button>}



{!showAddnDeleteGoal && savingsGoalsExist && !showAchievedGoalModal && <div className="amountallocatedcontainer">
 
<button className={showAddnDeleteGoal ? 'doneeditinggoalsbutton' : 'editgoalsbutton'} onClick={() => handleToggleAddnRemove()}>
  {showAddnDeleteGoal ? 'Done Editing Savings Goals' : 'Edit Savings Goals'}
</button> <span className="savingsgoalamounts">
{Math.round((totalAmountAllocated / originalSavings) * 100)}% of {currencySymbol}{Math.floor(originalSavings < 0 ? 0 : originalSavings)} assigned


</span></div>} 



{achievedGoals.length === 0 && !showAddnDeleteGoal && !savingsGoalsExist && sortedBudgets.length > 0 && originalSavings > 10 && 
<div className="emptystategoalsbutton"><button className="editgoalsbutton5" onClick={() => handleToggleAddnRemove()}>
  {showAddnDeleteGoal ? 'Done Editing' : 'Put your leftover income towards a savings goal'}
</button></div>} 

{achievedGoals.length > 0 && !showAddnDeleteGoal && !savingsGoalsExist && originalSavings > 10 && 
<div className="restartgoalsbutton"><button className="editgoalsbutton5" onClick={() => handleToggleAddnRemove()}>
  {showAddnDeleteGoal ? 'Done Editing' : 'Set up a savings goal'}
</button></div>} 

        </div>
    );
};

export default SavingsGoals;









 
 
