import React, { useState, useRef, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/database";
import "firebase/compat/storage";
import "firebase/compat/firestore";
import { FcConferenceCall } from "react-icons/fc";
import { FaSpinner } from "react-icons/fa";
import { IoIosClose } from "react-icons/io";
import "./BudgetDetails.css";

function BudgetDetails({ handleChangePurchaseIcon, setShowBudgetDetails, purchases, category, currencySymbol, setIsLoading, isLoading, handleEditEmojiClick }) {
  const containerRef = useRef(null);
  const [containerTop, setContainerTop] = useState(0);
  const [containerHeight, setContainerHeight] = useState(0);

  function handleCloseBudgetDetails() {
    setShowBudgetDetails(false);
  }

  const handleChangeEmoji = (purchaseId, buttonId, purchase) => {
    setIsLoading(true);
    const userId = firebase.auth().currentUser.uid;

  
    let selectedEmoji = purchase.note;
  

      handleEditEmojiClick(purchase);

  
    setIsLoading(false);
  
    return selectedEmoji;
  };

  // Filter purchases based on the category
  const filteredPurchases = purchases.filter(
    (purchase) => purchase.category === category
  );

  // Format the date in "MMM DD YYYY h:mmA" format
  const formatDate = (date) => {
    const options = { month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric" };
    return new Date(date).toLocaleString(undefined, options);
  };

  const purchaseTable = (
    <table>
      <tbody>
        {filteredPurchases.map((purchase) => (
          <tr key={purchase.id}>
            <td className="categorypurchasetabledata">
              <div className="categorypurchasedescriptionflexbox">
                <div className="categorydetailstableicongroup" style={{ width: "10%" }}>
                  <div 
                  
                  onClick={() =>
                    handleChangePurchaseIcon(
                        purchase.id,
                        purchase.id +
                            "-button"
                    )}
                  
                  
                  className="categorydetailsiconcontainer"
                  >{purchase.icon}</div>
                  <div className="purchaseindicatordotcontainer">
                    {purchase.worthit !== undefined ? (purchase.worthit ? "👍" : "👎") : null}
                    {purchase.icon === "💸" && (purchase.note === "📝 Add a purchase note" || purchase.note === "No note added") ? <div className="unmodifiedpurchasedot"></div> : null}

                  </div>
                </div>
                <div className="categorydetailstablenote" style={{ width: "60%" }}>
                  <div className="groupcategorynotecontainer">
                    <div className="categorydetailstabledate">{formatDate(purchase.date)}</div>
                    <div className="categorydetailsnote"
                    onClick={() =>
                        {setIsLoading(true);
                        handleChangeEmoji(
                            purchase.id,
                            purchase.id +
                                "-button",
                            purchase
                        ); setIsLoading(false)}
                    }
                    
                    
                    >{purchase.note}</div>
                  </div>
                </div>
                <div className="categorydetailstablenumbergroup">
                  <div className="groupamountcontainer">
                    <div className="categorydetailstableaddedby">
                      {purchase.group}
                      {purchase.group ? <FcConferenceCall /> : ""}
                    </div>
                    <div className={purchase.amount > 0 ? "categorydetailstableamount": "categorydetailstablenegativeamount"}>
                      {/* {purchase.wasModified ? <div className="modifiedpurchaseindicator">Modified</div> : ""} */}
                      {currencySymbol}
                      {purchase.amount.toFixed(2)}
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  return (
    <div ref={containerRef} className="budgetdetails-container">
      {filteredPurchases.length === 0 ? (
  <div className="nocategorypurchasesemptystate">No purchases found for this category.</div>
) : (
  <>{purchaseTable}</>
)}
    </div>
  );
}

export default BudgetDetails;
