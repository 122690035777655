import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/database";
import { FaRegStar, FaStar, FaStarHalf  } from "react-icons/fa";
import bird from "./images/BIRD.svg";
import panda from "./images/PANDA.svg";
import rabbit from "./images/RABBIT.svg";
import pig from "./images/PIG.svg";
import fox from "./images/FOX.svg";
import bear from "./images/BEAR.svg";
import crow from "./images/CROW.svg";
import cow from "./images/COW.svg";
import snail from "./images/SNAIL.svg";
import penguin from "./images/PENGUIN.svg";
import frog from "./images/FROG.svg";
import raccoon from "./images/RACCOON.svg";
import axolotl from "./images/AXOLOTL.svg";
import zebra from "./images/ZEBRA.svg";
import alien from "./images/ALIEN.svg";
import elephant from "./images/ELEPHANT.svg";
import ant from "./images/ANT.svg";
import phantom from "./images/PHANTOM.svg";
import { IoIosClose } from "react-icons/io";
import { TbAlertTriangleFilled } from "react-icons/tb";

const GroupPurchaseDetail = ({ purchase, groupId, handleClosePurchaseDetail, currencySymbol, currentUserEmail, handleDeletePurchase }) => {
  const {
    addedBy,
    amount,
    alias,
    creature,
    category,
    note,
    date,
    icon,
    worthit,

  } = purchase;
  

  const imageArray = [phantom, bird, panda, rabbit, pig, fox, bear, crow, cow, snail, penguin, frog, raccoon, zebra, alien, elephant, ant, axolotl];
  const [votes, setVotes] = useState(purchase.votes || {});
  const [comments, setComments] = useState(purchase.comments || {});
  const [vote, setVote] = useState(null);
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [comment, setComment] = useState("");
  const [editingComment, setEditingComment] = useState(null);
const [editedCommentText, setEditedCommentText] = useState("");
const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
const [pendingDeleteCommentId, setPendingDeleteCommentId] = useState(null);
const [rating, setRating] = useState(null);
const [ratings, setRatings] = useState(purchase.ratings || {});
const [averageRating, setAverageRating] = useState(purchase.averageRating || 0);
const [userRating, setUserRating] = useState(0);
const [worthitCount, setWorthitCount] = useState(0);
const [notWorthitCount, setNotWorthitCount] = useState(0);
const [userVote, setUserVote] = useState(null);
const [sortedVotes, setSortedVotes] = useState([]);
const [formattedDate, setFormattedDate] = useState('');
const [userAlias, setUserAlias] = useState(null);
const [userEmail, setUserEmail] = useState(null);
const [showConfirmDelete, setShowConfirmDelete] = useState(false);



const handleRating = async (newRating) => {
  const currentUser = firebase.auth().currentUser;
  const userId = currentUser.uid;
  const userEmail = currentUser.email;
  

  // Fetch alias
  const userRef = firebase.database().ref(`users/${userId}`);
  const userSnapshot = await userRef.once('value');
  const user = userSnapshot.val();
  const userAlias = user.alias || null;

  const ratingRef = firebase.database().ref(`groups/${groupId}/purchases/${purchase.purchaseId}/rating`);
  let existingRatingId = null;

  const snapshot = await ratingRef.orderByChild('addedBy').equalTo(userId).once('value');
  snapshot.forEach((childSnapshot) => {
    existingRatingId = childSnapshot.key;
  });

  let updatedRatings;

  if (existingRatingId) {
    await ratingRef.child(existingRatingId).update({
      stars: newRating,
      addedBy: userId,
      email: userEmail,
      alias: userAlias,
    });

    updatedRatings = {
      ...ratings,
      [existingRatingId]: {
        ...ratings[existingRatingId],
        stars: newRating,
        addedBy: userId,
        email: userEmail,
        alias: userAlias,
      },
    };

  } else {
    const newRatingId = ratingRef.push().key;
    await ratingRef.child(newRatingId).set({
      stars: newRating,
      addedBy: userId,
      email: userEmail,
      alias: userAlias,
    });

    updatedRatings = {
      ...ratings,
      [newRatingId]: {
        stars: newRating,
        addedBy: userId,
        email: userEmail,
        alias: userAlias,
      },
    };
  }

  setUserRating(newRating);
  setRatings(updatedRatings);

  // Calculate the new average rating
  const totalStars = Object.values(updatedRatings).reduce((total, rating) => total + Number(rating.stars), 0);
  const numberOfRatings = Object.keys(updatedRatings).length;
  setAverageRating(totalStars / numberOfRatings);
};

const formatDate = (timestamp) => {
  const date = new Date(timestamp);
  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
  return date.toLocaleString(undefined, options);
};

const calculateAverageRating = () => {
  const totalStars = Object.values(ratings).reduce((total, rating) => total + Number(rating.stars), 0);
  const numberOfRatings = Object.keys(ratings).length;
  setAverageRating(totalStars / numberOfRatings);
};

useEffect(() => {
  const fetchUserData = async () => {
    const currentUser = firebase.auth().currentUser;
    const userId = currentUser.uid;
    const userRef = firebase.database().ref(`users/${userId}`);
    const userSnapshot = await userRef.once('value');
    const user = userSnapshot.val();
    const alias = user.alias || null;
    const email = currentUser.email;
    setUserAlias(alias);
    setUserEmail(email);
  };

  fetchUserData();
}, []);

useEffect(() => {
  const ratingRef = firebase.database().ref(`groups/${groupId}/purchases/${purchase.purchaseId}/rating`);

  const fetchRatings = snapshot => {
    const ratingsData = snapshot.val();

    if (ratingsData) {
      let totalStars = 0;
      let count = 0;
      let userRating = 0;
      for (const key in ratingsData) {
        if (ratingsData[key].addedBy === firebase.auth().currentUser.uid) {
          userRating = ratingsData[key].stars || 0;
        }
        totalStars += parseInt(ratingsData[key].stars, 10);
        count++;
      }
      const avgRating = totalStars / count;

      setRatings(ratingsData);
      setUserRating(userRating);
      setAverageRating(avgRating.toFixed(1));
      // console.log("Fetched user rating: ", userRating);
    }
  };

  // Subscribe to the ratings data
  ratingRef.on('value', fetchRatings);

  // Unsubscribe from the ratings data when the component unmounts
  return () => {
    ratingRef.off('value', fetchRatings);
  };
}, []);




const handleVote = async (vote) => {
  const currentUser = firebase.auth().currentUser;
  const userId = currentUser.uid;
  const userEmail = currentUser.email;

  // Fetch alias
  const userRef = firebase.database().ref(`users/${userId}`);
  const userSnapshot = await userRef.once('value');
  const user = userSnapshot.val();
  const userAlias = user.alias || null;

  const voteRef = firebase.database().ref(`groups/${groupId}/purchases/${purchase.purchaseId}/votes`);
  let existingVoteId = null;

  const snapshot = await voteRef.orderByChild('addedBy').equalTo(userId).once('value');
  snapshot.forEach((childSnapshot) => {
    existingVoteId = childSnapshot.key;
  });

  if (existingVoteId) {
    if (votes[existingVoteId].worthit === vote) {
      await voteRef.child(existingVoteId).remove();

      const newVotes = { ...votes };
      delete newVotes[existingVoteId];


       // Update sortedVotes
    const newSortedVotes = sortedVotes.filter(v => v.addedBy !== (userAlias || userEmail) || v.worthit !== vote);
    setSortedVotes(newSortedVotes);
      setVotes(newVotes);
      setUserVote(null);
      if (vote === "true") {
        setWorthitCount(worthitCount - 1);
      } else {
        setNotWorthitCount(notWorthitCount - 1);
      }
    } else {
      await voteRef.child(existingVoteId).update({
        worthit: vote,
        addedBy: userId,
        email: userEmail,
        alias: userAlias,
        isAnonymous,
      });

      setVotes({
        ...votes,
        [existingVoteId]: {
          ...votes[existingVoteId],
          worthit: vote,
          addedBy: userId,
          email: userEmail,
          alias: userAlias,
          isAnonymous,
        },
      });
      setUserVote(vote);
    }
  } else {
    const newVoteId = voteRef.push().key;
    await voteRef.child(newVoteId).set({
      worthit: vote,
      addedBy: userId,
      email: userEmail,
      alias: userAlias,
      isAnonymous,
    });

    setVotes({
      ...votes,
      [newVoteId]: {
        worthit: vote,
        addedBy: userId,
        email: userEmail,
        alias: userAlias,
        isAnonymous,
      },
    });
    setUserVote(vote);
  }
};




useEffect(() => {
  const fetchVotes = () => {
    const voteRef = firebase.database().ref(`groups/${groupId}/purchases/${purchase.purchaseId}/votes`);
    voteRef.on('value', (snapshot) => {
      const votesData = snapshot.val();

      if (votesData) {
        let userVote = null;
        let worthitCount = 0;
        let notWorthitCount = 0;
        let sortedVotes = [];

        for (const key in votesData) {
          if (votesData[key].addedBy === firebase.auth().currentUser.uid) {
            userVote = votesData[key].worthit;
          }
          
          if (votesData[key].worthit !== null) {
            if (votesData[key].worthit === "true") {
              worthitCount++;
            } else if (votesData[key].worthit === "false") {
              notWorthitCount++;
            }
        
            const addedBy = votesData[key].isAnonymous
              ? 'Anonymous'
              : votesData[key].alias || votesData[key].email || 'Unknown';
        
            sortedVotes.push({
              addedBy,
              worthit: votesData[key].worthit,
            });
          }
        }

        // Sort votes: 'worthit' votes first, then 'not worthit' votes
        sortedVotes.sort((a, b) => (a.worthit === "true" && b.worthit === "false") ? -1 : 1);

        setVotes(votesData);
        setUserVote(userVote);
        setWorthitCount(worthitCount);
        setNotWorthitCount(notWorthitCount);
        setSortedVotes(sortedVotes);
      }
    });
  };

  fetchVotes();

  // Don't forget to clean up the listener when the component unmounts
  return () => {
    const voteRef = firebase.database().ref(`groups/${groupId}/purchases/${purchase.purchaseId}/votes`);
    voteRef.off();
  };
}, []);







const handleDeleteComment = (commentId) => {
  const currentUserId = firebase.auth().currentUser.uid;

  // Only delete if the current user is the author of the comment
  if (comments[commentId].addedBy === currentUserId) {
    setPendingDeleteCommentId(commentId);
    setIsDeleteModalVisible(true);
  }
};

const confirmDeleteComment = async () => {
  await firebase.database().ref(`groups/${groupId}/purchases/${purchase.purchaseId}/comments/${pendingDeleteCommentId}`).remove();

  // Update the comments in local state
  const updatedComments = { ...comments };
  delete updatedComments[pendingDeleteCommentId];
  setComments(updatedComments);

  // Close the confirmation dialog
  setIsDeleteModalVisible(false);
};

const cancelDeleteComment = () => {
  setIsDeleteModalVisible(false);
  setPendingDeleteCommentId(null);
};

const handleEditComment = (commentId) => {
  setEditingComment(commentId);
  setEditedCommentText(comments[commentId].text);
};

const handleDeleteGroupPurchase = () => {
  setShowConfirmDelete(true)
};

const handleCancelDeleteGroupPurchase = () => {
  setShowConfirmDelete(false)
};


const handleSaveComment = async (commentId) => {
  const currentUser = firebase.auth().currentUser;
  const currentUserId = currentUser.uid;
  const currentUserEmail = currentUser.email;

  // Only edit if the current user is the author of the comment
  if (comments[commentId].addedBy === currentUserId) {
    await firebase.database().ref(`groups/${groupId}/purchases/${purchase.purchaseId}/comments/${commentId}`).update({
      text: editedCommentText,
      addedBy: currentUserId,
      email: currentUserEmail,
    });

    // Update the comment in local state
    setComments({
      ...comments,
      [commentId]: {
        ...comments[commentId],
        text: editedCommentText,
        addedBy: currentUserId,
        email: currentUserEmail,
      },
    });

    // Exit editing mode
    setEditingComment(null);
  }
};




  
  
  
const handleComment = async () => {
  const currentUser = firebase.auth().currentUser;
  const userId = currentUser.uid;
  const userEmail = currentUser.email;

  // Fetch alias and profilePicIndex
  const userRef = firebase.database().ref(`users/${userId}`);
  const userSnapshot = await userRef.once('value');
  const user = userSnapshot.val();
  const userAlias = user.alias || null;
  const userProfilePicIndex = user.profilePicIndex || 0;

  const commentId = firebase.database().ref().child(`groups/${groupId}/purchases/${purchase.purchaseId}/comments`).push().key;
  const timestamp = new Date().toISOString();

  await firebase.database().ref(`groups/${groupId}/purchases/${purchase.purchaseId}/comments/${commentId}`).set({
    text: comment,
    addedBy: userId,
    alias: userAlias,
    email: userEmail,
    profilePicIndex: userProfilePicIndex,
    timestamp,
  });

  setComments({
    ...comments,
    [commentId]: {
      text: comment,
      addedBy: userId,
      alias: userAlias,
      email: userEmail,
      profilePicIndex: userProfilePicIndex,
      timestamp,
    },
  });

  setComment("");
};

  useEffect(() => {
    const date = new Date(purchase.date); // Replace this with your actual date value
    const options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    const formatted = date.toLocaleString(undefined, options);
    setFormattedDate(formatted);
  }, []);
  
  return (
    <div className="grouppurchasedetailcardcontainer">
        {!showConfirmDelete && 
      <div className="purchasedetailscontainer">



      <div className="closegrouppurchasedetailcontainer"><button className="closegrouppurchasedetailbutton" onClick={handleClosePurchaseDetail}><IoIosClose style={{fontSize: "1.6rem"}}/> </button></div>



 
   
<div className="grouppurchasedescriptioncontainer">

<div className="grouppurchasedescriptionflexbox">

                    <div className="tablenumbergroup2" style={{ width: '12N%' }}>
                    {purchase.recurring ? <div className="modifiedpurchaseindicator">Recurs</div> : ""}
                      <div className="groupdetailsiconcontainer">{purchase.icon}</div>
                      <div className="worthiticon2">
  {purchase.worthit !== undefined ? (purchase.worthit ? '👍' : '👎') : null}
</div>
                    </div>
                    <div className="tableheaderee" style={{ width: '50%' }}>
                      <div className="groupcategorynotecontainer">
                        <div className="grouptablecategory">
                          {purchase.category}
                        </div>
                        <div className="grouptablenote">{purchase.note}</div>
                      </div>
                    </div>
                    <div className="tablenumbergroup2">
                      <div className="groupamountcontainer">
                        <div className="grouptableaddedby">
                        {purchase.alias && purchase.alias.trim() !== "" ? purchase.alias : purchase.addedBy}
                        </div>
                        <div className="grouptableamount">
                        {purchase.wasModified ? <div className="modifiedpurchaseindicator">Modified</div> : ""}
                        
                          {currencySymbol}{parseFloat(purchase.amount).toFixed(2)}
                          
                        </div>
                        
                      </div>
                    </div>
                 
</div>




        {/* <div>Added By: {addedBy}</div>
        <div>Amount: {amount}</div>
        <div>Icon: {icon}</div>
        <div>Worthit: {worthit}</div>
        <div>Alias: {alias}</div>
        <div>Creature: {creature}</div>
        <div>Category: {category}</div>
        <div>Note: {note}</div> */}

        <div className="dateandratingcontainer">
      
     <div className="purchaserating">
  {[...Array(Math.floor(averageRating))].map((_, i) => <FaStar key={i} />)}
  {averageRating % 1 !== 0 && <FaStarHalf />}
</div>
<div>{formattedDate}</div>
</div>
</div>

<div className="votescontainer">
  {sortedVotes.filter(vote => vote.worthit !== null).map((vote, index) => (
    <div className="worthitvotelabel" key={index}>
      {vote.isAnonymous ? 'Anonymous' : vote.addedBy} {vote.worthit === "true" ? '👍' : '👎'}
    </div>
  ))}
</div>

<div className="grouppurchasevotingcontainer">
<div className="groupworthitbuttoncontainer">
<button className="groupvoteworthit" style={userVote === "true" ? { backgroundColor: "rgb(29, 56, 53)" } : {}} onClick={() => handleVote("true")}>
      👍 <div style={userVote === "false" ? { color: "#1a1a1a" } : { color: userVote === null ? "#111111" : "" }} className="worthitcountbutton">({worthitCount})</div>
    </button>
    <button className="groupvoteworthit" style={userVote === "false" ? { backgroundColor: "#852626" } : {}} onClick={() => handleVote("false")}>
      👎<div style={userVote === "true" ? { color: "#1a1a1a" } : { color: userVote === null ? "#111111" : "" }} className="worthitcountbutton">({notWorthitCount})</div>
    </button>

  
</div>
<div className="anonymousvotecontainer">
  <input  style={{background: "#d9d9d9"}}type="checkbox" checked={isAnonymous} onChange={() => setIsAnonymous(!isAnonymous)} />
  Vote Anonymously
</div>



<div className="ratethispurchasecontainer">
  {[...Array(5)].map((_, i) => {
    const ratingValue = i + 1;
    return (
      <span
        key={i}
        onClick={() => handleRating(ratingValue)}
      >
        {ratingValue <= userRating
          ? <FaStar />
          : <FaRegStar />}
      </span>
    );
  })}
</div>




</div>



<div className="grouppurchasecommentscontainer">
{purchase.addedBy === currentUserEmail && (
                <button className="deletemygrouppurchasebutton" onClick={() => {handleDeleteGroupPurchase()}}>Remove Purchase</button>
            )}

        <div className="purchaseaddcommentcontainer">
          <textarea placeholder="Post a comment about this purchase" className="newcommenttextarea" value={comment} onChange={(e) => setComment(e.target.value)} />
         <div className="addcommentbuttoncontainer"> 
         <div className="postingaswho">Posting as {userAlias || userEmail}</div>
      
         <button className={`newcommentbutton ${!comment ? 'disabled' : ''}`} onClick={handleComment} disabled={!comment}>Add Comment</button></div>
      
       
       
        </div>
        
        <div className="bottomborder"></div>
        




        <div className="purchasecommentthread">

{/* 
   {isDeleteModalVisible && (
  <div>
    <p>Are you sure you want to delete this comment?</p>
    <button onClick={confirmDeleteComment}>Yes, delete it</button>
    <button onClick={cancelDeleteComment}>No, cancel</button>
  </div>
)} */}




{comments && Object.keys(comments)
  .sort((a, b) => new Date(comments[b].timestamp) - new Date(comments[a].timestamp))
  .map((key) => (
    <div className="purchasecommentcontainer" key={key}>
            <div className="profileiconnameanddate">
                <div className="profileiconandname">
                  <img
                    className="leadercreature2"
                    src={imageArray[comments[key].profilePicIndex]}
                    alt="Profile Picture"
                  />
                  <p className="profilename">{comments[key].alias || comments[key].email}</p>
                </div>
                <p className="commenttimestamp">{formatDate(comments[key].timestamp)}</p>
              </div>
      {editingComment === key ? (
        <>
          <textarea className="editcommentinput" value={editedCommentText} onChange={(e) => setEditedCommentText(e.target.value)} />
          <div className="editgrouppurchasecommentbuttoncontainer">
          <button className="editgrouppurchasecommentbutton" onClick={() => handleSaveComment(key)}>Save Comment</button>
          <button className="editgrouppurchasecommentbutton" onClick={() => setEditingComment(null)}>Cancel</button>
          </div>
        </>
      ) : (
        <>
           {isDeleteModalVisible && pendingDeleteCommentId === key ? (
            <div>
              <p className="areyousuredeletecomment">Are you sure you want to delete this comment?</p>
              <div className="editgrouppurchasecommentbuttoncontainer">
              <button  className="editgrouppurchasecommentbutton2" onClick={confirmDeleteComment}>Yes, delete it</button>
              <button  className="editgrouppurchasecommentbutton3" onClick={cancelDeleteComment}>No, cancel</button>
           </div>
            </div>
          ) : (
            <>
        

              <p className="grouppurchasecommenttext">{comments[key].text}</p>

              {/* Only show delete and edit buttons to the author of the comment */}
              {comments[key].addedBy === firebase.auth().currentUser.uid && (
                <>
                <div className="editgrouppurchasecommentbuttoncontainer">
                  <button className="editgrouppurchasecommentbutton" onClick={() => handleDeleteComment(key)}>Delete Comment</button>
                  <button className="editgrouppurchasecommentbutton" onClick={() => handleEditComment(key)}>Edit Comment</button>
                  </div>
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  ))}
  {Object.keys(comments).length === 0 && (
  <div className="emptystate">No comments yet!</div>
)}
</div></div>
      </div>}
{showConfirmDelete && <div className="purchasedetailscontainer2"><div className="confirmdeletegrouppurchasecontainer"><TbAlertTriangleFilled className="confirmdeletegrouppurchaseicon"/><div className="confirmdeletegrouppurchasetext">Are you sure you want to remove this purchase from the group?</div><div className="deleteconfirmgrouppurchasebuttoncontainer"><button className="confirmdeletepurchaseconfirmed" onClick={() => {handleDeletePurchase(purchase.id); handleClosePurchaseDetail()}}>Yes</button><button className="confirmdeletepurchasenotconfirmed" onClick={handleCancelDeleteGroupPurchase}>Cancel</button></div></div></div>}


    </div>
  );
};

export default GroupPurchaseDetail;