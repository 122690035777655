import React, { useEffect, useState, useRef, memo } from 'react';
import Chart from 'chart.js/auto';
import { db } from './BudgetPage';
import moment from 'moment';
import 'chartjs-adapter-moment';

function ChartComponent(props) {
  const canvasRef = useRef(null);
  const [totalBudget, setTotalBudget] = useState(0);
  const [isChartReady, setIsChartReady] = useState(false);

  
  const spentPercentage = (props.totalSpent / totalBudget) || 0;
  const spentPercentageRounded = Math.round(spentPercentage * 100);
  const colorArray = [    'rgb(50, 92, 42)',    'rgb(60, 92, 42)',    'rgb(70, 92, 42)',    'rgb(80, 92, 42)',    'rgb(90, 92, 42)',    'rgb(100, 92, 42)',    'rgb(110, 92, 42)',    'rgb(120, 92, 42)',    'rgb(130, 92, 42)',    'rgb(140, 92, 42)',    'rgb(150, 80, 42)',    'rgb(160, 70, 42)',    'rgb(170, 60, 42)',    'rgb(180, 40, 42)',    'rgb(190, 30, 42)'  ];
  const spentPercentageColor = colorArray[Math.floor(spentPercentageRounded / (100 / colorArray.length))];




//CONFIGURE CHART WITH THE CORRECT DATA FROM THE DATABASE___________________________________

useEffect(() => {
  const ctx = canvasRef.current.getContext('2d');
  const categoriesRef = db.ref(`users/${props.userId}/categories`);

  const purchasesByDate = {};
  let totalSpent = 0;


  props.purchases.forEach((purchase) => {
    const dateISO = purchase.date.substring(0, 10);
    const date = moment(dateISO); // Parse the date using moment without specifying the format
    const dateLocale = date.format('YYYY-MM-DD');

    if (purchasesByDate[dateLocale]) {
      purchasesByDate[dateLocale] += purchase.amount;
    } else {
      purchasesByDate[dateLocale] = purchase.amount;
    }

    totalSpent += purchase.amount;
  });

  categoriesRef.once('value', (snapshot) => {
    let total = 0;
    snapshot.forEach((childSnapshot) => {
      total += childSnapshot.val();
    });
    setTotalBudget(total);
    setIsChartReady(true);
  });

  const dates = Object.keys(purchasesByDate).sort();
  const budgetData = dates.map(() => totalBudget);
  const spentData = dates.map((date) => purchasesByDate[date]);
 

  // Calculate running total of spentData
  for (let i = 1; i < spentData.length; i++) {
    spentData[i] += spentData[i - 1];
  }

  function addCommas(nStr) {
    nStr += '';
    var x = nStr.split('.');
    var x1 = x[0];
    var x2 = x.length > 1 ? '.' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2;
  }

  if (isChartReady) { // render chart only when isChartReady state variable is true

//CHART.JS CONFIGURATION_____________________________________________________________________________
    const config = {
      type: 'line',
      data: {
        labels: dates,
        datasets: [
          {
            label: 'Budget',
            data: budgetData,
            fill: false,
            backgroundColor: '#91919152',
            borderColor: '#d339396b',
            borderWidth: 2,
            pointBackgroundColor: 'rgb(51, 102, 49)',
            pointRadius: 0,
            pointHoverRadius: 8,
            pointHitRadius: 10,
            tension: 0.2,
          },
          {
            label: 'Spent',
            data: spentData,
            fill: true,
            backgroundColor: 'rgb(10, 100, 90)',
            borderColor: '#303030',
            borderWidth: 0,
            pointBackgroundColor: '#303030',
            pointRadius: 0,
            pointHoverRadius: 8,
            pointHitRadius: 10,
            tension: 0.2,
          },
      
        ],
      },
      options: {
        
        layout: {
          padding: {
            bottom: 0,
          },
        },
        aspectRatio: false,
        plugins: {
          tooltip: {
            multiKeyBackground: 'rgba(0,0,0,0)',
            callbacks: {
              label: function (context) {
                var label = context.dataset.label || '';
        
                if (label) {
                  label += ': ';
                }
                label += props.currencySymbol + ' ' + context.parsed.y;
        

                return label;
              },
              
            },
          },
          legend: {
            display: false,
          },
        },
        elements: {
          point: {
            radius: 2,
          },
          line: {
            tension: 0.4,
          },
        },
        scales: {
          x: {
            
            adapter: 'moment', // use the moment date adapter
            grid: {
              display: false,
              drawBorder: true,
            },
            ticks: {
              callback: function (value, index, values) {
                return moment(value).format('MMM D');
              }, 
              display: true,
              color: '#555555',
              font: { 
                size: 10, 
                // family: 'Raleway' 
              }
            },
            type: 'time',
            time: {unit: 'day',
            tooltipFormat: 'MMM D'},
            parser: 'YYYY-MM-DD', // Specify the date format used in the dates array
            displayFormats: {
              day: 'MMM D', // Format for displaying the tick labels
            },
          },
          y: {
            beginAtZero: true,
            ticks: {
              callback: function (value, index, values) {
                return props.currencySymbol + addCommas(value);
              },
              display: true,
              color: '#a9a9a9',
              font: { 
                size: 10,
                // family: 'Raleway' 
              }
            },
          },
        },
      },
    };

//RENDER THE CHART.JS CHART__________________________________________________________________________

    const chart = new Chart(ctx, config);

    return () => {
      chart.destroy();
    };
  }
}, [ props.userId, totalBudget, isChartReady, props.purchases]); 


//JSX________________________________________________________________________________________________

  return (
    <div className='chartcanvas'>
      <canvas  ref={canvasRef} />
    </div>
  );
}

export default memo(ChartComponent);
