import React, { useState, useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import { db } from "./BudgetPage";

function CategoryChart(props) {
  const canvasRef = useRef(null);
  const [chartData, setChartData] = useState({});
  const [numberOfPurchases, setNumberOfPurchases] = useState(0);


  useEffect(() => {
    if (numberOfPurchases !== props.purchases?.length) {
      const categoriesRef = db.ref(`users/${props.userId}/categories`);
  
    categoriesRef.once("value", (snapshot) => {
      const categoryData = snapshot.val();
      if (!categoryData) {
        setChartData({});
        return;
      }
      const labels = Object.keys(categoryData);
      const budgetData = labels.map((label) => categoryData[label]);
      const spentData = labels.map((label) => {
        const totalSpent = props.purchases.reduce(
          (total, purchase) =>
            purchase.category === label ? total + purchase.amount : total,
          0
        );
        return totalSpent;
      });

     const spentPercentageData = labels.map((label, index) => {
        const originalBudget = budgetData[index];
        const budget = budgetData[index] - spentData[index];
        const spentPercentage = (originalBudget - budget) / originalBudget;
        return Math.round(spentPercentage * 100);
      });
      const remainingBudgetData = budgetData.map((budget, index) => {
        const remainingBudget = budget - spentData[index];
        return remainingBudget > 0 ? remainingBudget : 0;
      });
      
      const colorArray = [
        'rgb(10, 100, 90)',
        'rgb(15, 99, 90)',
        'rgb(20, 98, 80)',
        'rgb(30, 97, 70)',
        'rgb(40, 96, 60)',
        'rgb(50, 95, 50)',
        'rgb(60, 94, 40)',
        'rgb(70, 93, 30)',
        'rgb(80, 92, 20)',
        'rgb(90, 91, 10)',
        'rgb(100, 90, 0)',
        'rgb(110, 90, 0)',
        'rgb(120, 90, 0)',
        'rgb(130, 90, 0)',
        'rgb(140, 90, 0)',
        'rgb(145, 90, 0)',
        'rgb(150, 70, 0)',
        'rgb(100, 45, 0)',
        'rgb(110, 30, 0)',
        'rgb(120, 0, 0)',
      ];
      const spentPercentageColorData = spentPercentageData.map(
        (percentage) => {
          if (percentage < 0) {
            return 'rgb(120, 0, 0)'; // red color for negative values
          } else {
            return colorArray[Math.floor(percentage / (100 / colorArray.length))];
          }
        }
      );
      setChartData({
        labels: labels,
        datasets: [
          {
            label: "Remaining Budget",
            data: remainingBudgetData,
            backgroundColor: spentPercentageColorData,
            
          },
          {
            label: "Spent",
            data: spentData,
            backgroundColor: '#c9c9c9',
          },
       
      
        ],
      });
    });
    setNumberOfPurchases(props.purchases?.length)
  }
  }, [props.userId, props.purchases]);

  useEffect(() => {
    if (canvasRef.current && Object.keys(chartData).length > 0) {
      const chart = new Chart(canvasRef.current, {
        type: "bar",
        data: chartData,
        
          options: {
           
            borderRadius:  [5, 5, 5, 5],
            borderRadiusOnAllStackedSeries: true,
            legend: {align: 'start',},
            layout: {
              padding: {
                bottom: 0, 
              },
            },
            aspectRatio: false,
            plugins: {
              legend: {
                display: false,
              },
            },
          scales: {
            x: {
              
              grid: {
                display: false
              },
              stacked: true,
              ticks: {
                
                color: '#555555',
          font: 
          { 
            size: 10,
            
          }}
            },
            y: 
            {
             
              grid: {
                display: true,
                color: '#E9e9e9'
              },
              stacked: true,
              ticks: {
                callback: function (value, index, values) {
                  return props.currencySymbol + value;
                },
                
                 color: '#a9a9a9',
          font: { 
            size: 10,
            // family: 'Raleway' 
          }
              },
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              mode: "nearest",
              intersect: false,
              label: false,
              callbacks: {
                label: function (context) {
                  const label = context.dataset.label;
                  const value = context.parsed.y;
                  return label + ": " + props.currencySymbol + parseFloat(value).toFixed(2);
                },
              },
            },
          },
        },
      });

      return () => {
        chart.destroy();
      };
    }
  }, [chartData]);



  return (
    <div className="chartcanvas">
      {Object.keys(chartData).length > 0 ?  (
        <canvas id="budgetchart" ref={canvasRef} />
      ) : (
        <div className="emptychartstate">No category data to display!<br></br>Add some purchases to see the chart.</div>
      )}
    </div>
  );
}

export default CategoryChart;