import React, { useState, useRef, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import GroupLineChart from './GroupLineChart';
import GroupPurchaseDetail from './GroupPurchaseDetail';
import AddPurchase from "./AddPurchaseForm";
import NumberPad from "./NumberPad";
import { FaSlidersH } from "react-icons/fa";
import { GrClose } from "react-icons/gr";
import cityback2 from "./images/cityback2.svg";
import lightmast from "./images/lightmast.svg";
import lightpole from "./images/lightpole.svg";
import lilwindow from "./images/lilwindow.svg";
import bird from "./images/BIRD.svg";
import panda from "./images/PANDA.svg";
import rabbit from "./images/RABBIT.svg";
import pig from "./images/PIG.svg";
import fox from "./images/FOX.svg";
import bear from "./images/BEAR.svg";
import crow from "./images/CROW.svg";
import cow from "./images/COW.svg";
import snail from "./images/SNAIL.svg";
import penguin from "./images/PENGUIN.svg";
import frog from "./images/FROG.svg";
import raccoon from "./images/RACCOON.svg";
import zebra from "./images/ZEBRA.svg";
import axolotl from "./images/AXOLOTL.svg";
import alien from "./images/ALIEN.svg";
import elephant from "./images/ELEPHANT.svg";
import ant from "./images/ANT.svg";
import phantom from "./images/PHANTOM.svg";
import lilman from "./images/lilman.svg";
import bigwindow from "./images/bigwindow.svg";
import { IoIosClose } from "react-icons/io";
import { BsFillChatLeftTextFill} from "react-icons/bs";
import { CSSTransition } from "react-transition-group";
import { AiFillCaretDown } from "react-icons/ai";
import GoalEmojiModal from "./GoalEmojiModal";


import 'firebase/compat/database';
import { getOverlappingDaysInIntervals } from 'date-fns';

const GroupDetail = ({ emojiCategories, handleShowAnimation, selectedGroup, currencySymbol, groupId, setShowInputWidget, handleLeaveGroup, setSelectedGroupDetail, setShowGroupDeleteMessage }) => {
  const [groupName, setGroupName] = useState('');
  const [inputValue, setInputValue] = useState("");
  const [groupMembers, setGroupMembers] = useState([]);
  const [groupPurchases, setGroupPurchases] = useState([]);
  const [groupMembersCount, setGroupMembersCount] = useState(0);
  const [categoryFilter, setCategoryFilter] = useState('');
  const [dateFilterFrom, setDateFilterFrom] = useState('');
  const [dateFilterTo, setDateFilterTo] = useState('');
  const [editingGroupName, setEditingGroupName] = useState(false);
  const [newGroupName, setNewGroupName] = useState('');
  const [groupedPurchases, setGroupedPurchases] = useState([]);
  const [groupCategoryFilter, setGroupCategoryFilter] = useState("");
  const [userColors, setUserColors] = useState([]);
  const [inviteEmail, setInviteEmail] = useState('');
  const [inviteSectionVisible, setInviteSectionVisible] = useState(false); 
  const [deleteConfirmationVisible, setDeleteConfirmationVisible] = useState(false);
  const [invitationStatus, setInvitationStatus] = useState('');
  const [groupOptionsVisible, setGroupOptionsVisible] = useState(false); 
  const [confirmLeaveGroupVisible, setConfirmLeaveGroupVisible] = useState(false); 
  const [confirmDeletePurchasesVisible, setConfirmDeletePurchasesVisible] = useState(false); 
  const [selectedPurchase, setSelectedPurchase] = useState(null);
  const [showPurchaseDetail, setShowPurchaseDetail] = useState(false);
  const [currentUserEmail, setCurrentUserEmail] = useState("");
const [showManualGroupPurchase, setShowManualGroupPurchase] = useState(false);
const [isLoading, setIsLoading] = useState(false);
const [showNumberPad, setShowNumberPad] = useState(false);
const [showAddPurchase, setShowAddPurchase] = useState(false);
const [selectedDate, setSelectedDate] = useState(new Date());
const [showEmojiModal, setShowEmojiModal] = useState(false);
const [newGroupPurchaseIcon, setNewGroupPurchaseIcon] = useState('🧾');
const [newRecurringGroupPurchaseIcon, setNewRecurringGroupPurchaseIcon] = useState('');
const [showRecurring, setShowRecurring] = useState(false);
const [recurringPurchases, setRecurringPurchases] = useState([]);
const [editId, setEditId] = useState(null);
const [deleteConfirmationId, setDeleteConfirmationId] = useState(null);
const [editRecurDay, setEditRecurDay] = useState(null);
const [selectedRecurringPurchase, setSelectedRecurringPurchase] = useState(null);
const [newRecurringExpense, setNewRecurringExpense] = useState(false);



const recurDayInput = useRef(null);
const noteInput = useRef(null);
const amountInput = useRef(null);
const categoryInput = useRef(null);
const iconInput = useRef(null);

const handleEditRecurDayChange = (e) => {
  setEditRecurDay(e.target.value);
};


const getOrdinalSuffix = (i) => {
  var j = i % 10,
      k = i % 100;
  if (j == 1 && k != 11) {
      return "st";
  }
  if (j == 2 && k != 12) {
      return "nd";
  }
  if (j == 3 && k != 13) {
      return "rd";
  }
  if (i == undefined | i == "") {
      return "";
  }
  return "th";
}


useEffect(() => {
  const groupRecurringRef = firebase.database().ref(`groups/${groupId}/recurringPurchases`);
  groupRecurringRef.on('value', (snapshot) => {
    const purchases = snapshot.val();
    const purchasesList = [];
    for(let id in purchases) {
      purchasesList.push({ id, ...purchases[id] });
    }
    setRecurringPurchases(purchasesList);
  });
}, [groupId]);


const [form, setForm] = useState({
  purchaseId: '',
  category: '',
  icon: '',
  note: '',
  amount: '',
  date: '',
  worthit: '',
  recurring: false,
  recurDay: 1,
});

const handleRecurDayChange = (event) => {
  setForm({
    ...form,
    [event.target.name]: event.target.value,
  });
};

const handleRecurringChange = (e) => {
  setForm({
    ...form,
    recurring: e.target.checked,
  });

};


const handleShowRecurringGroupPurchases = () => {
  setShowRecurring(!showRecurring)
  setGroupOptionsVisible(false)
  setEditId(null);
  setDeleteConfirmationId(null);
}

const handleHideRecurringGroupPurchases = () => {
  setShowRecurring(false)
  setEditId(null)
  setDeleteConfirmationId(null)
}

  const newMemberRef = useRef(null);
  const groupPurchaseRef = useRef(null);
  const userId = firebase.auth().currentUser.uid;
  const userEmail = firebase.auth().currentUser.email;

  

    const handleToggleEmojiModal = () => {
      setShowEmojiModal(!showEmojiModal);
    };

  const handleGroupPurchaseIconUpdate = (icon) => {
    setNewGroupPurchaseIcon(icon); // Update the selected icon
  };

  const handleRecurringGroupPurchaseIconUpdate = (emoji) => {
    setNewRecurringGroupPurchaseIcon(emoji)
    setSelectedRecurringPurchase((prevState) => ({
      ...prevState,
      icon: emoji,
    }));
  };

  const handleNumberPress = (number) => {
    if (number === "Del") {
      setInputValue(inputValue.slice(0, -1)); // Remove the last character
    } else if (number === "." && inputValue.includes(".")) {
      // Do not allow multiple decimal points
      return;
    } else {
      setInputValue(inputValue + number);
    }
  };

  const handleCloseNewRecurringPurchase = () => {
    setNewRecurringExpense(false);
    setShowInputWidget(true);
    setNewGroupPurchaseIcon("🧾");
  };

  const handleShowNewRecurringPurchase = () => {
    setNewRecurringExpense(true);
    setShowInputWidget(false);
  };

  const handleOpenOptions = () => {
    setGroupOptionsVisible(true);
  };

  const handleCloseOptions = () => {
    setGroupOptionsVisible(false);
  };

  const handleCloseInputWidget = () => {
    setShowInputWidget(true);
  };

  const handleCloseDeletePurchase = () => {
    setConfirmDeletePurchasesVisible(false);
  };

  const handleOpenDeletePurchase = () => {
    setConfirmDeletePurchasesVisible(true);
  };

  const handleOpenLeaveGroup = () => {
    setConfirmLeaveGroupVisible(true);
  };

  const handleCloseLeaveGroup = () => {
    setConfirmLeaveGroupVisible(false);
  };

  const handleManualAddPurchase = () => {
    setShowManualGroupPurchase(true);
    setShowInputWidget(false);
    setGroupOptionsVisible(false);
  };

  const handleCloseManualAddPurchase = () => {
    setShowManualGroupPurchase(false);
    setShowInputWidget(true);
    setNewGroupPurchaseIcon("🧾");
  }
 
  const leaveGroup = () => {
    handleLeaveGroup(groupId);
  };

  
  const handleToggleDeleteConfirmation = () => {
    setDeleteConfirmationVisible(!deleteConfirmationVisible);
  };


  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
        if (user) {
            setCurrentUserEmail(user.email);
        }
    });
}, []);

const handleDateChange = (date) => {
  setSelectedDate(date);
  setShowAddPurchase(false);
};

const handleChange = (e) => {
  if (e.target.name === 'amount') {
    setForm({
      ...form,
      [e.target.name]: Math.round(e.target.value * 100) / 100
    });
  } else {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    });
  }
};


// async function checkRecurringPurchasesLogic() {
//   const db = firebase.database();

//   const groupsRef = db.ref("groups");
//   const groupsSnapshot = await groupsRef.once("value");
//   const groups = groupsSnapshot.val();

//   const today = new Date();
//   const todayDay = today.getUTCDate();

//   console.log("Groups:", groups);

//   for (const groupId in groups) {
//     if (Object.prototype.hasOwnProperty.call(groups, groupId)) {
//       const recurringPurchases = groups[groupId].recurringPurchases;
  
//       if (recurringPurchases === undefined) {
//         console.log("No recurring purchases for Group:", groupId);
//         continue;
//       }
  
//       for (const key in recurringPurchases) {
//         if (Object.prototype.hasOwnProperty.call(recurringPurchases, key)) {
//           const recurringPurchase = recurringPurchases[key];
//           const recurDay = parseInt(recurringPurchase.recurDay, 10);
  
//           console.log("Checking Recurring Purchase:", recurringPurchase);
//           console.log("Recurring Purchase Day:", recurDay, typeof recurDay);
//           console.log("Today's Day:", todayDay, typeof todayDay);
//           console.log("Comparison Result:", recurringPurchase.recurDay == todayDay);
//           if (recurringPurchase.recurDay == todayDay) {
//             console.log("Progressing to comparing the dates")
//             const purchaseId = `purchase-${Date.now()}`;
//             console.log(purchaseId)
//             const purchase = {
//               ...recurringPurchase,
//               date: today.toISOString(),
//               id: purchaseId,
//             };

//             console.log("Adding Purchase:", purchase);

//             const groupPurchasesRef = db.ref(`groups/${groupId}/purchases`);
//             await groupPurchasesRef.push(purchase);

//             console.log("Purchase Added");
//           }
//         }
//       }
//     }
//   }
// }

// exports.checkRecurringPurchases = functions.pubsub
//   .schedule("every 24 hours")
//   .onRun((context) => checkRecurringPurchasesLogic());


const handleRecurringExpenseSubmit = async (e) => {
  e.preventDefault();
  await handleManualAddToRecurring();

  // reset form
  setForm({
    purchaseId: '',
    category: '',
    icon: '',
    note: '',
    amount: '',
    date: '',
    worthit: '',
    recurring: true,
    recurDay: 1,
    addPurchaseWhenFinished: false,
  });
};


const handleManualAddToRecurring = async () => {
  console.log('Handling manual add to recurring...');
  if (groupId) {
    console.log('Selected group exists:', groupId);

    const userRef = firebase.database().ref(`users/${userId}`);
    const userSnapshot = await userRef.once('value');
    const userAlias = userSnapshot.child('alias').val();
    const userProPic = userSnapshot.child('profilePicIndex').val();

    const purchaseId = `purchase-${Date.now()}`;
    console.log('Generated purchaseId:', purchaseId);

    const purchase = {
      id: purchaseId,
      category: form.category === '' ? 'No Category' : form.category,
      icon: newGroupPurchaseIcon,
      note: form.note === '' ? 'No note added' : form.note,
      amount: parseFloat(form.amount),
      addedBy: userEmail,
      alias: userAlias,
      date: selectedDate.toLocaleString(),
      group: groupName,
      groupId: groupId,
      creature: userProPic,
      recurring: true,
      recurDay: form.recurDay,
    };

    console.log('Generated purchase:', purchase);

    try {
      setIsLoading(true);

      const recurringPurchasesRef = firebase.database().ref(`groups/${groupId}/recurringPurchases`);
      await recurringPurchasesRef.push(purchase);
      console.log('Recurring purchase added');

      setIsLoading(false);
      setNewRecurringExpense(false);
      setShowInputWidget(true);
      setNewGroupPurchaseIcon("🧾");

      console.log('Successfully added purchase to recurring');
    } catch (error) {
      setIsLoading(false);
      console.error('Error processing purchase:', error);
    }
  } else {
    console.log('No selected group');
  }
};



const handleSubmit = async (e) => {
  e.preventDefault();
  await handleManualAddToGroup();

  // reset form
  setForm({
    purchaseId: '',
    category: '',
    icon: '',
    note: '',
    amount: '',
    date: '',
    worthit: '',
    recurring: false,
    recurDay: 1,
  });
};

const handleManualAddToGroup = async () => {
  console.log('Handling manual add to group...');
  if (groupId) {
    console.log('Selected group exists:', groupId);
    // console.log(selectedDate);

    const groupRef = firebase.database().ref(`groups/${groupId}/purchases`);
    console.log('groupRef:', groupRef);

    const userRef = firebase.database().ref(`users/${userId}`);
    const userSnapshot = await userRef.once('value');
    const userAlias = userSnapshot.child('alias').val();
    const userProPic = userSnapshot.child('profilePicIndex').val();

    const purchaseId = `purchase-${Date.now()}`;
    console.log('Generated purchaseId:', purchaseId);

    const purchase = {
      id: purchaseId,
      category: form.category === '' ? 'No Category' : form.category,
      icon: newGroupPurchaseIcon,
      note: form.note === '' ? 'No note added' : form.note,
      amount: parseFloat(form.amount),
      addedBy: userEmail,
      alias: userAlias,
      date: selectedDate.toLocaleString(),
      group: groupName,
      groupId: groupId,
      creature: userProPic,
      recurring: form.recurring,
      recurDay: form.recurDay,
    };

    console.log('Generated purchase:', purchase);

    try {
      setIsLoading(true);
      await groupRef.push(purchase);
      setIsLoading(false);

      console.log('Purchase pushed to group');

      const updatedSnapshot = await groupRef.once('value');
      const updatedPurchases = updatedSnapshot.val();
      if (purchase.recurring) {
        const recurringPurchasesRef = firebase.database().ref(`groups/${groupId}/recurringPurchases`);
        await recurringPurchasesRef.push(purchase);
        console.log('Recurring purchase added');
      }

      console.log('Updated purchases:', updatedPurchases);

      if (updatedPurchases && Object.values(updatedPurchases).find((p) => p.id === purchaseId)) {
        handleShowAnimation();
        setNewGroupPurchaseIcon("🧾");
        handleCloseManualAddPurchase();
        console.log('Successfully added purchase to group');
        // setAddedPurchases((prevState) => ({ ...prevState, [purchase.id]: true }));
        // show success message
      } else {
        throw new Error('Failed to add purchase to group');
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Error processing purchase:', error);
    }
  } else {
    console.log('No selected group');
  }
};

  const handleDeleteGroup = () => {
    // Get a reference to the group to be deleted
    const groupRef = firebase.database().ref(`groups/${groupId}`);
  
    // Remove the group from the database
    groupRef.remove()
      .then(() => {
        // Group successfully deleted
        // Perform any necessary cleanup or redirection here
  
        // Set `showGroupDetail` to false to hide the group detail component
        setSelectedGroupDetail('');
        setShowGroupDeleteMessage(true);
        setTimeout(() => {
          const messageElement = document.querySelector('.groupdeletemessage'); // Replace 'your-message-class' with the actual class of the element you want to add the class to
          if (messageElement) {
            messageElement.classList.add('slideoutmessage');
          }
          setTimeout(() => {
            setShowGroupDeleteMessage(false);
          }, 1000); // Set the duration in milliseconds (4 seconds = 4000 milliseconds)
        }, 3000);
      })
      .catch((error) => {
        // Error occurred while deleting the group
        console.log('Error deleting group:', error);
      });
  };
  const handleInviteMember = () => {
    if (!inviteEmail) {
      setInvitationStatus('error');
      return;
    }

    firebase
      .database()
      .ref('emailToUid')
      .child(btoa(inviteEmail))
      .once('value')
      .then((snapshot) => {
        const inviteUid = snapshot.val();

        if (inviteUid) {
          // We'll add the member to the group by their uid
          const groupRef = firebase.database().ref(`groups/${groupId}/members`);
          groupRef.update({
            [inviteUid]: true,
          });

          // Also add the group to the invited member's groups
          const inviteeGroupsRef = firebase.database().ref(`users/${inviteUid}/groups`);
          inviteeGroupsRef.child(groupId).set({
            name: newGroupName, // use the group name
            purchases: {}, // initialize purchases as empty
          });
          setInvitationStatus('success');
          setInviteEmail(''); // clear the input field
          setTimeout(() => {
            setInvitationStatus('');
          }, 5000);
          
        } else {
          setInvitationStatus('error');
          setTimeout(() => {
            setInvitationStatus('');
          }, 5000);
        }
      });
  };

  const imageArray = [phantom, bird, panda, rabbit, pig, fox, bear, crow, cow, snail, penguin, frog, raccoon, zebra, alien, elephant, ant, axolotl];


  const handleUserColor = (user, color) => {
    setUserColors(prevUserColors => [...prevUserColors, { user, color }]);
  };

  useEffect(() => {
    // Do something with the updated userColors whenever it changes
    // console.log(userColors);
  }, [userColors]);

  
  

  const deleteAllPurchases = () => {
    const groupPurchasesRef = firebase.database().ref(`groups/${groupId}/purchases`);

    // Remove all purchases from the database
    groupPurchasesRef.remove()
      .then(() => {
        // Purchases successfully deleted
        setGroupPurchases([]); 
        setConfirmDeletePurchasesVisible(false);// Update the state to reflect the changes
      })
      .catch((error) => {
        // Error occurred while deleting purchases
        console.log('Error deleting purchases:', error);
      });
  };
  

// Ensure 'amount' field is a number
function ensureAmountIsNumber(purchase) {
  if (typeof purchase.amount === 'string') {
    purchase.amount = parseFloat(purchase.amount);
  }
  return purchase;
}

  useEffect(() => {
    const groupRef = firebase.database().ref(`groups/${groupId}`);
    let purchases = []; // Declare the purchases variable outside the callback
  
    // Fetch group details
    groupRef.on('value', (snapshot) => {
      const groupData = snapshot.val();
      if (groupData) {
   
        setGroupName(groupData.name);
        setGroupMembers(Object.keys(groupData.members || {}));
        const membersCount = Object.keys(groupData.members || {}).length;
        setGroupMembersCount(membersCount);
      }
    });
  
    // Fetch group's purchases
  // Fetch group's purchases
groupRef.child('purchases').on('value', (snapshot) => {
  const groupPurchasesData = snapshot.val() || {};
  
  
  purchases = Object.entries(groupPurchasesData)
    .map(([key, value]) => ({
      ...ensureAmountIsNumber(value),
      purchaseId: key
    }))
    .sort((a, b) => new Date(b.date) - new Date(a.date));
  
  setGroupPurchases(purchases);
});
  

    // Clean up listeners
    return () => {
      groupRef.off();
      groupRef.child('purchases').off();
    };
  }, [groupId]);

  document.addEventListener('DOMContentLoaded', () => {
    const randomDelay = Math.random() * (1.6 - 0.4) + 0.4; // Generate a random delay between 0.4 and 1.6 seconds
  
    const element = document.querySelector('.leadercreature');
    if (element) {
      element.style.setProperty('--random-delay', `${randomDelay}s`);
    }
  });


  // const handleCategoryFilterChange = (e) => {
  //   setCategoryFilter(e.target.value.toLowerCase());
  // };

  const handleDateFilterFromChange = (e) => {
    const newDateFilterFrom = e.target.value;
    // console.log('dateFilterFrom:', newDateFilterFrom);
    setDateFilterFrom(newDateFilterFrom);
  };
  
  const handleDateFilterToChange = (e) => {
    const newDateFilterTo = e.target.value;
    // console.log('dateFilterTo:', newDateFilterTo);
    setDateFilterTo(newDateFilterTo);
  };

const handleCloseGroupDetail =() => {
  setSelectedGroupDetail('');
}

const handlePurchaseClick = (purchase) => {
  setSelectedPurchase(purchase);
  setShowPurchaseDetail(true);
  setShowInputWidget(false);
};

const handleClosePurchaseDetail = (purchase) => {
 
 
    const fullscreencontainer = document.querySelector(".fullscreencontainer");
    
    if (fullscreencontainer) {
      fullscreencontainer.classList.add("animateaway");
      setTimeout(() => {
        setShowPurchaseDetail(false);
        setSelectedPurchase(null);
        setShowInputWidget(true);
      }, 200);
    }
  
};


const unfilteredPurchases = groupPurchases;


  const getTotalPurchasesByUser = () => {
    const purchasesByUser = {};
    unfilteredPurchases.forEach((purchase) => {
      const { addedBy, amount, alias, creature } = purchase;
      if (purchasesByUser[addedBy]) {
        purchasesByUser[addedBy].amount += amount;
        // If you want to keep the most recent alias and creature
        purchasesByUser[addedBy].alias = alias;
        purchasesByUser[addedBy].creature = creature;
      } else {
        purchasesByUser[addedBy] = { amount, alias, creature };
      }
    });
    return purchasesByUser;
  };

  const handleDeletePurchase = (purchaseId, userEmail, userId) => {
    // Reference to the group purchases
    const groupPurchasesRef = firebase.database().ref(`groups/${groupId}/purchases`);
  
    // Fetch all purchases from the group
    groupPurchasesRef.once('value', (snapshot) => {
      const allPurchases = snapshot.val();
      
  
      // Loop over all purchases
      for(let key in allPurchases) {
        // If the purchase id matches and the email of the person who added the purchase matches the current user's email
        if(allPurchases[key].id === purchaseId && allPurchases[key].addedBy.email === userEmail) {
          // If both match, delete the purchase
          const purchaseRef = groupPurchasesRef.child(key);
          purchaseRef.remove()
            .then(async () => {
              console.log("Group Purchase successfully deleted");
              // Refresh or update your groupPurchases state here
  
              // Reference to the user's purchases
              const userPurchasesRef = firebase.database().ref(`users/${userId}/purchases`);
  
              // Fetch all purchases from the user
              const userPurchasesSnapshot = await userPurchasesRef.once('value');
              const allUserPurchases = userPurchasesSnapshot.val();
  
              // Loop over all user purchases
              for(let userKey in allUserPurchases) {
                // If the purchase id matches
                if(allUserPurchases[userKey].id === purchaseId) {
                  // If it matches, delete group-related properties from the user's purchase
                  const userPurchaseRef = userPurchasesRef.child(userKey);
                  await userPurchaseRef.child('group').remove();
                  await userPurchaseRef.child('groupId').remove();
                  await userPurchaseRef.child('groupAmount').remove();
                  await userPurchaseRef.child('groupWasModified').remove();
  
                  console.log("User Purchase updated successfully");

                  
                  break; // Stop the loop once we've found and updated the user purchase
                }
              }
            })
            .catch((error) => {
              console.log("Error deleting group purchase:", error);
            });
          
          break; // Stop the loop once we've found and deleted the group purchase
        }
      }
    });
  };
  
  
  const calculateTotals = (purchasesByUser) => {
    const grouptotals = {};
    Object.keys(purchasesByUser).forEach((user) => {
      const amount = parseFloat(purchasesByUser[user].amount);
      if (!isNaN(amount)) {
        grouptotals[user] = amount.toFixed(2);
      } else {
        console.log("Invalid amount:", purchasesByUser[user].amount);
        // Handle the invalid amount case as per your requirements
      }
    });
    return grouptotals;
  };

  const calculateDifference = (purchasesByUser) => {
    const difference = [];
    const amounts = Object.values(purchasesByUser).map(obj => parseFloat(obj.amount));
    const total = amounts.reduce((acc, curr) => acc + curr, 0);
    const maxAmount = Math.max(...amounts);
    const maxUser = Object.keys(purchasesByUser).find(
      (user) => parseFloat(purchasesByUser[user].amount) === maxAmount
    );
  
    Object.keys(purchasesByUser).forEach((user) => {
      const userTotal = parseFloat(purchasesByUser[user].amount);
      if (user === maxUser) {
        difference.push({
          user,
          alias: purchasesByUser[user].alias,
          creature: purchasesByUser[user].creature,
          value: 'Currently in the lead',
          leader: true,
        });
      } else {
        const amountNeeded = maxAmount - userTotal;
        
        difference.push({
          user,
          alias: purchasesByUser[user].alias,
          creature: purchasesByUser[user].creature,
          value: `${currencySymbol}${amountNeeded.toFixed(2)} to catch up`,
          leader: false,
        });
      }
    });
  
    // Sort the difference array so that the current leader is always on top
    difference.sort((a, b) => {
      if (a.leader) {
        return -1; // a comes before b
      } else if (b.leader) {
        return 1; // b comes before a
      } else {
        return 0; // maintain the original order
      }
    });
  
    return difference;
  };
  

  const unfilteredPurchasesByUser = getTotalPurchasesByUser(unfilteredPurchases);
  const purchasesByUser = getTotalPurchasesByUser(unfilteredPurchases);
  const difference = calculateDifference(purchasesByUser);
  const grouptotals = calculateTotals(unfilteredPurchasesByUser);

  const handleCloseEditGroupName = () => {
    setEditingGroupName(false);
  };

  const handleEditGroupName = () => {
    setEditingGroupName(true);
    setNewGroupName(groupName);
  };

  const handleSaveGroupName = () => {
    const groupRef = firebase.database().ref(`groups/${groupId}`);
    groupRef.update({ name: newGroupName });
    setEditingGroupName(false);
  };

  useEffect(() => {

     const sortedPurchases = [...unfilteredPurchases].sort((a, b) => new Date(b.date) - new Date(a.date));
  // Calculate grouped purchases by date
  const groupedByDate = {};
  sortedPurchases.forEach((purchase) => {
    const purchaseDate = new Date(purchase.date).toLocaleDateString();
    if (!groupedByDate[purchaseDate]) {
      groupedByDate[purchaseDate] = [];
    }
    groupedByDate[purchaseDate].push(purchase);
  });

    // Flatten the grouped purchases into an array
    const flattenedPurchases = Object.entries(groupedByDate).flatMap(
      ([date, purchases]) => {
        return [
          { type: 'dateRow', date },
          ...purchases,
        ];
      }
    );

    // Update the state variable
    setGroupedPurchases(flattenedPurchases);
  }, [unfilteredPurchases]);

  const handleToggleInviteSection = () => {
    setInviteSectionVisible(!inviteSectionVisible);
    setShowInputWidget(false);
  };

  const handleCloseInviteSection = () => {
    setInviteSectionVisible(false);
  };

  const menuRef = useRef(null);

  const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
          setGroupOptionsVisible(false);
      }
  };

  const handleClickOutsideGroupPurchase = (event) => {
    if (groupPurchaseRef.current && !groupPurchaseRef.current.contains(event.target)) {
        handleClosePurchaseDetail();
    }
};

  useEffect(() => {
    if (newMemberRef.current) {
      newMemberRef.current.focus();
    }
  }, [inviteSectionVisible]);

  useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
          document.removeEventListener("mousedown", handleClickOutside);
      };
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideGroupPurchase);
    return () => {
        document.removeEventListener("mousedown", handleClickOutsideGroupPurchase);
    };
}, []);

const handleUpdateRecurringPurchase = async (id) => {
  const updatedPurchase = {
    recurDay: editRecurDay ? editRecurDay : selectedRecurringPurchase.recurDay,
    note: noteInput.current.value,
    amount: amountInput.current.value,
    icon: newRecurringGroupPurchaseIcon ? newRecurringGroupPurchaseIcon : selectedRecurringPurchase.icon,
    category: categoryInput.current.value,
  };
  
  let key;
  const groupRecurringRef = firebase.database().ref(`groups/${groupId}/recurringPurchases`);
  await groupRecurringRef.once("value").then(function(snapshot) {
    snapshot.forEach(function(childSnapshot) {
      var childKey = childSnapshot.key;
      var childData = childSnapshot.val();
      if(childData.id === id){
        key = childKey;
      }
    });
  });

  groupRecurringRef.child(key).update(updatedPurchase);
  setEditId(null);
  setDeleteConfirmationId(null);
  setNewGroupPurchaseIcon("🧾");
  setShowInputWidget(true);
  setSelectedRecurringPurchase(null); // After updating, reset the selected recurring purchase
};

const handleDeleteConfirmation = (id) => {
  setDeleteConfirmationId(id);
};

const handleDeleteRecurringPurchase = (id) => {
  let key;
  const groupRecurringRef = firebase.database().ref(`groups/${groupId}/recurringPurchases`);
  groupRecurringRef.once("value")
    .then(function(snapshot) {
      snapshot.forEach(function(childSnapshot) {
        var childKey = childSnapshot.key;
        var childData = childSnapshot.val();
        if(childData.id === id){
          key = childKey;
        }
      });

      // Make sure this only executes after we have the key
      if(key) {
        groupRecurringRef.child(key).remove();
      }
      
      setDeleteConfirmationId(null);
    setEditId(null);
    setNewGroupPurchaseIcon("🧾");
    setSelectedRecurringPurchase(null);
    setShowInputWidget(true);
  });
};

  return (
    <div className="group-detail">

{showPurchaseDetail && (
 <div className="fullscreencontainer"> <div className="grouppurchasedetailcontainer" ref={groupPurchaseRef} ><GroupPurchaseDetail groupId={groupId} purchase={selectedPurchase} handleClosePurchaseDetail={handleClosePurchaseDetail} currencySymbol={currencySymbol} handleDeletePurchase={handleDeletePurchase} currentUserEmail={currentUserEmail}/></div> </div>
)}

      {!editingGroupName && <h2 className="group-name">{groupName}<div className="groupdetailoptions">{!groupOptionsVisible && <FaSlidersH onClick={handleOpenOptions}/>}</div></h2>}
      <h2 className="group-name">
        {/* {editingGroupName ? (
          <>
            <input
            className="editinggroupnameinput"
              type="text"
              value={newGroupName}
              onChange={(e) => setNewGroupName(e.target.value)}
            /><br></br>
            <button className="saveeditedgroup" onClick={handleSaveGroupName}>Save</button>
          </>
        ) : (
          <>
            
            <br></br><button className="editsavedgroup" onClick={handleEditGroupName}>Edit Group Name</button>
          </>
        )} */}
            {groupOptionsVisible && 
      <div ref={menuRef} className= "optionscomponentcontainer2">
 <div className="optionsmenucontainer">
 <div
                    className="closeoptionsmenucontainer"
                  
                >
                    <button className="closeoptionsmenubutton">
                        <GrClose onClick={handleCloseOptions}/>
                    </button>
                </div>
           
                <div className="optionsoptions">
                    <div className="optionselections">
                    <button className="menuoption" onClick={handleShowRecurringGroupPurchases}>
                      {showRecurring ? "Show Leaderboard" : "Recurring Expenses"}
                        </button>
                    <button className="menuoption" onClick={handleManualAddPurchase}>
                      Add Manual Expense
                      </button>
                    <button className="menuoption" onClick={handleToggleInviteSection}>
                        Invite Member
                        </button>
                    <button className="menuoption" onClick={handleOpenDeletePurchase}>
                          Reset Purchases
                        </button>
                        <button className="menuoption" style={{paddingBottom: "12px"}} onClick={handleOpenLeaveGroup}>
                         Leave Group
                        </button>
                        
                        {/* <button className="menuoptiondelete"
                        onClick={handleToggleDeleteConfirmation}>
                          Delete Group
                        </button> */}
                        </div></div>
                        </div></div>}</h2>

      

      <div className="table-container">
      {!showRecurring && <table style={{ backgroundColor: "#fffffe", paddingRight: "4px", borderRadius: "10px", borderBottom: "none", marginBottom: "5px"}}> <tbody>
  {difference.map((item) => {
    // Find the userColor that matches the item.user
    const userColor = userColors.find((color) => color.user === item.user);
    const backgroundColor = userColor ? userColor.color : '';



    return (
      
      <tr style={{ backgroundColor: "transparent", borderBottom: "none"}} key={item.user}>
      
        <td className="tableheadercc" colSpan="2" style={{ backgroundColor: "transparent", borderBottom: "none"}}>
        <div className="grouptotalscontainer">
        {userColor && <div className="colordot2" style={{ backgroundColor }}><img className="leadercreature" src={item.creature ? imageArray[item.creature] : imageArray[0]} /></div>}
          
        <div>
          <div className="leaderusername">
  {item.alias && item.alias.trim() !== '' ? item.alias : item.user}
</div>
            <div className="leaderstatus">{item.value}</div>
          </div></div>
        </td>
        <td className="tablenumbergroup2" style={{ backgroundColor: "transparent", borderBottom: "none"}}>{currencySymbol}{grouptotals[item.user]}</td>
      </tr>
    );
  })}
  </tbody>
</table>}





{/* <button onClick={checkRecurringPurchasesLogic}>Check Logic</button> */}



{showRecurring && (
  <div>
    <div className="recurring-purchases-info">
      Recurring expenses are automatically added to the group on whichever day of the month is chosen by the owner of the expense.
      <br></br>
      <br></br>
    </div><div className="add-new-recurring-expense-button-container">
    
    
    <button className="add-new-recurring-expense-button" onClick={handleShowNewRecurringPurchase}>Add Recurring Expense</button>
    <button className="add-new-recurring-expense-button2" onClick={() => {setShowRecurring(false)}}>Back to Leaderboard</button>
    </div>
    <div className="recurring-purchases-div-container">
      <div className="recurring-purchases-div-header">Recurring Group Expenses</div>
      <table className="recurring-purchases-div-container" style={{ marginBottom: "10px", backgroundColor: "transparent" }}>
        <tbody>
          {recurringPurchases.length > 0 ? (
            recurringPurchases.map((purchase) => (
              <React.Fragment key={purchase.key}>
                <tr style={{ padding: "10px", backgroundColor: "transparent" }} key={purchase.id}>
                  <td className="tablenumbergroup3" style={{ width: "10%", border: "none", backgroundColor: "transparent" }}>
                    <div className="groupiconcontainer">{purchase.icon}</div>
                  </td>
                  <td style={{ border: "none" }} className="tableheaderdd">
                    <div className="groupcategorynotecontainer">
                      <div className="grouptablecategory">{purchase.category}</div>
                      <div className="grouptablenote">{purchase.note}</div>
                    </div>
                  </td>
                  <td style={{ border: "none" }} className="tablenumbergroup2">
                    <div className="groupamountcontainer">
                      <div className="grouptableaddedby">
                        {purchase.alias && purchase.alias.trim() !== "" ? purchase.alias : purchase.addedBy}
                      </div>
                      <div className="grouptableamount">
                        {currencySymbol}
                        {parseFloat(purchase.amount).toFixed(2)}
                      </div>
                    </div>
                  </td>
                </tr>
                <tr style={{ border: "none", backgroundColor: "transparent", position: "relative" }} className="recurring-purchase-date-header">
                  <td className="recurring-purchase-date-text" style={{ textAlign: "left" }} colSpan="3">
                    Recurs monthly on the {purchase.recurDay}
                    {getOrdinalSuffix(purchase.recurDay)}
                    {currentUserEmail === purchase.addedBy && (
                      <button
                        className="recurring-purchase-edit-button"
                        onClick={() => {
                          setEditId(editId !== purchase.id ? purchase.id : null);
                          setSelectedRecurringPurchase(purchase);
                          setEditRecurDay(null);
                          setShowInputWidget(false);
                        }}
                      >
                        Edit
                      </button>
                    )}
                    <div className="recurring-purchase-bottomborder"></div>
                  </td>
                </tr>
              </React.Fragment>
            ))
          ) : (
            <tr>
              <td className="recurring-purchases-div-container2" style={{textAlign: "center"}} colSpan="3">
                No recurring expenses found. 
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
    
  </div>
  
)}







{groupedPurchases.length > 0 && !showRecurring &&
<GroupLineChart groupPurchases={groupPurchases} onUserColor={handleUserColor} currencySymbol={currencySymbol} userColors={userColors} />}

{groupedPurchases.length === 0 && !showRecurring && (<div className="nopurchaseempty"><div className="emptystatecontainer"><div className="citybackcontainer">
<img className="lilman" src={lilman} />
<img className="lilwindow" src={lilwindow} />
<img className="bigwindow" src={bigwindow} /><img className="lightmast" src={lightmast} /><img className="lightpole" src={lightpole} />
<img className="cityback2" src={cityback2} alt="Cityback2" /></div>
</div><div className="nopurchasefoundtext">This group is a ghost town!</div>
<div className="nopurchasefoundtext2">Add some purchases to bring it to life</div></div>)}



{groupedPurchases.length > 0 && !showRecurring && (<div> <div className="grouppurchasehistory">Purchase History</div>
        <table className="purchase-table">
          <thead>
           
            <tr style={{backgroundColor: "transparent"}} className="purchasedates">
              <td style={{padding: "0px"}} colSpan="3">
                <div className="grouptablefilters">
                  <input
                    type="text"
                    value={groupCategoryFilter}
                    onChange={(e) =>
                      setGroupCategoryFilter(e.target.value)
                  }
                    placeholder="Search group purchases"
                    className="filter-input1"
                  />
                  {/* <input
                    type="date"
                    value={dateFilterFrom}
                    onChange={handleDateFilterFromChange}
                    placeholder="Filter from date"
                    className="filter-input2"
                  />
                  <input
                    type="date"
                    value={dateFilterTo}
                    onChange={handleDateFilterToChange}
                    placeholder="Filter to date"
                    className="filter-input2"
                  /> */}
                </div>
              </td>
            </tr>
          </thead>
          <tbody>
          {groupedPurchases
     .filter(
              (item) =>
                item.type === 'dateRow' || // Always include date rows
                item.category.toLowerCase().includes(groupCategoryFilter.toLowerCase()) ||
                item.note.toLowerCase().includes(groupCategoryFilter.toLowerCase()) ||
                item.addedBy.toLowerCase().includes(groupCategoryFilter.toLowerCase()) ||
                item.alias?.toLowerCase().includes(groupCategoryFilter.toLowerCase()) ||
                item.amount.toString().includes(groupCategoryFilter.toLowerCase()) ||
                (dateFilterFrom &&
                  dateFilterTo &&
                  new Date(item.date) >= new Date(dateFilterFrom) &&
                  new Date(item.date) <= new Date(dateFilterTo))
            )
  
    .map((item) => {
              if (item.type === 'dateRow') {
                return (
                  <tr className="purchasedates" key={item.date}>
                    <td className="groupspurchasedatecontainer" colSpan="3">
                      <div className="groupspurchasedate">
                        {new Date(item.date).toLocaleDateString('en-US', {
                          weekday: 'short',
                          month: 'short',
                          day: 'numeric',
                          year: 'numeric',
                        })}
                      </div>
                    </td>
                  </tr>
                );
              } else {
                const purchase = item;
                
                return (
                  <tr key={purchase.id} onClick={() => handlePurchaseClick(purchase)}>
                    
                  <td className="tablenumbergroup2" style={{ width: '10%' }}>
                  {purchase.recurring ? <div className="modifiedpurchaseindicator">Recurring</div> : ""}
                    <div className="groupiconcontainer">{purchase.icon}</div>
                    {purchase.rating || purchase.votes || purchase.comments ? (
                      <div className="worthiticon2"><BsFillChatLeftTextFill style={{color: "rgb(29, 56, 53)"}} /></div>
                    ) : null}
                  </td>
                  <td className="tableheaderdd" >
                    <div className="groupcategorynotecontainer">
                      <div className="grouptablecategory">{purchase.category}</div>
                      <div className="grouptablenote">{purchase.note}</div>
                    </div>
                  </td>
                  <td className="tablenumbergroup2">
                    <div className="groupamountcontainer">
                      <div className="grouptableaddedby">
                        {purchase.alias && purchase.alias.trim() !== "" ? purchase.alias : purchase.addedBy}
                        
                      </div>
                      <div className="grouptableamount">
                        {currencySymbol}
                        {parseFloat(purchase.amount).toFixed(2)}
                      </div>
                      
                    </div>
                    
                  </td>
                  
                </tr>
                );
              }
            })}
          </tbody>
        </table></div>)}
      </div>




      {!inviteSectionVisible && groupedPurchases.length === 0 && (
  <p className="group-members-count-empty">
    {groupMembersCount === 1 ? '1 person' : `${groupMembersCount} people`} in this group
  </p>
)}
{groupedPurchases.length > 0 && (
  <p className="group-members-count">
    {groupMembersCount === 1 ? '1 person' : `${groupMembersCount} people`} in this group
  </p>
)}

<div className="addsomeonecontainer">
     {/* <button onClick={handleToggleInviteSection} className= {inviteSectionVisible ? 'dontdisplay' : 'toggleinvitebuttonshow'}>
        {inviteSectionVisible ? '' : 'Add Someone to this Group'}
      </button> */}
      </div>












      {editId && selectedRecurringPurchase &&

<div className="confirmation-message">
  <div className="resetconfirmbottom">
    <div className="divaccent4"></div>
    
    <GoalEmojiModal
  showModal={showEmojiModal}
  emojiCategories={emojiCategories}
  selectedEmoji={selectedRecurringPurchase.icon} 
  handleEmojiClick={handleRecurringGroupPurchaseIconUpdate}
  closeModal={() => setShowEmojiModal(false)}
/>
    <div className="group-manual-add-purchase">

    {deleteConfirmationId === selectedRecurringPurchase.id ? (
          
              <div className="delete-recurring-purchase-confirmation">
                <p style={{color: "white", fontWeight: "500"}}>Are you sure you want to delete this recurring purchase?</p>
                <button className="confirmation-delete-group" onClick={() => handleDeleteRecurringPurchase(selectedRecurringPurchase.id)}>Yes, delete it.</button>
                <button className="confirmation-okay" style={{backgroundColor: "transparent"}} onClick={() => setDeleteConfirmationId(null)}>Cancel</button>
              </div>
         
        ) : (
      <form 
        className="group-manual-add-purchase-form" 
        onSubmit={(event) => {
          event.preventDefault();
          handleUpdateRecurringPurchase(selectedRecurringPurchase.id);
        }}
      >
      <div className="group-manual-add-purchase-header">Edit Recurring Expense</div>
    <div className="group-manual-add-purchase-amountandcategory-container">
    <div className="group-manual-add-purcahse-currenyandamount-container">
    <div className="group-manual-add-purchase-currency">{currencySymbol}</div>
    <input 
      className="group-manual-add-purchase-amount"
      name="amount" 
      type="number" 
      placeholder="amt" 
      defaultValue={parseFloat(selectedRecurringPurchase.amount).toFixed(2)}
      ref={amountInput}
      step="0.01"
      required 
    />
    </div>
    <input
      className="group-manual-add-purchase-category" 
        name="category" 
        defaultValue={selectedRecurringPurchase.category} 
        placeholder="Category (Optional)" 
        ref={categoryInput}
      />
      </div>
      <div className="group-manual-add-purchase-iconandnote-container">
      <div
      className="group-manual-add-purchase-icon"
      ref={iconInput}
        onClick={handleToggleEmojiModal}>{selectedRecurringPurchase.icon}</div>
         
      
      <input 
      className="group-manual-add-purchase-note"
        name="note" 
        defaultValue={selectedRecurringPurchase.note} 
        placeholder="Description" 
        maxLength={65}
        required
        ref={noteInput}
      />
      </div>
     

      <div className="group-manual-add-purchase-recurrence">
  {selectedRecurringPurchase.recurring && (
    <div className="recurring-purchase-recurrance">
      Recurs on the  
      <input
      className="recurring-purchase-recurrance-input"
      ref={recurDayInput}
        type="number"
        min="1"
        max="31"
        name="recurDay"
        defaultValue={selectedRecurringPurchase.recurDay}
        required
        onChange={(event) => setEditRecurDay(event.target.value)}
      />
      <span className="ordinal-suffix">{getOrdinalSuffix(editRecurDay ? editRecurDay : selectedRecurringPurchase.recurDay)}</span>
    </div>
  )}
</div>
<button className="delete-recurring-group-purchase" onClick={() => handleDeleteConfirmation(selectedRecurringPurchase.id)}>Delete Recurring Expense</button>
      <button 
          disabled={!(selectedRecurringPurchase.amount && selectedRecurringPurchase.note && (editRecurDay !== "" || selectedRecurringPurchase.recurDay !== selectedRecurringPurchase.recurDay) && selectedRecurringPurchase.amount.toString().trim().length > 0 && selectedRecurringPurchase.note.trim().length > 0)} 
          className="confirmation-okay-dark" 
          type="submit"
        >
          Update Recurring Expense
        </button>
        
      </form>)}
    </div>
    <IoIosClose onClick={() => {setSelectedRecurringPurchase(null);
    setEditRecurDay(null);
    setEditId(null);
    setDeleteConfirmationId(null);
    setNewGroupPurchaseIcon("🧾");
    setShowInputWidget(true);
    }} style={{position: "absolute", top: "14px", right: "8px",fontSize: "1.5rem", color: "#c2c2c2"}}/>
  </div>
</div>
}











      {showManualGroupPurchase && 

<div className="confirmation-message">
  <div className="resetconfirmbottom">
    <div className="divaccent4"></div>
    <button
    className="diffdatebutton"
    onClick={() => {
        setShowAddPurchase(!showAddPurchase);
        if (!showAddPurchase && showNumberPad) {
            setShowNumberPad(false);
        }
    }}
>
{selectedDate.toDateString() === new Date().toDateString()
                    ? "Today"
                    : selectedDate.toDateString()}
                <AiFillCaretDown
                    style={{
                        fontSize: "0.9rem",
                        paddingTop: "3px",
                        paddingLeft: "5px",
                        boxSizing: "border-box",
                    }}
                />
            </button>
    {showNumberPad && <NumberPad handleNumberPress={handleNumberPress} />}
    {showAddPurchase && (
                <CSSTransition
                    in={showAddPurchase}
                    timeout={300}
                    classNames="datepicker-slide"
                    unmountOnExit
                >
                    <div className="diffdatecontainer">
                        <AddPurchase
                            handleDateChange={handleDateChange}
                            selectedDate={selectedDate}
                            setSelectedDate={setSelectedDate}
                          
                        />
                    </div>
                </CSSTransition>
            )}
            <GoalEmojiModal
          showModal={showEmojiModal}
          emojiCategories={emojiCategories}
          selectedEmoji={newGroupPurchaseIcon} 
          handleEmojiClick={handleGroupPurchaseIconUpdate} 
          closeModal={() => setShowEmojiModal(false)}
        />
        <div className="group-manual-add-purchase">
          
    <form className="group-manual-add-purchase-form" onSubmit={handleSubmit}>
      <div className="group-manual-add-purchase-header">Add Group Expense</div>
    <div className="group-manual-add-purchase-amountandcategory-container">
    <div className="group-manual-add-purcahse-currenyandamount-container">
    <div className="group-manual-add-purchase-currency">{currencySymbol}</div>
    <input 
      className="group-manual-add-purchase-amount"
      name="amount" 
      type="number" 
      value={form.amount} 
      onChange={handleChange} 
      placeholder="amt" 
      // onClick={() =>  setShowNumberPad((prevState) => !prevState)}
      required 
    />
    </div>
      <input
      className="group-manual-add-purchase-category" 
        name="category" 
        value={form.category} 
        onChange={handleChange} 
        placeholder="Category (Optional)" 
        
      />
      </div>
      <div className="group-manual-add-purchase-iconandnote-container">
      <div
      className="group-manual-add-purchase-icon"
          
        onClick={handleToggleEmojiModal}>{newGroupPurchaseIcon}</div>
         
      
      <input 
      className="group-manual-add-purchase-note"
        name="note" 
        value={form.note} 
        onChange={handleChange} 
        placeholder="Description" 
        maxLength={65}
        required
        
      />
      </div>
     

      <div className="group-manual-add-purchase-recurrence">
  <div className="recurring-checkbox-container">
    <input
      type="checkbox"
      name="recurring"
      checked={form.recurring}
      onChange={handleRecurringChange}
    />
    Recurring
  </div>
  {form.recurring && (
    <div className="recurring-purchase-recurrance">
      Recurs on the  
      <input
      className="recurring-purchase-recurrance-input"
        type="number"
        min="1"
        max="31"
        name="recurDay"
        value={form.recurDay}
        onChange={handleRecurDayChange}
        required
      />
      <span className="ordinal-suffix">{getOrdinalSuffix(form.recurDay)}</span>
    </div>
  )}
</div>
      {/* <input 
        name="date" 
        type="date" 
        value={form.date || new Date().toISOString().split('T')[0]} 
        onChange={handleChange} 
        required 
      /> */}
      <button disabled={!(form.amount && form.note && form.amount.toString().trim().length > 0 && form.note.trim().length > 0)} className="confirmation-okay-dark" type="submit">Add Group Expense</button>
    </form></div>
    <IoIosClose onClick={handleCloseManualAddPurchase} style={{position: "absolute", top: "14px", right: "8px",fontSize: "1.5rem", color: "#c2c2c2"}}/>
  </div>
</div>
}





{newRecurringExpense && 

<div className="confirmation-message">
  <div className="resetconfirmbottom">
    <div className="divaccent4"></div>
            <GoalEmojiModal
          showModal={showEmojiModal}
          emojiCategories={emojiCategories}
          selectedEmoji={newGroupPurchaseIcon} 
          handleEmojiClick={handleGroupPurchaseIconUpdate} 
          closeModal={() => setShowEmojiModal(false)}
        />
        <div className="group-manual-add-purchase">
          
    <form className="group-manual-add-purchase-form" onSubmit={handleRecurringExpenseSubmit}>
      <div className="group-manual-add-purchase-header">Add Recurring Group Expense</div>
    <div className="group-manual-add-purchase-amountandcategory-container">
    <div className="group-manual-add-purcahse-currenyandamount-container">
    <div className="group-manual-add-purchase-currency">{currencySymbol}</div>
    <input 
      className="group-manual-add-purchase-amount"
      name="amount" 
      type="number" 
      value={form.amount} 
      onChange={handleChange} 
      placeholder="amt" 
      // onClick={() =>  setShowNumberPad((prevState) => !prevState)}
      required 
    />
    </div>
      <input
      className="group-manual-add-purchase-category" 
        name="category" 
        value={form.category} 
        onChange={handleChange} 
        placeholder="Category (Optional)" 
        
      />
      </div>
      <div className="group-manual-add-purchase-iconandnote-container">
      <div
      className="group-manual-add-purchase-icon"
          
        onClick={handleToggleEmojiModal}>{newGroupPurchaseIcon}</div>
         
      
      <input 
      className="group-manual-add-purchase-note"
        name="note" 
        value={form.note} 
        onChange={handleChange} 
        placeholder="Description" 
        maxLength={65}
        required
        
      />
      </div>
     

      <div className="group-manual-add-purchase-recurrence">
 
 
    <div className="recurring-purchase-recurrance">
      Recurs on the  
      <input
      className="recurring-purchase-recurrance-input"
        type="number"
        min="1"
        max="31"
        name="recurDay"
        value={form.recurDay}
        onChange={handleRecurDayChange}
        required
      />
      <span className="ordinal-suffix">{getOrdinalSuffix(form.recurDay)}</span>
    </div>
  
</div>
      {/* <input 
        name="date" 
        type="date" 
        value={form.date || new Date().toISOString().split('T')[0]} 
        onChange={handleChange} 
        required 
      /> */}
      <button disabled={
    !(form.amount && form.note && form.amount.toString().trim().length > 0 && form.note.trim().length > 0) ||
    form.recurDay < 1 ||
    form.recurDay > 31
  }  className="confirmation-okay-dark" type="submit" >Add Recurring Group Expense</button>
    </form></div>
    <IoIosClose onClick={handleCloseNewRecurringPurchase} style={{position: "absolute", top: "14px", right: "8px",fontSize: "1.5rem", color: "#c2c2c2"}}/>
  </div>
</div>
}





      {inviteSectionVisible && (
       

<div className="confirmation-message">
<div className="resetconfirmbottom">
    <div className="divaccent4"></div>
    <div className="createnewgroupformheader1">Add a Group Member</div>
    <p className="addmembergroupheader2">{groupMembersCount === 1 ? '1 member' : `${groupMembersCount} members`} in this group

    </p>
    <div className="buttons-container">
    <div className="invitation-message">
        {invitationStatus === 'success' && (
          <span className="success-message-invite">Group member added!</span>
        )}
        {invitationStatus === 'error' && (
          <span className="error-message-invite">User not found</span>
        )}
      </div>
        {/* <div className="boxoballs2">
            <div className="ball1"></div>
            <div className="ball2"></div>
            <div className="ball3"></div>
            <div className="ball4"></div>
        </div> */}
       <input
       ref={newMemberRef}
        type="text"
        value={inviteEmail}
        onChange={(e) => setInviteEmail(e.target.value)}
        placeholder="Enter a valid user's email"
        className="invite-input"
      />
   
      <div className="yesno">
        <button className="confirmation-okay-dark" onClick={handleInviteMember}>
          Add Person to Group
        </button>
            <button
                className="confirmation-no-dark"
                onClick={() => { handleCloseInviteSection(); handleCloseInputWidget(); }}
            >
               <IoIosClose style={{fontSize: "1.5rem"}}/>
            </button>
        </div>
    </div>
</div>
</div>
      )}


{confirmDeletePurchasesVisible && (
       

       <div className="confirmation-message">
       <div className="resetconfirm">
           <div className="divaccent"></div><br></br>
           Delete All Purchases in This Group?<br></br>
              This cannot be undone!
           <div className="buttons-container">
               <div className="boxoballs2">
                   <div className="ball1"></div>
                   <div className="ball2"></div>
                   <div className="ball3"></div>
                   <div className="ball4"></div>
               </div>
             
             <div className="yesno">
               <button className="confirmation-okay" onClick={deleteAllPurchases}>
                 Delete All Purchases
               </button>
                   <button
                       className="confirmation-no"
                       onClick={handleCloseDeletePurchase}
                   >
                       Close
                   </button>
               </div>
           </div>
       </div>
       </div>
             )}

{confirmLeaveGroupVisible && (
       

       <div className="confirmation-message">
       <div className="resetconfirm">
           <div className="divaccent"></div>
           <br></br>
           Are you sure you want to leave this group?<br></br>
            
           <div className="buttons-container">
               <div className="boxoballs2">
                   <div className="ball1"></div>
                   <div className="ball2"></div>
                   <div className="ball3"></div>
                   <div className="ball4"></div>
               </div>
                        
             <div className="yesno">
               <button className="confirmation-okay" onClick={leaveGroup}>
                 Leave Group
               </button>
                   <button
                       className="confirmation-no"
                       onClick={handleCloseLeaveGroup}
                   >
                       Close
                   </button>
               </div>
           </div>
       </div>
       </div>
             )}

{/* {!deleteConfirmationVisible && !inviteSectionVisible &&
   
        <div className="deletegroupcontainer"><button className="deletegroupbutton" onClick={handleToggleDeleteConfirmation}>Delete Group</button></div>
      
    } */}

{deleteConfirmationVisible && (
     <div className="confirmation-message">
     <div className="resetconfirm">
         <div className="divaccent3"></div>
         <p className="paydayheader4">
             <br></br>Are you sure you want to delete this group?<br></br><br></br>This cannot be undone!
         </p>
         <div className="buttons-container">
             <div className="boxoballs2">
                 <div className="ball1"></div>
                 <div className="ball2"></div>
                 <div className="ball3"></div>
                 <div className="ball4"></div>
             </div>
             <div className="yesno">
                 <button
                     className="confirmation-delete-group"
                     onClick={handleDeleteGroup}
                 >
                     Yes, delete group
                 </button>
                 <button
                     className="confirmation-no"
                     onClick={handleToggleDeleteConfirmation}
                         
                     
                 >
                     Cancel
                 </button>
             </div>
         </div>
     </div>
 </div>
    )}

    </div>
  );
};

export default GroupDetail;
