import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import thermometer from './images/thermometer.svg';
import { FaLessThanEqual } from 'react-icons/fa';

const LeftoverWidgetChart = ({ totalBudget, remainingBudget, currencySymbol, spentPercentageColor }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const ctx = chartRef.current.getContext('2d');

    const chart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: ['Budget'],
        display: false,
        datasets: [
          {
            label: 'Total',
            data: [totalBudget - remainingBudget],
            backgroundColor: 'transparent',
            borderRadius: 25, // Set the border radius for the total bar
            borderSkipped: false,
          },
          {
            label: 'Remaining',
            data: [remainingBudget < 0 ? 0 : remainingBudget],
            backgroundColor: spentPercentageColor,
            borderRadius: 25, // Set the border radius for the remaining bar
            borderSkipped: false,
          },
       
        ],
      },
      options: {
        layout: {
          padding: {
            bottom: 0,
          },
        },
        aspectRatio: false,
        plugins: {
          tooltip: {
            enabled: false,
          },
          legend: {
            display: false,
          },
        },
        indexAxis: 'y',
        responsive: true,
        scales: {
          x: {
            display: false,
            max: totalBudget,
            grid: {
              display: false,
              color: 'rgb(55, 121, 129)',
            },
            title: false,
            stacked: true,
            ticks: {
              stepSize: 1,
              display: false,
              beginAtZero: true,
              max: totalBudget,
              callback: function (value, index, values) {
                return value.toLocaleString('en-US', { currencySymbol });
              },
              color: 'rgb(27, 27, 27, 0)',
            },
          },
          y: {
            display: false,
            title: false,
            stacked: true,
            ticks: {
              display: false,
              stepSize: 1,
            },
            color: 'rgb(27, 27, 27)',
          },
        },
        legend: {
          display: false,
          labels: {
            display: false,
            fontSize: 12,
            fontColor: 'rgb(27, 27, 27)',
          },
        },
      },
    });

    return () => {
      chart.destroy();
    };
  }, [totalBudget, remainingBudget, currencySymbol]);

  return (
    <div className="leftoverwidgetchartdiv">
      <canvas className="thermocanvas" ref={chartRef} />
    </div>
  );
};

export default LeftoverWidgetChart;
