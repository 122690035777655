import React, { useState } from 'react';
import DatePicker from './DatePicker';


function AddPurchase({ handleDateChange }) {

  const [selectedDate, setSelectedDate] = useState(new Date());


  return (<div className="datepicker">
        <DatePicker handleDateChange={handleDateChange}/></div>
  );
}

export default AddPurchase;