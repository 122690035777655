import React, { useState, useEffect } from 'react';
import './UserProfile.css'
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/storage';
import 'firebase/compat/auth';
import bird from "./images/BIRD.svg";
import panda from "./images/PANDA.svg";
import rabbit from "./images/RABBIT.svg";
import pig from "./images/PIG.svg";
import fox from "./images/FOX.svg";
import bear from "./images/BEAR.svg";
import crow from "./images/CROW.svg";
import cow from "./images/COW.svg";
import snail from "./images/SNAIL.svg";
import penguin from "./images/PENGUIN.svg";
import frog from "./images/FROG.svg";
import raccoon from "./images/RACCOON.svg";
import zebra from "./images/ZEBRA.svg";
import alien from "./images/ALIEN.svg";
import elephant from "./images/ELEPHANT.svg";
import ant from "./images/ANT.svg";
import phantom from "./images/PHANTOM.svg";
import axolotl from "./images/AXOLOTL.svg";
import emailjs from '@emailjs/browser';
import {FaSpinner} from "react-icons/fa";
import {  NavLink, useNavigate, useLocation  } from 'react-router-dom';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: "budget-app-d568a.firebaseapp.com",
    databaseURL: "https://budget-app-d568a-default-rtdb.firebaseio.com",
    projectId: "budget-app-d568a",
    storageBucket: "budget-app-d568a.appspot.com",
    messagingSenderId: "219321405159",
    appId: "1:219321405159:web:c4a51c20c9d13376629c42",
    measurementId: "G-6P5Y0TGT7T"
  };
  
  firebase.initializeApp(firebaseConfig);
const db = firebase.database();
const auth = firebase.auth();

const UserProfile = ({handleLogout, logout, showLogoutConfirmation, setShowLogoutConfirmation, setShowAliasMessage, backgroundAnimation, setBackgroundAnimation, currencySymbol, setCurrencySymbol, currencySymbols}) => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [deleteAccountConfirmation, setDeleteAccountConfirmation] = useState('');
  const [emailReminder, setEmailReminder] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [firstName, setFirstName] = useState('');
const [lastName, setLastName] = useState('');
const [alias, setAlias] = useState('');
const [userAlias, setUserAlias] = useState('');
const [showExpandedProfileInfo, setShowExpandedProfileInfo] = useState('');
const [selectedImageIndex, setSelectedImageIndex] = useState(0);
const [loading, setLoading] = useState(true); // Loading state
const [dailyReminderTime, setDailyReminderTime] = useState(null);



  const location = useLocation();
  const navigate = useNavigate();

  // useEffect(() => {
  //   if ('serviceWorker' in navigator) {
  //     navigator.serviceWorker.register('/service-worker.js')
  //       .then((registration) => {
  //         console.log('Service Worker registered:', registration);
  //       })
  //       .catch((error) => {
  //         console.error('Service Worker registration failed:', error);
  //       });
  //   }
  // }, []);

  // useEffect(() => {
  //   if (Notification.permission !== 'granted') {
  //     Notification.requestPermission().then((permission) => {
  //       if (permission === 'granted') {
  //         // Permission granted, you can now send push notifications
  //       } else {
  //         // Permission denied, handle accordingly
  //       }
  //     });
  //   }
  // }, []);

  // const handleEmailReminderChange = (e) => {
  //   const checked = e.target.checked;
  //   if (checked) {
  //     Notification.requestPermission().then((permission) => {
  //       if (permission === 'granted') {
  //         // Permission granted, you can now save the state and register the service worker
  //         setEmailReminder(true);
  
  //         if ('serviceWorker' in navigator) {
  //           navigator.serviceWorker.register('/service-worker.js')
  //             .then((registration) => {
  //               console.log('Service Worker registered:', registration);
  //             })
  //             .catch((error) => {
  //               console.error('Service Worker registration failed:', error);
  //             });
  //         }
  //       } else {
  //         // Permission denied, handle accordingly
  //         setEmailReminder(false);
  //       }
  //     });
  //   } else {
  //     setEmailReminder(false);
  //   }
  // };

  useEffect(() => {
    if (dailyReminderTime) {
      const userId = firebase.auth().currentUser.uid;
      const userRef = firebase.database().ref(`users/${userId}`);
      userRef.update({dailyReminderTime: dailyReminderTime}, function(error) {
        if (error) {
          console.log('Error updating reminder time:', error);
        } else {
          console.log('Reminder time updated successfully!');
        }
      });
    }
  }, [dailyReminderTime]);


  const imageArray = [phantom, bird, panda, rabbit, pig, fox, bear, crow, cow, snail, penguin, frog, raccoon, zebra, alien, elephant, ant, axolotl];

  const handleClickAnimal = async () => {
    const userId = firebase.auth().currentUser.uid;
    setSelectedImageIndex((prevIndex) => {
      // Increment the index by 1 and loop back to 0 if it exceeds the array length
      const newIndex = (prevIndex + 1) % imageArray.length;
      
      // Update the profilePicIndex in the Firebase user data
      const userRef = firebase.database().ref(`users/${userId}`);
      userRef.update({ profilePicIndex: newIndex });
  
      return newIndex;
    });
  };


  useEffect(() => {
    
    const fetchProfilePicIndex = async () => {
      const userId = firebase.auth().currentUser.uid;
      const userRef = firebase.database().ref(`users/${userId}`);
      const userSnapshot = await userRef.once('value');
      const profilePicIndex = userSnapshot.child('profilePicIndex').val();
  
      // If profilePicIndex is null, it means the user has not selected a profile picture yet, so we default to 0
      setSelectedImageIndex(profilePicIndex || 0);
      setLoading(false);
    };
  
    fetchProfilePicIndex();
  }, []);

  useEffect(() => {
    // Set the user's email address and alias in state
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUserEmail(user.email);
        const userRef = db.ref(`users/${user.uid}`);
        userRef.once('value', (snapshot) => {
          const alias = snapshot.child('alias').val();
          setUserAlias(alias);
          setLoading(false);
        });
      }
    });
    return unsubscribe;
  }, []);

  const handleChangeUserCurrency = (event) => {
    const newSymbol = event.target.value;
    const userId = firebase.auth().currentUser.uid;

    setCurrencySymbol(newSymbol);
    const userRef = firebase.database().ref(`users/${userId}`);
    userRef.update({currency: newSymbol}, function(error) {
        if (error) {
            console.log('Error updating currency:', error);
        } else {
            // console.log('Currency updated successfully!');
        }
    });
};

  const handlePasswordChange = () => {
    if (newPassword === confirmPassword) {
      auth.currentUser.updatePassword(newPassword).then(() => {
        // Password updated successfully
        const passwordStatus = document.querySelector('.user-profile__password-status');
        passwordStatus.textContent = 'Password updated successfully';
        setNewPassword('');
        setConfirmPassword('');
        handleLogout();
      }).catch((error) => {
        // An error occurred
        const passwordStatus = document.querySelector('.user-profile__password-status');
        passwordStatus.textContent = `An error occurred: ${error.message}`;
      });
    } else {
      const passwordStatus = document.querySelector('.user-profile__password-status');
      passwordStatus.textContent = 'Passwords do not match!';
    }
  };

  const handleDeleteAccount = () => {
    if (deleteAccountConfirmation === 'DELETE MY ACCOUNT') {
      const currentUser = firebase.auth().currentUser;
  
      if (currentUser) {
        const uid = currentUser.uid;
        // setShowLogoutConfirmation(true); // Set showLogoutConfirmation to true
  
        // Step 1: Delete the user and child nodes from the database
        firebase.database().ref(`/users/${uid}`).remove()
          .then(() => {
            // Step 2: Delete the Firebase Authentication account
            currentUser.delete()
              .then(() => {
                // Account deleted successfully
                document.querySelector(".user-profile__delete-status").textContent = "Account deleted successfully";
              })
              .catch((error) => {
                // An error occurred while deleting the account
                console.error(error);
              });
          })
          .catch((error) => {
            // An error occurred while deleting the user and child nodes from the database
            console.error(error);
          });
      }
    } else {
      document.querySelector(".user-profile__delete-status").textContent = 'Please enter "DELETE MY ACCOUNT" to confirm';
      console.log('Please enter "DELETE MY ACCOUNT" to confirm');
    }
  };


  const handleSave = () => {
    const user = auth.currentUser;
    if (user) {
      const uid = user.uid;
      const userRef = db.ref(`/users/${uid}`);
      userRef
        .update({
          alias: alias
        })
        .then(() => {
          setUserAlias(alias);
          setShowAliasMessage(true);
          setTimeout(() => {
            setShowAliasMessage(false);
          }, 4000); // hide the animation after 4 seconds
          // console.log('User profile updated successfully');
        })
        .catch((error) => {
          console.error('Error updating user profile:', error);
        });
    }
  };

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (!user) {
        logout(); // Or perform any necessary logout action
      }
    });

    return () => {
      unsubscribe(); // Unsubscribe when the component unmounts
    };
  }, []);


  const handleToggleExpandedInfo = () => {
    setShowExpandedProfileInfo(!showExpandedProfileInfo);
};


const handleBackgroundAnimation = async (e) => {
  const userId = firebase.auth().currentUser.uid;
  const userRef = firebase.database().ref(`users/${userId}`);
  const checked = e.target.checked;
  
  setBackgroundAnimation(checked);
  await userRef.update({ animateBackground: checked });
};


  if (loading) {
    return <div className="full-loader"><FaSpinner className="spinner" /></div>;
  }


  return (

    <div className="user-profile">
   

    {showLogoutConfirmation && (
                        <div className="confirmation-message">
                            <div className="resetconfirm">
                                <div className="divaccent"></div>
                                <p className="paydayheader4">
                                    <br></br>Are you sure you want to logout?
                                </p>
                                <div className="buttons-container">
                                    <div className="boxoballs2">
                                        <div className="ball1"></div>
                                        <div className="ball2"></div>
                                        <div className="ball3"></div>
                                        <div className="ball4"></div>
                                    </div>
                                    <div className="yesno">
                                        <button
                                            className="confirmation-okay"
                                            onClick={handleLogout}
                                        >
                                            Yes, log me out
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
      <h1 className="user-profile__title">{userAlias || userEmail}'s Account</h1>
      <div className="profilebigbox">
<div className="profilepicturecontainer">
      <div className="npprofilepicture">
      <img onClick={handleClickAnimal} className="profileanimal" src={imageArray[selectedImageIndex]} alt={`Selected Image`} />
      </div>


      <input
  className="user-profile__input6"
  type="text"
  placeholder={userAlias ? userAlias : "Create an Alias"}
  value={alias}
  onChange={(e) => setAlias(e.target.value)}
/>
</div>



<button className="user-profile__button" onClick={handleSave}>Update Alias</button>


<div className="user-profile__left-aligned-container">


<div className="user-profile__section">
        <h2 className="user-profile__subtitle">Options</h2>
        <div className="user-profile__input-group1">
        <input 
  type="checkbox" 
  id="resourcesaver" 
  className="user-profile__inputbox" 
  checked={backgroundAnimation} 
  onChange={handleBackgroundAnimation} 
/>
<label htmlFor="resourcesaver" className="user-profile__label3">Use animated background</label>
          
        </div>
        {/* <div className="user-profile__input-group1" style={{marginTop: "25px"}}>
        <input 
  type="checkbox" 
  id="email-reminder" 
  className="user-profile__inputbox" 
  checked={emailReminder} 
  onChange={handleEmailReminderChange} 
/>
<label htmlFor="email-reminder" className="user-profile__label3">Send me a daily reminder to log my purchases.</label>
</div>
{emailReminder && (
  <input 
    type="time" 
    id="daily-reminder-time" 
    // className="user-profile__inputbox" 
    value={dailyReminderTime}
    onChange={(e) => {
      setDailyReminderTime(e.target.value);
    }} 
  />
)} */}
        <div className="user-profile__input-group3">
  <select 
    id="currencySelect"
    placeholder={currencySymbol}
    value={currencySymbol} 
    onChange={handleChangeUserCurrency} 
    className="user-profile__inputdropdown"
  >
    {currencySymbols.map(symbol => (
      <option key={symbol} value={symbol}>{symbol}</option>
    ))}
  </select>
  <label htmlFor="currencySelect" className="user-profile__label4">Set currency</label>
</div>
        {/* <div className="user-profile__label2">Sorry, this feature is currently unavailable.</div> */}
      </div>
</div>







<button className="showorhideaccount" onClick={handleToggleExpandedInfo}>{showExpandedProfileInfo ? 'Hide Advanced Controls' : 'Show Advanced Controls'} </button>

{!showExpandedProfileInfo && <div className="user-profile__button-container">
      <div
              className="user-profile__button20"
              onClick={() => {
                navigate('/', { state: { fromExtension: true } });
              }}
            ><div className="close-profile-nav-container">
              <NavLink to="/" className="close-profile-nav">
                Close Profile
              </NavLink>
            </div></div>
          </div>}

{showExpandedProfileInfo && <div className="user-profile__section">
        <div className="user-profile__section">
        <h2 className="user-profile__subtitle">Change Password</h2>
        <div className="user-profile__input-group">
    
          <input type="password" id="new-password" placeholder="Type new password" className="user-profile__input" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
        </div>
        <div className="user-profile__input-group">
          
          <input type="password" id="confirm-password" placeholder="Confirm new password" className="user-profile__input" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
        </div>
        <button className="user-profile__button" onClick={handlePasswordChange}>Change Password</button>
        <div className="user-profile__password-status"></div>
      </div>
  
 
      <div className="user-profile__section">
        <h2 className="user-profile__subtitle">Delete Account</h2>
        <div className="user-profile__input-group">
          <label htmlFor="delete-account-confirmation" className="user-profile__label">To delete your account, type "DELETE MY ACCOUNT" below.</label>
       
          
             <div className="user-profile__label2">This action is irreversible!</div><input type="text" id="delete-account-confirmation" className="user-profile__input" value={deleteAccountConfirmation} onChange={(e) => setDeleteAccountConfirmation(e.target.value)} />
        </div>
        <button className="user-profile__button" onClick={handleDeleteAccount}>Delete Account</button>
        <div className="user-profile__delete-status"></div>
      </div>

     


<div className="user-profile__button-container">
      <div
              className="user-profile__button2"
              onClick={() => {
                navigate('/', { state: { fromExtension: true } });
              }}
            >
              <div className="close-profile-nav-container">
              <NavLink to="/" className="close-profile-nav">
                Close Profile
              </NavLink>
            </div>
            </div></div></div>}
            </div>
<div className="bottomanchor"></div>
    </div>





  );
};

export default UserProfile;