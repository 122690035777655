import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const GoalProgress = ({ goal }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const ctx = chartRef.current.getContext('2d');
    const totalGoalValue = goal.target;
    let allocatedValue = goal.progress;
    let remainingValue = totalGoalValue - allocatedValue;
    const roundNumber = (num, decimalPlaces) => {
      const factor = Math.pow(10, decimalPlaces);
      return Math.round(num * factor) / factor;
    };

    // Ensure allocatedValue never exceeds totalGoalValue
    if (allocatedValue > totalGoalValue) {
      allocatedValue = totalGoalValue;
      remainingValue = 0;
    }

    // Convert to percentage
    allocatedValue = roundNumber(((allocatedValue / totalGoalValue) * 100) * 2, 2); // double the value
remainingValue = roundNumber(((remainingValue / totalGoalValue) * 100) * 2, 2); // double the valuele the value

    const chart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: ['Goal Progress'],
        datasets: [
          {
            label: 'Allocated',
            data: [allocatedValue],
            backgroundColor: 'rgb(10, 100, 90)',
            borderWidth: 0,
            stack: 'progress',
            borderRadius: Number.MAX_VALUE,
            borderSkipped: false,
          },
          {
            label: 'Remaining',
            data: [remainingValue],
            backgroundColor: 'rgb(165, 165, 165)',
            borderWidth: 0,
            stack: 'progress',
            borderRadius: Number.MAX_VALUE,
            borderSkipped: false,
          },
        ],
      },
      options: {
        aspectRatio: false,
        indexAxis: 'y',
        responsive: true,
        scales: {
          x: {
            display: false,
            grid: {
              display: false,
            },
            ticks: {
              display: false,
              beginAtZero: true,
              max: 200,  // Maximum is 200% now because of doubling
            },
          },
          y: {
            display: false,
            grid: {
              display: false,
            },
            title: {
              display: false,
            },
            ticks: {
              display: false,
              beginAtZero: true,
              max: 200,  // Maximum is 200% now because of doubling
            },
          },
        },
        plugins: {
          tooltip: {
            enabled: false,
          },
          legend: {
            display: false,
          },
        },
      },
    });

    return () => {
      chart.destroy();
    };
  }, [goal]);

  return (
    <div className="goalprogress-container">
      <canvas className="goalprogress-canvas" ref={chartRef} />
    </div>
  );
};

export default GoalProgress;
