import React, { useState, useEffect, useRef } from "react";
import Chart from "chart.js/auto";
import { db } from "./BudgetPage";
import moment from "moment";
import 'chartjs-adapter-moment';

function PlotChart(props) {
  const canvasRef = useRef(null);
  const [chartData, setChartData] = useState({});

  useEffect(() => {
    const purchasesRef = db.ref(`users/${props.userId}/purchases`);
    purchasesRef.once("value", (snapshot) => {
      const purchaseData = snapshot.val();
      if (purchaseData) {
        const dates = Object.keys(purchaseData).map(
          (key) => new Date(purchaseData[key].date)
        );
        const amounts = Object.keys(purchaseData).map(
          (key) => purchaseData[key].amount
        );
        setChartData({
          labels: dates,
          datasets: [
            {
              label: "Purchases",
              data: amounts,
              backgroundColor: "rgb(30, 97, 70)",
              pointRadius: 5,
              pointHoverRadius: 8,
            },
          ],
        });
      }
    });
  }, [props.userId]);

  useEffect(() => {
    if (canvasRef.current && Object.keys(chartData).length > 0) {
      const chart = new Chart(canvasRef.current, {
        type: "scatter",
        data: chartData,
        options: {
          aspectRatio: false,
          // responsive: true,
          scales: {
            x: {
              grid: {
                color: "#E9e9e9",
              },
              ticks: {
                reverse: true,
                color: '#555555',
                font: {
                  size: 10,
                },
                callback: function (value, index, values) {
                  return moment(value).format("MMM D");
                },
              },
              type: "time",
              time: {
                unit: "day",
                tooltipFormat: "MMM D, YYYY HH:mm",
              },
              title: {
                display: false,
                        },
            },
            y: {
              grid: {
                color: "#E9e9e9"
              },
              ticks: {
                color: '#a9a9a9',
                font: {
                  size: 10,
                },
                callback: function (value, index, values) {
                  return props.currencySymbol + Math.round(value);
                },
              },
              type: "linear",
              position: "left",
              title: {
                display: false,
                text: "Amount",
              },
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              mode: "nearest",
              intersect: false,
              callbacks: {
                label: function (context) {
                  const label = context.dataset.label;
                  const value = context.parsed.y;
                  return (
                    props.currencySymbol +
                    value +
                    " on " +
                    moment(context.parsed.x).format("MMM D")
                  );
                },
              },
            },
          },
        },
      });

      return () => {
        chart.destroy();
      };
    }
  }, [chartData]);

  return (
    <div className="chartcanvas">
      {Object.keys(chartData).length > 0 ? (
        <canvas ref={canvasRef} />
      ) : (
        <div className="emptychartstate">No scatter plot data to display!<br></br>Add some purchases to see the chart.</div>
      )}
    </div>
  );
}

export default PlotChart;