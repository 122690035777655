import React, { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import './Achievements.css';

const Achievements = ({ purchaseAdded, currencySymbol }) => {
  const [categories, setCategories] = useState({});
  const [purchases, setPurchases] = useState([]);
  const [expenses, setExpenses] = useState([]);
   const [achievedGoals, setAchievedGoals] = useState([]);
   const [selectedAchievedGoal, setSelectedAchievedGoal] = useState(null);
   const [confirmDeleteAchievedGoal, setConfirmDeleteAchievedGoal] = useState(false);
 
   const handleGoalClick = (goal) => {
    setSelectedAchievedGoal(goal);
  };

  const handleShowConfirmDeleteAchievedGoal = () => {
setConfirmDeleteAchievedGoal(true)
  };

  const handleCloseConfirmDeleteAchievedGoal = () => {
    setConfirmDeleteAchievedGoal(false)
  };



  const handleCloseGoalClickModal = () => {
    const savingsachievedbackground = document.querySelector(".savingsachievedbackground");
    
    if (savingsachievedbackground) {
      savingsachievedbackground.classList.add("animateaway");
      setTimeout(() => {
        setSelectedAchievedGoal(null);
      }, 200);
    }
  }


   
      


      const deleteAchievedGoal = async (goalId) => {
        const auth = firebase.auth();
        const userId = auth.currentUser.uid;
        const goalRef = firebase.database().ref(`users/${userId}/achievedGoals/${goalId}`);
        try {
          await goalRef.remove();
          setSelectedAchievedGoal(null); 
          setConfirmDeleteAchievedGoal(false); 
        } catch (error) {
          console.error('Failed to delete goal:', error);
        }
      }

  
  useEffect(() => {
    const auth = firebase.auth();
    const userId = auth.currentUser.uid;
    const categoriesRef = firebase.database().ref(`users/${userId}/categories`);
    const purchasesRef = firebase.database().ref(`users/${userId}/purchases`);
    const expensesRef = firebase.database().ref(`users/${userId}/expenses`);
    const achievedGoalsRef = firebase.database().ref(`users/${userId}/achievedGoals`); // Added this line



   

    achievedGoalsRef.once('value', (snapshot) => {
      if (snapshot.exists()) {
        const achievedGoalsData = snapshot.val();
        const achievedGoals = Object.keys(achievedGoalsData).map((key) => ({
          ...achievedGoalsData[key],
          id: key,
        }));
        setAchievedGoals(achievedGoals); // Assumes you have a useState for achievedGoals
      } else {
        setAchievedGoals([]);
      }
    });


    
    // Fetch categories once
    categoriesRef.once('value', (snapshot) => {
      const categories = snapshot.exists() ? snapshot.val() : {};
      setCategories(categories);
    });

    // Fetch purchases once
    purchasesRef.once('value', (snapshot) => {
      if (snapshot.exists()) {
        const purchasesData = snapshot.val();
        console.log(purchasesData); 
        const purchases = Object.keys(purchasesData).map((key) => ({
          ...purchasesData[key],
          id: key,
        }));
        setPurchases(purchases);
      } else {
        setPurchases([]);
      }
    });

    expensesRef.once('value', (snapshot) => {
      if (snapshot.exists()) {
        const expensesData = snapshot.val();
        const expenses = Object.keys(expensesData).map((key) => ({
          ...expensesData[key],
          id: key,
        }));
        setExpenses(expenses);
      } else {
        setExpenses([]);
      }
    });


 // Attach real-time listener for expenses
 const expensesListener = expensesRef.on('value', (snapshot) => {
  if (snapshot.exists()) {
    const expensesData = snapshot.val();
    const expenses = Object.keys(expensesData).map((key) => ({
      ...expensesData[key],
      id: key,
    }));
    setExpenses(expenses);
  } else {
    setExpenses([]);
  }
});


    // Attach real-time listeners for categories and purchases
    const categoriesListener = categoriesRef.on('value', (snapshot) => {
      const categories = snapshot.exists() ? snapshot.val() : {};
      setCategories(categories);
    });

    const purchasesListener = purchasesRef.on('value', (snapshot) => {
      if (snapshot.exists()) {
        const purchasesData = snapshot.val();
        const purchases = Object.keys(purchasesData).map((key) => ({
          ...purchasesData[key],
          id: key,
        }));
        setPurchases(purchases);
      } else {
        setPurchases([]);
      }
    });


    const achievedGoalsListener = achievedGoalsRef.on('value', (snapshot) => {
      if (snapshot.exists()) {
        const achievedGoalsData = snapshot.val();
        const achievedGoals = Object.keys(achievedGoalsData).map((key) => ({
          ...achievedGoalsData[key],
          id: key,
        }));
        setAchievedGoals(achievedGoals);
      } else {
        setAchievedGoals([]);
      }
    });


    // Clean up the listeners when the component is unmounted
    return () => {
      categoriesRef.off('value', categoriesListener);
      purchasesRef.off('value', purchasesListener);
      expensesRef.off('value', expensesListener);
      achievedGoalsRef.off('value', achievedGoalsListener);
    };
  }, [purchaseAdded]);

  

  const checkMidnightShopperAchievement = (purchases) => {
    const hasMidnightPurchase = purchases.some((purchase) => {
      const purchaseTime = new Date(purchase.date); // Assuming each purchase has a 'date' property
      const purchaseHours = purchaseTime.getHours();
      const purchaseMinutes = purchaseTime.getMinutes();
  
      return (purchaseHours > 0 || (purchaseHours === 0 && purchaseMinutes >= 5)) && purchaseHours < 4;
    });
  
    return hasMidnightPurchase;
  };

  const hasSuperSaverExpense = expenses.some(
    (expense) => expense.name && (expense.name.toLowerCase() === 'savings' || expense.name.toLowerCase() === 'saving')
  );

  const crimeTriggers = ['drugs', 'crime', 'hookers', 'money laundering', 'meth', 'cocaine', 'heroin', 'bioweapons'];
const hasCrimeCategory = Object.keys(categories).some((category) => {
  const lowercaseCategory = category.toLowerCase();
  return crimeTriggers.includes(lowercaseCategory);
}) || purchases.some((purchase) => {
  const lowercaseNote = purchase.note.toLowerCase();
  return crimeTriggers.includes(lowercaseNote);
}) ;

const wrongTriggers = ['rent', 'mortgage', 'car payment', 'insurance', 'property taxes', 'loan'];
const hasWrongCategory = Object.keys(categories).some((category) => {
  const lowercaseCategory = category.toLowerCase();
  return wrongTriggers.includes(lowercaseCategory);
}) ;

const plantTriggers = ['plant', 'plants', 'gardening', 'landscaping', 'bonsai'];
const hasPlantCategory = Object.keys(categories).some((category) => {
  const lowercaseCategory = category.toLowerCase();
  return plantTriggers.includes(lowercaseCategory);
}) || purchases.some((purchase) => {
  const lowercaseNote = purchase.note.toLowerCase();
  return plantTriggers.includes(lowercaseNote);
}) ;

const travelTriggers = ['flight', 'flights', 'hotel', 'hotels', 'plane', 'travel', 'train', 'vacation', 'trip', 'adventure', 'wanderlust' ];
const hasTravelCategory = Object.keys(categories).some((category) => {
  const lowercaseCategory = category.toLowerCase();
  return travelTriggers.includes(lowercaseCategory);
}) || purchases.some((purchase) => {
  const lowercaseNote = purchase.note.toLowerCase();
  return travelTriggers.includes(lowercaseNote);
}) ;

const investingTriggers = ['investing', 'stocks', 'investments', 'investment', 'stonks', 'wall street', 'invest', 'shares', 'crypto', 'bitcoin', 'etherium' ];
const hasInvestingCategory = Object.keys(categories).some((category) => {
  const lowercaseCategory = category.toLowerCase();
  return investingTriggers.includes(lowercaseCategory);
}) || purchases.some((purchase) => {
  const lowercaseNote = purchase.note.toLowerCase();
  return investingTriggers.includes(lowercaseNote);
}) ;

const foodTriggers = ['restaurant', 'restaurants', 'dining', 'eating out', 'take-out', 'takeout', 'cuisine', 'tap house', 'bistro', 'dinner', 'brunch', 'breakfast', 'ramen'];
const hasFoodCategory = Object.keys(categories).some((category) => {
  const lowercaseCategory = category.toLowerCase();
  return foodTriggers.includes(lowercaseCategory);
}) || purchases.some((purchase) => {
  const lowercaseNote = purchase.note.toLowerCase();
  return foodTriggers.includes(lowercaseNote);
}) ;

const coffeeTriggers = ['starbucks', 'coffee', 'dunkins'];
const hasCoffeeCategory = Object.keys(categories).some((category) => {
  const lowercaseCategory = category.toLowerCase();
  return coffeeTriggers.includes(lowercaseCategory);
}) || purchases.some((purchase) => {
  const lowercaseNote = purchase.note.toLowerCase();
  return coffeeTriggers.includes(lowercaseNote);
}) 
;

const fitnessTriggers = ['gym', 'workout', 'exercise'];
const hasFitnessCategory = Object.keys(categories).some((category) => {
  const lowercaseCategory = category.toLowerCase();
  return fitnessTriggers.includes(lowercaseCategory);
}) || purchases.some((purchase) => {
  const lowercaseNote = purchase.note.toLowerCase();
  return fitnessTriggers.includes(lowercaseNote);
}) ;

const bikesTriggers = ['bike', 'bikes', 'mtb', 'bike parts'];
const hasBikesCategory = Object.keys(categories).some((category) => {
  const lowercaseCategory = category.toLowerCase();
  return bikesTriggers.includes(lowercaseCategory);
}) || purchases.some((purchase) => {
  const lowercaseNote = purchase.note.toLowerCase();
  return bikesTriggers.includes(lowercaseNote);
}) ;

const diyTriggers = ['hardware', 'home depot', 'lowes', 'renovation', 'home maintenance'];
const hasDIYCategory = Object.keys(categories).some((category) => {
  const lowercaseCategory = category.toLowerCase();
  return diyTriggers.includes(lowercaseCategory);
}) || purchases.some((purchase) => {
  const lowercaseNote = purchase.note.toLowerCase();
  return diyTriggers.includes(lowercaseNote);
}) ;

const bookTriggers = ['barnes n nobel', 'books', 'book', 'reading'];
const hasBookCategory = Object.keys(categories).some((category) => {
  const lowercaseCategory = category.toLowerCase();
  return bookTriggers.includes(lowercaseCategory);
}) || purchases.some((purchase) => {
  const lowercaseNote = purchase.note.toLowerCase();
  return bookTriggers.includes(lowercaseNote);
}) ;

const clothesTriggers = ['clothes', 'drip'];
const hasClothesCategory = Object.keys(categories).some((category) => {
  const lowercaseCategory = category.toLowerCase();
  return clothesTriggers.includes(lowercaseCategory);
}) || purchases.some((purchase) => {
  const lowercaseNote = purchase.note.toLowerCase();
  return clothesTriggers.includes(lowercaseNote);
}) ;

const petTriggers = ['pup', 'vet', 'fur baby', 'dog', 'cat', 'horse', 'dogs', 'cats', 'horses', 'cows', 'chickens', 'fish', 'birds'];
const hasPetCategory = Object.keys(categories).some((category) => {
  const lowercaseCategory = category.toLowerCase();
  return petTriggers.includes(lowercaseCategory);
}) || purchases.some((purchase) => {
  const lowercaseNote = purchase.note.toLowerCase();
  return petTriggers.includes(lowercaseNote);
}) ;

const christmasTriggers = ['christmas', 'black friday'];
const hasChristmasCategory = Object.keys(categories).some((category) => {
  const lowercaseCategory = category.toLowerCase();
  return christmasTriggers.includes(lowercaseCategory);
}) || purchases.some((purchase) => {
  const lowercaseNote = purchase.note.toLowerCase();
  return christmasTriggers.includes(lowercaseNote);
}) ;

const weddingTriggers = ['wedding', 'bachelor party', 'bachelorette party',];
const hasWeddingCategory = Object.keys(categories).some((category) => {
  const lowercaseCategory = category.toLowerCase();
  return weddingTriggers.includes(lowercaseCategory);
}) || purchases.some((purchase) => {
  const lowercaseNote = purchase.note.toLowerCase();
  return weddingTriggers.includes(lowercaseNote);
}) ;

const partyTriggers = ['party', 'birthday', 'shindig', 'bash'];
const hasPartyCategory = Object.keys(categories).some((category) => {
  const lowercaseCategory = category.toLowerCase();
  return partyTriggers.includes(lowercaseCategory);
}) || purchases.some((purchase) => {
  const lowercaseNote = purchase.note.toLowerCase();
  return partyTriggers.includes(lowercaseNote);
}) ;

const gamesTriggers = ['games', 'vidja', 'video games'];
const hasGamesCategory = Object.keys(categories).some((category) => {
  const lowercaseCategory = category.toLowerCase();
  return gamesTriggers.includes(lowercaseCategory);
}) || purchases.some((purchase) => {
  const lowercaseNote = purchase.note.toLowerCase();
  return gamesTriggers.includes(lowercaseNote);
}) ;

const healthTriggers = ['health', 'medical', 'prescriptions', 'meds'];
const hasHealthCategory = Object.keys(categories).some((category) => {
  const lowercaseCategory = category.toLowerCase();
  return healthTriggers.includes(lowercaseCategory);
}) || purchases.some((purchase) => {
  const lowercaseNote = purchase.note.toLowerCase();
  return healthTriggers.includes(lowercaseNote);
}) ;

const boatTriggers = ['boat', 'sailing', 'boating', 'boat!'];
const hasBoatCategory = Object.keys(categories).some((category) => {
  const lowercaseCategory = category.toLowerCase();
  return boatTriggers.includes(lowercaseCategory);
}) || purchases.some((purchase) => {
  const lowercaseNote = purchase.note.toLowerCase();
  return boatTriggers.includes(lowercaseNote);
}) ;

const golfTriggers = ['golf', 'golfing'];
const hasGolfCategory = Object.keys(categories).some((category) => {
  const lowercaseCategory = category.toLowerCase();
  return golfTriggers.includes(lowercaseCategory);
}) || purchases.some((purchase) => {
  const lowercaseNote = purchase.note.toLowerCase();
  return golfTriggers.includes(lowercaseNote);
}) ;



  const nefariousTriggers = ['weed', 'cannabis', 'marijuana',];

const hasNefariousCategory = Object.keys(categories).some((category) => {
  const lowercaseCategory = category.toLowerCase();
  return nefariousTriggers.includes(lowercaseCategory);
}) || purchases.some((purchase) => {
  const lowercaseNote = purchase.note.toLowerCase();
  return nefariousTriggers.includes(lowercaseNote);
}) ;

// const SuperSaver = purchases.some((purchase) => purchase.category.toLowerCase() === 'savings');

const currentDate = new Date(); // Current date
const oneWeekAgo = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000); // Date one week ago
let isGhosted = true;

for (const purchase of purchases) {
  const purchaseDate = new Date(purchase.date); // Convert purchase date to Date object

  if (purchaseDate > oneWeekAgo) {
    isGhosted = false; // If there is a purchase within the last week, set isGhosted to false
    break;
  }
}


const hasBuyersRemorse = () => {
  if (purchases.length > 10) {
    const trueWorthItCount = purchases.reduce((count, purchase) => {
      if (purchase.worthit === true) {
        return count + 1;
      }
      return count;
    }, 0);

    const trueWorthItPercentage = (trueWorthItCount / purchases.length) * 100;

    return trueWorthItPercentage >= 90;
  }

  return false;
};


const totalBudget = Object.values(categories).reduce((sum, value) => sum + Number(value), 0);
const isGucci = purchases.some((purchase) => purchase.amount > 0.5 * totalBudget);

const hasGoatPurchase = purchases.some((purchase) => purchase.icon === '🐐');
const hasMidnightShopperAchievement = checkMidnightShopperAchievement(purchases);
const hasNicePurchase = purchases.some((purchase) => purchase.amount === 69);

  // Calculate the distinct dates from the purchases (ignoring time)
const distinctDates = [...new Set(purchases.map((purchase) => purchase.date.split('T')[0]))];

  // Calculate the time range between the earliest and most recent dates
  const earliestDate = purchases.length > 0 ? new Date(Math.min(...purchases.map((purchase) => new Date(purchase.date)))) : null;
  const latestDate = purchases.length > 0 ? new Date(Math.max(...purchases.map((purchase) => new Date(purchase.date)))) : null;
  const timeRange = earliestDate && latestDate ? latestDate.getTime() - earliestDate.getTime() : null;

  const days = timeRange ? Math.floor(timeRange / (1000 * 60 * 60 * 24)) : null;
  const hours = timeRange ? Math.floor((timeRange % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)) : null;
  const minutes = timeRange ? Math.floor((timeRange % (1000 * 60 * 60)) / (1000 * 60)) : null;

  // Calculate the total value of all categories
const totalCategoryValue = Object.values(categories).reduce((sum, value) => sum + Number(value), 0);

const calcdailylimit = totalCategoryValue / 5;

  // Check if there is any purchase with an amount greater than 5% of the total 
  const hasPurchaseGreaterThanCalc = purchases.some((purchase) => purchase.amount > calcdailylimit);

// Check if the sum of purchase amounts under each category is greater than the category value
const isPurchaseSumGreaterThanCategory = Object.entries(categories).every(([category, value]) => {
    const purchaseSum = purchases.reduce((sum, purchase) => {
      if (purchase.category === category) {
        return sum + purchase.amount;
      }
      return sum;
    }, 0);
    return purchaseSum > Number(value); // Convert value to a number for comparison
  });
  

// Calculate the calculated spending ratio by dividing the total category value by 10
const calculatedSpendingRatio = totalCategoryValue / 10;
  const isSpendingExceedingRatio = distinctDates.some(date => {
    const dayPurchaseSum = purchases.reduce((sum, purchase) => {
      if (purchase.date.split('T')[0] === date) {
        return sum + purchase.amount;
      }
      return sum;
    }, 0);
    return dayPurchaseSum > calculatedSpendingRatio;
  });

  let isAtLeastOneCategoryExceedingValue = false;

Object.entries(categories).some(([category, value]) => {
  const purchaseSum = purchases.reduce((sum, purchase) => {
    if (purchase.category === category) {
      return sum + purchase.amount;
    }
    return sum;
  }, 0);
  const isGreater = purchaseSum > Number(value);

  if (isGreater) {
    isAtLeastOneCategoryExceedingValue = true;
    return true; // Exit the loop early once a category exceeds the value
  }

  return false;
}




);

return (
  <div className="achievement-table">







   




      <div className="achievementscontainerheader">Feats</div>
    <div className="achievementscontainer">
  
   

    {hasWrongCategory ? (
    <div className="achievementbad">
      <div className="achievementtextcontainer">
        <div className="goldstar">🚫</div>
        <div className="achievementtext">
          Doing it Wrong<br></br>
          <div className="achievementsubtext">You have a budget category that should really be in your fixed expenses</div>
        </div>
      </div>
    </div>
  ) : null}

    {/* <p>Number of categories: {Object.keys(categories).length}</p> */}
 


{hasSuperSaverExpense ? (
  <div className="achievementgood">
    <div className="achievementtextcontainer">
      <div className="goldstar">🐉</div>
      <div className="achievementtext">
        Dragon's Hoard<br></br>
        <div className="achievementsubtext">Have an additional savings amount in your fixed expenses</div>
      </div>
    </div>
  </div>
) : null}





{/* 
    <p>You've added purchases on {distinctDates.length} different days!</p> */}


  

    {/* <p>Time range: {days} days, {hours} hours, {minutes} minutes</p> */}

 

{purchases.length > 1 && isAtLeastOneCategoryExceedingValue ? (
  <div className="achievementbad">
    <div className="achievementtextcontainer">
      <div className="goldstar">📈</div>
      <div className="achievementtext">
        Broke the Bank<br></br>
        <div className="achievementsubtext">Exceeded your monthly budget in at least one category</div>
      </div>
    </div>
  </div>
) : purchases.length > 1 ? (
  <div className="achievementgood">
    <div className="achievementtextcontainer">
      <div className="goldstar">🏁</div>
      <div className="achievementtext">
        Stayed In Your Lane<br></br>
        <div className="achievementsubtext">Within budget in all categories</div>
      </div>
    </div>
  </div>
) : <div className="achievementtodo"> <div className="achievementtextcontainer">
  <div className="goldstartodo">🔒</div>
  <div className="achievementtext">Unlocks after adding at least one purchase<br></br>
  <div className="achievementsubtext">This achievement will change based on your spending!</div>
  </div></div></div>}


    {/* <p>Purchase amount > Calc: {hasPurchaseGreaterThanCalc.toString()}</p> */}
    
    
{purchases.length > 1 && hasPurchaseGreaterThanCalc ? (
  <div className="achievementbad"><div className="achievementtextcontainer">
    <div className="goldstar">💰</div><div className="achievementtext">Big Spender<br>
    </br><div className="achievementsubtext">Made at least one purchase that was over 20% of your total monthly budget</div>
    </div></div></div>
) : purchases.length > 1 ?  (
  <div className="achievementgood"> <div className="achievementtextcontainer">
    <div className="goldstar">🧘‍♂️</div><div className="achievementtext">Master of Fiscal Discipline<br></br>
    <div className="achievementsubtext">No single purchases made exceeding 20% of your total monthly budget</div></div></div></div>
) : 
<div className="achievementtodo"> <div className="achievementtextcontainer">
  <div className="goldstartodo">🔒</div><div className="achievementtext">Unlocks after adding at least one purchase<br></br>
  <div className="achievementsubtext">This achievement will change based on your spending!</div></div></div></div>
}



    

{purchases.length > 1 && isSpendingExceedingRatio ? (
  <div className="achievementbad">
    <div className="achievementtextcontainer"><div className="goldstar">🍾</div> 
    <div className="achievementtext">Treat Yo Self<br></br><div className="achievementsubtext">Spent over 10% of your total monthly budget in one day</div></div></div></div>
) : purchases.length > 1 ?  (
  <div className="achievementgood">
    <div className="achievementtextcontainer"><div className="goldstar">🧭</div> 
    <div className="achievementtext">Meticulous Budget Steward<br></br><div className="achievementsubtext">Maintained daily spending below 10% of monthly budget</div></div></div></div>
) : <div className="achievementtodo"> 
<div className="achievementtextcontainer"><div className="goldstartodo">🔒</div>
<div className="achievementtext">Unlocks after adding at least one purchase<br></br><div className="achievementsubtext">This achievement will change based on your spending!</div>
</div></div></div>}

{hasBuyersRemorse() ? (
        <div className="achievementgood">
          <div className="achievementtextcontainer">
            <div className="goldstar">🛍️</div>
            <div className="achievementtext">
              Intuitive Spender<br></br>
              <div className="achievementsubtext">At least 90% of purchases are worth it</div>
            </div>
          </div>
        </div>
      ) : null}

{achievedGoals.length >= 10 ? (
  <div className="achievementgood">
    <div className="achievementtextcontainer">
      <div className="goldstar">🎩</div>
      <div className="achievementtext">
        Monopoly Man<br></br>
        <div className="achievementsubtext">
          Completed 10 savings goals!
        </div>
      </div>
    </div>
  </div>
) : achievedGoals.length >= 5 ? (
  <div className="achievementgood">
    <div className="achievementtextcontainer">
      <div className="goldstar">🥋</div>
      <div className="achievementtext">
       Disciplined Saver<br></br>
        <div className="achievementsubtext">
          Completed five savings goals
        </div>
      </div>
    </div>
  </div>
) : achievedGoals.length >= 1 ? (
  <div className="achievementgood">
    <div className="achievementtextcontainer">
      <div className="goldstar">🥚</div>
      <div className="achievementtext">
       Nest Egg<br></br>
        <div className="achievementsubtext">
          Completed a savings goal
        </div>
      </div>
    </div>
  </div>
) : null}
      {/* <ul>
        {Object.entries(categories).map(([category, value]) => {
          const purchaseSum = purchases.reduce((sum, purchase) => {
            if (purchase.category === category) {
              return sum + purchase.amount;
            }
            return sum;
          }, 0);
          const isGreater = purchaseSum > Number(value);
          return (
            <li key={category}>
              Category: {category}, Purchase Sum: {purchaseSum}, Category Value: {value}, 
              Greater than Category Value: {isGreater.toString()}
            </li>
          );
        })}
      </ul> */}




{isGucci ? (
    <div className="achievementbad">
      <div className="achievementtextcontainer">
        <div className="goldstar">🔥</div>
        <div className="achievementtext">
          Gucci<br></br>
          <div className="achievementsubtext">Spent more than 50% of your total budget on one purchase</div>
        </div>
      </div>
    </div>
  ) : null}

  







{timeRange !== null && timeRange > 0 ? (
    <>
      {days === 0 && <div className="achievementtodo"><div className="achievementtextcontainer"><div className="goldstartodo">🔒</div><div className="achievementtext">Unlocks after one week of tracking your purchases</div></div></div>}
      {days > 0 && (
        <>
          {days >= 7 && days < 14 && (
            <div className="achievementneutral"><div className="achievementtextcontainer"><div className="goldstar">🎸</div> <div className="achievementtext">One Week <br></br><div className="achievementsubtext">You've been tracking purchases for over a week!</div></div></div></div>
          )}
          {days >= 14 && days < 21 && (
            <div className="achievementneutral"><div className="achievementtextcontainer"><div className="goldstar">🥉</div><div className="achievementtext">Two Weeks<br></br><div className="achievementsubtext">Halfway through the month- you've been tracking purchases for over 14 days!</div></div></div></div>
          )}
          {days >= 21 && days < 28 && (
            <div className="achievementgood"><div className="achievementtextcontainer"><div className="goldstar">🥈</div><div className="achievementtext">Three Weeks<br></br><div className="achievementsubtext">Almost there! You've been tracking purchases for over 21 days!</div></div></div></div>
          )}
          {days >= 28 && (
            <div className="achievementgood"><div className="achievementtextcontainer"><div className="goldstar">🥇</div> <div className="achievementtext">Four Weeks <br></br><div className="achievementsubtext">You've made it! Nice job tracking your purchases all month! </div></div></div></div>
          )}
        </>
      )}
    </>
  ) : (
    <div className="achievementtodo"><div className="achievementtextcontainer"><div className="goldstartodo">🔒</div><div className="achievementtext">Unlocks after one week of tracking your purchases</div></div></div>
  )}

{purchases.length > 1 && distinctDates.length >= 10 ? (
  <div className="achievementneutral"><div className="achievementtextcontainer">
    <div className="goldstar">🏆</div>
    <div className="achievementtext">Purchase Diversification Trophy<br></br>
    <div className="achievementsubtext">Added at least 10 purchases at 10 different times. Good job staying consistent!</div>
    </div></div></div>
) : purchases.length > 1 ? (
  <div className="achievementtodo"><div className="achievementtextcontainer">
    <div className="goldstartodo">🔒</div>
    <div className="achievementtext">Unlocks after adding purchases on at least ten different dates</div></div></div>
): null}

{Object.keys(categories).length >= 20 ? (
  <div className="achievementneutral">
  <div className="achievementtextcontainer">
    <div className="goldstar">👽</div>
    <div className="achievementtext">U.F.O. (Unrealistic Financial Obligation)<br></br>
    <div className="achievementsubtext">20+ spending categories? What?</div>
  </div>
</div>
</div>
) : Object.keys(categories).length >= 10 ? (<div className="achievementneutral">
    <div className="achievementtextcontainer">
      <div className="goldstar">🤖</div>
      <div className="achievementtext">Budgetron<br></br>
      <div className="achievementsubtext">10+ categories! You must be a machine to keep track of all that</div>
    </div>
  </div>
  </div>
) : Object.keys(categories).length >= 6 ? (
  <div className="achievementneutral">
    <div className="achievementtextcontainer">
      <div className="goldstar">🦸‍♂️</div>
      <div className="achievementtext">Budget Hero <br></br>
      <div className="achievementsubtext">Saving more than the neighborhood with at least six categories!</div>
    </div>
  </div>
  </div>
) : Object.keys(categories).length >= 3 ? (
  <div className="achievementneutral">
    <div className="achievementtextcontainer">
      <div className="goldstar">🧙‍♂️</div>
      <div className="achievementtext">Budget Wizard <br></br>
      <div className="achievementsubtext">Mastered the arcane art of creating a categorized budget</div>
    </div>
  </div>
  </div>
) : (
  <div className="achievementtodo">
    <div className="achievementtextcontainer">
      <div className="goldstartodo">🔒</div>
      <div className="achievementtext">Unlocks after tracking at least three different spending categories</div>
    </div>
  </div>
)}












{hasNefariousCategory ? (
  <div className="achievementneutral">
    <div className="achievementtextcontainer">
      <div className="goldstar">🌳</div>
      <div className="achievementtext">
        Nature Lover<br></br>
        <div className="achievementsubtext">Set aside money for trees</div>
      </div>
    </div>
  </div>
) : null}




{hasCrimeCategory ? (
  <div className="achievementneutral">
    <div className="achievementtextcontainer">
      <div className="goldstar">🦝</div>
      <div className="achievementtext">
        Do Crimes<br></br>
        <div className="achievementsubtext">Nefarious endeavors cost money too</div>
      </div>
      
    </div>
  </div>
) : null}




  {purchases.length > 1 && isGhosted ? (
    <div className="achievementneutral">
      <div className="achievementtextcontainer">
        <div className="goldstar">👻</div>
        <div className="achievementtext">
          Ghosted<br></br>
          <div className="achievementsubtext">Last purchase was over a week ago. We miss you. Pls respond.</div>
        </div>
      </div>
    </div>
  ) : null}





  {hasNicePurchase ? (
    <div className="achievementneutral">
      <div className="achievementtextcontainer">
        <div className="goldstar">👌</div>
        <div className="achievementtext">
          Nice<br></br>
          <div className="achievementsubtext">Made a purchase for exactly the right amount</div>
        </div>
      </div>
    </div>
  ) : null}

{hasBoatCategory ? (
    <div className="achievementneutral">
      <div className="achievementtextcontainer">
        <div className="goldstar">⛵</div>
        <div className="achievementtext">
          Boats and Woes<br></br>
          <div className="achievementsubtext">Invested in nautical adventures</div>
        </div>
      </div>
    </div>
  ) : null}

{hasGolfCategory ? (
    <div className="achievementneutral">
      <div className="achievementtextcontainer">
        <div className="goldstar">⛳</div>
        <div className="achievementtext">
          Fore!<br></br>
          <div className="achievementsubtext">Played the back nine</div>
        </div>
      </div>
    </div>
  ) : null}

{hasMidnightShopperAchievement ? (
      <div className="achievementneutral">
      <div className="achievementtextcontainer">
        <div className="goldstar">🌙</div>
        <div className="achievementtext">
          Night Owl<br></br>
          <div className="achievementsubtext">Made a purchase in the wee hours of the night</div>
        </div>
      </div>
    </div>
      ) : null}


{hasInvestingCategory ? (
  <div className="achievementneutral">
    <div className="achievementtextcontainer">
      <div className="goldstar">🏛</div>
      <div className="achievementtext">
        Buy High, Sell Never<br></br>
        <div className="achievementsubtext">Made market moves</div>
      </div>
    </div>
  </div>
) : null}

{hasPlantCategory ? (
  <div className="achievementneutral">
    <div className="achievementtextcontainer">
      <div className="goldstar">🌿</div>
      <div className="achievementtext">
        I Wet My Plants<br></br>
        <div className="achievementsubtext">Nurtured your botanical persuation</div>
      </div>
    </div>
  </div>
) : null}

{hasTravelCategory ? (
  <div className="achievementneutral">
    <div className="achievementtextcontainer">
      <div className="goldstar">✈️</div>
      <div className="achievementtext">
        Jet Setter<br></br>
        <div className="achievementsubtext">Put some money into travels</div>
      </div>
    </div>
  </div>
) : null}

{hasFoodCategory ? (
  <div className="achievementneutral">
    <div className="achievementtextcontainer">
      <div className="goldstar">🍜</div>
      <div className="achievementtext">
        Financial Foodie<br></br>
        <div className="achievementsubtext">Indulged in some culinary experiences</div>
      </div>
    </div>
  </div>
) : null}

{hasCoffeeCategory ? (
  <div className="achievementneutral">
    <div className="achievementtextcontainer">
      <div className="goldstar">☕</div>
      <div className="achievementtext">
        Bitter Bean Juice<br></br>
        <div className="achievementsubtext">Caffienate your budget</div>
      </div>
    </div>
  </div>
) : null}

{hasFitnessCategory ? (
  <div className="achievementneutral">
    <div className="achievementtextcontainer">
      <div className="goldstar">💪</div>
      <div className="achievementtext">
        Swole<br></br>
        <div className="achievementsubtext">Picked things up and put them down</div>
      </div>
    </div>
  </div>
) : null}

{hasDIYCategory ? (
  <div className="achievementneutral">
    <div className="achievementtextcontainer">
      <div className="goldstar">🔨</div>
      <div className="achievementtext">
        Measure Twice, Spend Once<br></br>
        <div className="achievementsubtext">Made precise payments on renovations or doing it yourself</div>
      </div>
    </div>
  </div>
) : null}

{hasBookCategory ? (
  <div className="achievementneutral">
    <div className="achievementtextcontainer">
      <div className="goldstar">📚</div>
      <div className="achievementtext">
        Bookworm<br></br>
        <div className="achievementsubtext">Chased some literary pursuits</div>
      </div>
    </div>
  </div>
) : null}

{hasClothesCategory ? (
  <div className="achievementneutral">
    <div className="achievementtextcontainer">
      <div className="goldstar">🧣</div>
      <div className="achievementtext">
        Style Icon<br></br>
        <div className="achievementsubtext">Invested in looking your best</div>
      </div>
    </div>
  </div>
) : null}

{hasPetCategory ? (
  <div className="achievementneutral">
    <div className="achievementtextcontainer">
      <div className="goldstar">🐾</div>
      <div className="achievementtext">
        They're Just Like Us!<br></br>
        <div className="achievementsubtext">Set aside some money for critters</div>
      </div>
    </div>
  </div>
) : null}

{hasChristmasCategory ? (
  <div className="achievementneutral">
    <div className="achievementtextcontainer">
      <div className="goldstar">🎄</div>
      <div className="achievementtext">
        The Season of Giving<br></br>
        <div className="achievementsubtext">Observe holiday expenses</div>
      </div>
    </div>
  </div>
) : null}

{hasWeddingCategory ? (
  <div className="achievementneutral">
    <div className="achievementtextcontainer">
      <div className="goldstar">👰</div>
      <div className="achievementtext">
        Wedding Crasher<br></br>
        <div className="achievementsubtext">Celebrate matrimony with some wedding-related expenses</div>
      </div>
    </div>
  </div>
) : null}

{hasPartyCategory ? (
  <div className="achievementneutral">
    <div className="achievementtextcontainer">
      <div className="goldstar">🎉</div>
      <div className="achievementtext">
        Kegstand<br></br>
        <div className="achievementsubtext">Finance a rager</div>
      </div>
    </div>
  </div>
) : null}

{hasGamesCategory ? (
  <div className="achievementneutral">
    <div className="achievementtextcontainer">
      <div className="goldstar">🎮</div>
      <div className="achievementtext">
        Get Good<br></br>
        <div className="achievementsubtext">Completed the side quest "spend money"... It was not a skill issue.</div>
      </div>
    </div>
  </div>
) : null}

{hasHealthCategory ? (
  <div className="achievementneutral">
    <div className="achievementtextcontainer">
      <div className="goldstar">🩺</div>
      <div className="achievementtext">
        Longevity<br></br>
        <div className="achievementsubtext">Allocated some funds for your health and wellness</div>
      </div>
    </div>
  </div>
) : null}

{hasBikesCategory ? (
  <div className="achievementneutral">
    <div className="achievementtextcontainer">
      <div className="goldstar">🚲</div>
      <div className="achievementtext">
        Lets Ride Bikes!<br></br>
        <div className="achievementsubtext">Set aside funds for two-wheeled locomotion</div>
      </div>
    </div>
  </div>
) : null}





    <div className="achievementtodo">
    <div className="achievementtextcontainer">
      <div className="goldstartodo">❔</div>
      <div className="achievementtext">
        More Hidden Feats<br></br>
        <div className="achievementsubtext">Discover them by tracking your budget!</div>
      </div>
    </div>
  </div>




{hasGoatPurchase ? (
    <div className="achievementspecial">
      <div className="achievementspecialcontainer">
      <div className="achievementtextcontainer">
        <div className="goldstar">🐐</div>
        <div className="achievementspecialtext">
          Black Philip<br></br>
          <div className="achievementspecialsubtext">Wouldst thou like the taste of butter? A pretty dress? Wouldst thou like to live deliciously?</div>
        </div>
        </div>
      </div>
    </div>
  ) : null}
</div>


{achievedGoals.length > 0 && (
  <div className="achievedgoalscontainer">
    <div className="achievementscontainerheader">Completed Savings Goals</div>
    <ul style={{padding: "0px"}}>
      {achievedGoals.map((goal) => {
        return (
          <li style={{listStyle:"none"}} key={goal.id} onClick={() => handleGoalClick(goal)}>
            <div className="achievementgoal">
              <div className="achievementtextcontainer">
                <div className="goldstar">{goal.icon}</div>
                <div className="achievementtext">
                  {goal.name}<br></br>
                  <div className="achievementsubtext">
                    Completed on {goal.dateAdded}. Saved {currencySymbol}{goal.target} in {Math.max(0, Math.floor((new Date(goal.dateAdded) - new Date(goal.createdDate)) / (1000 * 60 * 60 * 24)))} days!
                  </div>
                </div>
              </div>
            </div>
          </li>
        )
      })}
    </ul>
  </div>
)}

{selectedAchievedGoal && 
  <div className="savingsachievedbackground">
<div className="savingsachievedmodal">
  {!confirmDeleteAchievedGoal && <div className="achievements-goal-display">
  <h2 style={{color: "rgb(20, 20, 20)", fontSize: "1.2rem"}}>Completed Goal</h2>
  <p className="achievedgoallineitem" style={{color: "rgb(6, 59, 53)", fontWeight: "700", fontSize: "1.0rem"}}>{selectedAchievedGoal.name}</p>
  <p className="achievedgoallineitem" style={{fontSize: "2rem"}}>{selectedAchievedGoal.icon}</p>
  <p className="achievedgoallineitem" style={{color: "rgb(6, 59, 53)", fontWeight: "700", fontSize: "1.0rem"}}>{currencySymbol}{selectedAchievedGoal.target} / {currencySymbol}{selectedAchievedGoal.target}</p>
  <p className="achievedgoallineitem">
  Started {new Date(selectedAchievedGoal.createdDate).toLocaleDateString()}
</p>
  <p className="achievedgoallineitem">Completed on {selectedAchievedGoal.dateAdded}</p>
  
  <button className="achievements-goal-delete-goal-button" onClick={() => {handleShowConfirmDeleteAchievedGoal()}}>Delete Goal</button>
  <button className="confirmation-no" onClick={handleCloseGoalClickModal}>Close</button></div>}


{confirmDeleteAchievedGoal && 

<div className="achievements-goal-display">
  <h2 style={{color: "rgb(20, 20, 20)", fontSize: "1.2rem"}}>Delete Achieved Goal</h2>
  <p className="achievedgoallineitem" style={{color: "#852626", fontWeight: "700", fontSize: "1.0rem"}}>Are you sure you want to delete this achieved goal?</p>
  
  <p className="achievedgoallineitem">This cannot be undone.</p>
  
  <button style={{backgroundColor: "#852626"}} className="confirmation-okay" onClick={() => {deleteAchievedGoal(selectedAchievedGoal.id)}}>Yes, delete</button>
  <button className="confirmation-no" onClick={() => {handleCloseConfirmDeleteAchievedGoal()}}>Cancel</button></div>

}
  
</div></div>
}




  </div>
);













};

export default Achievements;
