import React, { useEffect, useState, useRef, memo } from 'react';
import Chart from 'chart.js/auto';
import { db } from './BudgetPage';
import moment from 'moment';
import './DailySpending.css';
import budgetsignature from "./images/budgetsignature.svg";
import 'chartjs-adapter-moment';

function DailySpending(props) {
    const canvasRef = useRef(null);

    
    const [isChartReady, setIsChartReady] = useState(false);
    
    const originalBudget = props.modifiedTotalBudget || props.totalBudget;
    const spentPercentage = (props.totalSpent / originalBudget) || 0;
    const spentPercentageRounded = Math.round(spentPercentage * 100);
    const colorArray = [
      'rgb(10, 100, 90)',
      'rgb(15, 99, 90)',
      'rgb(20, 98, 80)',
      'rgb(30, 97, 70)',
      'rgb(40, 96, 60)',
      'rgb(50, 95, 50)',
      'rgb(60, 94, 40)',
      'rgb(70, 93, 30)',
      'rgb(80, 92, 20)',
      'rgb(90, 91, 10)',
      'rgb(100, 90, 0)',
      'rgb(110, 90, 0)',
      'rgb(120, 90, 0)',
      'rgb(130, 90, 0)',
      'rgb(140, 90, 0)',
      'rgb(145, 90, 0)',
      'rgb(150, 70, 0)',
      'rgb(100, 45, 0)',
      'rgb(110, 30, 0)',
      'rgb(120, 0, 0)'
    ];
    const spentPercentageColor = colorArray[Math.floor(spentPercentageRounded / (100 / colorArray.length))];
  

useEffect(() => {
  let timeoutId = null;
  let intervalId = null;

  const checkElementExistence = () => {
    const emptyChartStateElement = document.querySelector('.emptychartstate');
    const dailySpendingCanvasElement = document.querySelector('.dailyspendingcanvas');

    if (emptyChartStateElement) {
      clearTimeout(timeoutId);
      dailySpendingCanvasElement.classList.add('dontdisplay');
    }

  };

  intervalId = setInterval(checkElementExistence, 100);

  // Cleanup function
  return () => {
    clearInterval(intervalId);
    clearTimeout(timeoutId);
  };
}, []);


    useEffect(() => {
      const ctx = canvasRef.current.getContext('2d');
      // const categoriesRef = db.ref(`users/${props.userId}/categories`);
      const purchasesRef = db.ref(`users/${props.userId}/purchases`);
  
      let chart = null;
      const fetchData = () => {
        const purchasesByDate = {};
      
        const handleChildAdded = (snapshot) => {
          const purchase = snapshot.val();
          const dateISO = purchase.date.substring(0, 10);
          const date = moment(dateISO).local();
          const dateLocale = date.format('YYYY-MM-DD');
      
          if (purchasesByDate[dateLocale]) {
            purchasesByDate[dateLocale].push(purchase.amount);
          } else {
            purchasesByDate[dateLocale] = [purchase.amount];
          }
      
          if (Object.keys(purchasesByDate).length >= 3) { // Check if purchases occurred on at least 3 different dates
            updateChart(purchasesByDate);
          }
        };
      
        const handleChildRemoved = (snapshot) => {
          const purchase = snapshot.val();
          const dateISO = purchase.date.substring(0, 10);
          const dateLocale = moment(dateISO).local().format('YYYY-MM-DD');
      
          if (purchasesByDate[dateLocale]) {
            const index = purchasesByDate[dateLocale].indexOf(purchase.amount);
            if (index > -1) {
              purchasesByDate[dateLocale].splice(index, 1);
            }
            if (Object.keys(purchasesByDate).length >= 3) { // Check if purchases occurred on at least 3 different dates
              updateChart(purchasesByDate);
            } else if (chart) {
              chart.destroy(); // If purchases occurred on less than 3 different dates, destroy the chart
              setIsChartReady(false);
            }
          }
        };
      
        purchasesRef.on('child_added', handleChildAdded);
        purchasesRef.on('child_removed', handleChildRemoved);
      };
  
      const updateChart = (purchasesByDate) => {
        if (chart) {
          chart.destroy(); // Destroy the previous chart instance
        }
  
        const dates = Object.keys(purchasesByDate).sort();
        const spentData = dates.map((date) => {
          const amounts = purchasesByDate[date];
          const totalAmount = amounts.reduce((acc, amount) => acc + amount, 0);
          return totalAmount;
        });
        
        
//CHART.JS CONFIGURATION_____________________________________________________________________________
    const config = {
      type: 'line',
      data: {
        labels: dates,
        datasets: [
          {
            label: 'Spent',
            data: spentData,
            fill: false,
            backgroundColor: '#ffffff',
            borderColor: "#ffbd2b",
            borderWidth: 2,
            pointBackgroundColor: '#303030',
            pointRadius: 0,
            pointHoverRadius: 8,
            pointHitRadius: 10,
            tension: 0.3,
          },
      
        ],
      },
      options: {
        elements: {
            point: {
                radius: 1},
            },
        layout: {
          padding: {
            bottom: 0,
          },
        },
        aspectRatio: false,
        plugins: {
            
          tooltip: {
            multiKeyBackground: 'rgba(0,0,0,0)',
            callbacks: {
              label: function (context) {
                var label = context.dataset.label || '';
        
                if (label) {
                  label += ': ';
                }
                label += props.currencySymbol + ' ' + context.parsed.y;
 
        
                return label;
              },
              
            },
          },
          legend: {
            display: false,
          },
        },
        elements: {
          point: {
            radius: 2,
          },
          line: {
            tension: 0.5,
          },
        },
        scales: {
          x: {
            display: false,
            adapter: 'moment', // use the moment date adapter
            grid: {
                color: context => context.tick.value == 0 ? 'blue' : 'transparent',
              display: false,
              border: {
                display: false},
                drawBorder: false,
            },
            ticks: {
              display: false,
            },
            type: 'time',
            time: {unit: 'day',
            tooltipFormat: 'MMM D'},
            parser: 'YYYY-MM-DD', // Specify the date format used in the dates array
            displayFormats: {
              day: 'MMM D', // Format for displaying the tick labels
            },
          },
          y: {
            grid: {
                display: false,
                drawBorder: false,

              },
            ticks: {
             callback: function (value, index, values) {
                if (value >= 1000) {
                  return props.currencySymbol + (value/1000).toFixed(0) + 'k';
                } else {
                  return props.currencySymbol + value.toFixed(0);
                }
              },
              color: '#ffffff',
              font: { 
                size: 10,
                family: 'Baloo 2', 
                
              },
              display: props.showYAxisTicks, 
              drawBorder: false,
            },
          },
        },
      },
    };

//RENDER THE CHART.JS CHART__________________________________________________________________________


chart = new Chart(ctx, config); // Create the new chart instance
setIsChartReady(true);

const emptyChartStateElement = document.querySelector('.emptychartstate');
if (emptyChartStateElement !== null) {
  emptyChartStateElement.classList.add('dontdisplay');
}

const dailySpendingCanvasElement = document.querySelector('.dailyspendingcanvas');
if (dailySpendingCanvasElement !== null) {
  dailySpendingCanvasElement.classList.remove('dontdisplay');
}
// document.querySelector('.emptychartstate').classList.add('dontdisplay');

};

fetchData();

return () => {
purchasesRef.off('child_added');
if (chart) {
  chart.destroy(); // Destroy the chart instance when the component unmounts
}
};
}, [props.userId, props.showYAxisTicks, props.purchases]);


//JSX________________________________________________________________________________________________

  return (
    <div className='chartcanvas2' >
      <div style={{color: "white"}} className="emptychartstate"><img className="budgetsignature" src={budgetsignature} />Your daily spending signature will appear here once you have made purchases on at least three different dates.</div>
      <canvas className="dailyspendingcanvas" style={{backgroundColor: "transparent"}}  ref={canvasRef} />
    </div>
  );
}

export default memo(DailySpending);
