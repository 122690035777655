import React, { useState } from 'react';
import { FaSpinner } from 'react-icons/fa';
import './LightMode.css';

const GoalEmojiModal = ({
  showModal,
  emojiCategories,
  selectedEmoji,
  handleEmojiClick,
  closeModal,
}) => {
  const [updatingEmoji, setUpdatingEmoji] = useState(false);

  const handleEmojiSelection = async (emoji) => {
    setUpdatingEmoji(true);
    try {
      handleEmojiClick(emoji); // Call the handleEmojiClick function from props
      closeModal();
    } catch (error) {
      console.error(error);
    } finally {
      setUpdatingEmoji(false);
    }
  };

  if (!showModal) {
    return null;
  }

  return (
    <div className="resetconfirm3">
      <div className="emoji-modal-content">
        {/* Iterate over each category */}
        {Object.keys(emojiCategories).map(category => (
          <div key={category}>
            <h2 className="emoji-modal-list-header2">{category}</h2>
            <div className="emoji-list2">
              {/* Iterate over each emoji in the current category */}
              {emojiCategories[category].map((emoji, index) => (
                <button
                  key={index}
                  className={`emoji-item ${selectedEmoji === emoji ? 'selected' : ''}`}
                  onClick={() => handleEmojiSelection(emoji)} // Call handleEmojiSelection instead of handleEmojiClick
                >
                  {emoji}
                </button>
              ))}
            </div>
          </div>
        ))}
        <div className="loader-container">
          {updatingEmoji && (
            <div className="loader">
              <FaSpinner className="spinner-icon" />
              <span>Updating Emoji...</span>
            </div>
          )}
        </div>
      </div>
      <button className="emojimodal-close-button" onClick={closeModal}>
        Cancel
      </button>
    </div>
  );
};

export default React.memo(GoalEmojiModal);
