import firebase from "firebase/compat/app";
import "firebase/compat/database";
import "firebase/compat/storage";
import { auth } from "firebase/compat/auth";
import React, { useState, useRef, useEffect } from "react";
import "./LightMode.css";
import coffee1 from "./images/coffee1.svg";
import coffee2 from "./images/coffee2.svg";
import coffee3 from "./images/coffee3.svg";
import circle1 from "./images/circle1.svg";
import circle2 from "./images/circle2.svg";
import circle3 from "./images/circle3.svg";
import star from "./images/star.svg"
import splashlogo1 from "./images/splashlogo1.svg";
import splashlogo2 from "./images/splashlogo2.svg";
import splashlogo3 from "./images/splashlogo3.svg";
import splashlogo4 from "./images/splashlogo4.svg";
import BudgetPage from "./BudgetPage";
import PurchasesPage from "./PurchasesPage";
import Achievements from "./Achievements" ;
import ArchivePage from "./ArchivePage";
import OptionsMenu from "./OptionsMenu";
import LoginForm from "./LoginForm";
import SupportForm from "./SupportForm";
import { endOfMonth, getDay, getDate } from 'date-fns'; 
import InputWidget from "./InputWidget";
import AboutUs from "./AboutUs";
import UserProfile from "./UserProfile";
import PaydayReview from "./PaydayReview";
import ExpenseReminders from "./ExpenseReminders";
import HowDoILilbudget from "./HowDoILilbudget";
import Groups from "./Groups";
import TinderLikeReview from './TinderLikeReview'; // Import the TinderLikeReview component
import { FaCheck, FaEllipsisH } from "react-icons/fa";
import { FcMoneyTransfer, FcDoughnutChart, FcAreaChart, FcConferenceCall, FcSynchronize, FcVip, FcCalendar  } from "react-icons/fc";
import {
    Route,
    Routes,
    NavLink,
    useNavigate,
    useLocation,
} from "react-router-dom";

const db = firebase.database();

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: "budget-app-d568a.firebaseapp.com",
    databaseURL: "https://budget-app-d568a-default-rtdb.firebaseio.com",
    projectId: "budget-app-d568a",
    storageBucket: "budget-app-d568a.appspot.com",
    messagingSenderId: "219321405159",
    appId: "1:219321405159:web:c4a51c20c9d13376629c42",
    measurementId: "G-6P5Y0TGT7T"
  };

firebase.initializeApp(firebaseConfig);

function App() {
    const [categories, setCategories] = useState({});
    const [purchases, setPurchases] = useState([]);
    const [remainingBudgets, setRemainingBudgets] = useState(categories);
    const [currentDate, setCurrentDate] = useState(new Date());
    const [showPurchase, setShowPurchase] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [authenticated, setAuthenticated] = useState(false);
    const [showPurchasesPage, setShowPurchasesPage] = useState(true);
    const [animateConfirmation, setAnimateConfirmation] = useState(false);
    const [activeTab, setActiveTab] = useState("");
    const [showMessage, setShowMessage] = useState(false);
    const [showDeleteMessage, setShowDeleteMessage] = useState(false);
    const [showGroupDeleteMessage, setShowGroupDeleteMessage] = useState(false);
    const [showLoginForm, setShowLoginForm] = useState(false);
    const [showTransactionMessage, setShowTransactionMessage] = useState(false);
    const [currencySymbol, setCurrencySymbol] = useState("$");
    const [showAnimation, setShowAnimation] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState("");
    const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);
    const [lastActivityTime, setLastActivityTime] = useState(new Date());
    const [openOptionsMenu, setOpenOptionsMenu] = useState(false);
    const [selectedPayQuote, setSelectedPayQuote] = useState("");
    const [showUpdateMessage, setShowUpdateMessage] = useState(false);
    const [isComponentMounted, setIsComponentMounted] = useState(false);
    const [showSplashPage, setShowSplashPage] = useState(true);
    const [step, setStep] = useState(1);
    const [activeComponent, setActiveComponent] = useState("budgets");
    const [topPurchases, setTopPurchases] = useState([]);
    const [showReview, setShowReview] = useState(false);
    const [showPaydayReview, setShowPaydayReview] = useState(false);
    const [groups, setGroups] = useState([]);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [showAliasMessage, setShowAliasMessage] = useState(false);
    const [showInputWidget, setShowInputWidget] = useState(true);
    const [triggerRefetch, setTriggerRefetch] = useState(false);
    const [showDateInfo, setShowDateInfo] = useState(false);
    const [hasPurchases, setHasPurchases] = useState(false);
    const [backgroundAnimation, setBackgroundAnimation] = useState(true);
    const [showTutorial, setShowTutorial] = useState(false);
    const [showJackDiv, setShowJackDiv] = useState(false);
    const [showBillReminders, setShowBillReminders] = useState(false);
    const [showExpenseReminders, setShowExpenseReminders] = useState(false);
    const [showIncomeBudgetForm, setShowIncomeBudgetForm] = useState(false);
    const [hasUpcomingExpenses, setHasUpcomingExpenses] = useState(false);
    const [expenses, setExpenses] = useState([{ name: "", amount: "" }]);
    const [editMode, setEditMode] = useState(false);
    const [hasPayableUpcomingExpenses, setHasPayableUpcomingExpenses] = useState(false);
    const [lastPaidExpense, setLastPaidExpense] = useState(null);
    const [showNavTutorial, setShowNavTutorial] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedQuote, setSelectedQuote] = useState("");
    const [showForm, setShowForm] = useState(false);
    const [selectedPurchase, setSelectedPurchase] = useState(null);
    const [selectedPurchaseId, setSelectedPurchaseId] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [selectedEmoji, setSelectedEmoji] = useState("");


    const emojiCategories = {
        "Food & Drink": ["🍔", "🍟", "🍕", "🌭", "🥪", "🌮", "🌯", "🥙", "🥚", "🍳", "🥘", 
        "🍲", "🥣", "🥗", "🍿", "🧈", "🧂", "🥫", "🍱", "🍘", "🍙", "🍚", "🍛", "🍜", "🍝", "🍠", "🍢", "🍣", "🍤", "🍥", "🥮", 
        "🍡", "🥟", "🥠", "🥡", "🍦", "🍧", "🍨", "🍩", "🍪", "🎂", "🍰", "🧁", "🥧", "🍫", "🍬", "🍭", 
        "🍮", "🍯", "🍼", "🥛", "☕", "🍄", "🍵", "🍶", "🍾", "🍷", "🍸", "🍹", "🍺", "🍻", "🥂", "🥃", "🥤", "🧃", "🧉", "🧊", "🍏", "🍎", "🍐", "🍊", "🍋", "🍌", "🍉", "🍇", "🍓", "🍈", "🍒", 
        "🍑", "🥭", "🍍", "🥥", "🥝", "🍅", "🍆", "🥑", "🥦", "🥬", "🥒", "🌶️", "🌽", "🥕", "🧄", "🧅", "🥔", "🍠", "🥐", "🥯", 
        "🍞", "🥖", "🥨", "🧇", "🥞", "🧀", "🍖", "🍗", "🥩",],
        "Merchandise & Retail": ["🔖", "🏷️", "📜", '🛒', '👗', '🧦', '🧣', '🩳', '👖', '👕', '👔', '🥼', '🧥', '🧤', '👘', '🥻', '👚', '🩲', '🩱', '👙', '👛', '👜', '👝', '👞', '👟', '🎒', 
        '🥾', '🥿', '👠', '🩰', '🧢', '👒', '🎩', '💄', '👡', '👓', '🕶', "💡", "🔦", "🏮", "📔", "📕", "📖", "📗", "📘", "📙", "📚", "🧮", "📓", "📒", "🧵", "🧶", '🎨', "📷", "📸", "📹", "📼",  "🖨️", "⌨️", "🖱️", "🖲️", "💽", "💾", "💿", "📀",  "🔌", "💻", "🖥️", "📱",],
        "Services & Utilties": ["💈", "✂️", "🛢️", 
        "⛽", "⚖️", "📭", "📮", "📦",  "📰", "🗞️", "🗃️", "🗄️","🛡️", "🚑","🏢", "🏣", "🏤", "🏥", "🏦", "🏨", "🏩", "🏪", "🏫", "🏬","📑", "🔍","🏭","🚓",  
        "🚒", "🚐", "🚚", "🚛", "🚜", '🎓', "👩‍🎓", "👨‍🎓", "👮‍♂️", "👮‍♀️", "👷‍♂️", "👷‍♀️", "👩‍🏫", "👨‍🏫", "👩‍⚖️", "👨‍⚖️", "👩‍🌾", "👨‍🌾", "👩‍🍳", "👨‍🍳", "👩‍🔧", "👨‍🔧", "👩‍🏭", "👨‍🏭", 
        "👩‍💼", "👨‍💼",  "👩‍💻", "👨‍💻", "👩‍🎤", "👨‍🎤", "👩‍🎨", "👨‍🎨", "👩‍✈️", "👨‍✈️",  "🕵️‍♂️", "🕵️‍♀️",  
        "👩‍🚒", "👨‍🚒", "👮‍♂️", "👮‍♀️", "👷‍♂️", "👷‍♀️",  "🏗️",  "☎️", "📞", '💋',  "📟", "📠", "🎚️", "🎛️",],
        "Games & Entertainment": ["🎥", "🎞️", "📽️", 
        "🎬", "📺",  "📻", "🎙️","🪁", "🎳", "🎮", "🕹️", "🎰", "🎲", "🧩", "♟️", "🎭", "⛲", 
        "🎠", "🎡", "🎢", "🎼", "🎵",  "📡",
        "🎶", "🎼",  "📯", "🎤", "🎧", "🎷", "🎸", "🎹", "🎺", "🎻", "🥁", "🤳", "💃", "🕺", ],
        "Health, Sports & Fitness": [ "🏃‍♀️", "🏃‍♂️", "🧍‍♀️", "🧍‍♂️", "🧎‍♀️", "🧎‍♂️", "💪", "🦵", "🦶", "👩‍⚕️", "👨‍⚕️", "👩‍🔬", "👨‍🔬", "⚽", 
        "🏀", "🏈", "⚾", "🥎", "🎾", "🏐", "🏉", "🥏", "🎱", "🪀", "🏓", "🏸", "🥅", "🏒", "🏑", "🥍", "🏏", "🪁", "🥊", "🥋", 
        "🎣", "🤿", "🛹", "🎿", "🛷", "⛸️", "🥌", "🎯","🛶", "🏹",],
        "Transportation, Travel & Vacation": [ "🚗", "🚕", "🚙", "🚌", "🚎", "🏎️",  "🏍️", "🛵", "🚲", "🛴",       '🚏', "🚦", "🚥", "🚀", "👩‍🚀", "👨‍🚀", "💂‍♂️", "💂‍♀️", 
        "🛸", "🛫", "🛬", "🛰️", "🚁", "🏰", "🏟️", "💒", "🗼", "🗽", "⛪", "🕌", "🛕", "🕍", "⛩️", "🕋", "🏯", "🌋", "🏔️", "⛰️", "🏕️", "🏖️", "🏜️", "🏝️", "🏞️", "🛣️", "🛤️", '🚄', '🚝', '🚂', '🚟', '🪂', '🚢', '⚓', "⛵", "🚤", "🛥️", "🛳️", "⛺", "🌁", "🌃", "🏙️", "🌄", "🌅", "🌆", "🌇", "🌉",  ],
        "Home & Garden": [ "🏚️", "🏠", "🏡", "🏘️", "🚪", "🛏️", "🛋️", "🚽", '🧻', '🧼', '🧽', '🧴', '🪒', 
        '🧹', '🧺', '🧯', '☔', "🌡️", "🗑️",
        "🚿", "🛁", "🔨", "🪓", "⛏️", "🔧",'🎄', '🎋', '🎍', '💐', '🌸', '🌹', '🌺', '🌻', '🌼', '🌷', '🥀', '🌱', '🌲', '🌳', 
        '🌴', '🌵', '🌾', '🌿', '🍀', '🍁', '🍂', '🍃',],
        "Banking & Investing": ["💰", "💴", "💵", "💶", "💷", "💸", "💳", "💹", "💱", "💲", "✉️", "🏛️",],
        "Animals": [ "🐶", "🐱", "🐭", "🐹", "🐰", "🦊", "🐻", "🐼", "🐨", "🐯", "🦁", "🐮", "🐷", "🐽", "🐸", "🐵", "🙈", "🙉", "🙊", "🐔", 
        "🐧", "🐦", "🐤", "🐣", "🐥",  "🦅", "🦉", "🦇", "🐺", "🐗", "🐴", "🦄", "🐝", "🐛", "🦋", "🐌", "🐞", "🐜", "🦟", 
        "🦗", "🕷️", "🕸️", "🦂", "🦞", "🦀", "🦐", "🦑", "🐙", "🦆", "🦩", "🦚", "🦢", "🦩", "🦚", "🦜", 
        "🦘", "🦡", "🦨",  "🦥",   "🦘",      "🦝",  "🦮", "🐒", "🦍", "🦧", "🐕‍🦺", "🐩", "🐕", "🐈", "🐅", "🐆", "🐎", "🦌", "🦏", "🦛", "🐪", "🐐", "🐑", "🐏", "🐖", "🐄", "🐃", "🐂", "🐫",
        "🐾", "🦙", "🐇",],
        "Miscellaneous": [ "😀", "😃", "😄", "😁", "😆", "😅", "🤣", "😂", "🙂", "🙃", "😉", "😊", "😇", "😍", "🥰", "😘", "😗", "😚", "😙", "😋", 
        "😛", "😜", "🤪", "😝", "🤑", "🤗", "🤭", "🤫", "🤔", "🤐", "🤨", "😐", "😑", "😶", "😏", "😒", "🙄", "😬", "🤥", "😌", 
        "😔", "😪", "🤤", "😴", "😷", "🤒", "🤕", "🤢", "🤮", "🤧", "🥵", "🥶", "🥴", "😵", "🤯", "🤠", "🥳", "😎", "🤓", 
        "🧐", "😕", "😟", "🙁", "😮", "😯", "😲", "😳", "😦", "😧", "😨", "😰", "😥", "😢", "😭", "😱", "😖", "😣", "😞", 
        "😓", "😩", "😫", "🥱", "😤", "😡", "😠", "🤬", "😈", "👿", "💀", "☠️", "💩", "🤡", "👹", "👺", "👻", "👽", "🤖", 
        "😺", "😸", "😹", "😻", "😼", "😽", "🙀", "😿", "😾", "👶", "👧", "🧒", "👦", "👩", "🧑", "👨", "👵", "🧓", "👴", 
        "👲", "🧔", "👱‍♂️", "👱‍♀️",  "👩‍🦯", 
        "👨‍🦯", "👩‍🦼", "👨‍🦼",  "👩‍🦽", "👨‍🦽",   "💑", 
        "👩‍❤️‍👨", "👨‍❤️‍👨", "👩‍❤️‍👩", "💏", "👩‍❤️‍💋‍👨", "👨‍❤️‍💋‍👨", "👩‍❤️‍💋‍👩", "👪", "👨‍👩‍👦", "👨‍👩‍👧", "👨‍👩‍👧‍👦", 
        "👨‍👩‍👦‍👦", "👨‍👩‍👧‍👧", "👨‍👨‍👦", "👨‍👨‍👧", "👨‍👨‍👧‍👦", "👨‍👨‍👦‍👦", "👨‍👨‍👧‍👧", "👩‍👩‍👦", "👩‍👩‍👧", "👩‍👩‍👧‍👦", 
        "👩‍👩‍👦‍👦", "👩‍👩‍👧‍👧", "👩‍👦", "👩‍👧", "👩‍👧‍👦", "👩‍👦‍👦", "👩‍👧‍👧", "👨‍👦", "👨‍👧", "👨‍👧‍👦", "👨‍👦‍👦", "👨‍👧‍👧", "📣", "📢", "⏸️", "⏹️", "▶️", "⏭️", "⏯️", "🔀", "🔁", "🔂", "↩️", "↪️", "🔼", "⏫", "⬆️", "🔽", "⏬", 
        "⬇️", "➡️", "⬅️", "⬆️", "⬇️", "↗️", "↘️", "↔️", "↕️", "🔃", "🔄", "🔙", "🔚", "🔛", "🔜", "🔝", "🛐", "⚛️", "🔯", 
        "🕎", "✝️", "☦️", "☪️", "☸️", "✡️", "🔰", "🕉️", "☮️", "🕎", "☪️", "✝️", "☮️", "☪️", "✡️", "⛎", "♈", "♉", "♊", 
        "♋", "♌", "♍", "♎", "♏", "♐", "♑", "♒", "♓", "⛎", "🚻", "🚮", "🛢️", "⛽", "🚰", "♿", "🚹", "🚺", "🚻", "🚼", 
        "🚾", "🛂", "🛃", "🛄", "🛅", "🚸", "⛔", "🚫", "🚳", "🚭", "🚯", "🚱", "🚷", "📵", "🔞", "☢️", "☣️", "⬆️", "⬇️", 
        "⬅️", "➡️", "🔠", "🔡", "🔢", "🔣", "🅰️", "🅱️", "🆎", "🅾️", "🅿️", "🈷️", "🈶", "🈯", "🉐", "🈹", "🈚", "🈲", "🉑", 
        "🈸", "🈴", "🈳", "🈺", "🈵", "🔀", "🔁", "🔂", "🔃", "🔄", "🔼", "⤴️", "⤵️", "🆕", "🆓", "🆖", "🆗", "🆙", 
        "🆚", "🈁", "📶", "📳", "📴", "🆔", "⚕️", "🌐", "📛","🛑","🔇","🔊", "🔔", "🔕","🗳️", "✏️", "✒️", "🖋️", "🖊️", "🖌️", "🖍️", "📝", "📁", 
        "📂", "🗂️", "📅", "📆", "🗒️", "🗓️", "📋", "📇", "📌", "📍", "📎", "🖇️", "📏", "📐", "📨", "📩", "📤", "📥", "🚧", "⚠️",'🏁', '🏴‍☠️',   "🧭", "⏰", "⏱️", "⏲️", "🕰️", 
        "⌛", "⏳", "🔋",  "🔎", 
        "📃",  "📄",    "📧", 
         "📫", "📪", "📬",  "🔒", 
        "🔓", "🔏", "🔐", "🔑", "🗝️",  "🔩", "⚙️", "🗜️",]
      };





const openModal = () => {
    setShowModal(true);
};

const closeModal = () => {
    setShowModal(false);
};



const handleChangePurchaseIcon = (purchaseId, buttonId) => {
    setIsLoading(true);
    const userId = firebase.auth().currentUser.uid;
    const purchasesRef = db.ref(`users/${userId}/purchases`);
  
    setSelectedPurchaseId(purchaseId);
    openModal();
  
    // Update the purchase within the group
    // handleUpdateGroupPurchase(purchaseId, { icon: selectedEmoji });
    setIsLoading(false);
  };


const handleEditEmojiClick = (purchase) => {
    setIsLoading(true); 
    setSelectedPurchase(purchase);
  
    // Check if the current note is "📝 Add a purchase note"
    if (purchase.note === "📝 Add a purchase note") {
      // Replace the current note with an empty string
      purchase.note = "";
    }
  
    setShowForm(true);
  setShowInputWidget(false);
    const notequotes = ["A short note describing your purchase"];
    const randomIndex = Math.floor(Math.random() * 8);
  
    setIsLoading(false); 
  };

    const handleHideInputWidget = () => {
        const inputWidgetContainer = document.querySelector('.inputwidgetcontainer');
        if (inputWidgetContainer) {
          inputWidgetContainer.classList.add('dontdisplay');
        }
      }
      
      const handleUnhideInputWidget = () => {
        const inputWidgetContainer = document.querySelector('.inputwidgetcontainer');
        if (inputWidgetContainer) {
          inputWidgetContainer.classList.remove('dontdisplay');
        }
      }
  


    useEffect(() => {
        const unsubscribe = firebase.auth().onAuthStateChanged(user => {
          if (user) {
            // User is signed in
            setAuthenticated(true);
            setShowLoginForm(false);
          } else {
            // User is signed out
            setAuthenticated(false);
            setShowLoginForm(true);
          }
        });
    
        // Cleanup subscription on unmount
        return () => unsubscribe();
      }, []);


      useEffect(() => {
        let timeoutId = null;
        let intervalId = null;
      
        const checkElementExistence = () => {
          const remainingBudgetChartElement = document.querySelector('.remainingbudgetchart');
      
          if (remainingBudgetChartElement) {
            clearInterval(intervalId);
            timeoutId = setTimeout(() => {
              remainingBudgetChartElement.classList.remove('dontdisplay');
            }, 1000);
          }
        };
      
        intervalId = setInterval(checkElementExistence, 100);
      
        // Cleanup function
        return () => {
          clearInterval(intervalId);
          clearTimeout(timeoutId);
        };
      }, []);


 

      const handleShowExpenseReminders = () => {
        setShowIncomeBudgetForm(true);
        setShowExpenseReminders(true);
        setShowInputWidget(false)
      };
      


    //AN ARRAY OF POSSIBLE HEADERS WHEN USER CLICKS PAYDAY___________________
    const payquotes = [
        "Money in the bank!",
        "Payday!",
        "Cha-ching!",
        "Cash me outside!",
        "Another day, another dollar!",
        "Bank balance on-point.",
        "Time to break out the champagne!",
        "Getting paid, not played!",
        "More money, more problems... wait, scratch that!",
        "Money longer than the aisles at the grocery store.",
        "It's all about the Benjamins.",
        "Stacks on stacks!",
        "Bank account be poppin!",
    ];

    useEffect(() => {
        setIsComponentMounted(true);
       setShowInputWidget(true);
      }, []);
    
      useEffect(() => {
        if (isComponentMounted) {
            
          setTimeout(() => {
            setShowSplashPage(false);
          }, 1600);
        }
      }, [isComponentMounted]);


      useEffect(() => {
        setIsComponentMounted(true);
      
  
        
        return () => {
          setIsComponentMounted(false);
        };
      }, []);


          
const handleCloseTutorial = () => {
    setShowTutorial(false);
    setShowInputWidget(true)
  };

  const handleCloseBillReminders = () => {
    setShowBillReminders(false);
    setShowInputWidget(true)
  };

 

  const handleShowBillReminders = () => {
    setShowBillReminders(true);
    setShowInputWidget(false)
    
  };



  
useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        const userEmail = user.email; // Get the user's email.

        if(userEmail === 'jack@home.com') { // Here is your check
            setShowJackDiv(true); // If email matches, set the showDiv state to true.
        }
      }
    });

    // Cleanup function to remove listener
    return () => unsubscribe();

}, []);
  



useEffect(() => {
    const authUnsub = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        const uid = user.uid;
        const purchasesRef = firebase.database().ref(`users/${uid}/purchases`);
        const handleData = (snap) => {
          const purchases = snap.val();
          if (purchases && Object.keys(purchases).length >= 2) {
            setHasPurchases(true);
          } else {
            setHasPurchases(false);
          }
        }
  
        purchasesRef.on('value', handleData);
  
        // Cleanup function
        return () => {
          purchasesRef.off('value', handleData);
        };
      }
    });
  
    // Clean up the auth state listener
    return () => {
      authUnsub();
    };
  }, []);
     

      useEffect(() => {
        const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
          if (user) {
            const userId = user.uid;
            const groupsRef = firebase.database().ref('groups');
    
            groupsRef.on('value', (snapshot) => {
              const data = snapshot.val() || {};
    
              // Convert the data object to an array
              const groupsArray = Object.entries(data).map(([groupId, groupData]) => ({
                id: groupId,
                name: groupData.name,
                members: groupData.members,
              })).filter(group => group.members && group.members[userId]);
    
              setGroups(groupsArray);
            });
    
            return () => {
              groupsRef.off();
              unsubscribe();
            };
          }
        });
      }, []);



      useEffect(() => {
        const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
          if (user) {
            const userId = user.uid;
            const userRef = firebase.database().ref(`users/${userId}`);
      
            userRef.once('value', (snapshot) => {
              const data = snapshot.val();
              if (data && data.hasOwnProperty('animateBackground')) {
                setBackgroundAnimation(data.animateBackground);
              }
            });
      
            // Cleanup function to remove listener
            return () => {
              userRef.off();
              unsubscribe();
            };
          }
        });
      }, []);


      
      


    //HOOK TO CHOOSE AND DISPLAY A RANDOM EXCLAIMATION FROM THE LIST___________________________________________________
    useEffect(() => {
        const randomIndex = Math.floor(Math.random() * 13);
        setSelectedPayQuote(payquotes[randomIndex]);
    }, []);

    const location = useLocation();
    const navigate = useNavigate();

    const { pathname } = useLocation();

    //OPEN THE OPTIONS MENU
    const handleOpenOptionsMenu = () => {
        setOpenOptionsMenu((prevState) => !prevState);
    };

    function handleClickOutside(event) {
        // Check if the event target is outside of the OptionsMenu component
        const optionsMenu = document.querySelector(
            ".optionscomponentcontainer"
        );
        if (optionsMenu && !optionsMenu.contains(event.target)) {
            closeMenu();
        }
    }

    const closeMenu = () => {
        setOpenOptionsMenu(false);
    };

    const toggleWidget = () => {
        setShowInputWidget(!showInputWidget);
    };

    const handleShowPaydayReview = () => {
        setShowPaydayReview(true);
        setShowDateInfo(false);
        setShowInputWidget(true);
    };

    const handleClosePaydayReview = () => {
        setShowPaydayReview(false);
    };

    //HANDLE USER LOG IN______________________________________________________________________
    const handleLogin = () => {
        setShowSplashPage(true);
        setShowLoginForm(false);
        setShowPurchasesPage(false);
        setActiveTab("budget");

        setTimeout(() => {
            // Code to execute after the splash animation completes
            // Remove the splash page and show the loaded data
            setShowSplashPage(false);
            // Additional code for showing the loaded data or transitioning to the next screen
        }, 1600);
    };



    // Check if the component is navigated to from another extension
    const fromExtension = location.state?.fromExtension;
    
    useEffect(() => {
      if (fromExtension) {
        // Set showSplashPage to true to indicate that data should be loaded in the background
        setShowSplashPage(true);
        setTimeout(() => {
            // Code to execute after the splash animation completes
            // Remove the splash page and show the loaded data
            setShowSplashPage(false);
            // Additional code for showing the loaded data or transitioning to the next screen
        }, 1600);
        let timeoutId = null;
        let intervalId = null;
      
        const checkElementExistence = () => {
          const remainingBudgetChartElement = document.querySelector('.remainingbudgetchart');
      
          if (remainingBudgetChartElement) {
            clearInterval(intervalId);
            timeoutId = setTimeout(() => {
              remainingBudgetChartElement.classList.remove('dontdisplay');
            }, 1000);
          }
        };
      
        intervalId = setInterval(checkElementExistence, 100);
      
        // Cleanup function
        return () => {
          clearInterval(intervalId);
          clearTimeout(timeoutId);
        };
      }
    }, [fromExtension]);


    //LOG THE USER OUT AFTER TEN MINUTES OF INACTIVITY__________________________
    useEffect(() => {
        const timer = setInterval(() => {
            const currentTime = new Date();
            const timeDiff = (currentTime - lastActivityTime) / (1000 * 60); // convert milliseconds to minutes
            if (timeDiff >= 2880) {
                // log out the user if ten minutes have passed
                firebase.auth().signOut();
                setAuthenticated(false);
                setShowLoginForm(true);
                setCategories({});
                setSelectedCategory("");
                // navigate('/login'); // redirect the user to the login page
            }
        }, 3600000); // check every minute

        return () => clearInterval(timer); // clear the timer on unmount
    }, [lastActivityTime]);

    const handleActivity = () => {
        setLastActivityTime(new Date());
    };

    useEffect(() => {
        window.addEventListener("mousemove", handleActivity);
        window.addEventListener("keydown", handleActivity);
        return () => {
            window.removeEventListener("mousemove", handleActivity);
            window.removeEventListener("keydown", handleActivity);
        };
    }, []);

 

    //HANDLE ALLOWING THE USER TO CHANGE CURRENCY TYPE
    const currencySymbols = ["$", "€", "£", "¥", "₹"];
    const handleChangeCurrency = () => {
        const userId = firebase.auth().currentUser.uid;
        const currentSymbolIndex = currencySymbols.indexOf(currencySymbol);
        const nextSymbolIndex =
            (currentSymbolIndex + 1) % currencySymbols.length;
        const newSymbol = currencySymbols[nextSymbolIndex];
        setCurrencySymbol(newSymbol);
        const userRef = firebase.database().ref(`users/${userId}`);
       userRef.update({currency: newSymbol}, function(error) {
            if (error) {
                console.log('Error updating currency:', error);
            } else {
                // console.log('Currency updated successfully!');
            }
        });
    };

    const handleRowClick = (category) => {
        
        setSelectedCategory(category);
        // setCategories(category);
    };

    const logout = () => {
        if (showLogoutConfirmation) {
          firebase.auth().signOut().then(() => {
            // Log out successful
            setAuthenticated(false);
            setShowLoginForm(true);
            setShowLogoutConfirmation(false);
            setCategories({});
            setSelectedCategory("");
            setActiveComponent("budgets");
            setShowJackDiv(false);
          }).catch((error) => {
            // An error occurred while logging out
            console.error(error);
          });
        }
      };
    

    //HANDLE USER LOG OUT_____________________________________________________________________
    const handleLogout = () => {
        if (showLogoutConfirmation) {
            firebase.auth().signOut();
            setAuthenticated(false);
            setShowLoginForm(true);
            setShowLogoutConfirmation(false);
            setShowTutorial(false);
            setCategories({});
            setSelectedCategory("");
            setActiveComponent("budgets");
        }
    };

    const openLogoutConfirmation = () => {
        setShowLogoutConfirmation(true);
    };

    //SHOW A SMALL ANIMATION WHEN THE ADD PURCHASE BUTTON IS CLICKED
    const handleShowAnimation = () => {
        setShowAnimation(true);
        setTimeout(() => {
            setShowAnimation(false);
        }, 4000); // hide the animation after 2 seconds
    };

    //ALLOW THE USER TO CLICK A BUTTON TO RESET THEIR PURCHASE DATA___________________________
    const handleReset = () => {
        if (showConfirmation) {
            const uid = firebase.auth().currentUser.uid;
            const purchasesRef = firebase.database().ref(`users/${uid}/purchases`);
            const budgetRef = firebase.database().ref(`users/${uid}/budget`);
            const archivedPurchasesRef = firebase.database().ref(`users/${uid}/archivedpurchases`);
            const savingsGoalsRef = firebase.database().ref(`users/${uid}/savingsGoals`);
    
            archivedPurchasesRef.once("value", (snapshot) => {
                if (snapshot.exists()) {
                    // do something with the existing archivedpurchases
                } else {
                    // create a new archivedpurchases node in the database
                    archivedPurchasesRef.set({});
                }
            });
    
            purchasesRef.once("value").then((snapshot) => {
                const purchases = snapshot.val();
                const purchasesArray = Object.values(purchases);
                
                purchasesArray.sort((a, b) => b.price - a.price);
                Object.keys(purchases).forEach((key) => {
                    // Add archiveDate to the purchase object
                    purchases[key].archiveDate = firebase.database.ServerValue.TIMESTAMP;
                    // Then push it to the archivedPurchasesRef
                    archivedPurchasesRef.push(purchases[key]);
                });
                purchasesRef.remove();
            });

           
    
            savingsGoalsRef.once("value").then((snapshot) => {
                const savingsGoals = snapshot.val();
                for (let key in savingsGoals) {
                    const goal = savingsGoals[key];
                    goal.startingAmount = goal.progress;
                    savingsGoalsRef.child(key).update({ startingAmount: goal.startingAmount });
                }
            });
    
            budgetRef.remove();
           
    
            // reset the state variables and Firebase database
            setPurchases([]);
            setRemainingBudgets(categories);
            setCurrentDate(new Date());
            setTriggerRefetch(prev => !prev);
            // dismiss the confirmation modal
            setShowConfirmation(false);
    
        } else {
            // show the confirmation message
            setShowConfirmation(true);
            setAnimateConfirmation(true);
        }
    
        // hide the reset button
        setShowReview(true);
        setShowPurchase(false);
    };
    

    //WHEN THE USER TRIES TO RESET THEIR DATA, MAKE THEM CONFIRM FIRST________________________
    const handleConfirmation = () => {
        setShowMessage(true);
        setTimeout(() => {
          const messageElement = document.querySelector('.confirm-message'); // Replace 'your-message-class' with the actual class of the element you want to add the class to
          if (messageElement) {
            messageElement.classList.add('slideoutmessage');
          }
          setTimeout(() => {
            setShowMessage(false);
          }, 1000); // Set the duration in milliseconds (4 seconds = 4000 milliseconds)
        }, 3000);
      };

    //HOOK TO ENSURE THE CORRECT TAB IS ACTIVE_________________________________________________

    const containerRef = useRef();

    const handleScroll = (event) => {
        const container = event.target;
        const scrollPosition = container.scrollLeft;
        const buffer = 50; // Adjust the buffer value as per your requirement
        const containerWidth = container.clientWidth;
        const containerCenter = container.scrollWidth / 2;
      
        if (scrollPosition <= buffer) {
          setActiveComponent("budgets");
        } else if (scrollPosition >= container.scrollWidth - containerWidth - buffer) {
          setActiveComponent("achievements");
        } else if (scrollPosition >= containerCenter - buffer) {
          setActiveComponent("groups");
        } else {
          setActiveComponent("purchases");
        }
      };

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            container.addEventListener("scroll", handleScroll);
        }
        return () => {
            if (container) {
                container.removeEventListener("scroll", handleScroll);
            }
        };
    }, [handleScroll]);

    const handleScrollToStart = () => {
        const container = containerRef.current;
        if (container) {
            const firstElement = container.querySelector(
                ".budgetpagecontainer"
            );

            if (firstElement) {
                const firstElementLeft = firstElement.getBoundingClientRect()
                    .left;
                container.scrollTo({
                    left: container.scrollLeft + firstElementLeft,
                    behavior: "smooth",
                    block: "nearest",
                });
            }
        }
    };

    const handleScrollToEnd = () => {
        const container = containerRef.current;
        if (container) {
            const lastElement = container.querySelector(
                ".purchasespagecontainer"
            );

            if (lastElement) {
                const lastElementRight = lastElement.getBoundingClientRect()
                    .right;
                container.scrollTo({
                    left:
                        container.scrollLeft +
                        (lastElementRight - container.clientWidth),
                    behavior: "smooth",
                    block: "nearest",
                });
            }
        }
    };

    const handleScrollToGroups = () => {
        const container = containerRef.current;
        if (container) {
            const lastElement = container.querySelector(
                ".groupspagecontainer"
            );

            if (lastElement) {
                const lastElementRight = lastElement.getBoundingClientRect()
                    .right;
                container.scrollTo({
                    left:
                        container.scrollLeft +
                        (lastElementRight - container.clientWidth),
                    behavior: "smooth",
                    block: "nearest",
                });
            }
        }
    };
    
    const handleScrollToMid = () => {
        const container = containerRef.current;
        if (container) {
            const lastElement = container.querySelector(
                ".achievementspagecontainer"
            );

            if (lastElement) {
                const lastElementRight = lastElement.getBoundingClientRect()
                    .right;
                container.scrollTo({
                    left:
                        container.scrollLeft +
                        (lastElementRight - container.clientWidth),
                    behavior: "smooth",
                    block: "nearest",
                });
            }
        }
    };



 //CALCULATE THE REMAINING DAYS IN THE MONTH FOR A SHORT COUNTDOWN STATEMENT BELOW THE BUDGET TABLE__________
 const daysLeftInMonth = () => {
    const lastDayOfMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0
    );
    const daysLeft = Math.ceil(
        (lastDayOfMonth.getTime() - currentDate.getTime()) /
            (1000 * 3600 * 24)
    );
    return daysLeft;
};

    const toggleDateInfoModal = () => {
        setShowInputWidget(!showInputWidget);
        setShowDateInfo(!showDateInfo);
    };


    useEffect(() => {
        const today = new Date();
        const daysInMonth = getDate(endOfMonth(today)); 
        const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
            if (user) {
              const userId = user.uid;
              const expenseRef = firebase.database().ref(`users/${userId}/expenses`);
        
              expenseRef.on('value', (snapshot) => {
                let updatedExpenses = snapshot.val();
                
                updatedExpenses = Object.entries(updatedExpenses || {}).map(([key, value]) => ({
                  ...value,
                  dueDate: value.dueDate === 'End' ? daysInMonth : parseInt(value.dueDate, 10) || 'No due date set',
                  key,
                }));
        
                let hasPayableUpcoming = false;
                
                for (let expense of updatedExpenses) {
                  if (expense.dueDate !== 'No due date set' && !expense.paid && !expense.autopay) {
                    const daysUntilDue = expense.dueDate >= today.getDate() ? expense.dueDate - today.getDate() : expense.dueDate + daysInMonth - today.getDate();
                    if (daysUntilDue <= 2) {
                        hasPayableUpcoming = true;
                        break;
                    }
                  }
                }
        
                setHasPayableUpcomingExpenses(hasPayableUpcoming);
              });
        
              // Cleanup function to remove listener
              return () => {
                expenseRef.off();
                unsubscribe();
              };
            }
          });
        }, [lastPaidExpense]);

    //JSX _____________________________________________________________________________________

    return (
        <>
            <div className="App">
                {backgroundAnimation && <div
                    className="coffee-content"
                    style={{
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                    }}
                >
                    <img className="coffee2" src={coffee2} />
                    <img className="coffee3" src={coffee3} />
                    <img className="coffee1" src={coffee1} />
                </div>}
                {isComponentMounted && !showLoginForm && showSplashPage && (
                    <div className="splashpagecontainer">
                        <div className="splashpagelogobox">
                            <img className="splashlogo1" src={splashlogo1} />
                            <img className="splashlogo2" src={splashlogo2} />
                            <img className="splashlogo3" src={splashlogo3} />
                            <img className="splashlogo4" src={splashlogo4} />
                        </div>
                    </div>
                )}
                <div className="appcontainer">
                    {authenticated && !showTutorial && (
                        <header>
                            {pathname === "/archive" ||
                            pathname === "/support" ||
                            pathname === "/review" ||
                            pathname === "/about" ||
                            pathname === "/groups" ||
                            pathname === "/profile" ? null : (
                                <div className="reset-container">

<button className="component-indicator4" onClick={toggleWidget}>
    {showInputWidget ? "Hide Purchase Toolbar" : "Show Purchase Toolbar"}
</button>
                                    <button
                                        className="component-indicator3"
                                        onClick={handleShowPaydayReview}
                                        style={{
                                            display: showConfirmation
                                                ? "block"
                                                : "block",
                                        }}
                                    >
                                        <FcSynchronize />
                                        {daysLeftInMonth() < 2 && hasPurchases && (<div className="notificationdot"></div>)}

                                    </button>

                                    <button
                                        className={`component-indicator5 ${showDateInfo ? 'greyscale' : ''}`}
                                        onClick={handleShowBillReminders}
                                    >
                                        <FcCalendar />
                                        {hasPurchases && hasPayableUpcomingExpenses && (<div className="notificationdot"></div>)}
                                        

                                    </button>
                                
                                </div>
                            )}
                            <div className="message-container">
                                {showUpdateMessage && (
                                    <div className="confirm-message">
                                        Income worksheet has been updated!
                                    </div>
                                )}
                            </div>
                            <div className="message-container">
                                {showDeleteMessage && (
                                    <div className="deletemessage">
                                        Transaction deleted
                                    </div>
                                )}
                            </div>
                            <div className="message-container">
                                {showGroupDeleteMessage && (
                                    <div className="groupdeletemessage">
                                        Group deleted
                                    </div>
                                )}
                            </div>
                            <div className="message-container">
                                {showMessage && (
                                    <div
                                        className={`confirm-message  ${
                                            showTransactionMessage
                                                ? "slide-in"
                                                : "slide-out"
                                        }`}
                                    >
                                        Budgets have been reset!
                                    </div>
                                )}
                            </div>
                            <div className="message-container">
                                {showAliasMessage && (
                                    <div
                                        className={`confirm-message  ${
                                            showTransactionMessage
                                                ? "slide-in"
                                                : "slide-out"
                                        }`}
                                    >
                                        Alias has been updated!
                                    </div>
                                )}
                            </div>
                            <div className="message-container">
                                {errorMessage && (
                                    <div className="groupdeletemessage">
                                       {errorMessage}
                                    </div>
                                )}
                            </div>
                            <div className="message-container">
  {successMessage && (
    <div
      className={`confirm-message ${successMessage ? "slide-in" : ""}`}
    >
      {successMessage}
    </div>
  )}
</div>

                     
                        </header>
                    )}
                    {authenticated &&
                        (pathname === "/archive" ||
                        pathname === "/support" ||
                        pathname === "/review" ||
                        pathname === "/about" ||
                        pathname === "/groups" ||
                        pathname === "/profile" ? null : (
                            <div className="component-indicator-container">
                                <button
                                    style={{
                                        fontWeight: "600",
                                        textAlign: "center",
                                    }}
                                    onClick={handleScrollToStart}
                                    className={`component-indicator ${
                                        activeComponent === "budgets"
                                            ? "active"
                                            : ""
                                    }`}
                                >
                                    <FcMoneyTransfer/> 
                                </button>
                                <button
                                    style={{
                                        fontWeight: "600",
                                        textAlign: "center",
                                    }}
                                    onClick={handleScrollToEnd}
                                    className={`component-indicator ${
                                        activeComponent === "purchases"
                                            ? "active"
                                            : ""
                                    }`}
                                >
                                    <FcAreaChart />
                                </button>

                                <button
                                    style={{
                                        fontWeight: "600",
                                        textAlign: "center",
                                    }}
                                    onClick={handleScrollToGroups}
                                    className={`component-indicator ${
                                        activeComponent === "groups"
                                            ? "active"
                                            : ""
                                    }`}
                                >
                                    <FcConferenceCall />
                                </button>
                            
                            
                           
                                <button
                                    style={{
                                        textAlign: "center",
                                        paddingBottom: "4px"
                                    }}
                                    onClick={handleScrollToMid}
                                    className={`component-indicator ${
                                        activeComponent === "achievements"
                                            ? "active"
                                            : ""
                                    }`}
                                >
                                    <img className="star" src={star}/>
                                </button>
                                
                                </div>
                        ))}
{showTutorial && (
                <div style={{width: "100vw", height: "100vh"}}>
                    <HowDoILilbudget onClose={handleCloseTutorial} setShowNavTutorial={setShowNavTutorial} />
                </div>
            )}






{showDateInfo && (<div className="paydayreviewcontainer3">
    <div className="paydayreviewcontainer4">
        <div className="paydaycontainer4">
            <h2 className="paydateinfoheader">It's nearly the end of the month!</h2>
            <div className="datereviewdonuticon"><FcDoughnutChart /></div>
            <p className="paydateinfotext"><strong>Your budget review is waiting for you.</strong><br></br><br></br>After you check out your stats, you can reset your budget categories and start over when you're ready.</p>
            <button className="confirmation-no" onClick={toggleDateInfoModal}>
                OK
            </button>
        </div></div>
    </div>
)}


                    {authenticated && showInputWidget && (pathname === "/archive" ||
                        pathname === "/support" ||
                        pathname === "/review" ||
                        pathname === "/about" ||
                        pathname === "/profile" ? null :

                        <div className="inputwidgetcontainer">
                            <div className="inputflexcontainer">
                                <InputWidget
                                    handleShowAnimation={handleShowAnimation}
                                    currencySymbol={currencySymbol}
                                    setCurrencySymbol={setCurrencySymbol}
                                    handleChangeCurrency={handleChangeCurrency}
                                    categories={categories}
                                    setCategories={setCategories}
                                    selectedCategory={selectedCategory}
                                    setSelectedCategory={setSelectedCategory}
                                    setTriggerRefetch={setTriggerRefetch}
                                    remainingBudgets={remainingBudgets}
                                    setRemainingBudgets={setRemainingBudgets}
                                />
                            </div>
                        </div>


                    )}
                    {showLogoutConfirmation && (
                        <div className="confirmation-message">
                            <div className="resetconfirm">
                                <div className="divaccent"></div>
                                <p className="paydayheader4">
                                    <br></br>Are you sure you want to logout?
                                </p>
                                <div className="buttons-container">
                                    <div className="boxoballs2">
                                        <div className="ball1"></div>
                                        <div className="ball2"></div>
                                        <div className="ball3"></div>
                                        <div className="ball4"></div>
                                    </div>
                                    <div className="yesno">
                                        <button
                                            className="confirmation-okay"
                                            onClick={handleLogout}
                                        >
                                            Yes, log me out
                                        </button>
                                        <button
                                            className="confirmation-no"
                                            onClick={() =>
                                                setShowLogoutConfirmation(false)
                                            }
                                        >
                                            Stay logged in
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {showConfirmation && (
                        <div
                            className={`confirmation-message ${
                                animateConfirmation ? "show" : ""
                            }`}
                        >
                            <div className="resetconfirm">
                                <div className="divaccent"></div>
                                <br></br>
                                {selectedPayQuote}
                                <br></br>
                                <br></br>
                                <p className="paydayheader2">
                                    Ready to fund your spending categories?
                                </p>
                                <br></br>
                                <p className="paydayheader3">
                                    {" "}
                                    
                                    Progress towards savings goals will be saved.<br></br>
                                    This period's purchase data will be archived.{" "}
                                    <br></br>Your category budgets will be
                                    reset.
                                    {" "}
                                </p>
                                <div className="buttons-container">
                                    <div className="boxoballs2">
                                        <div className="ball1"></div>
                                        <div className="ball2"></div>
                                        <div className="ball3"></div>
                                        <div className="ball4"></div>
                                    </div>
                                    <div className="yesno">
                                        <button
                                            className="confirmation-okay"
                                            onClick={() => {
                                                // handleShowPaydayReview();
                                                handleReset();
                                                handleConfirmation();
                                            }}
                                        >
                                            Archive Purchases & Reset Budgets
                                        </button>
                                        <button
                                            className="confirmation-no"
                                            onClick={() =>
                                                setShowConfirmation(false)
                                            }
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {showLoginForm && (
                        <LoginForm
                        setBackgroundAnimation={setBackgroundAnimation}
                            onDismiss={handleLogin}
                            onLogin={() => {setAuthenticated(true);  setActiveComponent("budgets")}}
                        />
                    )}
                    {authenticated && (
                        <div
                            className="backgroundmotion"
                            style={{ marginBottom: "60px" }}
                        >
                            
                            <div className="portfolioicon"></div>
                            <nav>
                                {pathname === "/archive" ||
                                pathname === "/support" ||
                                pathname === "/review" ||
                                pathname === "/profile" ? null : (
                                    <div className="tabcontainer"></div>
                                )}
                            </nav>
                            <div className="budget-container">
                            
                                <Routes>
                                    <Route
                                        path="/"
                                        element={
                                            <div className="viewport-container">
                                                <div
                                                    className={setShowSplashPage ? "page-container" : "budgetcontainer dontdisplay"}
                                                    ref={containerRef}
                                                >
                                                   
                                                    <div
                                                        style={{
                                                            left:
                                                                (step - 1) *
                                                                    -100 +
                                                                "%",
                                                        }}
                                                        className="budgetpagecontainer"
                                                    >
                                                        <BudgetPage
                                                            id="budgetpage"
                                                            purchases={
                                                                purchases
                                                            }
                                                            remainingBudgets={
                                                                remainingBudgets
                                                            }
                                                            categories={
                                                                categories
                                                            }
                                                            setCategories={
                                                                setCategories
                                                            }
                                                            selectedCategory={
                                                                selectedCategory
                                                            }
                                                            setSelectedCategory={
                                                                setSelectedCategory
                                                            }
                                                            setCurrencySymbol={
                                                                setCurrencySymbol
                                                            }
                                                            currencySymbol={
                                                                currencySymbol
                                                            }
                                                            onRowClick={
                                                                handleRowClick
                                                            }
                                                            setShowUpdateMessage={
                                                                setShowUpdateMessage
                                                            }
                                                            showSplashPage={
                                                                showSplashPage
                                                            }
                                                            setShowSplashPage={
                                                                setShowSplashPage
                                                            }
                                                            showDateInfo={showDateInfo}
                                                            toggleDateInfoModal={toggleDateInfoModal}
                                                            setShowDateInfo={setShowDateInfo}
                                                            setShowInputWidget={setShowInputWidget}
                                                            showInputWidget={showInputWidget}
                                                            containerRef={containerRef}
                                                            triggerRefetch={triggerRefetch}
                                                            setTriggerRefetch={setTriggerRefetch}
                                                            daysLeftInMonth={daysLeftInMonth}
                                                            setShowTutorial={setShowTutorial}
                                                            showBillReminders={showBillReminders}
                                                            showExpenseReminders={showExpenseReminders}
                                                            setShowExpenseReminders={setShowExpenseReminders}
                                                            showIncomeBudgetForm={showIncomeBudgetForm}
                                                            setShowIncomeBudgetForm={setShowIncomeBudgetForm}
                                                            expenses={expenses}
                                                            setExpenses={setExpenses}
                                                            editMode={editMode}
                                                            setEditMode={setEditMode}
                                                            handleHideInputWidget={handleHideInputWidget}
                                                            handleUnhideInputWidget={handleUnhideInputWidget}
                                                            setShowNavTutorial={setShowNavTutorial}
                                                            showNavTutorial={showNavTutorial}
                                                            isLoading={isLoading}
                                                            setIsLoading={setIsLoading}
                                                            handleEditEmojiClick={handleEditEmojiClick}
                                                            handleChangePurchaseIcon={handleChangePurchaseIcon}
                                                            emojiCategories={emojiCategories}
                                                        />
                                                    </div>




                                                    <div
                                                        style={{
                                                            left:
                                                                (step - 3) *
                                                                    -100 +
                                                                "%",
                                                        }}
                                                        className="purchasespagecontainer"
                                                    >
                                                        <div className="willitscroll">
                                                            <PurchasesPage
                                                                id="purchasespage"
                                                                purchases={
                                                                    purchases
                                                                }
                                                                setPurchases={setPurchases}
                                                                remainingBudgets={
                                                                    remainingBudgets
                                                                }
                                                                currencySymbol={
                                                                    currencySymbol
                                                                }
                                                                setShowDeleteMessage={
                                                                    setShowDeleteMessage
                                                                }
                                                                setActiveComponent={setActiveComponent}
                                                                groups={groups}
                                                                setErrorMessage={setErrorMessage} 
                                                                setSuccessMessage={setSuccessMessage}
                                                                setShowInputWidget={setShowInputWidget}
                                                                isLoading={isLoading}
                                                                setIsLoading={setIsLoading}
                                                                selectedQuote={selectedQuote}
                                                                setSelectedQuote={setSelectedQuote}
                                                                showForm={showForm}
                                                                setShowForm={setShowForm}
                                                                selectedPurchase={selectedPurchase}
                                                                setSelectedPurchase={setSelectedPurchase}
                                                                handleEditEmojiClick={handleEditEmojiClick}
                                                                handleChangePurchaseIcon={handleChangePurchaseIcon}
                                                                selectedPurchaseId={selectedPurchaseId}
                                                                setSelectedPurchaseId={setSelectedPurchaseId}
                                                                openModal={openModal}
                                                                closeModal={closeModal}
                                                                setShowModal={setShowModal}
                                                                showModal={showModal}
                                                                emojiCategories={emojiCategories}
                                                                selectedEmoji={selectedEmoji}
                                                                setSelectedEmoji={setSelectedEmoji}
                                                            />
                                                        </div>
                                                    </div>
                                                    
    



      <div
                                                        style={{
                                                            left:
                                                                (step - 5) *
                                                                    -100 +
                                                                "%",
                                                        }}
                                                        className="groupspagecontainer"
                                                    >
                                                        <Groups
                                                        emojiCategories={emojiCategories}
                                                showSplashPage={
                                                    showSplashPage
                                                }
                                                setShowSplashPage={
                                                    setShowSplashPage
                                                }
                                                fromExtension={fromExtension}
                                                currencySymbol={currencySymbol}
                                                setShowGroupDeleteMessage={setShowGroupDeleteMessage}
                                                setShowInputWidget={setShowInputWidget}
                                                handleShowAnimation={handleShowAnimation}
                                            />
                                                         
                                                        

                                                    </div>





                                                    <div
                                                        style={{
                                                            left:
                                                                (step - 7) *
                                                                    -100 +
                                                                "%",
                                                        }}
                                                        className="achievementspagecontainer"
                                                    >
                                                        <div className="willitscroll">
                                                            <Achievements
                                                                id="achievementspage"
                                                                currencySymbol={currencySymbol}
                                                            />
                                                        </div>
                                                    </div>




                                                </div>
                                            </div>
                                        }
                                    />
                                    <Route
                                        path="/archive"
                                        element={
                                            <ArchivePage
                                                purchases={purchases}
                                                remainingBudgets={
                                                    remainingBudgets
                                                }
                                                currencySymbol={currencySymbol}
                                                showSplashPage={
                                                    showSplashPage
                                                }
                                                setShowSplashPage={
                                                    setShowSplashPage
                                                }
                                                fromExtension={fromExtension}
                                                setActiveComponent={setActiveComponent}
                                            />
                                        }
                                    />
                                    <Route
                                        path="/support"
                                        element={
                                            <SupportForm
                                                purchases={purchases}
                                                remainingBudgets={
                                                    remainingBudgets
                                                }
                                                currencySymbol={currencySymbol}
                                                showSplashPage={
                                                    showSplashPage
                                                }
                                                setShowSplashPage={
                                                    setShowSplashPage
                                                }
                                                fromExtension={fromExtension}
                                            />
                                        }
                                    />
                                    <Route
                                        path="/profile"
                                        element={
                                            <UserProfile
                                                handleLogout={handleLogout}
                                                showSplashPage={
                                                    showSplashPage
                                                }
                                                setShowSplashPage={
                                                    setShowSplashPage
                                                }
                                                fromExtension={fromExtension} logout={logout}
                                                setShowLogoutConfirmation={setShowLogoutConfirmation}
                                                showLogoutConfirmation={showLogoutConfirmation}
                                                setShowAliasMessage={setShowAliasMessage}
                                                backgroundAnimation={backgroundAnimation}
                                                setBackgroundAnimation={setBackgroundAnimation}
                                                currencySymbol={currencySymbol}
                                                currencySymbols={currencySymbols}
                                                setCurrencySymbol={setCurrencySymbol}
                                            />
                                        }
                                    />
                                     <Route
                                        path="/about"
                                        element={
                                            <AboutUs
                                                showSplashPage={
                                                    showSplashPage
                                                }
                                                setShowSplashPage={
                                                    setShowSplashPage
                                                }
                                                fromExtension={fromExtension}
                                                currencySymbol={currencySymbol}
                                                setShowInputWidget={setShowInputWidget}
                                                setShowGroupDeleteMessage={setShowGroupDeleteMessage}
                                            />
                                        }
                                    />
                                       <Route
                                        path="/review"
                                        element={
                                           <div className="tindercontainer"><TinderLikeReview    showSplashPage={
                                            showSplashPage
                                        }
                                        setShowSplashPage={
                                            setShowSplashPage
                                        } 
                                        setSuccessMessage={setSuccessMessage}
                                        setErrorMessage={setErrorMessage}
                                        fromExtension={fromExtension}
                                        groups={groups}
                                        
                                        
                                        purchases={purchases} currencySymbol={currencySymbol}/> </div>
                                        }
                                    />
                                </Routes>
                              </div>
                            {!openOptionsMenu && !showTutorial &&(
                                
                               
                                
                                
                                
                                <button
                                    className="optionsbutton"
                                    onClick={handleOpenOptionsMenu}
                                >
                                    <FaEllipsisH />
                                </button>
                            )}
                            {openOptionsMenu && (
                                <div className="optionscomponentcontainer">
                                    <OptionsMenu
                                        handleClickOutside={handleClickOutside}
                                        closeMenu={closeMenu}
                                        openLogoutConfirmation={
                                            openLogoutConfirmation
                                        }
                                        setShowPurchasesPage={
                                            setShowPurchasesPage
                                        }
                                        setActiveTab={setActiveTab}
                                        setActiveComponent={setActiveComponent}
                                        setShowReview={setShowReview}
                                        setShowTutorial={setShowTutorial}
                                        setShowInputWidget={setShowInputWidget}
                                    />
                                </div>
                            )}

{showJackDiv && <div className="topuserdiv"><FcVip style={{fontSize: "1.1rem"}}/>#1 User</div>}
                        </div>
                    )}
                </div>
                {showAnimation && (
                    <div className="transactionanimationcontainer">
                        <div className="circlebackground">
                            <div className="circletext">
                                <FaCheck />
                            </div>
                        </div>
                        <img className="circle1" src={circle1} />
                        <img className="circle2" src={circle2} />
                        <img className="circle3" src={circle3} />
                    </div>
                )}
{showBillReminders && (<div className="paydayreviewcontainer"><ExpenseReminders handleUnhideInputWidget={handleUnhideInputWidget} handleHideInputWidget={handleHideInputWidget} lastPaidExpense={lastPaidExpense} setLastPaidExpense={setLastPaidExpense} hasPayableUpcomingExpenses={hasPayableUpcomingExpenses} setHasPayableUpcomingExpenses={setHasPayableUpcomingExpenses} handleScrollToStart={handleScrollToStart} setActiveComponent={setActiveComponent} setShowInputWidget={setShowInputWidget} setShowIncomeBudgetForm={setShowIncomeBudgetForm} setHasUpcomingExpenses={setHasUpcomingExpenses} currencySymbol={currencySymbol} handleShowExpenseReminders={handleShowExpenseReminders} showBillReminders={showBillReminders} setShowBillReminders={setShowBillReminders} handleCloseBillReminders={handleCloseBillReminders} expenses={expenses} setExpenses={setExpenses} setShowExpenseReminders={setShowExpenseReminders} showExpenseReminders={showExpenseReminders} setEditMode={setEditMode}/></div>)}
                {showPaydayReview && (<div className="paydayreviewcontainer"><PaydayReview currencySymbol={currencySymbol} handleReset={handleReset} handleConfirmation={handleConfirmation} setShowPaydayReview={setShowPaydayReview}/></div>)}
           
            </div>
        </>
    );
}
export default App;
