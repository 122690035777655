import React from "react";
import "./Leftover.css";
import LeftoverWidgetChart from "./LeftoverWidgetChart";

function LeftoverWidget({
    modifiedTotalBudget,
    totalBudget,
    totalSpent,
    currencySymbol,
}) {
    const leftoverAmount = totalBudget - totalSpent;
    const leftoverAmountClassName =
        leftoverAmount < 0 ? "negative" : "positive";
    const leftoverAmountAlert =
        leftoverAmount < 0 ? "divaccent3" : "divaccent2";
    const leftoverAmountText = leftoverAmount < 0 ? "over budget" : "remaining";
    const originalBudget = modifiedTotalBudget || totalBudget;
    const spentPercentage = totalSpent / originalBudget || 0;
    const spentPercentageRounded = Math.round(spentPercentage * 100);
    const colorArray = [
        "rgb(10, 100, 90)",
        "rgb(15, 99, 90)",
        "rgb(20, 98, 80)",
        "rgb(30, 97, 70)",
        "rgb(40, 96, 60)",
        "rgb(50, 95, 50)",
        "rgb(60, 94, 40)",
        "rgb(70, 93, 30)",
        "rgb(80, 92, 20)",
        "rgb(90, 91, 10)",
        "rgb(100, 90, 0)",
        "rgb(110, 90, 0)",
        "rgb(120, 90, 0)",
        "rgb(130, 90, 0)",
        "rgb(140, 90, 0)",
        "rgb(145, 90, 0)",
        "rgb(150, 70, 0)",
        "rgb(100, 45, 0)",
        "rgb(110, 30, 0)",
        "rgb(120, 0, 0)",
    ];
    const spentPercentageColor =
        colorArray[
            Math.floor(spentPercentageRounded / (100 / colorArray.length))
        ];

    const remainingBudget = totalBudget - totalSpent;

    return (
        <div className="widgetcomponent">
            <div className="leftoverstatement">
                <div className="leftoveramountremaining">
                    <span className={leftoverAmountClassName}>
                        {currencySymbol}
                        {Math.trunc(leftoverAmount)}
                    </span>
                </div>
                <div className="leftoveramounttext">{leftoverAmountText}</div>
            </div>
            <div className="remainingbudgetchart dontdisplay">
                <LeftoverWidgetChart
                    totalBudget={totalBudget}
                    remainingBudget={remainingBudget}
                    currencySymbol={currencySymbol}
                    spentPercentageColor={spentPercentageColor}
                />{" "}
            </div>
            <div className="widgetelementcontainer"></div>
        </div>
    );
}

export default LeftoverWidget;
