import React, { useState, useRef, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/database";
import "firebase/compat/storage";
import "firebase/compat/firestore";
import ChartComponent from "./Chart";
import PlotChart from "./PlotChart";
import EmojiModal from "./EmojiModal";
import CategoryChart from "./CategoryChart";
import RadarChart from "./RadarChart";
import { FcConferenceCall  } from "react-icons/fc";
import {FaSpinner} from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { IoIosClose } from "react-icons/io";






const db = firebase.database();

const categories = [];

function PurchasesPage({     selectedEmoji, setSelectedEmoji, emojiCategories, openModal,
  closeModal,
  setShowModal,
  showModal, selectedPurchaseId, setSelectedPurchaseId, handleChangePurchaseIcon, handleEditEmojiClick, isLoading,
  setIsLoading,
  selectedQuote,
  setSelectedQuote,
  showForm,
  setShowForm,
  selectedPurchase,
  setSelectedPurchase, purchases, setPurchases, groups, setShowInputWidget, currencySymbol, setShowDeleteMessage, setActiveComponent, setErrorMessage, setSuccessMessage }) {
    // const [purchases, setPurchases] = useState([]);
    const [budgets, setBudgets] = useState([]);
    const [remainingBudgets, setRemainingBudgets] = useState(categories);
    const [editMode, setEditMode] = useState(false);
    const [changedEmoji, setChangedEmoji] = useState("");
    const [clickCounts, setClickCounts] = useState({});
    const [deletedPurchase, setDeletedPurchase] = useState(null);
    const [loading, setLoading] = useState(true);
 
    const [optionalNoteQuote, setOptionalNoteQuote] = useState(null);
    const [deleteButtonsClicked, setDeleteButtonsClicked] = useState({});
   
    
  
    const [categoryFilter, setCategoryFilter] = useState("");
    const [dateFilter, setDateFilter] = useState("");
    const [selectedGroup, setSelectedGroup] = useState("");
    const [selectedGroupName, setSelectedGroupName] = useState(null);
    const [addedPurchases, setAddedPurchases] = useState({});
    const [showGroupSelectionPopup, setShowGroupSelectionPopup] = useState(false);
    const [selectedChartType, setSelectedChartType] = useState("categories");
    const [showGroupRemovalMessage, setShowGroupRemovalMessage] = useState(false);
    const [showGroupController, setShowGroupController] = useState(false);
    const [showGroupPurchaseAddedMessage, setShowGroupPurchaseAddedMessage] = useState(false);
    const [isAddingFullAmount, setIsAddingFullAmount] = useState(true);
const [modifiedAmount, setModifiedAmount] = useState(null);
const [modifiedPercentage, setModifiedPercentage] = useState(null);
const [wantsToModify, setWantsToModify] = useState(false);
const [showErrorMessage, setShowErrorMessage] = useState(false);
const [lastSync, setLastSync] = useState(null);

    // const [selectedGroupPurchaseId, setSelectedGroupPurchaseId] = useState(null);


    const navigate = useNavigate();


    const newEmojiRef = useRef(null);
    const selectRef = useRef(null);
    const scrollerContainerRef = useRef(null);
    const chartcontainer3Ref = useRef(null);
    const modifyAmountRef = useRef(null);
    const userId = firebase.auth().currentUser.uid;
    const userEmail = firebase.auth().currentUser.email;
    const userPurchasesRef = firebase.database().ref(`users/${userId}/purchases`);

    const onSubmit = (e) => {
      e.preventDefault();
    
      if (selectedPurchase) {
        handleAddToGroup(
          selectedPurchase.id,
          selectedPurchase.category,
          selectedPurchase.icon,
          selectedPurchase.note,
          modifiedAmount / 100,
          selectedPurchase.date,
          selectedPurchase.worthit
        );
      }
      const resetConfirmBottom = document.querySelector('.resetconfirmbottom');
      resetConfirmBottom.classList.add('animateaway');
    
      // setWantsToModify(false);
      // setIsAddingFullAmount(true);
      setTimeout(() => {
        setSelectedPurchase(null);
        setShowInputWidget(true);
        setShowGroupController(false);
      }, 200);// The user has finished modifying, so set it back to null or an appropriate initial value
    };
    


    // useEffect(() => {
    //   const userRef = firebase.database().ref(`users/${userId}`);
    //   userRef.on('value', (snapshot) => {
    //     const userData = snapshot.val();
    //     if (userData.lastSync) {
    //       // Convert timestamp to date and then to local string
    //       const lastSyncDate = new Date(userData.lastSync);
    //       setLastSync(lastSyncDate.toLocaleString());
    //     }
    //   });
    //   // Remember to clean up the listener
    //   return () => {
    //     userRef.off();
    //   };
    // }, [userId]);




    const handleAddToGroup = async (
      purchaseId,
      category,
      icon,
      note = 'No note added',
      amount,
      date,
      worthit
    ) => {
      if (selectedGroup) {
        const groupRef = firebase.database().ref(`groups/${selectedGroup}/purchases`);
        const purchaseRef = userPurchasesRef.child(purchaseId);
        const userRef = firebase.database().ref(`users/${userId}`);
        const userSnapshot = await userRef.once('value');
        const userAlias = userSnapshot.child('alias').val();
        const userProPic = userSnapshot.child('profilePicIndex').val();
    
        const purchase = {
          id: purchaseId,
          category: category,
          icon: icon,
          note: note === '📝 Add a purchase note' ? 'No note added' : note,
          amount: amount,
          originalAmount: selectedPurchase ? selectedPurchase.amount : 0, // Store the original amount
          addedBy: userEmail,
          alias: userAlias,
          date: date,
          group: selectedGroupName,
          groupId: selectedGroup,
          creature: userProPic,
        };
    
        // Only include worthit property if it exists
        if (worthit !== undefined) {
          purchase.worthit = worthit;
        }
    
        if (wantsToModify === true && amount.toFixed(2) !== (selectedPurchase ? selectedPurchase.amount.toFixed(2) : 0)) {
          // console.log('Amounts not equal. Amount:', amount.toFixed(2), 'Selected purchase amount:', selectedPurchase ? selectedPurchase.amount.toFixed(2) : 0);
          purchase.wasModified = true;
        }
        
    
        try {
          setIsLoading(true); // show loading indicator
    
          // Check if purchase with the same id already exists in the group
          const snapshot = await groupRef.once('value');
          const purchases = snapshot.val();
          const doesPurchaseExist = purchases && Object.values(purchases).some((p) => p.id === purchaseId);
    
          if (doesPurchaseExist) {
            // console.log('Purchase already exists in the group. Removing...');
            await handleRemoveFromGroup(purchaseId);
            return; // Return after removing the purchase to prevent further execution
          } else {
            // console.log('Adding purchase to the group...');
          
            const isAmountModified = wantsToModify ===  true && amount.toFixed(2) !== (selectedPurchase ? selectedPurchase.amount.toFixed(2) : 0);

            // console.log('Amount Modified:', isAmountModified);

            purchase.wasModified = isAmountModified;
          
            await groupRef.push(purchase);
            await purchaseRef.update({
              group: selectedGroupName,
              groupId: selectedGroup,
              groupAmount: amount, 
              groupWasModified: isAmountModified,
            });
          
            // check the update
            const updatedSnapshot = await groupRef.once('value');
            const updatedPurchases = updatedSnapshot.val();
            if (updatedPurchases && Object.values(updatedPurchases).find((p) => p.id === purchaseId)) {
              setAddedPurchases((prevState) => ({ ...prevState, [purchase.id]: true }));
              setIsLoading(false); // hide loading indicator
              setSuccessMessage('Purchase added to group successfully');
              setTimeout(() => {
                const messageElement = document.querySelector('.confirm-message');
                if (messageElement) {
                  messageElement.classList.add('slideoutmessage');
                }
                setTimeout(() => {
                  setSuccessMessage(null);
                }, 1000);
              }, 3000); // show success message
            } else {
              throw new Error('Failed to add purchase to group');
            }
          }
        } catch (error) {
          setIsLoading(false); // hide loading indicator
          setErrorMessage('Error processing purchase: ' + error.message);
          setTimeout(() => {
            const messageElement = document.querySelector('.groupdeletemessage');
            if (messageElement) {
              messageElement.classList.add('slideoutmessage');
            }
            setTimeout(() => {
              setErrorMessage(false);
            }, 1000);
          }, 3000);
          console.error('Error processing purchase:', error);
        }
      }
    };
    
    
      
      
      const handleRemoveFromGroup = async (purchaseId) => {
        if (selectedGroup) {
          const groupRef = firebase.database().ref(`groups/${selectedGroup}/purchases`);
          const purchaseRef = userPurchasesRef.child(purchaseId);
      
          try {
            setIsLoading(true); // show loading indicator
      
            const snapshot = await groupRef.once('value');
            const purchases = snapshot.val();
      
            if (purchases) {
              const keysToRemove = Object.keys(purchases).filter(key => purchases[key].id === purchaseId);
      
              // Loop over each key to remove all duplicates
              for (const key of keysToRemove) {
                await groupRef.child(key).remove();
              }
              await purchaseRef.update({ group: null, groupId: null, groupAmount: null, 
                groupWasModified: null,  });
      
              // check the update
              const updatedSnapshot = await groupRef.once('value');
              const updatedPurchases = updatedSnapshot.val();
              if (!updatedPurchases || !Object.values(updatedPurchases).find(p => p.id === purchaseId)) {
                setIsLoading(false); // hide loading indicator
                setAddedPurchases(prevState => ({ ...prevState, [purchases.id]: false }));
                setSuccessMessage('Purchase removed from group successfully');
                setTimeout(() => {
                  const messageElement = document.querySelector('.confirm-message');
                  if (messageElement) {
                    messageElement.classList.add('slideoutmessage');
                  }
                  setTimeout(() => {
                    setSuccessMessage(null);
                  }, 1000);
                }, 3000);
      
                // show success message
              } else {
                throw new Error('Failed to remove purchase from group');
              }
            }
          } catch (error) {
            setIsLoading(false); // hide loading indicator
            setErrorMessage('Error processing purchase: ' + error.message);
            setTimeout(() => {
              const messageElement = document.querySelector('.groupdeletemessage'); // Replace 'your-message-class' with the actual class of the element you want to add the class to
              if (messageElement) {
                messageElement.classList.add('slideoutmessage');
              }
              setTimeout(() => {
                setErrorMessage(false);
              }, 1000); // Set the duration in milliseconds (4 seconds = 4000 milliseconds)
            }, 3000);// show error message
            console.error('Error processing purchase:', error);
          }
        }
      };
      

      useEffect(() => {
        if (selectedPurchase) {
          setModifiedAmount(selectedPurchase.amount * 100);
          setModifiedPercentage(100);
        }
      }, [selectedPurchase]);

      const handleGroupSelect = (groupId) => {
        const groupRef = firebase.database().ref(`groups/${groupId}`);
      
        groupRef.once('value', snapshot => {
          const groupData = snapshot.val() || {};
          const groupName = groupData.name;
      
          if (groupName) {
            setSelectedGroup(groupId);
            setSelectedGroupName(groupName);
          } else {
            console.error('Group name not found.');
          }
        });
      };

      const handleCloseGroupController = () => {
        const resetConfirmBottom = document.querySelector('.resetconfirmbottom');
        resetConfirmBottom.classList.add('animateaway');
        
        setTimeout(() => {
          setShowGroupController(false);
          setShowInputWidget(true);
          setShowErrorMessage(false);
        }, 200);
      };
     


      
      const handleAddModifiedPurchaseClick = (
        id,
        category,
        icon,
        note,
        amount,
        date,
        worthit
      ) => {
        const modifiedPurchase = {
          id,
          category,
          icon,
          note,
          amount,
          date,
          worthit,
        };
      
        setSelectedPurchase(modifiedPurchase);
        setIsLoading(true);
        setShowGroupController(true);
        setShowInputWidget(false);
        setIsLoading(false);
      };


  

  const handleSaveChanges = (event) => {
    setIsLoading(true);
    event.preventDefault();
    let newEmoji = event.target.elements.emoji.value;
    const userId = firebase.auth().currentUser.uid;
    const purchasesRef = db.ref(`users/${userId}/purchases`);

    if (newEmoji === "") {
        // Replace the current note with an empty string
        newEmoji = "No note added";
      }
  
      purchasesRef
      .child(selectedPurchase.id)
      .update({ note: newEmoji })
      .then(async () => {
          setSelectedPurchase(null);
          setShowForm(false);
          setShowInputWidget(true);
          await handleUpdateGroupPurchase(selectedPurchase.id, newEmoji);
          setIsLoading(false);
      })
      .catch((error) => {
          console.error(error);
          setIsLoading(false);
      });
  };
  
  const handleChangeEmoji = (purchaseId, buttonId, purchase) => {
    setIsLoading(true);
    const userId = firebase.auth().currentUser.uid;

  
    let selectedEmoji = purchase.note;
  

      handleEditEmojiClick(purchase);

  
    setIsLoading(false);
  
    return selectedEmoji;
  };

      

  
  
  


    const handleClickOutside = (event) => {
      
      if (
        scrollerContainerRef.current &&
        !scrollerContainerRef.current.contains(event.target) &&
        chartcontainer3Ref.current &&
        !chartcontainer3Ref.current.contains(event.target) &&
        modifyAmountRef.current &&
        !modifyAmountRef.current.contains(event.target)
      ) {
        
        setWantsToModify(false);
        setIsAddingFullAmount(true);
        setShowGroupController(false);
        setShowErrorMessage(false);

        setFirstOptionAsSelected();
      }
    };

    const handleCloseGroupEditor = (event) => {
  
        setWantsToModify(false);
        setIsAddingFullAmount(true);
        setShowGroupController(false);
        setShowErrorMessage(false);
        setFirstOptionAsSelected();
        setSelectedGroup("");
      
    };
    
    useEffect(() => {
      const handleMouseDown = (event) => {
        handleClickOutside(event);
      };
    
      document.addEventListener("mousedown", handleMouseDown);
    
      return () => {
        document.removeEventListener("mousedown", handleMouseDown);
      };
    }, []);
  


    // const handleChangePurchaseIcon = (purchaseId, buttonId) => {
    //     setIsLoading(true);
    //     const userId = firebase.auth().currentUser.uid;
    //     const purchasesRef = db.ref(`users/${userId}/purchases`);
      
    //     setSelectedPurchaseId(purchaseId);
    //     openModal();
      
    //     // Update the purchase within the group
    //     // handleUpdateGroupPurchase(purchaseId, { icon: selectedEmoji });
    //     setIsLoading(false);
    //   };

      useEffect(() => {
        if (newEmojiRef.current) {
          newEmojiRef.current.focus();
        }
      }, [showForm]);

      const handleUpdateGroupPurchase = async (purchaseId, newNote) => {
        const userId = firebase.auth().currentUser.uid;
        const groupsRef = db.ref('groups');
        const snapshot = await groupsRef.once('value');
        const allGroups = snapshot.val();
      
        const updatePromises = [];  // We'll keep track of all update operations
    
        // For each group, check if the user is a member and if so, update the purchases
        for (let groupId in allGroups) {
            const group = allGroups[groupId];
            const members = group.members || {};
            const groupPurchases = group.purchases || {};
    
            if (members[userId]) {  // If the user is a member of this group
                // Iterate over the purchases of the group
                for (let groupPurchaseId in groupPurchases) {
                    const groupPurchase = groupPurchases[groupPurchaseId];
                    if (groupPurchase.id === purchaseId) {  // If the group's purchase ID matches with the input purchaseId
                        // Update the note for the purchase in this group
                        const updatePromise = db.ref(`groups/${groupId}/purchases/${groupPurchaseId}`).update({ note: newNote });
                        updatePromises.push(updatePromise);  // Push the promise into our array
                    }
                }
            }
        }
      
        // Wait for all update operations to complete
        await Promise.all(updatePromises);
    };

    const handleDropdownChange = (e) => {
        const selectedGroupId = e.target.value;
        const groupRef = firebase.database().ref(`groups/${selectedGroupId}`);
        groupRef.once('value', (snapshot) => {
          const groupData = snapshot.val() || {};
          const groupName = groupData.name;
          setSelectedGroupName(groupName);
         
          setSelectedGroup(selectedGroupId);
       
        });
      };



      const handleSyncToGroup = async () => {
  
        if (groups.length === 0) {
          return; // Wait until the groups array is populated
        }
      
        try {
          setIsLoading(true); // Set isLoading to true while processing
      
          const userPurchasesRef = firebase.database().ref(`users/${userId}/purchases`);
          const userPurchasesSnapshot = await userPurchasesRef.once('value');
          const userPurchases = userPurchasesSnapshot.val();
      
          for (const purchaseKey in userPurchases) {
            const purchase = userPurchases[purchaseKey];
      
            if (purchase.groupId) {
              const matchingGroup = groups.find(group => group.id === purchase.groupId);
        
              if (!matchingGroup) {
                await removeGroupProperties(purchaseKey);
              } else {
                const groupPurchasesRef = firebase.database().ref(`groups/${matchingGroup.id}/purchases`);
                const groupPurchasesSnapshot = await groupPurchasesRef.once('value');
                const groupPurchases = groupPurchasesSnapshot.val();
      
                if (!groupPurchases) {
                  await removeGroupProperties(purchaseKey);
                } else {
                  const hasMatchingGroupPurchase = Object.values(groupPurchases).some(groupPurchase => groupPurchase.id === purchaseKey);
        
                  if (!hasMatchingGroupPurchase) {
                    await removeGroupProperties(purchaseKey);
                  }
                }
              }
            }
          }
      
          const userRef = firebase.database().ref(`users/${userId}`);
          await userRef.update({ lastSync: firebase.database.ServerValue.TIMESTAMP });
          userRef.off();
          setIsLoading(false); // Set isLoading to false after processing
        } catch (error) {
          setIsLoading(false); // Set isLoading to false in case of error
          console.error('Error deleting or updating purchases:', error);
        }
      };
      
      const removeGroupProperties = async (purchaseKey) => {
        await userPurchasesRef.child(`${purchaseKey}/group`).remove();
        await userPurchasesRef.child(`${purchaseKey}/groupId`).remove();
        await userPurchasesRef.child(`${purchaseKey}/groupAmount`).remove();
        await userPurchasesRef.child(`${purchaseKey}/groupWasModified`).remove();
      }

      useEffect(() => {
        const userRef = firebase.database().ref(`users/${userId}/lastSync`);
      
        const handleValueChange = (snapshot) => {
          const lastSyncTimestamp = snapshot.val();
          if (lastSyncTimestamp) {
            const lastSyncDate = new Date(lastSyncTimestamp);
            setLastSync(lastSyncDate.toLocaleString());
          }
        };
      
        // Attach the listener
        userRef.on('value', handleValueChange);
      
        // Clean up the listener when the component is unmounted or the userId changes
        return () => {
          userRef.off('value', handleValueChange);
        };
      }, [userId]);


      // useEffect(() => {
        
      //   if (groups.length === 0) {
      //     return; // Wait until the groups array is populated
      //   }
      
      //   const removeGroupProperties = async () => {
      //     try {
      //       setIsLoading(true); // Set isLoading to true while processing
      
      //       const userPurchasesRef = firebase.database().ref(`users/${userId}/purchases`);
      //       const userPurchasesSnapshot = await userPurchasesRef.once('value');
      //       const userPurchases = userPurchasesSnapshot.val();
      
      //       for (const purchaseKey in userPurchases) {
      //         const purchase = userPurchases[purchaseKey];
      
      //         if (purchase.groupId) {
      //           console.log('Checking purchase:', purchaseKey);
      
      //           const matchingGroup = groups.find(group => group.id === purchase.groupId);
      //           console.log('Matching group:', matchingGroup);
      
      //           if (!matchingGroup) {
      //             console.log('No matching group found. Removing group properties from purchase:', purchaseKey);
      
      //             // Remove properties if the matching group does not exist
      //             await userPurchasesRef.child(`${purchaseKey}/group`).remove();
      //             await userPurchasesRef.child(`${purchaseKey}/groupId`).remove();
      //             await userPurchasesRef.child(`${purchaseKey}/groupAmount`).remove();
      //             await userPurchasesRef.child(`${purchaseKey}/groupWasModified`).remove();
      //           } else {
      //             const groupPurchasesRef = firebase.database().ref(`groups/${matchingGroup.id}/purchases`);
      //             const groupPurchasesSnapshot = await groupPurchasesRef.once('value');
      //             const groupPurchases = groupPurchasesSnapshot.val();
      //             console.log('Group purchases:', groupPurchases);
      //             if (!groupPurchases) {
      //               console.log('No group purchases found. Removing group properties from purchase:', purchaseKey);
                  
      //               // Remove properties since there are no group purchases
      //               await userPurchasesRef.child(`${purchaseKey}/group`).remove();
      //               await userPurchasesRef.child(`${purchaseKey}/groupId`).remove();
      //               await userPurchasesRef.child(`${purchaseKey}/groupAmount`).remove();
      //               await userPurchasesRef.child(`${purchaseKey}/groupWasModified`).remove();
      //             } else {
      
      //             const hasMatchingGroupPurchase = Object.values(groupPurchases).some(groupPurchase => groupPurchase.groupId === purchase.groupId);

      
      //             if (!hasMatchingGroupPurchase) {
      //               console.log('No matching purchase found in the group. Removing group properties from purchase:', purchaseKey);
      
      //               // Remove properties if no matching purchase is found in the group
      //               await userPurchasesRef.child(`${purchaseKey}/group`).remove();
      //               await userPurchasesRef.child(`${purchaseKey}/groupId`).remove();
      //               await userPurchasesRef.child(`${purchaseKey}/groupAmount`).remove();
      //               await userPurchasesRef.child(`${purchaseKey}/groupWasModified`).remove();
      //             }}
      //           }
      //         }
      //       }
      
      //       setIsLoading(false); // Set isLoading to false after processing
      //     } catch (error) {
      //       setIsLoading(false); // Set isLoading to false in case of error
      //       console.error('Error deleting or updating purchases:', error);
      //     }
      //   };
      
      //   removeGroupProperties(); // Call the removeGroupProperties function
      
      //   // eslint-disable-next-line react-hooks/exhaustive-deps
      // }, [groups]);
      
      
      

    //AN ARRAY OF PASSIVE QUOTES THAT ARE MEANT TO BE SHOWN RANDOMLY WHEN THE COMPONENT LOADS___________________
    const quotes = [
        "The best way to save money is to not have any. That way, you won't be tempted to spend it.",

        "A wise investor knows that to grow your fortune you must water your stocks and wait for them to bloom.",

        "Why did the economy cross the road? To get to the other side, where it can continue to ruin your financial future.",

        "The secret to unimaginable wealth is not in working harder, but in mastering the art of extreme couponing.",

        "Financial planning is like playing chess, except the pieces are all made of money, and every move you make is a bad one.",

        "Saving money is like planting a money tree. But instead of a tree, it's just a small plant that never grows, and instead of money, it's just disappointment.",

        "The only way to truly save money is to never earn it in the first place.",

        "The more you save, the less you'll have to regret when you inevitably die alone with all your money.",

        "Financial security is an illusion, a mirage in the desert of capitalism that disappears the closer you get to it.",
    ];

    //HOOK TO CHOOSE AND DISPLAY A RANDOM QUOTE FROM THE LIST___________________________________________________
    useEffect(() => {
        const randomIndex = Math.floor(Math.random() * 10);
        setSelectedQuote(quotes[randomIndex]);
    }, []);

    //ALLOW THE USER TO DELETE A PURCHASE FROM THE DATABASE_______________________________________________
    const handleDeletePurchase = (
        purchaseId,
        purchaseCategory,
        purchaseAmount
      ) => {
        setIsLoading(true);
        const userId = firebase.auth().currentUser.uid;
        const purchasesRef = db.ref(`users/${userId}/purchases`);
        const purchaseRef = purchasesRef.child(purchaseId);
      
        purchaseRef.remove();
      
        // update the remaining budget for the deleted purchase category
        const newRemainingBudgets = {
          ...remainingBudgets,
          [purchaseCategory]: remainingBudgets[purchaseCategory] + purchaseAmount,
        };
        setRemainingBudgets(newRemainingBudgets);
      
        // update the purchases list with the deleted purchase
        const deletedPurchase = purchases.find((p) => p.id === purchaseId);
        setDeletedPurchase(deletedPurchase);
        setDeleteButtonsClicked({
          ...deleteButtonsClicked,
          [purchaseId]: false,
        });
        // set the status of the deleted purchase to "pending"
        setDeletedPurchase({ ...deletedPurchase, status: "pending" });
        // show delete message for 3 seconds
        setShowDeleteMessage(true);
      
        // Delete the purchase from the groups where the user is a member
        const groupsRef = db.ref('groups');
        groupsRef.on('value', (snapshot) => {
          const allGroups = snapshot.val();
          for (let groupId in allGroups) {
            const group = allGroups[groupId];
            const members = group.members || {};
            const groupPurchases = group.purchases || {};
      
            if (members[userId]) {  // If the user is a member of this group
              // Iterate over the purchases of the group
              for (let groupPurchaseId in groupPurchases) {
                const groupPurchase = groupPurchases[groupPurchaseId];
                if (groupPurchase.id === purchaseId) {  // If the group's purchase ID matches with the input purchaseId
                  // Remove the purchase from this group
                  db.ref(`groups/${groupId}/purchases/${groupPurchaseId}`).remove();
                }
              }
            }
          }
          setIsLoading(false);
        });
      
        setTimeout(() => {
          const messageElement = document.querySelector('.deletemessage'); // Replace 'your-message-class' with the actual class of the element you want to add the class to
          if (messageElement) {
            messageElement.classList.add('slideoutmessage');
          }
          setTimeout(() => {
            setShowDeleteMessage(false);
          }, 1000); // Set the duration in milliseconds (4 seconds = 4000 milliseconds)
        }, 3000);
      };

    //HOOK TO LISTEN FOR ANY CHANGES IN THE 'PURCHASE' DATA (such as new and deleted purchases)____________
    useEffect(() => {
        // console.log("useEffect for purchases is being called!");
        const userId = firebase.auth().currentUser.uid;
        const purchasesRef = db
            .ref(`users/${userId}/purchases`)
            .orderByChild("date");

        // set loading to true when effect is called
        setLoading(true);

        purchasesRef.on("value", (snapshot) => {
            const data = snapshot.val() || {};
            const purchasesArray = Object.keys(data)
                .map((key) => ({
                    ...data[key],
                    id: key,
                }))
                .sort((a, b) => new Date(b.date) - new Date(a.date));
            setPurchases(purchasesArray);

            // set loading to false when data is loaded
            setLoading(false);
        });
    }, []);

    //ALLOW THE USER TO SELECT DIFFERENT TYPES OF CHARTS TO VIEW
  

    const handleChartTypeClick = (chartType) => {
        setSelectedChartType(chartType);
    };

    let chartComponent;
    if (selectedChartType === "categories") {
        chartComponent = (
            <CategoryChart
                currencySymbol={currencySymbol}
                purchases={purchases}
                remainingBudgets={remainingBudgets}
                budgets={budgets}
                userId={firebase.auth().currentUser.uid}
            />
        );
    } else if (selectedChartType === "total") {
        chartComponent = (
            <ChartComponent
                purchases={purchases}
                budgets={budgets}
                userId={firebase.auth().currentUser.uid}
                currencySymbol={currencySymbol}
            />
        );
    } else if (selectedChartType === "plot") {
        chartComponent = (
            <PlotChart
                purchases={purchases}
                budgets={budgets}
                userId={firebase.auth().currentUser.uid}
                currencySymbol={currencySymbol}
            />
        );
    } else if (selectedChartType === "radar") {
        chartComponent = (
            <RadarChart
                purchases={purchases}
                budgets={budgets}
                userId={firebase.auth().currentUser.uid}
                currencySymbol={currencySymbol}
            />
        );
    }

    const setFirstOptionAsSelected = () => {
        setSelectedGroup('');
        if (selectRef.current) {
          selectRef.current.selectedIndex = 0;
        }
      };

      // useEffect(() => {
      //   console.log('useEffect - Checking deleted purchases and updating groups');
        
      //   const groupNames = groups.map((group) => group.name);
      //   console.log('Group Names:', groupNames);
      //   const userId = firebase.auth().currentUser.uid;
      //   const deletedPurchases = purchases.filter((purchase) => purchase.group && !groupNames.includes(purchase.group));
      //   console.log('Deleted Purchases:', deletedPurchases);
      
      //   const updatePromises = deletedPurchases.map((deletedPurchase) => {
      //     console.log('Updating purchase:', deletedPurchase.id);
      //     const purchaseRef = firebase.database().ref(`users/${userId}/purchases/${deletedPurchase.id}`);
    
      //     return purchaseRef.child('group').remove();
      //   });
      
      //   Promise.all(updatePromises)
      //     .then(() => {
      //       console.log('Purchases updated successfully.');
      //     })
      //     .catch((error) => {
      //       console.error('Error updating purchases:', error);
      //     });
      // }, [purchases, groups]);

    //JSX__________________________________________________________________________________________________

    return (
        <div className="purchases-container">


   
    {isLoading && <div className="full-loader"><FaSpinner className="spinner" /></div>}


    

            <div className="chartntable">
                {showModal && (
                    <div className="emojimodalcontainer">
                        <div className="confirmation-message">
                            <EmojiModal
                                showModal={showModal}
                                emojiCategories={emojiCategories}
                                selectedEmoji={selectedEmoji}
                                setSelectedEmoji={setSelectedEmoji}
                                purchaseId={selectedPurchaseId}
                                closeModal={closeModal}
                                selectedGroup={selectedGroup}
                            />
                        </div>
                    </div>
                )}
                <p className="purchasescontainerheader">
  Transaction History
  {/* {purchases.every((purchase) => purchase.worthit !== null && purchase.worthit !== undefined) ? null : (
    <div className="newunreviewedpurchasedot"></div>
  )} */}


  {/* <button
    className="reviewpurchasesminibutton"
    onClick={() => {
    setActiveComponent("budgets");
      navigate('/review');
    }}
  >
    Review Purchases
  </button>*/}
    <button
    className="reviewpurchasesminibutton"
    onClick={() => {
      handleSyncToGroup();
    }}
  >
    {lastSync ? `Last sync: ${lastSync}` : ''}
  </button>
</p> 



{selectedGroup !== "" ? (
  <div ref={chartcontainer3Ref} className="chartcontainer3">
  <div className="group-purchase-info-header">{`Editing Group Purchases for ${selectedGroupName}`}</div>
  <div className="group-purchase-info-container">
    
    <div className="group-purchase-info-container-2">
    
  <div className="group-purchase-controller-label">
       Modify purchases before adding       
    </div>

    {/* {!wantsToModify && <div>Editing purchases for {selectedGroupName}</div>}
    {!selectedPurchase && wantsToModify && <div>Select a purchase to add</div>} */}
    <label className="group-purchase-controller-toggle-switch">
      <input
        className="exception"
        type="checkbox"
        checked={!isAddingFullAmount} // Update the checked attribute
        onChange={() => {
          setShowGroupController(false);
          setIsAddingFullAmount(!isAddingFullAmount);
          setWantsToModify(!wantsToModify);
          if (!isAddingFullAmount) {
            setSelectedPurchase(null);
          }
        }}
      />
      <span className="group-purchase-controller-slider"></span>
      
    </label>
   
  </div></div> 
  <button
    className="groups-sync-purchases"
    onClick={() => {
    handleSyncToGroup();
    }}
  >
    <p className="groups-sync-purchases-label">Sync Purchases</p> {lastSync ? `Last sync: ${lastSync}` : ''}
  </button>
  <button onClick={handleCloseGroupEditor} className="group-purchase-done">Done editing group purchases</button>
</div>
) : (
  <div className="chartcontainer2">{chartComponent}</div>
)}
                {selectedGroup === "" && <div className="charttypetoggle">
                    <button
                        className={`chartbycategory ${
                            selectedChartType === "categories" ? "active" : ""
                        }`}
                        onClick={() => handleChartTypeClick("categories")}
                    >
                        Categories
                    </button>
                    <button
                        className={`chartbycategory ${
                            selectedChartType === "total" ? "active" : ""
                        }`}
                        onClick={() => handleChartTypeClick("total")}
                    >
                        Total
                    </button>
                    <button
                        className={`chartbycategory ${
                            selectedChartType === "plot" ? "active" : ""
                        }`}
                        onClick={() => handleChartTypeClick("plot")}
                    >
                        Plot
                    </button>
                    <button
                        className={`chartbycategory ${
                            selectedChartType === "radar" ? "active" : ""
                        }`}
                        onClick={() => handleChartTypeClick("radar")}
                    >
                        Radar
                    </button>
                </div>}
                <div ref={scrollerContainerRef} className="scrollercontainer">
                    <div className="purchase-list">
                    <select
  className={selectedGroup === "" ? "groupselection" : "groupselection2"}
  value={selectedGroup}
  onChange={handleDropdownChange}
  ref={selectRef}
>
  {groups.length === 0 ? (
    <option className="groupselectionoption" value="">
      My Purchases
    </option>
  ) : (
    <>
      <option disabled hidden value="">
        Add or remove a group purchase
      </option>
      {selectedGroup !== "" && (
        <option value="">Done editing group purchases</option>
      )}
    </>
  )}
  {groups.map((group) => (
    <option key={group.id} value={group.id}>
      {selectedGroup === group.id ? `Editing group purchases for ${group.name}` : group.name}
    </option>
  ))}
</select>
                        <table className="scrollabletable">
                       
                            {/* {!editMode && (<td className="purchasefilters" colSpan="4">
                                <input
                                    className="purchaselistfilter"
                                    placeholder="Start typing to search your purchases..."
                                    type="text"
                                    id="categoryFilter"
                                    value={categoryFilter}
                                    onChange={(e) =>
                                        setCategoryFilter(e.target.value)
                                    }
                                />
                            </td>)} */}
                            <thead>
                                {!editMode && (
                                    <tr
                                        style={{
                                            backgroundColor: "#f1f1f1f3",
                                            fontSize: "0.8rem",
                                        }}
                                    ></tr>
                                )}
                                {editMode && (
                                    <tr
                                        style={{
                                            backgroundColor: "#ffbd2b",
                                            fontSize: "0.8rem",
                                            animation:
                                                "fade-in 0.5s linear forwards",
                                        }}
                                    ></tr>
                                )}
                            </thead>

                            <tbody className="purchaselistbody">
                            {purchases
    .filter((purchase) =>
      (
        (!purchase.groupId || purchase.groupId === selectedGroup) ||
        (purchase.groupId && !selectedGroup)
      ) &&
      (
        categoryFilter === "" ||
        purchase.category.toLowerCase().includes(categoryFilter.toLowerCase()) ||
        purchase.note.toLowerCase().includes(categoryFilter.toLowerCase())
      ) &&
      (
        dateFilter === "" ||
        new Date(purchase.date).toLocaleDateString() === dateFilter
      )
    )
    .reduce((acc, purchase, index, filteredPurchases) => {
      const purchaseDate = new Date(purchase.date).toLocaleDateString();
      const prevPurchase = filteredPurchases[index - 1];
      const prevPurchaseDate = prevPurchase && new Date(prevPurchase.date).toLocaleDateString();

                                            if (
                                                purchaseDate !==
                                                prevPurchaseDate
                                            ) {
                                                const totalForDate = filteredPurchases.reduce(
                                                    (
                                                        total,
                                                        currentPurchase
                                                    ) => {
                                                        if (
                                                            new Date(
                                                                currentPurchase.date
                                                            ).toLocaleDateString() ===
                                                            purchaseDate
                                                        ) {
                                                            return (
                                                                total +
                                                                parseFloat(
                                                                    currentPurchase.amount
                                                                )
                                                            );
                                                        }
                                                        return total;
                                                    },
                                                    0
                                                );

                                                acc.push(
                                                    <tr
                                                        key={purchaseDate}
                                                        style={{
                                                            backgroundColor:
                                                                "#f1f1f1f3",
                                                            fontSize: "0.8rem",
                                                        }}
                                                    >
                                                        {!editMode && (
                                                            <th
                                                                className="purchasedates"
                                                                colSpan="3"
                                                            >
                                                                <div className="purchasedatedate">
                                                                    {new Date(
                                                                        purchaseDate
                                                                    ).toLocaleDateString(
                                                                        "en-US",
                                                                        {
                                                                            weekday:
                                                                                "short",
                                                                            month:
                                                                                "short",
                                                                            day:
                                                                                "numeric",
                                                                            year:
                                                                                "numeric",
                                                                        }
                                                                    )}
                                                                </div>
                                                                <div className="purchasedateamount">{`${currencySymbol}${totalForDate.toFixed(
                                                                    2
                                                                )}`}</div>
                                                            </th>
                                                        )}
                                                        {editMode && (
                                                            <th
                                                                className="purchasedates"
                                                                colSpan="4"
                                                            >
                                                                <div className="purchasedatedate">
                                                                    {new Date(
                                                                        purchaseDate
                                                                    ).toLocaleDateString(
                                                                        "en-US",
                                                                        {
                                                                            weekday:
                                                                                "short",
                                                                            month:
                                                                                "short",
                                                                            day:
                                                                                "numeric",
                                                                            year:
                                                                                "numeric",
                                                                        }
                                                                    )}
                                                                </div>
                                                                <div className="purchasedateamount">{`${currencySymbol}${totalForDate.toFixed(
                                                                    2
                                                                )}`}</div>
                                                            </th>
                                                        )}
                                                    </tr>
                                                );
                                            }

                                            acc.push(
                                                <tr
                                                    key={purchase.id}
                                                    className={
                                                        deletedPurchase &&
                                                        deletedPurchase.id ===
                                                            purchase.id
                                                            ? "deleted"
                                                            : ""
                                                    }
                                                >
                                                    <td
                                                        style={{
                                                            width: "10%",
                                                            padding: "5px",
                                                            textAlign: "center",
                                                            fontSize: "1.3rem",
                                                        }}
                                                    >
                                                        <button
                                                            className="iconbutton"
                                                            onClick={() =>
                                                                handleChangePurchaseIcon(
                                                                    purchase.id,
                                                                    purchase.id +
                                                                        "-button"
                                                                )
                                                            }
                                                        >
                                                            {purchase.icon}
                                                            <div className="worthiticon">
  {purchase.worthit !== undefined ? (purchase.worthit ? '👍' : '👎') : null}
  {purchase.icon === "💸" && (purchase.note === "📝 Add a purchase note" || purchase.note === "No note added") ? <div className="unmodifiedpurchasedot"></div> : null}
</div>
                                                        </button>
                                                        
                                                    </td>
                                                    <td
                                                        style={{
                                                            paddingLeft: "0px",
                                                            width: "50%",
                                                        }}
                                                        className="tableheader"
                                                        
                                                    >
                                                        <div className="categoryandnotecontainer">
                                                            <div className="tableheadercc">
                                                                {
                                                                    purchase.category
                                                                }
                                                            </div>
                                                            <button
                                                                className="lilemoji"
                                                                style={{
                                                                    textAlign:
                                                                        "left",
                                                                }}
                                                                onClick={() =>
                                                                    {setIsLoading(true);
                                                                    handleChangeEmoji(
                                                                        purchase.id,
                                                                        purchase.id +
                                                                            "-button",
                                                                        purchase
                                                                    ); setIsLoading(false)}
                                                                }
                                                            >
                                                                {purchase.note}
                                                            </button>
                                                        </div>
                                                    </td>
                                                    <td
                                                        className="tablenumber2"
                                                        style={{
                                                            textAlign: "right",
                                                        width: "40%"
                                                        }}
                                                    >
                                                        <div className="dateandamountcontainer">
                                                        {selectedGroup !== "" && (
 <button
 disabled={isLoading}
 className="purchaseaddgroup2"
 onClick={() => {
   setSelectedPurchase(purchase);

   if (addedPurchases[purchase.id]) {
     handleRemoveFromGroup(purchase.id);
     setAddedPurchases(prevState => ({ ...prevState, [purchase.id]: false }));
   } else if (groups.length === 1) {
     handleGroupSelect(groups[0].id);

     if (!wantsToModify) {
       handleAddToGroup(
         purchase.id,
         purchase.category,
         purchase.icon,
         purchase.note,
         purchase.amount,
         purchase.date,
         purchase.worthit
       );
      } else {handleAddModifiedPurchaseClick( purchase.id,
        purchase.category,
        purchase.icon,
        purchase.note,
        purchase.amount,
        purchase.date,
        purchase.worthit)}

     
   // } else if (groups.length > 1 && !selectedGroup) {
   //  setShowGroupSelectionPopup(true);
   } else {
     // Handle the case when there are multiple groups and selectedGroup is already set
     if (!wantsToModify) {
       handleAddToGroup(
         purchase.id,
         purchase.category,
         purchase.icon,
         purchase.note,
         purchase.amount,
         purchase.date,
         purchase.worthit
       );
       
     }else {handleAddModifiedPurchaseClick( purchase.id,
      purchase.category,
      purchase.icon,
      purchase.note,
      purchase.amount,
      purchase.date,
      purchase.worthit)}
   }
 }}
>
{isLoading 
   ? 'Working...' 
   : (groups.find(group => group.id === purchase.groupId) 
       ?  <p className="alreadyaddedtogroup"><><FcConferenceCall style={{fontSize: "0.9rem", marginLeft: "5px"}} />Remove</> </p>
       : <p className="notaddedtogroup"><FcConferenceCall style={{fontSize: "0.9rem", marginLeft: "5px", filter: "grayscale(1)" }} />Add</p>
     )
 }
</button>
)}
{selectedGroup === "" && (
  <button className="purchaseaddgroup" disabled={isLoading}>
  {isLoading
    ? 'Working...'
    : (groups.find((group) => group.id === purchase.groupId)
      ? (
        <>
          {purchase.groupWasModified && <span className="group-purchase-amount-indicator">({currencySymbol}{Math.trunc(purchase.groupAmount)})</span>}
          {!purchase.groupWasModified && ''}
          {purchase.group}
        </>
      )
      : ''
    )
  }
  {groups.find((group) => group.id === purchase.groupId) && <FcConferenceCall style={{fontSize: "0.9rem", marginLeft: "5px"}} />}
</button>
)}
<div className={purchase.amount > 0 ? "amountcontainer": "negativeamountcontainer"}>{`${currencySymbol}${Number(
                                                                purchase.amount
                                                            ).toFixed(
                                                                2
                                                            )}`}</div>
                                                        </div>
                                                    </td>
                                                    {editMode && (
                                                        <td
                                                            className="tablenumber2"
                                                            style={{
                                                                textAlign:
                                                                    "center",
                                                            }}
                                                        >
                                                            {deletedPurchase &&
                                                            deletedPurchase.id ===
                                                                purchase.id &&
                                                            deletedPurchase ===
                                                                "pending" ? (
                                                                <></>
                                                            ) : (
                                                                <button
                                                                    style={{
                                                                        width:
                                                                            "50px",
                                                                        textAlign:
                                                                            "center",
                                                                        fontWeight:
                                                                            "600",
                                                                        border:
                                                                            "none",
                                                                        borderRadius:
                                                                            "5px",
                                                                        backgroundColor: deleteButtonsClicked[
                                                                            purchase
                                                                                .id
                                                                        ]
                                                                            ? "#852626"
                                                                            : "transparent",
                                                                        color: deleteButtonsClicked[
                                                                            purchase
                                                                                .id
                                                                        ]
                                                                            ? "#FFFFFF"
                                                                            : "#852626",
                                                                    }}
                                                                    className="lilemoji"
                                                                    onClick={() => {
                                                                        if (
                                                                            deleteButtonsClicked[
                                                                                purchase
                                                                                    .id
                                                                            ]
                                                                        ) {
                                                                            handleDeletePurchase(
                                                                                purchase.id,
                                                                                purchase.category,
                                                                                purchase.amount,
                                                                                purchase.note, 
                                                                                purchase.worthit
                                                                    
                                                                            );
                                                                        } else {
                                                                            setDeleteButtonsClicked(
                                                                                {
                                                                                    ...deleteButtonsClicked,
                                                                                    [purchase.id]: true,
                                                                                }
                                                                            );
                                                                        }
                                                                    }}
                                                                >
                                                                    {deleteButtonsClicked[
                                                                        purchase
                                                                            .id
                                                                    ]
                                                                        ? "Confirm Delete"
                                                                        : "Delete"}
                                                                </button>
                                                            )}
                                                        </td>
                                                    )}
                                                </tr>
                                            );

                                            return acc;
                                        },
                                        []
                                    )}
                                     {!editMode && purchases.length > 0 && (<tr><td className="purchasefilters" colSpan="3">
                                <input
                                    className="purchaselistfilter"
                                    placeholder="Start typing to search your purchases..."
                                    type="text"
                                    id="categoryFilter"
                                    value={categoryFilter}
                                    onChange={(e) =>
                                        setCategoryFilter(e.target.value)
                                    }
                                    
                                />
                            </td></tr>)}
                            </tbody>
                        </table>
                    </div>
                    {purchases.length > 0 && <button
                        className={`editbutton ${
                            editMode
                                ? "editbutton2-edit-mode"
                                : "editbutton-normal-mode"
                        }`}
                        style={{
                            margin: "0% 25% 0% 25%",
                            width: "50%",
                            height: "20px",
                        }}
                        onClick={() => {
                            setEditMode(!editMode);
                            setDeleteButtonsClicked(false);
                        }}
                    >
                        {editMode && purchases.length > 0
                            ? "Done Editing"
                            : "Edit Purchase List"}
                    </button>}
                </div>
            </div>
         {/* <div className="group-container">
    <h2 className="group-heading">Your Groups</h2>
    <ul className="group-list">
      {groups.map((group) => (
        <li
          key={group.id}
          className={`group-item ${selectedGroup === group.id ? 'selected' : ''}`}
          onClick={() => handleGroupSelect(group.id)}
        >
          {group.name}
        </li>
      ))}
    </ul>
  </div> */}

  
{showGroupController && selectedPurchase && !isAddingFullAmount && (
  <div ref={modifyAmountRef} className="emojimodal">
    <div className="resetconfirmbottom">
      <div className="divaccent4"></div>
      <div className="group-purchase-controller-container1">
        <button className="group-purchase-close-container" onClick={handleCloseGroupController}><IoIosClose style={{marginRight: "0px", paddingRight: "0px"}}/></button>
        <form className="group-purchase-form" onSubmit={(e) => {
  e.preventDefault();

  // Do not submit if percentage > 100, or if amount or percentage are empty or zero
  if (modifiedPercentage > 100 || !modifiedAmount || modifiedAmount === 0 || !modifiedPercentage || modifiedPercentage === 0) {
    setShowErrorMessage(true);
    return;
  }

  setShowErrorMessage(false); // Reset error message when form submission is valid
  onSubmit(e);
}}
>
        <div className="group-purchase-header">Modify Purchase Amount</div>
        <table className="group-purchase-selected-purchase-table" style={{borderBottom: "none"}}>
          <tr className="group-purchase-selected-purchase-tablerow">
            <td style={{ width: "10%", padding: "5px", textAlign: "center", fontSize: "1.3rem", borderBottom: "none" }}>
              <button className="iconbutton">
                {selectedPurchase.icon}
                <div className="worthiticon">
                  {selectedPurchase.worthit !== undefined ? (selectedPurchase.worthit ? "👍" : "👎") : null}
                </div>
              </button>
            </td>
            <td style={{ paddingLeft: "0px", width: "50%", borderBottom: "none"  }} className="tableheader">
              <div className="categoryandnotecontainer">
                <div className="tableheadercc">{selectedPurchase.category}</div>
                <button className="lilemoji" style={{ textAlign: "left", borderBottom: "none" }}>
                  {selectedPurchase.note}
                </button>
              </div>
            </td>
            <td className="tablenumber2" style={{ textAlign: "right", width: "40%", borderBottom: "none"}}>
              <div className="dateandamountcontainer">
                <div className="amountcontainer">
                  {`${currencySymbol}${Number(selectedPurchase.amount).toFixed(2)}`}
                </div>
              </div>
            </td>
          </tr>
        </table>
        {showErrorMessage && 
  <div className="group-purchase-error">
    Cannot add a blank purchase.
  </div>
}
          <div className="group-purchase-modify-inputs">


          <span className="group-purchase-currency">{currencySymbol}</span>   
<input
  className="group-purchase-change-amount-cash"
  type="number"
  value={modifiedAmount ? (modifiedAmount / 100).toFixed(2) : ''}
  onChange={(e) => {
    const inputAmount = e.target.value.replace(/[^0-9]/g, "");
    const newAmount = parseFloat(inputAmount);
    if (newAmount <= selectedPurchase.amount * 100) {
      setModifiedAmount(newAmount);
      setModifiedPercentage((newAmount / selectedPurchase.amount) );
    }
  }}
/>
<span className="group-purchase-currency"></span> 
<input
  className="group-purchase-change-percent"
  type="number"
  value={modifiedPercentage ? `${(modifiedPercentage).toFixed(0)}` : ''}
  step="1"
  max="100"
  onChange={(e) => {
    const inputPercentage = e.target.value.replace(/[^0-9]/g, "");
    if (inputPercentage === '') {
      setModifiedPercentage(0);
      setModifiedAmount(0);
    } else {
      const newPercentage = parseInt(inputPercentage, 10);
      if (newPercentage <= 100) {
        setModifiedPercentage(newPercentage);
        setModifiedAmount((newPercentage) * selectedPurchase.amount);
      }
    }
  }}
/>
<span className="group-purchase-currency">%</span> 
          </div>
          <button className="group-purchase-submit" type="submit">{`Add Modified Purchase to ${selectedGroupName}`}</button>
        </form>
      </div>
    </div>
  </div>
)}











            {showForm && (
                <div className="emojimodal">
                    <div className="resetconfirmbottom">
                        <div className="divaccent4"></div>
                        <form
                            className="newemojiform"
                            onSubmit={handleSaveChanges}
                        >
                            <p className="emojiheader">Purchase Note</p>
                            <textarea
                                ref={newEmojiRef}
                                className="newemoji"
                                type="textarea"
                                name="emoji"
                                defaultValue={
                                    selectedPurchase ? selectedPurchase.note : ""
                                }
                                maxLength={65}
                            />
                            <div className="emojibuttons">
                                <button className="confirmation-okay-dark" type="submit">
                                   Save Note
                                </button>
                                <button
            
            className="confirmation-no-dark"
            onClick={() => {setShowForm(false); setShowInputWidget(true);} }
        >
            <IoIosClose style={{fontSize: "1.5rem"}}/>
        </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}

            <div className="portfoliolink">
                {" "}
                <div className="moneyiconcontainer">
                    <div className="financemessage">{selectedQuote}</div>
                </div>
            </div>
        </div>
    );
}

export default PurchasesPage;
