import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const GroupLineChart = ({ groupPurchases, onUserColor, currencySymbol, userColors  }) => {
  const chartRef = useRef(null);
  const chart = useRef(null);
  const chartColors = useRef([]);
  
  useEffect(() => {
    if (!groupPurchases || groupPurchases.length === 0) {
      return; // Return early if groupPurchases is null, undefined, or empty
    }

    const ctx = chartRef.current.getContext('2d');

    // Sort groupPurchases by date in ascending order
    const sortedPurchases = groupPurchases.sort((a, b) => new Date(a.date) - new Date(b.date));

    // Extract the necessary data for the line chart
    const users = {};
    const datasets = sortedPurchases.reduce((datasets, purchase) => {
      const { addedBy, amount, date } = purchase;
      const dateString = new Date(date).toLocaleDateString();

      if (!users[addedBy]) {
        users[addedBy] = {};
      }

      const userDataset = datasets.find((dataset) => dataset.label === addedBy);

      if (!userDataset) {
        const color = getRandomColor(addedBy, userColors, onUserColor);
        chartColors.current.push(color);
        
        onUserColor(addedBy, color); 


        datasets.push({
          label: addedBy,
          data: [],
          borderColor: color,
          fill: false,
          borderWidth: 2,
          pointRadius: 0,
          pointHoverRadius: 8,
          pointHitRadius: 10,
          tension: 0.2,
        });
      }

      const userAccumulatedTotal = users[addedBy];
      userAccumulatedTotal[dateString] = (userAccumulatedTotal[dateString] || 0) + amount;

      const accumulatedTotal = Object.values(userAccumulatedTotal).reduce((sum, value) => sum + value, 0);

      datasets.find((dataset) => dataset.label === addedBy).data.push({
        x: dateString,
        y: accumulatedTotal,
      });

      return datasets;
    }, []);

    chart.current = new Chart(ctx, {
      type: 'line',
      data: {
        datasets: datasets,
      },
      options: {
        layout: {
          padding: {
            bottom: 0,
          },
        },
        aspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
        elements: {
          point: {
            radius: 0,
          },
          line: {
            tension: 0.4,
          },
        },
        scales: {
          x: {
            display: false,
            grid: {
              display: false,
              drawBorder: true,
            },
            type: 'time',
            time: {
              unit: 'day',
              displayFormats: {
                day: 'MMM D',
              },
            },
            ticks: {
              display: true,
              color: '#303030',
              font: { 
                size: 10,
                // family: 'Raleway' 
              },
              source: 'auto',
              stepSize: 2, 
            },
          },
          y: {
            beginAtZero: false,
            ticks: {
              display: true,
              color: '#2c2c2c',
              font: { 
                size: 10,
                // family: 'Raleway' 
              },
              callback: function (value, index, values) {
                if (value >= 1000) {
                  return currencySymbol + (value/1000).toFixed(0) + 'k';
                } else {
                  return currencySymbol + value.toFixed(0);
                }
              },
              source: 'auto',
              maxTicksLimit: 6
            },
          },
        },
      },
    });

    return () => {
      if (chart.current) {
        chart.current.destroy();
      }
    };
  }, [groupPurchases]);

  return (
    <div>
      <canvas ref={chartRef} />
    </div>
  );
};

export default GroupLineChart;

function getRandomColor(user, userColors, onUserColor) {
  // Check if user already has a color assigned
  const existingColor = userColors.find((color) => color.user === user);
  if (existingColor) {
    return existingColor.color;
  }

  // If not, generate a new random color
  const colors = ['#D9CAC0', '#BAA8A6', '#9C918C', '#C8BFA3', '#27292c', '#2f445e', '#6487a3', '#6f4f30', '#445a72', '#847772', '#343e3d', '#607466', '#aedcc0', '#7bd389', '#38e4ae', '#faf9f4', '#d88600', '#c8aecf', '#a08bbe', '#554271', '#000535', '#cce0e2', '#986c2c', '#b08b5a', '#6a2924', '#433013', '#cc964f', '#c35033', '#e4cc9e', '#9f1920', '#bb321f', '#15090b', '#ad1c01', '#ddc061', '#964B00', '#CC7722', '#5D3954', '#4B5320'];
  const letters = '0123456789ABCDEF';
  let color = '#';
  return colors[Math.floor(Math.random() * colors.length)];
  

  // Update the color assignments in the parent
  onUserColor(user, color);

  return color;
}



 

