import firebase from "firebase/compat/app";
import "firebase/compat/database";
import "firebase/compat/storage";
import "firebase/compat/firestore";
import React, { useState, useEffect, useCallback, useRef } from "react";
import LeftoverWidget from "./LeftoverWidget";
import { useLocation, useNavigate } from "react-router-dom";
import IncomeBudgetForm from "./IncomeBudgetForm";
import AllocatedChart from "./AllocatedChart";
import SavingsGoals from "./SavingsGoals";
import BudgetDetails from "./BudgetDetails";
import {FaSpinner, FaQuestionCircle} from "react-icons/fa";
import budgetlogo from "./images/lillogo.svg";
import DailySpending from "./DailySpending";
import { debounce } from "lodash";
import { set } from "date-fns";




const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "budget-app-d568a.firebaseapp.com",
  databaseURL: "https://budget-app-d568a-default-rtdb.firebaseio.com",
  projectId: "budget-app-d568a",
  storageBucket: "budget-app-d568a.appspot.com",
  messagingSenderId: "219321405159",
  appId: "1:219321405159:web:c4a51c20c9d13376629c42",
  measurementId: "G-6P5Y0TGT7T"
};

firebase.initializeApp(firebaseConfig);

const db = firebase.database();

export { db };



function calculateRemainingBudgets(data, purchasesArray) {
    const categoryTotals = purchasesArray.reduce((totals, purchase) => {
        const { category, amount } = purchase;
        return {
            ...totals,
            [category]: (totals[category] || 0) + parseFloat(amount),
        };
    }, {}); 

    const remainingBudgetsCopy = { ...data };
    for (const [category, amount] of Object.entries(categoryTotals)) {
        remainingBudgetsCopy[category] = data[category] - amount;
    }

    return remainingBudgetsCopy;
}

// Function to calculate total spent
function calculateTotalSpent(purchasesArray) {
    const monthlySpent = purchasesArray.reduce(
        (acc, val) => acc + parseFloat(val.amount),
        0
    );
    return monthlySpent;
}

// Function to calculate sorted budgets
function calculateSortedBudgets(data) {
    const sortedBudgets = Object.keys(data)
        .map((category) => ({
            category,
            budget: data[category],
        }))
        .sort((a, b) => a.category.localeCompare(b.category));

    return sortedBudgets;
}

function BudgetPage({
  handleHideInputWidget,
  handleUnhideInputWidget,
    currencySymbol,
    onRowClick,
    purchases,
    setShowUpdateMessage,
    showInputWidget,
    setShowInputWidget,
    selectedCategory,
    setSelectedCategory,
    containerRef,
    triggerRefetch, 
    setTriggerRefetch,
    showDateInfo, 
    setShowDateInfo, toggleDateInfoModal, daysLeftInMonth, setShowTutorial, showBillReminders, showExpenseReminders, setShowExpenseReminders,
    showIncomeBudgetForm, setShowIncomeBudgetForm,
    expenses, setExpenses, editMode, setEditMode, setShowNavTutorial, showNavTutorial, isLoading, setIsLoading, handleEditEmojiClick, handleChangePurchaseIcon,
emojiCategories
}) {
    const [sortedBudgets, setSortedBudgets] = useState([]);
    const [currentDate, setCurrentDate] = useState(new Date()); 
    const [showPurchasesPage, setShowPurchasesPage] = useState(false);
    const [totalBudget, setTotalBudget] = useState(0);
    const [totalSpent, setTotalSpent] = useState(0);
    const [lastUpdatedRowId, setLastUpdatedRowId] = useState(null);
    const [purchasesArray, setPurchasesArray] = useState([]);
    const [showBudgetDetails, setShowBudgetDetails] = useState(false);
    const [categories, setCategories] = useState({});
    const [remainingBudgets, setRemainingBudgets] = useState({});
    const [selectedRow, setSelectedRow] = useState("");
    const [showButton, setShowButton] = useState(false);
    const [deletingCategory, setDeletingCategory] = useState(null);
    const [intervalId, setIntervalId] = useState(null);
    const [showDeleteConfirmAlert, setShowDeleteConfirmAlert] = useState(false);

    const [discretionaryIncome, setDiscretionaryIncome] = useState(0);
    const [showCategoryTips, setShowCategoryTips] = useState(false);
    const [greeting, setGreeting] = useState("Hello!");
    const [selectedRowCategory, setSelectedRowCategory] = useState(null);
    const [initialDataLoaded, setInitialDataLoaded] = useState(false);
    const [showLeftoverWidget, setShowLeftoverWidget] = useState(false);
    const [userId, setUserId] = useState(null);
    const [categoriesRef, setCategoriesRef] = useState(null);
const [purchasesRef, setPurchasesRef] = useState(null);
const [loading, setLoading] = useState(false);
const [showYAxisTicks, setShowYAxisTicks] = useState(false);

const selectedRowRef = useRef();
const longPressTimer = useRef();
const budgetDetailsLongPressTimer = useRef();
const isLongPressTriggered = useRef(false);


const handleHiddenButtonPress = () => {
  longPressTimer.current = setTimeout(() => {
    navigate("/review");
  }, 5000);
}

const handleHiddenButtonRelease = () => {
  clearTimeout(longPressTimer.current);
}

const handleShowBudgetDetailsButtonPress = (category) => {
  budgetDetailsLongPressTimer.current = setTimeout(() => {
    setShowBudgetDetails(true);
    isLongPressTriggered.current = true;
  }, 800);
}

const handleHideBudgetDetailsButtonPress = () => {
  clearTimeout(budgetDetailsLongPressTimer.current);
  if (isLongPressTriggered.current) {
    // Reset the long press trigger when hiding the menu after a long press
    isLongPressTriggered.current = false;
  } else {
    // Hide the menu only if the long press timer has not been triggered
    // Additional conditions can be added if needed
    // hideMenu();
  }
} 

useEffect(() => {
  const newRemainingBudgets = calculateRemainingBudgets(categories, purchases);
  setRemainingBudgets(newRemainingBudgets);
  setSortedBudgets(calculateSortedBudgets(categories));
}, [categories, purchases]);

const handleCloseExpenseReminders = () => {
  setShowExpenseReminders(false);
  setShowIncomeBudgetForm(false);
  handleUnhideInputWidget();
  setShowInputWidget(true);
  setEditMode(false);
};

const handleCloseNavTutorial = () => {
  setShowNavTutorial(false)
};

const handleShowExpenseReminders = () => {
  setShowExpenseReminders(true);
  handleHideInputWidget();
};

    const location = useLocation();
    const navigate = useNavigate();


    const handleOpenTutorial = () => {
      setShowTutorial(true);
      setShowInputWidget(false);
    };

    const handleContextMenu = (e) => {
      e.preventDefault();
    };

    const handleChartAxisClick = () => {
      setShowYAxisTicks((prevValue) => !prevValue); // Toggle the state value
    };

    const monthStart = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
    );
    const monthEnd = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0
    );

    const examples = [
        "clown therapy",
        "gourmet ice cubes",
        "scented candles",
        "novelty hats",
        "designer shoelaces",
        "cat clothing",
        "artisinal toothpicks",
        "fidget spinners",
        "exotic spices",
        "bottle service",
        "bespoke bookmarks",
    ];
    const [exampleCategory, setExampleCategory] = useState(
        examples[Math.floor(Math.random() * examples.length)]
    );

    // Function to compute remaining budgets
const computeRemainingBudgets = (purchasesArray, categories) => {
    // calculate the total amount of purchases for each category
    const categoryTotals = purchasesArray.reduce((totals, purchase) => {
        const { category, amount } = purchase;
        return {
            ...totals,
            [category]: (totals[category] || 0) + amount,
        };
    }, {});

    // calculate the remaining budget
    const remainingBudgetsCopy = { ...categories };
    for (const [category, amount] of Object.entries(categoryTotals)) {
        remainingBudgetsCopy[category] = categories[category] - amount;
    }

    return remainingBudgetsCopy;
};

useEffect(() => {
  // Listen for changes in the authentication state
  const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      // User is signed in.
      setUserId(user.uid);
      setLoading(true);

      const categoriesRef = db.ref(`users/${user.uid}/categories`);
      const budgetRef = db.ref(`budget/${user.uid}`);
      const purchasesRef = db.ref(`users/${user.uid}/purchases`);
      const ref = firebase.database().ref(`users/${user.uid}`);

      // Start listening for changes in the data
      budgetRef.on("child_changed", (snapshot) => {
        const data = snapshot.val() || {};
        const monthlyBudget = Object.values(data).reduce(
          (acc, val) => acc + val,
          0
        );
        setTotalBudget(monthlyBudget);
      });

      categoriesRef.on("value", (snapshot) => {
        const data = snapshot.val() || {};
        setCategories(data);
        setTotalBudget(
          Object.values(data).reduce((acc, val) => acc + val, 0)
        );
        
        const newRemainingBudgets = computeRemainingBudgets(purchasesArray, data);
        setRemainingBudgets(newRemainingBudgets);
        
        // Newly added
        const newSortedBudgets = Object.keys(newRemainingBudgets)
          .map((category) => ({
            category,
            budget: newRemainingBudgets[category],
          }))
          .sort((a, b) => a.category.localeCompare(b.category));

        setSortedBudgets(newSortedBudgets);
      });

      purchasesRef.on("value", (snapshot) => {
        const data = snapshot.val() || {};
        const newPurchasesArray = Object.keys(data).map((key) => ({
          ...data[key],
          id: key,
        }));
      
        const monthlySpent = newPurchasesArray.reduce(
          (acc, val) => acc + val.amount,
          0
        );
        setTotalSpent(monthlySpent);
        setPurchasesArray(newPurchasesArray);  // <-- set the purchasesArray state here
      
        setLoading(false);
      });
      return () => {
        // Stop listening for changes
        budgetRef.off();
        categoriesRef.off();
        purchasesRef.off();
        ref.off();
      };
    } else {
      // No user is signed in.
    }
  });

  // Clean up function
  return () => unsubscribe();
}, [remainingBudgets]); 

    useEffect(() => {
        const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                const ref = firebase.database().ref(`users/${user.uid}`);
    
                ref.once("value", (snapshot) => {
                    const data = snapshot.val();
                    if (data) {
                        const totalExpenses = Object.values(data.expenses || []).reduce(
                            (acc, expense) => acc + parseFloat(expense.amount || 0),
                            0
                        );
                        const remainingIncome =
                            parseFloat(data.income || 0) - totalExpenses;
                        setDiscretionaryIncome(remainingIncome);
                    }
                });
    
                return () => {
                    // Stop listening for changes
                    ref.off();
                };
            }
        });
    
        // Clean up function
        return () => unsubscribe();
    }, []);
    
    useEffect(() => {
      const newSortedBudgets = Object.keys(remainingBudgets)
        .map((category) => ({
          category,
          budget: remainingBudgets[category],
        }))
        .sort((a, b) => a.category.localeCompare(b.category));
    
      setSortedBudgets(newSortedBudgets);
    }, [remainingBudgets]);
  
// This hook will update the sortedBudgets whenever there's a change in categories or remainingBudgets
useEffect(() => {
  // sort the remaining budgets by category
  const sortedBudgets = Object.keys(remainingBudgets)
      .map((category) => ({
          category,
          budget: remainingBudgets[category],
      }))
      .sort((a, b) => a.category.localeCompare(b.category));
  setSortedBudgets(sortedBudgets);
}, [remainingBudgets, categories]);


    useEffect(() => {
        if (userId) {
            setCategoriesRef(db.ref(`users/${userId}/categories`));
            setPurchasesRef(db.ref(`users/${userId}/purchases`));
        }
    }, [userId]);


    const updateDiscretionaryIncome = (newDiscretionaryIncome) => {
        setDiscretionaryIncome(newDiscretionaryIncome);
    };


    function onDeleteButtonClick(category) {
        setDeletingCategory(category);
    }

    function onCancelDeleteClick() {
        setDeletingCategory(null);
    }

    function onDeleteConfirmClick(category) {
        setDeletingCategory(null);
        handleDeleteCategory(category);
        setShowDeleteConfirmAlert(false);
    }

    function handleCloseIncomeBudgetForm() {
        setShowIncomeBudgetForm(false);
    }

    function handleShowIncomeBudgetForm() {
        setShowIncomeBudgetForm(true);
    }


   

    const getDateCounterClassName = () => {
        if (daysLeftInMonth() < 2) {
            return "classForLessThan2";
        } else if (daysLeftInMonth() < 4) {
            return "classForLessThan4";
        }
        return "";
    }

    const daysText = daysLeftInMonth() === 1 ? 'day left in the month' : 'days left in the month';

    //HOOK TO ENSURE THAT THE CORRECT TAB IS ACTIVE_____________________________________________________________
    useEffect(() => {
        if (
            location.pathname === "/" &&
            location.state?.from === "/purchases"
        ) {
            setShowPurchasesPage(false);
            navigate("/");
        }
    }, [location, navigate]);

   
   //ASYNC FUNCTION TO HANDLE CATEGORIES BEING ADDED___________________________________________________________
   async function handleAddCategory(event) {
    event.preventDefault();
    
    const form = event.target;
    const { categoryName, budgetAmount } = form.elements;
  
    // Save the new category to Firebase Realtime Database
    await categoriesRef.update({
      [categoryName.value]: Number(budgetAmount.value),
    });
  
    // Update the categories state
    setCategories((prevState) => {
      return {
        ...prevState,
        [categoryName.value]: Number(budgetAmount.value),
      };
    });
  
    // Clear the form inputs
    form.reset();
  }

  //ASYNC FUNCTION TO HANDLE CATEGORIES BEING DELETED_________________________________________________________
  async function handleDeleteCategory(category) {
    // Remove the category from Firebase Realtime Database
    await categoriesRef.child(category).remove();

    // Remove all purchases related to the category from Firebase Realtime Database
    purchasesRef
        .orderByChild("category")
        .equalTo(category)
        .once("value", (snapshot) => {
            snapshot.forEach((childSnapshot) => {
                childSnapshot.ref.remove();
            }); 
        });

    // Update the categories state with the deleted category removed
    setCategories((prevState) => {
        const newState = { ...prevState };
        delete newState[category];
        return newState;
    });

    if (category === selectedCategory) {
        setSelectedCategory(null); 
    }
}

    const modifiedTotalBudget = totalBudget < 0 ? 0 : totalBudget;

    useEffect(() => {
        const timerId = setTimeout(() => setShowLeftoverWidget(true), 50);
        return () => clearTimeout(timerId); 
    }, []);

    useEffect(() => {
        const randomIndex = Math.floor(Math.random() * 10);
        setExampleCategory(examples[randomIndex]);
    }, []);


    async function handleUpdateCategory(category, newBudget) {
      // Save the new budget for the category to Firebase Realtime Database
      await categoriesRef.update({
          [category]: newBudget, 
      });
  
      // Update the categories state
      setCategories((prevState) => {
          return {
              ...prevState,
              [category]: newBudget,
          };
      });
  }

    const handleCategoryTips = () => {
        setShowCategoryTips(true);
    };

    const handleRowClick = (category) => {
        if (selectedRow === category ) {
            setSelectedRow(null);
            setShowBudgetDetails(false);
            setSelectedRowCategory(null); // Clear the selected row if it's already selected
        } else {
            onRowClick(category);
            setSelectedRow(category);
            setShowBudgetDetails(false);
            setSelectedRowCategory(category);
        }
    };



    
    useEffect(() => {
        const randomIndex = Math.floor(Math.random() * 10);
        setExampleCategory(examples[randomIndex]);
    }, []);

    useEffect(() => {
        const id = setTimeout(() => setShowButton(true), 200);
        setIntervalId(id);
        return () => clearInterval(intervalId);
    }, []);


    const colorArray = [
        "rgb(10, 100, 90)",
        "rgb(15, 99, 90)",
        "rgb(20, 98, 80)",
        "rgb(30, 97, 70)",
        "rgb(40, 96, 60)",
        "rgb(50, 95, 50)",
        "rgb(60, 94, 40)",
        "rgb(70, 93, 30)",
        "rgb(80, 92, 20)",
        "rgb(90, 91, 10)",
        "rgb(100, 90, 0)",
        "rgb(110, 90, 0)",
        "rgb(120, 90, 0)",
        "rgb(130, 90, 0)",
        "rgb(140, 90, 0)",
        "rgb(145, 90, 0)",
        "rgb(150, 70, 0)",
        "rgb(100, 45, 0)",
        "rgb(110, 30, 0)",
        "rgb(120, 0, 0)",
    ];

    const remainingToAllocate = discretionaryIncome - totalBudget;

    //JSX______________________________________________________________________________________________________

    return (
        <>
        {loading ? (
          <div className="full-loader"><FaSpinner className="spinner" /></div>
        ) : (
          <>
        <div className={editMode ? "edit-mode" : "entirebudgetcontainer"}>

    



            {showIncomeBudgetForm && !showBillReminders &&(<div>
                <div className="incomebudgetformcontainerreminder">
                    <IncomeBudgetForm
                        handleCloseIncomeBudgetForm={
                            handleCloseIncomeBudgetForm
                        }
                        currencySymbol={currencySymbol}
                        setDiscretionaryIncome={setDiscretionaryIncome}
                        discretionaryIncome={discretionaryIncome}
                        updateDiscretionaryIncome={updateDiscretionaryIncome}
                        setShowUpdateMessage={setShowUpdateMessage}
                        setShowExpenseReminders={setShowExpenseReminders}
                        showExpenseReminders={showExpenseReminders}
                        setShowIncomeBudgetForm={setShowIncomeBudgetForm}
                        setShowInputWidget={setShowInputWidget}
                        setEditMode={setEditMode}
                        expenses={expenses}
                        setExpenses={setExpenses}
                        handleUnhideInputWidget={handleUnhideInputWidget}
                        sortedBudgets={sortedBudgets}


                        
                    />
                    
                </div>
                   {/* <div className="ibfa-button-container">
                   <button onClick={handleCloseExpenseReminders}
                   
                               className="ibfa-close">
                               Done
                               
                             </button></div> */}
                             </div>
            )}
            {!editMode && !showExpenseReminders && sortedBudgets.length > 0 && (
                <div className="widgetarea">
                    {showLeftoverWidget && sortedBudgets.length > 0 && (
                        <LeftoverWidget
                            modifiedTotalBudget={modifiedTotalBudget}
                            totalBudget={totalBudget}
                            totalSpent={totalSpent}
                            remainingBudgets={remainingBudgets}
                            currencySymbol={currencySymbol}
                        />
                    )}
                </div>
            )}
            {!editMode && !showExpenseReminders && sortedBudgets.length > 0 && (
                <div onClick={handleChartAxisClick} className="dailyspendingcontainer">
                    <DailySpending
                        purchases={purchases}
                        userId={firebase.auth().currentUser.uid}
                        currencySymbol={currencySymbol}
                        totalBudget={totalBudget}
                        totalSpent={totalSpent}
                        modifiedTotalBudget={modifiedTotalBudget}
                        showYAxisTicks={showYAxisTicks}
                    />
                </div>
            )}



            {showCategoryTips && (
                <div className="confirmation-message">
                    <div className="resetconfirm">
                        <div className="divaccent"></div>
                        <div className="categorytipsheader1">
                            Tips for categorizing your spending:
                        </div>
                        <ul className="categorytiplist">
                            <li className="categorytiplistitem">
                                <div className="categorytiplistheader">
                                    🧾 Make Broad Categories{" "}
                                </div>
                                Keep it simple! Try to avoid having to split
                                receipts to log a transaction.{" "}
                            </li>
                            <li className="categorytiplistitem">
                                <div className="categorytiplistheader">
                                    🎯 Choose the Right Expenses{" "}
                                </div>
                                Don't include expenses that occur without you
                                thinking about it. If it's on auto-pay, it
                                should go into your fixed expenses.{" "}
                            </li>
                            <li className="categorytiplistitem">
                                <div className="categorytiplistheader">
                                    🧠 Be Dynamic!
                                </div>{" "}
                                Regularly review and adjust your categories to
                                make room for changes, like events and seasonal
                                expenses.
                            </li>
                            <li className="categorytiplistitem">
                                <div className="categorytiplistheader">
                                    🔍 Fine-Tune
                                </div>{" "}
                                Learn from your categorized spending! If you're
                                consistently spending a different amount than
                                you budget, consider adjusting your allowance.
                            </li>
                        </ul>

                        <div className="buttons-container">
                            <div className="yesno">
                                <button
                                    className="confirmation-no"
                                    onClick={() => setShowCategoryTips(false)}
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {showLeftoverWidget && (
                <div className="budget-table">
                    <table className="budgettable" style={!editMode ? { marginTop: "20px" } : null}>
  {editMode && sortedBudgets.length === 0 && !showIncomeBudgetForm && (
    <div style={{ width: "400%", backgroundColor: "transparent" }} className="emptystate">
      <div className="emptycategoryflex">
        <div className="nocategoryemptystate">You haven't set up any categories!</div>
        <div className="nocategoryemptystate2">
          Use the form above to create a category and set its spending limit.
        </div>
      </div>
    </div>
  )}
  <thead>
    {editMode && !showExpenseReminders && !showIncomeBudgetForm && (
      <div
        className="howtocategorycontainer"
        style={{
          borderLeft: !showIncomeBudgetForm ? "none" : "3px solid #ffbd2b",
          borderRadius: showIncomeBudgetForm ? "0px" : "10px 10px 0px 0px",
        }}
      >
        {showDeleteConfirmAlert && sortedBudgets.length > 0 ? (
          <div className="deletecategoryalertmessage">
            Deleting a category will also delete its purchases!<br></br>
            Your archived purchases will be safe.<br></br>
            <button
              className="canceldeletecategory"
              onClick={() => {
                setDeletingCategory(null);
                setShowDeleteConfirmAlert(false);
              }}
            >
              Cancel Delete
            </button>
          </div>
        ) : (
          <div className="totalvsavailablecontainer">
            {remainingToAllocate >= 0 ? (
              <AllocatedChart
                discretionaryIncome={discretionaryIncome}
                currencySymbol={currencySymbol}
                totalBudget={totalBudget}
              />
            ) : (
              <div className="overbudget"></div>
            )}
            {typeof remainingToAllocate === "number" && !isNaN(remainingToAllocate) &&  (
              <div
                className={`discretiontotal ${remainingToAllocate >= 0 ? "" : "over-budget"}`}
              >
                {remainingToAllocate >= 0 ? "Available discretionary income:" : "Spending more than you make by"}{" "}
                {currencySymbol}
                {Math.round(Math.abs(remainingToAllocate))}
              </div>
            )}
            <button
              className="showincomeformbutton"
              onClick={showIncomeBudgetForm ? handleCloseIncomeBudgetForm : handleShowIncomeBudgetForm}
            >
              {showIncomeBudgetForm ? "" : "Manage Income & Expenses"}
            </button>
            <div className="budgetpageheader">
              {showIncomeBudgetForm ? (
                ""
              ) : (
                <button
                  style={{}}
                  className={`editbutton ${
                    editMode ? "editbutton-edit-mode" : "editbutton-normal-mode"
                  }`}
                  onClick={() => {
                    setEditMode(!editMode);
                    setDeletingCategory(null);
                    setShowDeleteConfirmAlert(false);
                    setShowIncomeBudgetForm(false);
                    handleUnhideInputWidget();
                    setShowInputWidget(true);
                  }}
                >
                  {editMode ? "Done Editing" : "Edit Categories"}
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    )}
    {editMode && !showExpenseReminders && !showIncomeBudgetForm && (
      <tr>
        <td style={{ backgroundColor: "#ddddddf3" }} colSpan={4}>
          <div className="dontdisplay">
            <div className="newcategoryform">
              <form onSubmit={handleAddCategory}>
              {!showIncomeBudgetForm && <div className="categoryformcontainer">
                  <input
                    maxLength={25}
                    className="categorynameinput"
                    type="text"
                    name="categoryName"
                    id="categoryName"
                    placeholder="add category"
                    required
                  />
                  <div style={{ color: "#707070", fontSize: "0.9rem", marginLeft: "5px", marginRight: "2px", lineHeight: "35px" }}>
                    {currencySymbol}
                  </div>
                  <input
                    className="categoryamountinput"
                    step="1"
                    type="number"
                    name="budgetAmount"
                    placeholder="limit"
                    id="budgetAmount"
                    required
                  />
                  <div className="formbuttoncontainer">
                    <button className="addcategorybutton" type="submit">
                      Add
                    </button>
                  </div>
                </div>}
              </form>
            </div>
          </div>
        </td>
      </tr>
    )}
    {sortedBudgets.length > 0 ? !showIncomeBudgetForm && (
      <tr>
        {!editMode && (
          <th
            style={{
              textAlign: "left",
              paddingLeft: "10px",
              color: "#aaaaaa",
              width: "40%",
              fontSize: "0.7rem",
              borderRadius: "10px 0px 0px 0px",
            }}
          >
            Category
          </th>
        )}
        {!editMode && (
          <th
            style={{
              color: "#aaaaaa",
              width: "20%",
              fontSize: "0.7rem",
              textAlign: "center",
            }}
          >
            Budget
          </th>
        )}
        {!editMode && (
          <th
            style={{
              color: "#aaaaaa",
              width: "20%",
              fontSize: "0.7rem",
              textAlign: "center",
            }}
          >
            Spent
          </th>
        )}
        {!editMode && (
          <th
            style={{
              color: "#aaaaaa",
              width: "20%",
              fontSize: "0.7rem",
              textAlign: "right",
              borderRadius: "0px 10px 0px 0px",
            }}
          >
            Balance
          </th>
        )}
      </tr>
    ) : null}
  </thead>
  {!editMode && sortedBudgets.length === 0 && (discretionaryIncome === 0 || discretionaryIncome === "" || discretionaryIncome === null ) && (
    
    <div>
      {showNavTutorial &&
      <div className="navigationtutorial">
        <div className="component-indicator-container-tutorial"></div>
        <div className="component-indicator-container-tutorial-line"></div>
        <div className="component-indicator3-tutorial"></div>
        <div className="component-indicator5-tutorial"></div>
        <div className="component-indicator7-tutorial"></div>
        <div className="component-indicator-tutorial-text1">Navigation</div>
        <div className="component-indicator-tutorial-text2">Expense Reminders</div>
        <div className="component-indicator-tutorial-text3">Review & Reset Budget</div>
        <div className="component-indicator-tutorial-text4">Account, Settings & More</div>
        <button onClick={handleCloseNavTutorial} className="component-indicator-tutorial-dismiss">Dismiss</button>
      </div>}
      {showNavTutorial && <div className="onboard-nav-spacer"></div>}
      <div >
      <div className="onboardinstruction">
        <p className="onboardinstructionheader2">Welcome!</p>
        <p className="onboardinstructionbody"></p>
      </div>
      <div className="onboardinstruction">
        <p className="onboardinstructionheader">Step 1:</p>
        <p className="onboardinstructionbody">Enter your income and fixed expenses</p>
      </div>
      <div className="onboardinstruction">
        <p className="onboardinstructionheader">Step 2:</p>
        <p className="onboardinstructionbody">
          Create categories to budget your discretionary expenses with the remaining funds
        </p>
      </div></div>
    </div>
  )}

{showButton && (discretionaryIncome > 0 || discretionaryIncome < 0) && !editMode && sortedBudgets.length === 0 && <div className="budgetpage-emptystateheadertext"> No budget categories found!</div>}

  {!editMode && sortedBudgets.length === 0 && (
    <div style={{ backgroundColor: "transparent" }}>
      <div
        style={{
          backgroundColor: "transparent",
          width: "100%",
          borderBottom: "none",
        }}
        className="emptystate"
        colSpan={6}
      >
        {showButton && (<div>
        
          <button
  className="emptystatebutton"
  onClick={
    discretionaryIncome === 0 || isNaN(discretionaryIncome)
      ? () => {
          setEditMode(true);
          handleCloseNavTutorial();
          handleHideInputWidget();
          setShowIncomeBudgetForm(true);
        }
      : () => {
          setEditMode(true);
          handleCloseNavTutorial();
          handleHideInputWidget();
        }
  }
>
            {discretionaryIncome === 0 || isNaN(discretionaryIncome)
              ? "Set up your income and create your spending categories"
              : `Add a spending category, like "${exampleCategory}"`}
          </button>

          </div>
        )}
        
      </div>
      
      {discretionaryIncome === 0 && <div className="show-tutorial-container">
      <button className="opentutorialbutton" onClick={handleOpenTutorial}>Want a crash course on setting up your budget?<br></br> <p style={{textDecoration: "underline", color: "rgb(10, 62, 110)", fontWeight: "700"}}>Check out the tutorial</p></button></div>}
    </div>
  )}
   {!showIncomeBudgetForm && 
   <tbody>
    {sortedBudgets.map(({ category, budget }) => {
      const isLastUpdatedRow = category === lastUpdatedRowId;
      const remainingBudget = remainingBudgets[category];
      const rowClass = category === selectedRow ? "highlight" : "";
      const originalBudget = categories[category];
      const spentPercentage = (originalBudget - budget) / originalBudget;
      const spentPercentageRounded = Math.round(spentPercentage * 100);
      const spentPercentageColor =
        colorArray[Math.floor(spentPercentageRounded / (100 / colorArray.length))];
      const isLowBudget = budget / originalBudget > 0 && budget / originalBudget <= 0.2;
      const isNegativeBudget = budget < 0.001;
      const remainingBudgetClass = isLowBudget
        ? "remainingbudgettext lowbudget"
        : isNegativeBudget
        ? "remainingbudgettext negativebudget"
        : "remainingbudgettext";

        const renderBudgetDetails = category === selectedRow && showBudgetDetails;

      return (
        <React.Fragment key={category}>
      <tr
  style={{ marginBottom: "10px", userSelect: 'none'}}
  key={category}
  className={rowClass}
  onClick={() => !editMode && handleRowClick(category)}
  onDoubleClick={() => handleShowBudgetDetailsButtonPress(category)}
  onMouseDown={() => handleShowBudgetDetailsButtonPress(category)} 
  onMouseUp={handleHideBudgetDetailsButtonPress}
  onTouchStart={() => handleShowBudgetDetailsButtonPress(category)} 
  onTouchEnd={handleHideBudgetDetailsButtonPress}
  onContextMenu={handleContextMenu}
>
          {editMode && <td colSpan={2} className="tableheader">{category}</td>}
          {!editMode && (
            <td className="tableheader">
              <div className="sidecarcontainer">
                <div
                  className="sidecar"
                  style={{ backgroundColor: spentPercentageColor }}
                ></div>
                <div className="tableheaderbb">{category}</div>
              </div>
            </td>
          )}
          {editMode && (
            <td
              className="tablenumber6"
              style={{
                fontWeight: "700",
                color: "#3f523a",
                fontSize: "0.9rem",
                textAlign: "center",
              }}
            >
              <input
                className="categoryamountinput"
                placeholder={originalBudget}
                type="number"
                step="1"
                onChange={debounce((e) => {
                  const newBudget = Number(e.target.value);
                  setCategories((prevCategories) => ({
                    ...prevCategories,
                    [category]: newBudget,
                  }));
                  handleUpdateCategory(category, newBudget);
                }, 1500)}
              />
            </td>
          )}
          {!editMode && (
            <td className="tablenumber" style={{ textAlign: "center", color: "#888888" }}>
              {currencySymbol}
              {Number(originalBudget).toFixed(0)}
            </td>
          )}
          {!editMode && (
            <td className="tablenumber" style={{ textAlign: "center", color: "#888888" }}>
              {currencySymbol}
              {Number(originalBudget - budget).toFixed(0)}
            </td>
          )}
          {!editMode && (
            <td
              className={remainingBudgetClass}
              style={{ textAlign: "right", color: spentPercentageColor }}
            >
              {currencySymbol}
              {Number(budget).toFixed(0) || ""}
            </td>
          )}
          {editMode && (
            <td style={{ textAlign: "right" }}>
              <div className="dontdisplay">
                {deletingCategory === category ? (
                  <>
                    <div className="deletebuttoncontainer">
                      <button
                        className="deletecategorybutton2"
                        onClick={() => onDeleteConfirmClick(category)}
                      >
                        Confirm
                        <br></br>
                        Delete
                      </button>
                    </div>
                  </>
                ) : (
                  <div className="deletebuttoncontainer">
                    <button
                      className="deletecategorybutton"
                      onClick={() => {
                        onDeleteButtonClick(category);
                        setShowDeleteConfirmAlert(true);
                      }}
                    >
                      Delete
                    </button>
                  </div>
                )}
              </div>
            </td>
          )}
        </tr>
        {renderBudgetDetails && (
  <tr ref={selectedRowRef}>
    <td style={{padding: "0"}}colSpan={4}>
      <BudgetDetails
      handleChangePurchaseIcon={handleChangePurchaseIcon}
      handleEditEmojiClick={handleEditEmojiClick}
      setIsLoading={setIsLoading}
      isLoading={isLoading}
      currencySymbol={currencySymbol}
        selectedRow={selectedRow}
        setShowInputWidget={setShowInputWidget}
        purchases={purchases}
        setShowBudgetDetails={setShowBudgetDetails}
        category={selectedRow} // Pass the selectedRow as the category prop
        selectedRowRef={selectedRowRef} // Pass the selectedRowRef as a prop
      />
    </td>
  </tr>
)}
        </React.Fragment>
       );
      })}
    </tbody>}
</table>

                    {editMode && !showIncomeBudgetForm && (
                        <button
                            className="showcategorytipsbutton"
                            onClick={handleCategoryTips}
                        >
                            What makes a good category?
                        </button>
                    )}
                </div>
            )}
            {!editMode && !showExpenseReminders && sortedBudgets.length > 0 && (
                <div className="budgetpageheader2">
                    <div className="budgetpageheader2">
                    <div
    style={{
        lineHeight: "1.2rem",
        textAlign: "left",
        float: "left",
    }}
    className={`datecounter ${getDateCounterClassName()}`}
>
    {daysLeftInMonth() < 5 && (
        <div 
            className="infocircle"
            onClick={toggleDateInfoModal}
        >
            <FaQuestionCircle />
        </div>
    )}
    {`${daysLeftInMonth()} ${daysText}`}
</div>


</div>


{" "}
                    <button
                        style={{ position: "absolute", right: "10px", top: "8px" }}
                        className={`editbutton ${
                            editMode
                                ? "editbutton-edit-mode"
                                : "editbutton-normal-mode"
                        }`}
                        onClick={() => {
                            setEditMode(!editMode);
                            setDeletingCategory(null);
                            setShowDeleteConfirmAlert(false);
                            setShowIncomeBudgetForm(false);
                            handleHideInputWidget();
                        }}
                    >
                        {editMode ? "Done Editing" : "Edit Categories"}
                    </button>
                </div>
            )}
{!editMode && !showExpenseReminders && <SavingsGoals  emojiCategories={emojiCategories} sortedBudgets={sortedBudgets} currencySymbol={currencySymbol} setShowInputWidget={setShowInputWidget} containerRef={containerRef} triggerRefetch={triggerRefetch} setTriggerRefetch={setTriggerRefetch}/>
}


            {!showExpenseReminders && !editMode && (
                <div className="budgetlogo">
                    <img
                        className="budgetlogoimg"
                        src={budgetlogo}
                        alt="Budgetlogo"
                        onMouseDown={handleHiddenButtonPress} 
                        onMouseUp={handleHiddenButtonRelease}
                        onTouchStart={handleHiddenButtonPress} 
                        onTouchEnd={handleHiddenButtonRelease}
                        
                    />
                    
                </div>
                
            )}
{/* {!showExpenseReminders &&
<button
                                    className="confirmation-no"
                                    onClick={() => {handleShowExpenseReminders(true); setShowIncomeBudgetForm(true)}}
                                >
                                    Show Expense Reminders
                                </button>} */}
      
      
                
            
       
        </div>
        </>
    )}
  </>
);
    
}

export default BudgetPage;
