import React, { useRef } from 'react';
import './Onboarding.css';
import { FcStatistics, FcOk, FcSalesPerformance, FcMoneyTransfer, FcConferenceCall, FcApproval } from "react-icons/fc";
import { RxDoubleArrowDown } from "react-icons/rx";
import { IoIosClose } from "react-icons/io";
import discretionary from "./images/discretionary2.svg";
import fixedexpenses from "./images/fixedexpenses2.svg";
import enterincome from "./images/enterincome.png";
import entercategories from "./images/entercategories.PNG";
import addpurchase from "./images/addpurchase.PNG";
import addpurchasewidget from "./images/addpurchasewidget.PNG";
import addpaymentwidget from "./images/addpaymentwidget.PNG";
import setupincome from "./images/setupincome.PNG";
import lillogo from "./images/lillogo.svg";

const HowDoILilbudget = (props) => {

  const handleDismissValueProp = () => {
    props.onClose();
    props.setShowNavTutorial(true);
  };


 

  const section2Ref = useRef();
  const section3Ref = useRef();
  const section4Ref = useRef();
  const section5Ref = useRef();
  const section6Ref = useRef();
  const section7Ref = useRef();
  const section8Ref = useRef();
  const section9Ref = useRef();

  
  const handleScrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="scroll-container">
      <div className="value-card-container">
        <section className="scroll-section section1">
          <div className="fadeindivs section5">
          <button onClick={handleDismissValueProp} className='closevaluepropanytimecont'><IoIosClose /></button>
            <div><img className="tutorialimage" src={lillogo} alt="lillogo" style={{width: "60px", transform: "rotate(270deg)"}} />
            <h1 className="valuepropheader1">Welcome to lilBudget</h1>
            <h1 className="valuepropheader1">Fret not, because setting up your budget is easy.</h1>
            
              <h1 className="valuepropheader1">lilBudget works by separating your monthly expenses into two types: </h1>
              
              

              
              {/* <p className="valuepropemoji"><FcMoneyTransfer /></p> */}

             
              
              
              
             
            </div>
            <div>
        
            </div>
          </div>
          <p className="valuepropmore" onClick={() => handleScrollToSection(section2Ref)}>
            <RxDoubleArrowDown />
          </p>
        </section>

        <section ref={section2Ref} className="scroll-section section1">
          <div className="fadeindivs section5">
          <button onClick={handleDismissValueProp} className='closevaluepropanytimecont'><IoIosClose /></button>
          <div style={{height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-evenly"}}>
                             <h1 className="tutorialheader">Fixed Expenses</h1>
               <div className="fixedexpensescontainer"><img className="fixedexpenses" src={fixedexpenses} alt="fixedexpenses" /></div> 
              <p className="tutorialtext1">Fixed expenses are usually the same amount every month, like a loan payment or subscription. Examples are your rent or mortgage, most types of debt (auto/student/personal), streaming services, or anything that you put on autopay.</p>
              
            </div>
            <div>
             
            </div>
          </div>
          <p className="valuepropmore" onClick={() => handleScrollToSection(section3Ref)}>
            <RxDoubleArrowDown />
          </p>
        </section>

        <section ref={section3Ref} className="scroll-section section1">
          <div className="fadeindivs section5">
          <button onClick={handleDismissValueProp} className='closevaluepropanytimecont'><IoIosClose /></button>
          <div style={{height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-evenly"}}>
                         <h1 className="tutorialheader">Discretionary Expenses</h1>
              <div className="discretionaryexpensescontainer"><img className="discretionary" src={discretionary} alt="discretionaryexpenses" /></div>
              <p className="tutorialtext1">Discretionary expenses are the rest of your transactions that occur throughout the month. These could be things like food, entertainment, clothes or anything that you buy frequently enough to want to keep tabs on.</p>
              
            </div>
            <div>
             
            </div>
          </div>
          <p className="valuepropmore" onClick={() => handleScrollToSection(section4Ref)}>
            <RxDoubleArrowDown />
          </p>
        </section>

        <section ref={section4Ref} className="scroll-section section1">
          <div className="fadeindivs section2">
          <button onClick={handleDismissValueProp} className='closevaluepropanytimecont'><IoIosClose /></button>
          <div style={{height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-evenly", alignItems: "center"}}>
              <h1 className="tutorialheader">Step 1: Entering Income and Fixed Expenses</h1>
              <p className="tutorialtext1">Start by entering your monthly income and fixed expenses into the <strong>Income Worksheet</strong> using this button on your home screen:</p>
              <button
            className="emptystatebutton">
            Set up your income and create your spending categories
             </button>
              <p className="tutorialtext1">Once there, enter your information. We will substract your total expenses from your monthly income to get your <strong>discretionary income.</strong> </p>
              <p className="tutorialtext1"><strong>This is the amount we will use to set up your categories for discretionary spending.</strong></p>
              
              <p className="tutorialtext1">You can always edit this form later if your expenses or income change by clicking <strong style={{color: "#274b5a", textDecoration: "underline" }}>Manage Income & Expenses</strong> when editing your budget categories.</p>
              
            </div>
            <div>
             
            </div>
          </div>
          <p className="valuepropmore" onClick={() => handleScrollToSection(section5Ref)}>
            <RxDoubleArrowDown />
          </p>
        </section>

        <section ref={section5Ref} className="scroll-section section1">
          <div className="fadeindivs section2">
          <button onClick={handleDismissValueProp} className='closevaluepropanytimecont'><IoIosClose /></button>
          <div style={{height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-evenly"}}>
            <p className="tutorialtext1">Your income worksheet might look something like this:<br></br> </p>
              <img className="tutorialimage" src={enterincome} alt="enteringyourincome" />
              <p className="tutorialtext1">When you're done, save and close the income worksheet.</p>
             
              
              
              
            </div>
            <div>
             
            </div>
          </div>
          <p className="valuepropmore" onClick={() => handleScrollToSection(section6Ref)}>
            <RxDoubleArrowDown />
          </p>
        </section>

        <section ref={section6Ref} className="scroll-section section1">
          <div className="fadeindivs section4">
          <button onClick={handleDismissValueProp} className='closevaluepropanytimecont'><IoIosClose /></button>
          <div style={{height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-evenly"}}>
              <h1 className="tutorialheader">Step 2: Budget Categories</h1>
              
              
              <p className="tutorialtext1">With your income figured out, you can start setting up allowances for each category:</p>
              <img className="tutorialimage" src={entercategories} alt="enteringyourcategories" />
              <p className="tutorialtext1">Create a category and assign a monthly allowance to it from your discretionary income.</p>
              {/* <p className="tutorialtext1">You can categorize your spending however you want, but don't be so specific that you end up having to split receipts for your purchases. It's okay to have a 'Miscellaneous' category. (But it's also good to track how much you're spending on "miscellaneous" stuff!</p> */}
              <p className="tutorialtext1">You don't need to assign all of your discretionary income to your budget. You can use anything you have leftover to set up savings goals.</p>
              
            </div>
            <div>
            
            </div>
          </div>
          <p className="valuepropmore" onClick={() => handleScrollToSection(section7Ref)}>
            <RxDoubleArrowDown />
          </p>
        </section>

        <section ref={section7Ref} className="scroll-section section1">
          <div className="fadeindivs section3">
          <button onClick={handleDismissValueProp} className='closevaluepropanytimecont'><IoIosClose /></button>
          <div style={{height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-evenly"}}>
              <h1 className="tutorialheader">Adding Purchases</h1>
              
              
              <p className="tutorialtext1">Once your budget categories are created, you can start adding purchases!</p>
              
              <p className="tutorialtext1">Whenever you make a purchase, file it under one of your categories and enter the amount.</p>
              <img className="tutorialimage" src={addpurchasewidget} alt="addapurchasewidget" />
              <p className="tutorialtext1">Your purchase will get added to your transactions, where you can add a note, change it's icon, add it to a group, or review it for 'worthiness'.</p>
              
              <img className="tutorialimage" src={addpurchase} alt="addapurchase" />
            </div>
            <div>
             
            </div>
          </div>
          <p className="valuepropmore" onClick={() => handleScrollToSection(section8Ref)}>
            <RxDoubleArrowDown />
          </p>
        </section>

        
        <section ref={section8Ref} className="scroll-section section1">
          <div className="fadeindivs section3">
          <button onClick={handleDismissValueProp} className='closevaluepropanytimecont'><IoIosClose /></button>
          <div style={{height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-evenly"}}>
              <h1 className="tutorialheader">Refunds and Credits </h1>
              
              <p className="tutorialtext1">Refunds and transactions where you add funds to a category are handled in the same form used to add a purchase.</p>
             
              <img className="tutorialimage" src={addpaymentwidget} alt="addapurchasewidget" />
      
              <p className="tutorialtext1">To post a credit to a category, first hold down the 'Add Purchase' button. It'll change to 'Add Refund', and allow you to post the transaction as a credit to the chosen category.</p>
              <p className="tutorialtext1">Note that this action can make some of your charts look wonky if a category's allowance is less than its 'spent' amount.</p>
                         </div>
            <div>
             
            </div>
          </div>
          <p className="valuepropmore" onClick={() => handleScrollToSection(section9Ref)}>
            <RxDoubleArrowDown />
          </p>
        </section>

        <section ref={section9Ref} className="scroll-section section1">
          <div className="fadeindivs section6">
            <div >
            <button onClick={handleDismissValueProp} className='closevaluepropanytimecont'><IoIosClose /></button>
              <h1 className="valuepropheader1">That's it!</h1>
              
              <p className="valuepropheader4">There's plenty more to discover, but that'll get you started on tracking your budget.<br></br><br></br>Don't forget to check out your account profile and explore a bit!</p>
            </div> <img className="tutorialimage" src={lillogo} alt="lillogo" style={{width: "60px", transform: "rotate(270deg)"}} />
            <div className='valueclosediv'>
           
              <p className="valuepropheader3">If you run into any issues, you can always send a message to lilBudget by visiting 'Support'</p>
              <div className="valueclosebuttoncontainer">
                <button onClick={handleDismissValueProp} className='closevaluepropcont'>Okay, I'm ready!</button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default HowDoILilbudget;