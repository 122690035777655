import React, { useRef } from 'react';
import coinstack from './images/coinstack.svg';
import './Onboarding.css';
import { FcStatistics, FcOk, FcSalesPerformance, FcMoneyTransfer, FcConferenceCall, FcApproval } from "react-icons/fc";
import { RxDoubleArrowDown } from "react-icons/rx";

const ValueProp = (props) => {

  const handleDismissValueProp = () => {
    props.onClose();
  };

  const section2Ref = useRef();
  const section3Ref = useRef();
  const section4Ref = useRef();
  const section5Ref = useRef();
  
  const handleScrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="scroll-container">
      <div className="value-card-container">
        <section className="scroll-section section1">
          <div className="fadeindivs section5">
            <div>
              <h1 className="valuepropheader1">lilBudget makes the most of your money.</h1>
              <p className="valuepropemoji"><FcMoneyTransfer /></p>
              <p className="valuepropheader4">lilBudget helps you keep an eye on every penny, from income to savings and everything in-between.</p>
            </div>
            <div>
              <div className="featurecontainer">
                <div className="featurecheckmark"><FcOk /></div>
                <div className="featuretitle">Budget Tracking</div>
              </div>
            </div>
          </div>
          <p className="valuepropmore" onClick={() => handleScrollToSection(section2Ref)}>
            <RxDoubleArrowDown />
          </p>
        </section>

        <section ref={section2Ref} className="scroll-section section1">
          <div className="fadeindivs section2">
            <div>
              <h1 className="valuepropheader1">lilBudget keeps your eyes on the prize.</h1>
              <p className="valuepropemoji"><FcSalesPerformance /></p>
              <p className="valuepropheader4">It's easy to forget about your long-term goals in the day-to-day hustle and bustle. We put your ambitions front and center to help you fund your dreams.</p>
            </div>
            <div>
              <div className="featurecontainer">
                <div className="featurecheckmark"><FcOk /></div>
                <div className="featuretitle">Saving Goals</div>
              </div>
            </div>
          </div>
          <p className="valuepropmore" onClick={() => handleScrollToSection(section3Ref)}>
            <RxDoubleArrowDown />
          </p>
        </section>

        <section ref={section3Ref} className="scroll-section section1">
          <div className="fadeindivs section4">
            <div>
              <h1 className="valuepropheader1">lilBudget simplifies buying the next round.</h1>
              <p className="valuepropemoji"><FcConferenceCall /></p>
              <p className="valuepropheader4">When you share expenses with a friend, partner or roomate, things can get complicated fast. We take the guesswork out of managing who owes who.</p>
            </div>
            <div>
              <div className="featurecontainer">
                <div className="featurecheckmark"><FcOk /></div>
                <div className="featuretitle">Squareup Groups</div>
              </div>
            </div>
          </div>
          <p className="valuepropmore" onClick={() => handleScrollToSection(section4Ref)}>
            <RxDoubleArrowDown />
          </p>
        </section>

        <section ref={section4Ref} className="scroll-section section1">
          <div className="fadeindivs section3">
            <div>
              <h1 className="valuepropheader1">lilBudget knows data is beautiful.</h1>
              <p className="valuepropemoji"><FcStatistics /></p>
              <p className="valuepropheader4">Visualizing your finances can give you all kinds of helpful insights. We went hard with the charts so you can see your spending habits every which way.</p>
            </div>
            <div>
              <div className="featurecontainer">
                <div className="featurecheckmark"><FcOk /></div>
                <div className="featuretitle">Data Insights</div>
              </div>
            </div>
          </div>
          <p className="valuepropmore" onClick={() => handleScrollToSection(section5Ref)}>
            <RxDoubleArrowDown />
          </p>
        </section>

        <section ref={section5Ref} className="scroll-section section1">
          <div className="fadeindivs section6">
            <div>
              <h1 className="valuepropheader1">lilBudget makes it fun.</h1>
              <p className="valuepropemoji4"><FcApproval /></p>
              <p className="valuepropheader4">Accounting doesn't have to be dry. Win achievements, be a raccoon, and get into a heated group discussion over the worthiness of a $50 pizza. We'll worry about getting the math right.</p>
            </div>
            <div className='valueclosediv'>
              <p className="valuepropheader6">Also, lilBudget is<strong> totally free.</strong></p>
              <p className="valuepropheader3">Try it out and see why people love lilBudget.</p>
              <div className="valueclosebuttoncontainer">
              <button onClick={handleDismissValueProp} className='closevaluepropcont'>Back to Login</button>
                
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default ValueProp;
