import React, { useState, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';




const getRandomColor = () => {
    const colors = ['#D9CAC0', '#BAA8A6', '#9C918C', '#C8BFA3', '#27292c', '#2f445e', '#6487a3', '#6f4f30', '#445a72', '#847772', '#343e3d', '#607466', '#aedcc0', '#7bd389', '#38e4ae', '#faf9f4', '#d88600', '#c8aecf', '#a08bbe', '#554271', '#000535', '#cce0e2', '#986c2c', '#b08b5a', '#6a2924', '#433013', '#cc964f', '#c35033', '#e4cc9e', '#9f1920', '#bb321f', '#15090b', '#ad1c01', '#ddc061', '#964B00', '#CC7722', '#5D3954', '#4B5320'];
  
    return colors[Math.floor(Math.random() * colors.length)];
  };

  
//   function hexToRgb(hex) {
//     let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
//     return result ? {
//         r: parseInt(result[1], 16),
//         g: parseInt(result[2], 16),
//         b: parseInt(result[3], 16)
//     } : null;
// }

// function colorDistance(color1, color2) {
//     let rgb1 = hexToRgb(color1);
//     let rgb2 = hexToRgb(color2);

//     let rmean = (rgb1.r + rgb2.r) / 2;
//     let r = rgb1.r - rgb2.r;
//     let g = rgb1.g - rgb2.g;
//     let b = rgb1.b - rgb2.b;
//     let weightR = 2 + rmean / 256;
//     let weightG = 4.0;
//     let weightB = 2 + (255 - rmean) / 256;
//     return Math.sqrt(weightR * r * r + weightG * g * g + weightB * b * b);
// }

// const threshold = 50; // color difference threshold

// let colors = [
//       "#D9CAC0", "#BAA8A6", "#B4A6AC", "#D4D2C2", "#9C918C",
//       "#BEB7A4", "#C0B1A8", "#B7A99A", "#BDB1A8", "#A8A8A4",
//       "#E0CDA9", "#C8BFA3", "#E5D3B3", "#C3B1A5", "#D1C0A3",
//       "#C7B29B", "#D3C5B2", "#BDA798", "#CABFA9", "#AFA6A4",
//       "#27292c", "#2f445e", "#6487a3", "#d1bea8", "#6f4f30", "#2d2832", "#445a72", "#708090", "#847772", "#dbb393",
//       "#343e3d", "#607466", "#aedcc0", "#7bd389", "#38e4ae", "#8a9090", "#cbcbc2", "#faf9f4", "#d88600", "#eecda9",
//       "#c8aecf", "#a08bbe", "#554271", "#000535", "#435255", "#869c9b", "#cce0e2", "#aebea6", "#edf4ef", "#986c2c",
//       "#b08b5a", "#6a2924", "#25201b", "#433013", "#1e1a17", "#cecac6", "#cc964f", "#c35033", "#a1968a", "#d6d1c6",
//       "#928f81", "#a6b0a2", "#75836d", "#34372b", "#bea591", "#e4cc9e", "#9f1920", "#aa6d37", "#352521", "#bb321f",
//       "#15090b", "#9f9795", "#695f5e", "#ad1c01", "#153138", "#0a2922", "#ddc061","#964B00", "#CC7722", "#654321", "#5D3954", "#734A12", "#4B5320", "#3B331C", "#1B4D3E", "#592804", "#1B4D3E"
// ];

// let uniqueColors = [];

// for (let color of colors) {
//     if (uniqueColors.every(uniqueColor => colorDistance(uniqueColor, color) > threshold)) {
//         uniqueColors.push(color);
//     }
// }

// console.log(uniqueColors);




const PaydayReviewDonut = ({ includeExpenses, currencySymbol, expenses, categorySpendings, savings }) => {
  const [colors, setColors] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  

  useEffect(() => {
    let newTotalAmount = 0;
  
    // Calculate total expenses
    let totalExpenses = 0;
    if (expenses) {
      for (let expense in expenses) {
        totalExpenses += parseFloat(expenses[expense]);
      }
    }
    // Always add expenses to the total
    newTotalAmount += totalExpenses;
  
    // Calculate total category spending
    for (let category in categorySpendings) {
      newTotalAmount += parseFloat(categorySpendings[category]);
    }
  
    // Add savings to the total only if it is not a negative number and if includeExpenses is true
    if (savings >= 0 && includeExpenses) {
      newTotalAmount += parseFloat(savings);
    }
  
    setTotalAmount(newTotalAmount);
  }, [expenses, categorySpendings, includeExpenses, savings]);


  useEffect(() => {
    const expenseColors = [];
    const categoryColors = [];
    const newColors = [];
  
    // Generate a color for each category
    for (let category in categorySpendings) {
      categoryColors.push(getRandomColor());
    }
  
    if (savings >= 0) {
      newColors.push('rgb(29, 56, 53)');
    }
  
    if (!includeExpenses) {
      // If expenses are not included, add a color for total expenses at the start
      expenseColors.push(getRandomColor());
      // Concatenate the total expenses color, category colors, and savings colors
      setColors([...expenseColors, ...categoryColors, ...newColors]);
    } else {
      // If expenses are included, add a color for each expense
      for (let expense in expenses) {
        expenseColors.push(getRandomColor());
      }
      // Concatenate the expense colors, category colors, and savings colors
      setColors([...expenseColors, ...categoryColors, ...newColors]);
    }
  }, [expenses, categorySpendings, includeExpenses, savings]);
  
  const getChartData = () => {
    let labels = [];
    let data = [];
    let backgroundColors = [];

  
    if (includeExpenses) {
      // Add expenses to the chart
      for (let expense in expenses) {
        labels.push(expense);
        data.push(parseFloat(expenses[expense]));
        backgroundColors.push(colors[labels.length - 1]);
      }
    } else {
      // Calculate total expenses and add to the chart
      let totalExpenses = 0;
      for (let expense in expenses) {
        totalExpenses += parseFloat(expenses[expense]);
      }
  
      labels.push("Total Expenses");
      data.push(totalExpenses);
      backgroundColors.push(colors[0]); 
    }
  
    // Add categories to the chart
    for (let category in categorySpendings) {
      labels.push(category);
      data.push(parseFloat(categorySpendings[category]));
      backgroundColors.push(colors[labels.length - 1]);

    }
  
    if (includeExpenses && savings >= 0) {
      // Add savings to the chart only if it is not a negative number
      labels.push('Unspent Income');
      data.push(parseFloat(savings));
      backgroundColors.push(colors[colors.length - 1]);
   
    }

    
    
    return {
        labels: labels,
        datasets: [
          {
            data: data,
            backgroundColor: backgroundColors,
            borderWidth: 0, // Remove the borders
          },
        ],
        
      };
    };
    const options = {
        maintainAspectRatio: false,
        cutout: 60,
        plugins: {
          legend: {
            display: true,
            position: 'bottom',
            align: 'start',
            labels: {
              usePointStyle: true,
              font: {
                size: 10,
                family: "Quicksand",
                weight: "600",
              },
              padding: 30,
              pointStyle: 'circle',
              generateLabels: (chart) => {
                const { data } = chart;
                if (data.labels.length && data.datasets.length) {
                  return data.labels.map((label, index) => {
                    const dataset = data.datasets[0];
                    const categoryAmount = Number(dataset.data[index]);
                    const categoryPercentage = totalAmount ? Number(((categoryAmount / totalAmount) * 100).toFixed(0)) : 0;
                    const formattedLabel = `${categoryPercentage}% ${label} (${currencySymbol}${categoryAmount.toFixed(0)})`;
                    return {
                      text: formattedLabel,
                      fillStyle: dataset.backgroundColor[index],
                      hidden: !chart.getDataVisibility(index),
                      index: index,
                    };
                  });
                }
                return [];
              },
              
            },
          },
          overrides: {
            doughnut: {
              plugins: {
                legend: {
                  color: 'red', // Set the font color of the legend labels for doughnut chart
                },
              },
            },
          },
        },
      };


 
 
       
      
    
   
  
  return (<div className="donutcanvas">
    <Doughnut className="donutchart" data={getChartData()} options={options} /></div>
  );
};

export default PaydayReviewDonut; 
