import React from "react";
import "./NumberPad.css";

const NumberPad = ({ handleNumberPress }) => {
  const numbers = [1, 4, 7, ".", 2, 5, 8, 0, 3, 6, 9, "Del"];

  return (
    <div className="custom-number-pad">
  <div className="number-row">
    {numbers.slice(0, 4).map((number, index) => (
      <button
        key={index}
        className="number-button"
        onClick={() => handleNumberPress(number)}
      >
        {number}
      </button>
    ))}
  </div>
  <div className="number-row">
    {numbers.slice(4, 8).map((number, index) => (
      <button
        key={index}
        className="number-button"
        onClick={() => handleNumberPress(number)}
      >
        {number}
      </button>
    ))}
  </div>
  <div className="number-row">
    {numbers.slice(8, 12).map((number, index) => (
      <button
        key={index}
        className="number-button"
        onClick={() => handleNumberPress(number)}
      >
        {number}
      </button>
    ))}
  </div>
</div>

  );
};

export default NumberPad;
