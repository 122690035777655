import React, { useState, useEffect, useRef } from "react";
import "./IncomeBudgetForm.css";

import firebase from "firebase/compat/app";
import cebits2 from "./images/cebits2.svg";
import "firebase/compat/database";
import "firebase/compat/storage";
import "firebase/compat/firestore";

const IncomeBudgetForm = ({
    handleCloseIncomeBudgetForm,
    currencySymbol,
    updateDiscretionaryIncome,
    setShowUpdateMessage,
    showExpenseReminders,
    setShowExpenseReminders,
    setShowIncomeBudgetForm,
    setShowInputWidget,
    setEditMode, 
    expenses,
    setExpenses,
    handleUnhideInputWidget,
    sortedBudgets

}) => {
    const userId = firebase.auth().currentUser.uid;

    // Define state variables to hold form data
    const [income, setIncome] = useState("");
    const [payFrequency, setPayFrequency] = useState("");
    
    const [savedData, setSavedData] = useState(null);
    const [changesMade, setChangesMade] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const incomeContainerRef = useRef(null);
    

    const handleClickOutside = (event) => {
        if (
          incomeContainerRef.current &&
          !incomeContainerRef.current.contains(event.target)
        ) {
            setShowExpenseReminders(false);
            setShowIncomeBudgetForm(false);
            setShowInputWidget(true);
            handleUnhideInputWidget();
            setEditMode(false);
         
        }
      };

      useEffect(() => {
        const handleMouseDown = (event) => {
          handleClickOutside(event);
        };
      
        document.addEventListener("mousedown", handleMouseDown);
      
        return () => {
          document.removeEventListener("mousedown", handleMouseDown);
        };
      }, []);

  

    // Fetch saved data from Firebase when component mounts
    useEffect(() => {
        const ref = firebase.database().ref(`users/${userId}`);
        ref.once("value", (snapshot) => {
            const data = snapshot.val();
            if (data) {
                setIncome(data.income || 0);
                setExpenses(data.expenses || [{ name: "", amount: "" }]);
            }
        });
    }, [userId]);

    const deleteExpenseRow = (index, event) => {
        event.preventDefault();
        if (!firebase.auth().currentUser) return;
        const newExpenses = [...expenses];
        newExpenses.splice(index, 1);
        setExpenses(newExpenses);
        setChangesMade(true);
    };

    // Define a function to update the expenses state variable when the user adds a new row
    const addExpenseRow = () => {
        setExpenses([...expenses, { name: "", amount: "" }]);
        setChangesMade(true);
    };

    // Define a function to update an individual expense object in the expenses array
    const updateExpense = (index, field, value) => {
        const newExpenses = [...expenses];
        newExpenses[index][field] = value;
        setExpenses(newExpenses);
        setChangesMade(true);
    };

    // Define a function to calculate the user's remaining income after subtracting expenses
    const calculateRemainingIncome = () => {
        const totalExpenses = expenses.reduce(
            (acc, expense) => acc + parseFloat(expense.amount),
            0
        );
        const remainingIncome = parseFloat(income) - totalExpenses;
        updateDiscretionaryIncome(remainingIncome); // Call the callback function to update the state in the parent component
        return remainingIncome.toFixed(0);
    };

    // Define a function to save the data to Firebase
    const saveDataToFirebase = () => {
        firebase
            .database()
            .ref(`users/${userId}/income`)
            .set(income)
            .then(() => {
                firebase
                    .database()
                    .ref(`users/${userId}/expenses`)
                    .set(expenses)
                    .then(() => {
                        setSavedData({
                            income: income,
                            expenses: expenses,
                        });
                        setChangesMade(false);
                    })
                    .catch((error) => console.error(error));
            })
            .catch((error) => console.error(error));
    };

    // Define a function to reset the form to the saved data
    const resetForm = () => {
        setIncome(savedData.income);
        setExpenses(savedData.expenses);
    };

    // Define a function to handle saving data to the savedData state variable
    const handleSaveData = () => {
        setIsUpdating(true);
        setSavedData({
            income: income,
            expenses: expenses,
        });
        saveDataToFirebase();
        setShowUpdateMessage(true); // Set showUpdateMessage to true
        setTimeout(() => {
            const messageElement = document.querySelector('.confirm-message'); // Replace 'your-message-class' with the actual class of the element you want to add the class to
            if (messageElement) {
              messageElement.classList.add('slideoutmessage');
            }
            setTimeout(() => {
              setShowUpdateMessage(false);
            }, 1000); // Set the duration in milliseconds (4 seconds = 4000 milliseconds)
          }, 3000);
    };

    return ( <div>
        <div  className="incomebudgetformcontainer">
           {!showExpenseReminders && <div className="incomebudgetformcomponentcontainer"><img className="cebits" src={cebits2} alt="cebits" />
            <h2 className="calculateyourbudget">Income Worksheet</h2>

            <form>
                <div className="incomeheader">
                    1. Enter your take home monthly income:
                </div>
                <div
                    style={{ paddingBottom: "10px" }}
                    className="incomeheader2"
                >
                    All income and paychecks after taxes & deductions.
                </div>
                <div className="currencysymbol1">
                    {currencySymbol}
                    <input
                        className="incomeinput"
                        type="number"
                        value={income}
                        onChange={(e) => setIncome(parseFloat(e.target.value))}
                    />
                </div>
                <div className="incomeheader">
                    2. Enter your fixed monthly expenses:
                </div>
                <div className="incomeheader2">
                    Examples of fixed expenses include rent/mortgage, car
                    payments, phone bills, student loans, streaming
                    subscriptions, and anything you have on autopay.<br></br>
                    <strong>If you split an expense with someone else, only enter your portion of it.</strong>
                </div>
                {/* <div className="incomesavingstip">This is a great place to add a savings goal!</div> */}
                <table
                    style={{
                        paddingRight: "10px",
                        boxSizing: "border-box",
                        border: "none",
                    }}
                >
                    <thead>
                        <tr style={{ height: "30px" }}>
                            <th
                                style={{ borderBottom: "none" }}
                                className="expensename"
                            >
                                Monthly Expense Name
                            </th>
                            <th
                                style={{ borderBottom: "none" }}
                                className="expenseamount"
                            >
                                Amount
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {expenses.map((expense, index) => (
                            <tr
                                className="expenserow"
                                key={index}
                                style={{ backgroundColor: "EBEBEB" }}
                            >
                                <td
                                    className="expense1"
                                    style={{
                                        borderRadius: "0px",
                                        borderBottom: "none",
                                    }}
                                    onChange={(e) =>
                                        updateExpense(
                                            index,
                                            "name",
                                            e.target.value
                                        )
                                    }
                                    onKeyPress={(e) => {
                                        if (e.key === "Enter") {
                                            e.preventDefault();
                                        }
                                    }}
                                >
                                    <input
                                        className="expenseinput1"
                                        type="text"
                                        value={expense.name}
                                        onChange={(e) =>
                                            updateExpense(
                                                index,
                                                "name",
                                                e.target.value
                                            )
                                        }
                                        placeholder="Expense Name"
                                    />
                                </td>
                                <td
                                    className="expense2"
                                    style={{
                                        borderRadius: "0px",
                                        textAlign: "left",
                                        borderBottom: "none",
                                    }}
                                >
                                    <div className="currencysymbol2">
                                        {currencySymbol}
                                        <input
                                            className="expenseinput2"
                                            type="number"
                                            value={expense.amount}
                                            onChange={(e) =>
                                                updateExpense(
                                                    index,
                                                    "amount",
                                                    e.target.value
                                                )
                                            }
                                            placeholder="amt"
                                            onKeyPress={(e) => {
                                                if (e.key === "Enter") {
                                                    e.preventDefault();
                                                }
                                            }}
                                        />
                                    </div>
                                </td>
                                {expenses.length > 1 && (
                                    <td
                                        style={{ borderBottom: "none" }}
                                        className="deletebutton"
                                    >
                                        <button
                                            className="deleteexpensebutton"
                                            onClick={(event) =>
                                                deleteExpenseRow(index, event)
                                            }
                                        >
                                            X
                                        </button>
                                    </td>
                                )}
                                {expenses.length === 1 && (
                                    <td
                                        className="expense2"
                                        style={{ borderBottom: "none" }}
                                    ></td>
                                )}
                            </tr>
                        ))}
                        <tr className="expenserow">
                            <td
                                className="expense2"
                                style={{
                                    fontWeight: "bold",
                                    textAlign: "left",
                                    borderBottom: "none",
                                }}
                            >
                                <button
                                    className="expensebutton"
                                    type="button"
                                    onClick={addExpenseRow}
                                >
                                    Add Another Expense
                                </button>
                            </td>
                            <td
                                className="expense3"
                                style={{
                                    fontWeight: "bold",
                                    textAlign: "center",
                                    paddingLeft: "20px",
                                    borderBottom: "none",
                                }}
                            >
                            <div className="currencysymbol2">
    {currencySymbol}
    {Math.round(
        expenses.reduce(
            (total, expense) => total + parseFloat(expense.amount || 0),
            0
        )
    )}
</div>
                            </td>

                            <td
                                style={{
                                    textAlign: "left",
                                    fontSize: "0.7rem",
                                    borderBottom: "none",
                                }}
                                className="expense2"
                            >
                                total expenses
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>

            <h2 className="incomeheader">
                {isNaN(calculateRemainingIncome()) ? (
                    <p
                        style={{
                            fontWeight: "700",
                            margin: "0px",
                            color: "#852626",
                        }}
                    >
                        All amounts must have a number!
                        
                    </p>
                ) : (
                    `3. Your monthly discretionary income: ${currencySymbol}${calculateRemainingIncome()}`
                )}
                 {isNaN(calculateRemainingIncome()) ? (
                    <div></div>
                 ) : (
                    <p
                    style={{ margin: "2px 0px 0px 0px" }}
                    className="incomeheader2"
                >
                    What's left after paying your fixed expenses. <br></br>This is how much you have available for 
                    day-to-day purchases, like food and merchandise. 
                    
                </p>
                )}
            </h2>
            <div className="incomebudgetform-buttoncontainer">

                
            {sortedBudgets.length === 0 && <button
                className="expensebutton2"
                disabled={isNaN(calculateRemainingIncome())}
                onClick={() => {
                    handleSaveData();
                    handleCloseIncomeBudgetForm();
                }}
            >
                Next: Set Up Spending Categories
            </button>}


            {sortedBudgets.length === 0 && 
            <button className="expensebutton4" disabled={isNaN(calculateRemainingIncome())}
                onClick={() => {
                    setEditMode(false);
                    setShowIncomeBudgetForm(false);
                    handleUnhideInputWidget();
                    setShowInputWidget(true);
                    handleSaveData();
                    handleCloseIncomeBudgetForm();
                }}>
                    Save & Close Worksheet
                    </button>}


                    {sortedBudgets.length > 0 && 
            <button className="expensebutton2" disabled={isNaN(calculateRemainingIncome())}
                onClick={() => {
                    setEditMode(false);
                    setShowIncomeBudgetForm(false);
                    handleUnhideInputWidget();
                    setShowInputWidget(true);
                    handleSaveData();
                    handleCloseIncomeBudgetForm();
                }}>
                    Save & Close Worksheet
                    </button>}

            {sortedBudgets.length > 0 && <button
                className="expensebutton4"
                disabled={isNaN(calculateRemainingIncome())}
                onClick={() => {
                    handleSaveData();
                    handleCloseIncomeBudgetForm();
                }}
            >
                Edit Categories
            </button>}




           
          
            <button
                className="expensebutton3"
                disabled={isNaN(calculateRemainingIncome())}
                onClick={() => {
                    handleCloseIncomeBudgetForm();
                    setEditMode(false);
                    setShowInputWidget(true);
                    handleUnhideInputWidget();
                }}
            >
                Close & Don't Save
            </button></div>
            
            
            
            </div>}

        </div>
       
            </div>
    );
};

export default IncomeBudgetForm;
