import firebase from "firebase/compat/app";
import "firebase/compat/database";
import "firebase/compat/storage";
import "firebase/compat/auth";
import { useGesture } from 'react-use-gesture';
import AddPurchase from "./AddPurchaseForm";
import { AiFillCaretDown } from "react-icons/ai";
import { CSSTransition } from "react-transition-group";
import React, { useState, useEffect, useRef } from "react";
import NumberPad from "./NumberPad";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: "budget-app-d568a.firebaseapp.com",
    databaseURL: "https://budget-app-d568a-default-rtdb.firebaseio.com",
    projectId: "budget-app-d568a",
    storageBucket: "budget-app-d568a.appspot.com",
    messagingSenderId: "219321405159",
    appId: "1:219321405159:web:c4a51c20c9d13376629c42",
    measurementId: "G-6P5Y0TGT7T"
  };
firebase.initializeApp(firebaseConfig);

const db = firebase.database();
const budgetRef = db.ref("budget");


function InputWidget({
    handleChangeCurrency,
    currencySymbol,
    setCurrencySymbol,
    handleShowAnimation,
    categories,
    setCategories,
    selectedCategory,
    setSelectedCategory,
    setTriggerRefetch,
}) {
  
    const [inputValue, setInputValue] = useState("");
    const [remainingBudgets, setRemainingBudgets] = useState("");
    const [currentDate, setCurrentDate] = useState(new Date());
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [isButtonAnimating, setIsButtonAnimating] = useState(false);
    const [buttonText, setButtonText] = useState("Add Purchase");
    const [showTransactionMessage, setShowTransactionMessage] = useState(false);
    const [currentMessage, setCurrentMessage] = useState("");
    const [isAddButtonClicked, setIsAddButtonClicked] = useState(false);
    const [showAddPurchase, setShowAddPurchase] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [purchaseAdded, setPurchaseAdded] = useState(false);
    const [showNumberPad, setShowNumberPad] = useState(false);
    const [isPayment, setIsPayment] = useState(false);
    const [isTransactionAdded, setIsTransactionAdded] = useState(false);

    const handleDateChange = (date) => {
        setSelectedDate(date);
        setShowAddPurchase(false);
    };

    const dollarInputRef = useRef(null);
    const pressTimer = useRef();

    function focusDollarInput() {
        setShowNumberPad(!showNumberPad);
      }

    //AN ARRAY OF "SUCCESS" MESSAGES THAT GET BRIEFLY DISPLAYED WHEN THE USER ADDS A PURCHASE
    const messages = [
        "Money has been siphoned!",
        "Your account is now poorer!",
        "You're basically broke now!",
        "Money successfully vanished!",
        "Another nail in the coffin!",
        "That's one less coffee!",
        "Money gone with the wind!",
        "Your bank balance is weeping!",
        "Cha-ching, but in reverse!",
        "Mission accomplished, poverty attained!",
    ];

    const buttonLabel = isPayment ? "Add Refund" : "Add Purchase";

    const handleButtonPress = () => {
        pressTimer.current = setTimeout(() => {
          setIsPayment(prevIsPayment => !prevIsPayment);
        //   console.log(`Button state changed to: ${isPayment ? "Add Payment" : "Add Purchase"}`);
        }, 1000);
      }
      
      const handleButtonRelease = () => {
        clearTimeout(pressTimer.current);
      }


    useEffect(() => {
        const currentUser = firebase.auth().currentUser;
        if (currentUser) {
            const userId = currentUser.uid;
            const userRef = firebase.database().ref(`users/${userId}`);
            userRef.once('value').then(function(snapshot) {
                const user = snapshot.val();
                if (user && user.currency) {
                    const userCurrency = user.currency;
                    setCurrencySymbol(userCurrency);
                } else {
                    // console.log(`No currency found for user ${userId}`);
                }
            });
        } else {
            // console.log("No current user");
        }
    }, []);

    //HOOK TO DISPLAY THE RANDOM TRANSACTION MESSAGE FROM THE ARRAY OF "SUCCESS" MESSAGES
    // useEffect(() => {
    //     if (!showTransactionMessage && !showConfirmation) {
    //         const randomIndex = Math.floor(Math.random() * messages.length);
    //         setCurrentMessage(messages[randomIndex]);
    //         setShowMessage(true);
    //         const messageTimerId = setTimeout(() => {
    //             setShowMessage(false);
    //         }, 3000);
    //         return () => clearTimeout(messageTimerId);
    //     }

    //     const timerId = setTimeout(() => {
    //         setShowTransactionMessage(false);
    //         setShowConfirmation(false);
    //     }, 5000);

    //     if (!showTransactionMessage) {
    //         const transactionTimerId = setTimeout(() => {
    //             setShowTransactionMessage(false);
    //         }, 1000); // delay for 1 second before hiding the element
    //         return () => clearTimeout(transactionTimerId);
    //     }

    //     return () => clearTimeout(timerId);
    // }, [showTransactionMessage, showConfirmation, messages]);


    useEffect(() => {
        const handleOutsideClick = (event) => {
          const numberPadElement = document.querySelector(".custom-number-pad");
          const dollarInputElement = document.querySelector(".dollarinput");
          const addButtonElement = document.querySelector(".add-purchase");
          if (
            numberPadElement &&
            dollarInputElement &&
            addButtonElement &&
            !numberPadElement.contains(event.target) &&
            !dollarInputElement.contains(event.target) &&
            !addButtonElement.contains(event.target)
          ) {
            // Click outside the custom-number-pad and dollarInput, hide the NumberPad
            setShowNumberPad(false);
          }
        };
      
        document.addEventListener("click", handleOutsideClick);
      
        return () => {
          document.removeEventListener("click", handleOutsideClick);
        };
      }, [showNumberPad]);

    //HANDLE CHANGING THE CATEGORY IN THE DROP DOWN MENU
    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
    };

    //ALLOW THE USER TO CHANGE THEIR CURRENCY SYMBOL BY TAPPING IT
    const handleCurrencyClick = () => {
        handleChangeCurrency();
    };

    //DISABLE THE ADD PURCHASE BUTTON WHEN THERE IS NO VALUE OR CATEGORY
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    useEffect(() => {
        setIsButtonDisabled(!selectedCategory);
    }, [selectedCategory]);

    //WHEN A USER MAKES A PURCHASE, USE THIS BLACK CIRCLE EMOJI AS THE 'KEY'
    const getEmoji = () => {
        const emojis = ["📝 Add a purchase note"];
        const randomIndex = Math.floor(Math.random() * emojis.length);
        return emojis[randomIndex];
    };

    const getIcon = () => {
        const icons = ["💸"];
        const randomIndex = Math.floor(Math.random() * icons.length);
        return icons[randomIndex];
    };

    //CORRECTLY FORMAT THE PURCHASE AMOUNT AS DOLLARS/CENTS PREPENDED WITH '$'
    const handleInput = (event) => {
        const value = event.target.value;

        // check if the value is a valid number
        if (/^\d*\.?\d{0,2}$/.test(value)) {
            setInputValue(value);
        }
    };

    const categoryList = Object.keys(categories);
    const categoriesExist = categoryList?.length;

    //!! THIS CREATES AN INFINITE LOOP AND NEEDS REFACTORING !!____________________
    //HOOK TO LISTEN FOR CHANGES TO THE CATEGORY LIST SO THAT THE DROPDOWN IS UPDATED
    useEffect(() => {
        const userId = firebase.auth().currentUser?.uid;
        if (!userId) {
            // user not logged in, do nothing
            return;
        }

        const categoriesRef = firebase
            .database()
            .ref(`users/${userId}/categories`);
        categoriesRef.on("value", (snapshot) => {
            const categories = snapshot.val();
            if (categories) {
                setCategories(categories);
                if (!selectedCategory && Object.keys(categories).length > 0) {
                    // set the initial value of selectedCategory to the first category
                    setSelectedCategory(Object.keys(categories)[0]);
                    // console.log("dropdown categories have been adjusted!");
                }
            }

            if (!categories) {
                setCategories({});
                setSelectedCategory(null);
                setIsButtonDisabled(true);
            }
        });

        return () => {
            categoriesRef.off("value");
        };
    }, [categories]);

    //BIG FUNCTION CALLED WHEN THE USER CLICKS 'ADD PURCHASE'
    const handleAddPurchase = (props) => {

        setIsButtonAnimating(true);
        // dollarInputRef.current.focus();
        // setIsAddButtonClicked(true);

        const category = selectedCategory;
        const amount = isPayment ? -Number(inputValue) : Number(inputValue);
        const date = selectedDate; // use selectedDate if it exists, otherwise use the current date
        const note = getEmoji();
        const icon = getIcon();
        const isDateValid = !isNaN(date); //  check if the date is valid
        

        setSelectedCategory(selectedCategory); // set the selected category

        

       
 if (!inputValue.trim()) {
    setShowNumberPad((prevState) => !prevState);
//   console.log("Please enter an amount");
  return;
}

        if (!isDateValid) {
            // console.log("Invalid date");
            // console.log(selectedDate);
            return;
        }

        if (inputValue && selectedCategory) {
            const purchase = {
                category: selectedCategory,
                amount: inputValue,
                date: selectedDate,
                note: getEmoji(),
                icon: getIcon(),
            };
            setTriggerRefetch(prev => !prev);

            // add the purchase data to Firebase Realtime Database

            if (purchase.category) {
                const user = firebase.auth().currentUser;
                const purchasesRef = firebase
                    .database()
                    .ref("users/" + user.uid + "/purchases");
                purchasesRef.push({
                    category,
                    amount,
                    date: new Date(date).toLocaleString(),

                    // .toLocaleString()

                    note,
                    icon,
                });
            }
        }

        setInputValue(inputValue);

        // update the remaining budget for the selected category
        const updateBudget = (category, amount) => {
            if (isNaN(amount)) {
                return;
            }
            const newBudget = {
                ...remainingBudgets,
                [category]: Number(amount),
            };
            setRemainingBudgets(newBudget);
            budgetRef.set(newBudget);
        };

        // clear the input field and reset the selected category
        setInputValue("");
        setSelectedDate(new Date());
        setShowAddPurchase(false);
        setPurchaseAdded(true);

        if (
            inputValue &&
            (selectedCategory === null || selectedCategory === 0)
        ) {
            // console.log("Please select a category");
            return;
        }

        // show animation only if there was a category selected
        if (selectedCategory) {
            handleShowAnimation();
        }

        const randomIndex = Math.floor(Math.random() * messages.length);
        setCurrentMessage(messages[randomIndex]);
        handleShowAnimation();
        setIsTransactionAdded(true);

        // add the animation class to the button
     

        // reset the button after 0.3 seconds
        setTimeout(() => {
            setIsButtonAnimating(false);
            setIsTransactionAdded(false);
            setButtonText(buttonLabel);
        }, 2000);
    };

    useEffect(() => {
        if (isAddButtonClicked) {
          const addValue = document.querySelector(".dollarinput");
          if (addValue && addValue.value.trim() === "") {
            addValue.focus();
          }
          setIsAddButtonClicked(false); // Reset the state value
        }
      }, [isAddButtonClicked]);

        // Handle pressing a number in the custom number pad
  const handleNumberPress = (number) => {
    if (number === "Del") {
      setInputValue(inputValue.slice(0, -1)); // Remove the last character
    } else if (number === "." && inputValue.includes(".")) {
      // Do not allow multiple decimal points
      return;
    } else {
      setInputValue(inputValue + number);
    }
  };

    //JSX_________________________________________________________________________________________

    return (
        <div className="masterinputbox">
            {" "}
            <button
    className="diffdatebutton"
    onClick={() => {
        setShowAddPurchase(!showAddPurchase);
        if (!showAddPurchase && showNumberPad) {
            setShowNumberPad(false);
        }
    }}
>
                {selectedDate.toDateString() === new Date().toDateString()
                    ? "Today"
                    : selectedDate.toDateString()}
                <AiFillCaretDown
                    style={{
                        fontSize: "0.9rem",
                        paddingTop: "3px",
                        paddingLeft: "5px",
                        boxSizing: "border-box",
                    }}
                />
            </button>{" "}

         



            {showAddPurchase && (
                <CSSTransition
                    in={showAddPurchase}
                    timeout={300}
                    classNames="datepicker-slide"
                    unmountOnExit
                >
                    <div className="diffdatecontainer">
                        <AddPurchase
                            handleDateChange={handleDateChange}
                            selectedDate={selectedDate}
                            setSelectedDate={setSelectedDate}
                            handleAddPurchase={handleAddPurchase}
                        />
                    </div>
                </CSSTransition>
            )}

{showNumberPad && <NumberPad handleNumberPress={handleNumberPress} />}
            <div className="input-container">
                <div className="inputcontainerform">
                    <button
                        className="currencytype"
                        
                    >
                        {currencySymbol}
                    </button>
                    <input
            type="text"
            className="dollarinput"
            value={inputValue}
            readOnly
            placeholder="hint: round up!"
            ref={dollarInputRef}
            onClick={() =>  setShowNumberPad((prevState) => !prevState)}
          />
                    <select
                        className="dropdown"
                        value={selectedCategory || ''}
                        onChange={(event) =>
                            setSelectedCategory(event.target.value)
                        }
                        placeholder="Pick a Category"
                    >
                        {Object.keys(categories).map((category) => (
                            <option key={category} value={category}>
                                {category}
                            </option>
                        ))}
                    </select>
                    <button
                    style={{userSelect: 'none'}}
  className={`add-purchase ${isPayment ? 'payment' : ''}${isButtonAnimating ? " animate" : ""}`}
  onMouseDown={handleButtonPress} 
  onMouseUp={handleButtonRelease}
  onTouchStart={handleButtonPress} 
  onTouchEnd={handleButtonRelease}
  onClick={() => {
    if (!inputValue.trim()) {
      setShowNumberPad((prevState) => !prevState);
    //   console.log("Please enter an amount");
      return;
    } else {
      handleAddPurchase();
      setShowNumberPad(false);
    }
  }}
  disabled={isButtonDisabled}
>
{isTransactionAdded ? '✓' : isPayment ? 'Add Refund' : 'Add Purchase'}
</button>
                </div>
            </div>
        </div>
    );
}

export default InputWidget;
