import React, { useState } from 'react';
import './Onboarding.css';
import arrow1 from './images/arrow1.svg';
import arrow2 from './images/arrow2.svg';
import bracket from './images/bracket.svg';
import googleplayicon from './images/googleplayicon.svg';
import { IoShareOutline } from "react-icons/io5";

function Onboarding(props) {
  const [step, setStep] = useState(1);

  const handleNextiOS = () => {
    if (step < 4) {
      setStep(step + 2);
    } else {
      // Once the user reaches the last step, close the onboarding component
      props.onClose();
    }
  };

  
  const handleNext = () => {
    if (step < 4) {
      setStep(step + 1);
    } else {
      // Once the user reaches the last step, close the onboarding component
      props.onClose();
    }
  };


  const handleEscape = () => {
    // console.log('clicked');
    props.onClose();
  };

  return (
    <div className="onboarding">
      <div className="slide slide1" style={{ top: (step - 1) * -100 + '%' }}>
        <div className="slide1container">
        <button className="slidebutton" onClick={handleNext}>I'm using Google Chrome</button>
        <button className="slidebuttonios" onClick={handleNextiOS}>I'm using Safari</button>
        <button className="slideescapebutton" onClick={handleEscape}>Cancel</button>
      </div></div>
      <div className="slide slide2" style={{ top: (step - 2) * -100 + '%' }}>
      {/* <img className="arrow1" src={arrow1}/>
      <img className="bracket" src={bracket}/> */}
      <a className="googleplaylink" href="https://play.google.com/store/apps/details?id=lilbudget.me_android&hl=en&gl=US"><div className="googleplaycontainer">
      
      <img className="googleplayicon"  alt="google play icon "src={googleplayicon}/>
      <div className="googleplaytext">Download lilBudget on Google Play!</div>
      </div></a>
        {/* <p className="slide2text1">Step 1: Open your browser settings</p>
        <p className="slide2text2">Step 2: <br></br>In the settings menu, look for<br></br>"Install App"</p> */}
       
        <button className="slideescapebutton2" onClick={handleEscape}>Close</button>
      </div>
      <div className="slide slide2" style={{ top: (step - 3) * -100 + '%' }}>
      <img className="iosarrow1" src={arrow2}/>
        <p className="iostext1">Step 1: Tap on the 'Share' icon <IoShareOutline /></p>
        <p className="iostext2">Step 2: In the menu, look for "Add to Home Screen"</p>
        <button className="slideescapebutton3" onClick={handleEscape}>Close</button>
      </div>
    </div>
  );
}

export default Onboarding;