import React, { useState, useEffect } from 'react';
import emailjs from '@emailjs/browser';
import firebase from 'firebase/compat/app';
import inkwell from "./images/inkwell.svg";
import 'firebase/compat/database';
import { useNavigate, useLocation } from 'react-router-dom';


const db = firebase.database();


function SupportForm(fromExtension) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formError, setFormError] = useState(false);


  const navigate = useNavigate();
  const location = useLocation();

  const handleClose = () => {
    navigate('/', { state: { fromExtension: true } });
  };

  useEffect(() => {
    const userId = firebase.auth().currentUser.uid;
    const emailRef = db.ref(`users/${userId}/email`);

    emailRef.on('value', (snapshot) => {
      setEmail(snapshot.val());
    });

    return () => {
      emailRef.off('value');
    };
  }, []);


  const handleSubmit = (event) => {
    event.preventDefault();

    // EmailJS configuration
    const serviceId = 'service_f1v8btq';
    const templateId = 'template_iivgilm';
    const userId = 'OiawIy6juunIIdd6k';

    // Send email using EmailJS
    emailjs.send(serviceId, templateId, {
      from_name: name,
      from_email: email,
      subject: subject,
      message: message,
    }, userId)
      .then(() => {
        setFormSubmitted(true);
        setName('');
        setEmail('');
        setSubject('');
        setMessage('');
      })
      .catch(() => {
        setFormError(true);
      });
  };

  return (
    <div className="supportformcontainer"><div className="support-form-container">
        <div className="supportformheader"><p className="supportformheader1">Questions, comments or problems?</p><p className="supportformheader2">Send us a message and we'll look into it.</p></div>
      {!formSubmitted ? (
        <form onSubmit={handleSubmit}>
         
         <div className="supportmessageemailcontainer"> 
         <p className="emaillabel"> To: </p>
         <div className="supportemaildiv2">
         <div className="supportmessageemailfake"

          >info@lilbudget.me</div></div></div>
        
         <div className="supportmessageemailcontainer"> 
         <p className="emaillabel"> From: </p>
         <div className="supportemaildiv2">
         <input
          className="supportmessageemail"
          placeholder="Your email goes here"
            id="email"
            type="email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            required
          /></div></div>

          <div className="supportmessagebodybox">
      
          <div className="supportmessagesubjectcontainer"><input
          className="supportmessagesubject"
          placeholder='Enter the subject of your message'
            id="subject"
            type="text"
            value={subject}
            onChange={(event) => setSubject(event.target.value)}
            required
          /></div>
          
          <textarea
          className="supportmessagebody"
          placeholder='Write your message here'
            id="message"
            value={message}
            onChange={(event) => setMessage(event.target.value)}
            required
          />
         <div className="supportmessagesubmitbox"> 
         <button className="supportmessagesubmitbutton" type="submit">Submit</button>
         
         </div>
         </div>
          {formError && <div className="support-error-message">There was an error submitting the form. Please try again later.</div>}
        </form>
      ) : (
        <div className="support-success-message">Thank you for your message! We'll get back to you as soon as possible.</div>
      )}
      <button className="closesupportform" onClick={handleClose}>Close</button>
      <div className="inkwellcontainer">
                    <img
                        className="inkwellimg"
                        src={inkwell}
                        alt="inkwell"
                    />
                </div>
                <div className="bottomanchor"/>
    </div>
   </div>
  );
}

export default SupportForm;