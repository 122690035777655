import firebase from "firebase/compat/app";
import "firebase/compat/database";
import "firebase/compat/storage";
import './PaydayReview.css';
import './Groups.css'
import lilman from "./images/lilman.svg";
import bigwindow from "./images/bigwindow.svg";
import budgetlogo from "./images/lillogo.svg";
import cityback2 from "./images/emptydesert.svg";
import tumbleweed from "./images/tumbleweed.svg";
import lightmast from "./images/lightmast.svg";
import lightpole from "./images/lightpole.svg";
import lilwindow from "./images/lilwindow.svg";
import { FcConferenceCall  } from "react-icons/fc";
import PaydayReviewDonut from "./PaydayReviewDonut";
import React, { useEffect, useState } from 'react';
import { IoIosClose } from "react-icons/io";
const db = firebase.database();

const PaydayReview = ({setShowPaydayReview, currencySymbol, handleReset, handleConfirmation}) => {
    const [totalPurchases, setTotalPurchases] = useState(0);
    const [totalSpent, setTotalSpent] = useState(0);
    const [totalCategoryAmount, setTotalCategoryAmount] = useState(0);
    const [groupSpendings, setGroupSpendings] = useState({});
    const [worthitRatio, setWorthitRatio] = useState(null);
    const [categorySpendings, setCategorySpendings] = useState({});
    const [income, setIncome] = useState(0);
    const [expenses, setExpenses] = useState({});
    const [overUnderBudget, setOverUnderBudget] = useState(0);
const [totalExpenses, setTotalExpenses] = useState(0);
const [brokeBank, setBrokeBank] = useState(false);
    const [budgetStatus, setBudgetStatus] = useState("");
    const [remainingIncome, setRemainingIncome] = useState(0);
    const [earliestDate, setEarliestDate] = useState(null);
const [latestDate, setLatestDate] = useState(null);
const [topThreePurchases, setTopThreePurchases] = useState([]);
const [mostFrequentCategories, setMostFrequentCategories] = useState([]);
const [savings, setSavings] = useState(0);
const [includeExpenses, setIncludeExpenses] = useState(false);
const [topCategory, setTopCategory] = useState('');
const [showMoreStats, setShowMoreStats] = useState(false);
const [lowestWorthitRatioCategory, setLowestWorthitRatioCategory] = useState(false);
const [groupHighestPurchases, setGroupHighestPurchases] = useState({});
const [purchases, setPurchases] = useState({});
const [totalAllocated, setTotalAllocated] = useState(0);
const [nearCompletionGoals, setNearCompletionGoals] = useState([]);

const handleShowMoreStats = () => {
    setShowMoreStats(true);
  };

  const handleHideMoreStats = () => {
    setShowMoreStats(false);
  };


const toggleIncludeExpenses = () => {
    setIncludeExpenses(!includeExpenses);
  };
  
  useEffect(() => {
    fetchAndCalculateTotalPurchases();
    fetchAndCalculateTotalSpent();
    fetchAndCalculateTotalCategoryAmount();
    fetchAndCalculateGroupSpendings();
    fetchAndCalculateWorthitRatio();
    fetchAndCalculateCategorySpendings();
    fetchIncomeAndExpenses();
    fetchAndCalculateBrokeBank();
    fetchAndCalculateBudgetStatus();
    fetchAndCalculateSavingsGoals();
  }, []);

    const handleUndoPayday = () => {
        setShowPaydayReview(false);
    };


    
    
    const handleUndoPayday2 = () => {
        const paydayContainer = document.querySelector(".paydaycontainer2");
        
        if (paydayContainer) {
          paydayContainer.classList.add("animateaway");
          setTimeout(() => {
            setShowPaydayReview(false);
          }, 200);
        }
      };

    useEffect(() => {
        setSavings(remainingIncome - totalSpent);
    }, [remainingIncome, totalSpent]);


    useEffect(() => {
        const overUnder = totalCategoryAmount - totalSpent;
        setOverUnderBudget(overUnder);
    }, [totalCategoryAmount, totalSpent]);

    





    const fetchAndCalculateSavingsGoals = async () => {
      const userId = firebase.auth().currentUser.uid;
      const userRef = db.ref(`users/${userId}/savingsGoals`);
      const snapshot = await userRef.once('value');
      const savingsGoals = snapshot.val();
    
      let totalAllocated = 0;
      let nearCompletionGoals = [];
    
      for (let goalId in savingsGoals) {
        const goal = savingsGoals[goalId];
        totalAllocated += goal.amountAllocated;
    
        const progressToTargetDifference = Math.abs(goal.progress - goal.target);
        if (progressToTargetDifference <= 0.5) {
          nearCompletionGoals.push(goal);
        }
      }
    
      setTotalAllocated(totalAllocated);
      setNearCompletionGoals(nearCompletionGoals);
    };



    const fetchAndCalculateBrokeBank = async () => {
        const userId = firebase.auth().currentUser.uid;
        const userRef = db.ref(`users/${userId}/purchases`);
        const snapshot = await userRef.once('value');
        const purchases = snapshot.val();
        let brokeBank = false;
        for (let purchaseId in purchases) {
          if (purchases[purchaseId].amount > totalCategoryAmount * 0.1) {
            brokeBank = true;
            break;
          }
        }
        setBrokeBank(brokeBank);
      };
  
      const fetchAndCalculateBudgetStatus = async () => {
        const userId = firebase.auth().currentUser.uid;
        let status = "stayed within budget";
        for (let category in categorySpendings) {
          if (categorySpendings[category] > expenses[category]) {
            status = "didn't stay within budget";
            break;
          }
        }
        setBudgetStatus(status);
      };
  


      

      const fetchIncomeAndExpenses = async () => {
        const userId = firebase.auth().currentUser.uid;
        const userRef = db.ref(`users/${userId}`);
        const snapshot = await userRef.once('value');
        const user = snapshot.val();
      
        // Set income
        setIncome(user.income);
      
        // Set expenses
        let totalExpenses = {};
        for (let expense of user.expenses) {
            totalExpenses[expense.name] = expense.amount;
        }
        setExpenses(totalExpenses);
    
        // Calculate total expenses
        const totalExpense = Object.values(totalExpenses).reduce((acc, cur) => acc + parseFloat(cur), 0);
        setTotalExpenses(totalExpense);
    
        // Calculate and set remaining income
        const remainingIncome = user.income - totalExpense;
        setRemainingIncome(remainingIncome);
    };


    const fetchAndCalculateCategorySpendings = async () => {
        const userId = firebase.auth().currentUser.uid;
        const userRef = db.ref(`users/${userId}/purchases`);
        const snapshot = await userRef.once('value');
        const purchases = snapshot.val();
        let categorySpendings = {};
        for (let purchaseId in purchases) {
          const category = purchases[purchaseId].category;
          if (!categorySpendings[category]) categorySpendings[category] = 0;
          categorySpendings[category] += purchases[purchaseId].amount;
        }
        setCategorySpendings(categorySpendings);
        const overUnder = totalCategoryAmount - totalSpent;
        setOverUnderBudget(overUnder);

        setSavings(remainingIncome - totalSpent);
      };


      const fetchAndCalculateTotalPurchases = async () => {
        const userId = firebase.auth().currentUser.uid;
        const userRef = db.ref(`users/${userId}/purchases`);
        const snapshot = await userRef.once('value');
        const purchases = snapshot.val();
        setTotalPurchases(Object.keys(purchases).length);
      
        const sortedPurchases = Object.values(purchases).sort((a, b) => b.amount - a.amount);
        const topThreePurchases = sortedPurchases.slice(0, 3);
      
        // Extract the required information from the top three purchases
        const topThreePurchaseInfo = topThreePurchases.map((purchase) => {
          const { category, icon, note, amount, group, date } = purchase;
          const worthit = purchase.worthit !== undefined ? purchase.worthit : null;
          // console.log('Purchase worthit:', worthit);
          return {
            category,
            icon,
            note,
            amount,
            group,
            date,
            worthit
          };
        });
      
        setTopThreePurchases(topThreePurchaseInfo);
      
        const purchaseDates = Object.values(purchases).map((purchase) => purchase.date);
        const sortedDates = purchaseDates.sort();
        const earliest = new Date(sortedDates[0]).toLocaleDateString();
        const latest = new Date(sortedDates[purchaseDates.length - 1]).toLocaleDateString();
        setEarliestDate(earliest);
        setLatestDate(latest);
      
        const categoryAmountMap = {};
        const categoryWorthitCountMap = {};
      
        Object.values(purchases).forEach((purchase) => {
          const category = purchase.category;
          const amount = parseFloat(purchase.amount);
          const worthit = purchase.worthit !== undefined ? purchase.worthit : null;
      
          categoryAmountMap[category] = (categoryAmountMap[category] || 0) + amount;
      
          if (worthit !== null) {
            categoryWorthitCountMap[category] = (categoryWorthitCountMap[category] || 0) + 1;
          }
        });
      
        const maxAmount = Math.max(...Object.values(categoryAmountMap));
        const topCategory = Object.keys(categoryAmountMap).find(
          (category) => categoryAmountMap[category] === maxAmount
        );
      
        setTopCategory(topCategory);
      
        const lowestWorthitRatioCategory = Object.keys(categoryWorthitCountMap).reduce((a, b) =>
        categoryWorthitCountMap[a] / (categoryAmountMap[a] || 1) <
        categoryWorthitCountMap[b] / (categoryAmountMap[b] || 1)
          ? a
          : b
      , null);
      
        setLowestWorthitRatioCategory(lowestWorthitRatioCategory);
      
        const categoryCountMap = {};
      
        Object.values(purchases).forEach((purchase) => {
          const category = purchase.category;
          categoryCountMap[category] = (categoryCountMap[category] || 0) + 1;
        });
        const maxCount = Math.max(...Object.values(categoryCountMap));
        const mostFrequentCategories = Object.keys(categoryCountMap).filter(
          (category) => categoryCountMap[category] === maxCount
        );
        
        // If there is a tie, select the first category in the array
        const selectedCategory = mostFrequentCategories[0];
        
        setMostFrequentCategories([selectedCategory]);
      };
      

      
  
    const fetchAndCalculateTotalSpent = async () => {
        const userId = firebase.auth().currentUser.uid;
      const userRef = db.ref(`users/${userId}/purchases`);
      const snapshot = await userRef.once('value');
      const purchases = snapshot.val();
      let total = 0;
      for (let purchaseId in purchases) {
        total += purchases[purchaseId].amount;
      }
      setTotalSpent(total);
    };
  
    const fetchAndCalculateTotalCategoryAmount = async () => {
        const userId = firebase.auth().currentUser.uid;
      const userRef = db.ref(`users/${userId}/categories`);
      const snapshot = await userRef.once('value');
      const categories = snapshot.val();
      let total = 0;
      for (let category in categories) {
        total += categories[category];
      }
      setTotalCategoryAmount(total);
    };
  
    const fetchAndCalculateGroupSpendings = async () => {
      const userId = firebase.auth().currentUser.uid;
      const userRef = db.ref(`users/${userId}/purchases`);
      const snapshot = await userRef.once('value');
      const purchases = snapshot.val();
      let groupSpendings = {};
      let groupHighestPurchases = {}; // Added variable to store highest purchases per group
    
      for (let purchaseId in purchases) {
        const group = purchases[purchaseId].group;
    
        // Skip purchases without a group
        if (!group) continue;
    
        const amount = purchases[purchaseId].groupAmount ? parseFloat(purchases[purchaseId].groupAmount) : parseFloat(purchases[purchaseId].amount);
  

    
        if (groupSpendings[group] === undefined) {
          groupSpendings[group] = 0;
          groupHighestPurchases[group] = {
            category: purchases[purchaseId].category,
            icon: purchases[purchaseId].icon,
            note: purchases[purchaseId].note,
            amount: amount,  // save as number
            date: purchases[purchaseId].date,
            group: group,
          };
  
          
        } else {
          groupSpendings[group] += amount;
          if (amount > parseFloat(groupHighestPurchases[group].amount)) { // explicit conversion
            groupHighestPurchases[group] = {
              category: purchases[purchaseId].category,
              icon: purchases[purchaseId].icon,
              note: purchases[purchaseId].note,
              amount: amount,  // save as number
              date: purchases[purchaseId].date,
              group: group,
            };
  
       
          }
        }
      }
  
     
    
      setGroupSpendings(groupSpendings);
      setGroupHighestPurchases(groupHighestPurchases); // Set the state for highest purchases per group
  };
  
      const fetchAndCalculateWorthitRatio = async () => {
        const userId = firebase.auth().currentUser.uid;
        const userRef = db.ref(`users/${userId}/purchases`);
        const snapshot = await userRef.once('value');
        const purchasesData = snapshot.val();
        let worthitCount = 0;
        let totalPurchases = 0;
      
        for (let purchaseId in purchasesData) {
          const worthit = purchasesData[purchaseId].worthit;
          if (worthit !== null) {
            totalPurchases++;
            if (worthit) worthitCount += 1;
          }
        }
      
        const worthitRatio = totalPurchases === 0 ? null : (worthitCount / totalPurchases) * 100;
        setWorthitRatio(worthitRatio);
        setPurchases(purchasesData); // Store the purchases data in state
      };

      const isEmptyState = Object.keys(categorySpendings).length === 0 || totalPurchases === 0;

return (
    <div className="paydayreviewcontainer">
       
        <div className="paydaycontainer2">
        <button  onClick={() => {
                                               handleUndoPayday2();
                                            }} className='closepaydayreviewcontainer'><IoIosClose /></button>
        {isEmptyState ? (
          <div className="empty-state-review">
            <div className="citybackcontainer">
{/* <img className="lilman" src={lilman} />
<img className="lilwindow" src={lilwindow} />
<img className="bigwindow" src={bigwindow} /><img className="lightmast" src={lightmast} /><img className="lightpole" src={lightpole} /> */}
<div className="shadow"></div>
<img className="tumbleweed" src={tumbleweed} />
<img className="cityback2" src={cityback2} alt="Cityback2" /></div>

            <p className="empty-state-review-message">
              <p className="empty-state-review-header">Nothing to review!</p><br></br><br></br>Once you've been adding purchases and tracking your budget for a while, this is where you can find a review of your spending.<br></br><br></br>You'll also have the option to reset your budget categories, so you can start the next month with a clean slate. 
            </p>

            <button
                                            className="confirmation-no"
                                            onClick={() => {
                                               handleUndoPayday2();
                                            }}
                                        >
                                            OK
                                        </button>
          </div>
        ) : (
        
        <div className="paydaycontainer3">
            <div className="paddingdiv">
        <h2 className="reviewheader1">Budget Review</h2>
        <h2 className="reviewheader1">{earliestDate} to {latestDate}</h2>
<div className="reviewdonut">
        <PaydayReviewDonut
        currencySymbol={currencySymbol}
        expenses={expenses}
        categorySpendings={categorySpendings}
        includeExpenses={includeExpenses}
        savings={savings}
      /></div>
        <button className="toggleexpensesbutton" onClick={toggleIncludeExpenses}>Toggle Expense Details</button>
        
        <div className="bottomborder"></div>

        <div className="topcategoriescontainer">
 {mostFrequentCategories.map((category, index) => (
  
          <div className="category-item" key={index}>
            <div className="topcategorytile"><div className="topcategoryheader">Most frequent:</div> <div className="topcategoryvalue">{category}</div></div>
          </div>
        ))}
         {topCategory && (
      <div className="topcategorytile"><div className="topcategoryheader">Highest total cost:</div> <div className="topcategoryvalue">{topCategory}</div></div>
    )}

    {lowestWorthitRatioCategory && (<div className="topcategorytile"><div className="topcategoryheader">Lowest 👍 Ratio:</div> <div className="topcategoryvalue">{lowestWorthitRatioCategory}</div></div>)}
</div> 
               {totalPurchases > 2 && <div><h2 className="reviewheader2">Top 3 of {totalPurchases} total purchases:</h2>
        {topThreePurchases.map((purchase, index) => (
  <div className="reviewstat2" key={index}>
    
    <div className="purchase-info">
      <div className="top3iconworthit">
        <span className="purchase-icon">{purchase.icon}</span>
        {purchase.worthit === true ? (
  <span className="purchase-worthit">👍</span>
) : purchase.worthit === false ? (
  <span className="purchase-worthit">👎</span>
) : (
  <span className="purchase-worthit"></span>
)}
      </div>
      <div className="top3categorynote">
      <span className="purchase-date">{new Date(purchase.date).toLocaleDateString('en-US')}</span>
        <span className="purchase-category">{purchase.category}</span>
        <span className="purchase-note">{purchase.note}</span>
      </div>
      <div className="top3groupamount">
        {purchase.group && <span className="purchase-group">{purchase.group}<FcConferenceCall style={{paddingLeft: "5px"}} /></span>}
        <span className="purchase-amount">{currencySymbol}{purchase.amount}</span>
      </div>
    </div>
  </div>
  
))} </div>}
{/* <h2 className="reviewheader2">Groups</h2>
 {Object.entries(groupSpendings).map(([group, amount]) => (
            <div className="purchase-info2"> <div className="top3iconworthit">
            <span className="purchase-icon"><FcConferenceCall /></span>
          </div>
          <div className="top3categorynote">
          
            <span className="purchase-category" key={group}>{group}</span>
            <span className="purchase-note">Some text</span>
          </div>
          <div className="top3groupamount">
            
            <span className="purchase-amount">{currencySymbol}{amount}</span>
          </div>
        </div>
          ))} */}
          {Object.entries(groupSpendings).length > 0 && (
  <>
    <h2 className="reviewheader2">Top Group Purchases</h2>
    {Object.entries(groupSpendings).map(([group, amount]) => (
      <div className="purchase-info2" key={group}>
        <div className="top3iconworthit">
          <span className="purchase-icon">{groupHighestPurchases[group].icon}</span>
        </div>
        <div className="top3categorynote">
        <span className="purchase-date">{new Date(groupHighestPurchases[group].date).toLocaleDateString('en-US')}</span>

          <span className="purchase-category">{groupHighestPurchases[group].category}</span>
          <span className="purchase-note">{groupHighestPurchases[group].note}</span>
        </div>
        <div className="top3groupamount">
        <span className="purchase-group">{groupHighestPurchases[group].group}<FcConferenceCall style={{paddingLeft: "5px"}} /></span>
          <span className="purchase-amount">{currencySymbol}{groupHighestPurchases[group].amount}</span>
         
        </div>
      </div>
    ))}
  </>
)}

{worthitRatio !== null && Object.values(purchases).some(purchase => purchase.hasOwnProperty('worthit')) && (
  <p className="reviewstat3">
    <span className="emoji">
      {worthitRatio >= 70
        ? `👍 ${worthitRatio.toFixed(0)}% of purchases were worth It`
        : `👎 ${(100 - worthitRatio).toFixed(0)}% of purchases were not Worth It`}
    </span>
  </p>
)}

{worthitRatio === null && Object.values(purchases).some(purchase => purchase.hasOwnProperty('worthit')) && (
  <p className="reviewstat3">
    <span className="emoji">
      No worthit values recorded for purchases.
    </span>
  </p>
)}

{!Object.values(purchases).some(purchase => purchase.hasOwnProperty('worthit')) && (
  <p className="reviewstat3">
    <span className="emoji">
      
    </span>
  </p>
)}



</div>
<div className="paddingdiv">

         

</div>


{!showMoreStats && (
        <button className="toggleexpensesbutton" onClick={handleShowMoreStats}>Show Worksheet</button>
      )}
{showMoreStats && (
<button className="toggleexpensesbutton" onClick={handleHideMoreStats}>Hide Worksheet</button>
)}

      
      {showMoreStats && (
        <div className={`spendstatscontainer ${showMoreStats ? 'expanded' : ''}`}>
        <div>
       
                {/* <p className="reviewstat">Broke the Bank: {brokeBank ? "True" : "False"}</p>
      <p className="reviewstat">Budget Status: {budgetStatus}</p> */}
      {/* <p className="reviewstat">Allowance Leftover: {currencySymbol}{overUnderBudget >= 0 ? `${overUnderBudget}` : `${Math.abs(overUnderBudget)} Over Budget`}</p>  */}
<p className="worksheettext"><div className="paydayworksheetheader">total income: </div> <div className="bolder">{currencySymbol}{income}</div></p> 
<p className="worksheettext"><div className="paydayworksheetheader2">- total fixed expenses: </div> <div className="bolder2">({currencySymbol}{totalExpenses})</div></p> 
<p className="worksheettext"><div className="paydayworksheetheader">= remaining discretionary income: </div> <div className="bolder">{currencySymbol}{Math.round(remainingIncome)}</div></p> 
            {/* <p className="reviewstat5">Total Allowance: {currencySymbol}{totalCategoryAmount}</p>   */}
      <p className="worksheettext"><div className="paydayworksheetheader2">- total spent in budget categories: </div> <div className="bolder2">({currencySymbol}{Math.round(totalSpent)})</div></p>
      <p className="worksheettext"><div className="paydayworksheetheader">= total unspent income: </div> <div className="bolder">{currencySymbol}{Math.round(savings)}</div></p>
      <p className="worksheettext"><div className="paydayworksheetheader2">- total allocated to savings goals: </div> <div className="bolder2">({currencySymbol}{Math.round(totalAllocated)})</div></p>
      <p className="worksheettext"><div className="paydayworksheetheader">= total leftover income: </div> <div className="bolder3">{currencySymbol}{Math.round(savings - totalAllocated)}</div></p>
      {/* <p className="worksheettext"><div className="paydayworksheetheader">= total unspent income: </div> <div className="bolder3">{currencySymbol}{savings}</div></p> */}
            
      {/* <p className="reviewstat5">Savings: {currencySymbol}{savings}</p> */}
        </div></div>
      )}
    
      


      {/* <div className="totalsavingscontainer"><div className="unspentincomecontainer">
      <div className="unspentincome"> {currencySymbol}{savings}</div>Total Amount Unspent 
       </div></div> */}


      <div className="reviewbuttoncontainer">
      <button
                                            className="confirmation-okay"
                                            onClick={() => {
                                               
                                                handleReset();
                                                handleUndoPayday2();
                                               
                                            }}
                                        >
                                            Reset Budget Categories
                                        </button>
                                        <button
                                            className="confirmation-no"
                                            onClick={() => {
                                               handleUndoPayday2();
                                            }}
                                        >
                                            Close Review
                                        </button>
                                        </div>
                                        <div className="budgetlogo">
                    <img
                        className="budgetlogoimg2"
                        src={budgetlogo}
                        alt="Budgetlogo"
                    />
                </div>
    </div>)}</div></div>
  );
};

export default PaydayReview;