

function PrivacyPolicy(props) {

 

    const handleCloseClick = () => {
      props.setShowPrivacyPolicy(false);
    };
  
    return (
        <div className="termsandconditions">
        <div className="tncbody">
    
            <h1 className="tncheader">Privacy Policy</h1>
    
            <h3 className="tncbody">This Privacy Policy explains how we, at lilBudget, collect, use, and protect your personal information.</h3>
    
            <h3 className="tncsubheader">Information we collect:</h3>
            <p>We collect only your email address when you create an account with us. The income, expense, and purchase information you enter into lilBudget are stored for your use and not used for any other purposes.</p>
    
            <h3 className="tncsubheader">How we use your information:</h3>
            <p>We use your email address to send you a link to reset your password if it is forgotten. Your income, expense, and purchase information are used solely to provide you with personalized budgeting information and to improve our services.</p>
    
            <h3 className="tncsubheader">How we protect your information:</h3>
            <p>We take the security and privacy of your personal information seriously. We use industry-standard security measures to protect your information from unauthorized access, use, or disclosure. We do not collect any other personal data nor do we sell, trade, or rent your personal information to third parties.</p>
    
            <h3 className="tncsubheader">Your choices:</h3>
            <p>You have the right to access, update, and correct the personal information you've provided to us. You can delete your account at any time by using the 'Delete My Account' tool in the 'Account' page. You can also request that your account be deleted by contacting info@lilbudget.me.</p>
    
            <h3 className="tncsubheader">Data Retention:</h3>
            <p>We retain your personal data for as long as your account is active or as necessary to provide you services. If you choose to delete your account, all your personal data is erased.</p>
    
            <h3 className="tncsubheader">Updates to this policy:</h3>
            <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting a notice in our app.</p>
    
            <h3 className="tncsubheader">Contact us:</h3>
            <p>If you have any questions or concerns about this Privacy Policy, please contact us at info@lilbudget.me.</p>
    
        </div>
  
  
  
  
  
  
  
  
        
        <button className="tncclose" onClick={handleCloseClick}>Close</button>
      </div>
    );
  }
  
  export default PrivacyPolicy;