import React, { useState, useEffect, useRef } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/database";

const IncomeBudgetFormAdvanced = ({ handleUnhideInputWidget, handleHideInputWidget, handleScrollToStart, setActiveComponent, setShowMainContent, setShowInputWidget, setShowIncomeBudgetForm, showExpenseReminders, setShowExpenseReminders, currencySymbol, expenses, setExpenses, userId, setEditMode, setShowBillReminders }) => {
  const [selectedExpense, setSelectedExpense] = useState(null);
  const [dueDateType, setDueDateType] = useState("");
  const [dueDateTypes, setDueDateTypes] = useState({});
  const [dueDateInputValues, setDueDateInputValues] = useState({});
  const [dueDates, setDueDates] = useState({});
  const [isAutoPayChecked, setIsAutoPayChecked] = useState(false);
  const [editDueDateMode, setEditDueDateMode] = useState({});
  const specificDateRef = useRef(null);
  const remindersHeaderRef = useRef(null);

  useEffect(() => {
 if(remindersHeaderRef.current) {
    remindersHeaderRef.current.scrollIntoView({ top: 0, behavior: 'smooth' });
}
  }, []);

  



const handleCloseExpenseReminders = () => {
    setShowExpenseReminders(false);
  setShowMainContent(true);
};

  useEffect(() => {
    const expenseRef = firebase.database().ref(`users/${userId}/expenses`);
    expenseRef.on("value", (snapshot) => {
      const updatedExpenses = snapshot.val();
      setExpenses(Object.values(updatedExpenses || {}));
    });

    return () => {
      expenseRef.off(); // Unsubscribe from the database updates when component unmounts
    };
  }, [userId, setExpenses]);

const cancelEditMode = (expense) => {
    setEditDueDateMode(prevMode => ({
      ...prevMode,
      [expense.key]: false
    }));
    
    setDueDateTypes(prevTypes => ({
      ...prevTypes,
      [expense.key]: 'Change due date'
    }));
  };

  const getDueDateTypeFromValue = (dueDateValue) => {
    if (dueDateValue === "1") {
      return "First of the month";
    } else if (dueDateValue === "End") {
      return "End of the month";
    } else {
      return "Specific Day";
    }
  };

const handleDueDateTypeChange = (e, expense) => {
    const updatedExpense = { ...expense };
    const dueDateType = e.target.value;
  
    let adjustedDueDate = "";
if (dueDateType === "First of the month") {
  adjustedDueDate = "1";
} else if (dueDateType === "End of the month") {
  adjustedDueDate = "End";
} else if (e.target.value === 'Specific Day') {
    setEditDueDateMode(prevMode => ({
      ...prevMode,
      [expense.key]: true
    }));
    specificDateRef.current = specificDateRef.current || React.createRef();
    return;
  // Don't assign a value here. This will be handled by handleDueDateValueChange
}
  

  
    // Update local dueDateTypes state immediately
    setDueDateTypes(prevTypes => ({ ...prevTypes, [expense.key]: dueDateType }));
    setDueDates(prevDates => ({ ...prevDates, [expense.key]: adjustedDueDate }));
  
    // update dueDate in local state immediately
    updatedExpense.dueDate = adjustedDueDate;
    const updatedExpenses = expenses.map((ex) =>
      ex.key === expense.key ? updatedExpense : ex
    );
    setExpenses(updatedExpenses);
  
    // then, handle the Firebase update
    const expenseRef = firebase
      .database()
      .ref(`users/${userId}/expenses/${expense.key}`);
  
    if (adjustedDueDate === "") {
      expenseRef.child("dueDate").remove();
    } else {
      expenseRef.update({ dueDate: adjustedDueDate });
    }
  };

  const handleDueDateValueChange = (e, expense) => {
    setDueDateInputValues(prevValues => ({
      ...prevValues,
      [expense.key]: e.target.value
    }));
  };

    const handleBackToIncome = () => {
    const paydayContainer = document.querySelector(".paydaycontainer2");
    
    if (paydayContainer) {
      paydayContainer.classList.add("animateaway");
      setTimeout(() => {
        setActiveComponent("budgets");
        handleScrollToStart();
        setEditMode(true);
        setShowIncomeBudgetForm(true);
        setEditDueDateMode(false);
        handleHideInputWidget();
    setShowExpenseReminders(false);
    setShowBillReminders(false);
      }, 200);
    }
  };

  

  const getDueDateString = (dueDate) => {
    if (dueDate === "1") {
      return "Due by the first of the month";
    } else if (dueDate === "End") {
      return "Due by the end of the month";
    } else if (dueDate >= 2 && dueDate <= 28) {
      const ordinalSuffix = getOrdinalSuffix(dueDate);
      return `Due on the ${dueDate}${ordinalSuffix} of the month`;
    } else {
      return "No due date set";
    }
  };
  

  const handleDueDateValueSubmit = (expense) => {
    const updatedDueDate = dueDateInputValues[expense.key];
    if (updatedDueDate) {
      const updatedExpense = { ...expense };
      updatedExpense.dueDate = updatedDueDate;
  
      // Update the corresponding expense in the expenses array
      const updatedExpenses = expenses.map((ex) =>
        ex.key === expense.key ? updatedExpense : ex
      );
      setExpenses(updatedExpenses);
  
      // Update the expense in the Firebase database
      const expenseRef = firebase
        .database()
        .ref(`users/${userId}/expenses/${expense.key}`);
      expenseRef.update({ dueDate: updatedDueDate });
    }
  
    setEditDueDateMode((prevMode) => ({
      ...prevMode,
      [expense.key]: false,
    }));
  
    // Retrieve the previous due date type from expenses array
    const prevDueDateType = expense.dueDate
      ? getDueDateTypeFromValue(expense.dueDate)
      : "Change due date";
  
    setDueDateTypes((prevTypes) => ({
      ...prevTypes,
      [expense.key]: "Change due date",
    }));
  };

  const getOrdinalSuffix = (i) => {
    var j = i % 10,
        k = i % 100;
    if (j == 1 && k != 11) {
        return "st";
    }
    if (j == 2 && k != 12) {
        return "nd";
    }
    if (j == 3 && k != 13) {
        return "rd";
    }
    if (i == undefined | i == "") {
        return "";
    }
    return "th";
  }

  const handleToggleAutoPay = (expense) => {
    const expenseRef = firebase
      .database()
      .ref(`users/${userId}/expenses/${expense.key}`);
    const updatedAutoPay = !expense.autopay;

    expenseRef.update({ autopay: updatedAutoPay }).then(() => {
      const updatedExpensesRef = firebase.database().ref(`users/${userId}/expenses`);
      updatedExpensesRef.once("value", (snapshot) => {
        const updatedExpenses = snapshot.val();
        setExpenses(Object.values(updatedExpenses || {}));
      });
    });
  };

  const expensesArray = Object.entries(expenses || {}).map(([key, value]) => ({
    key,
    ...value,
  }));

  return (
    <div className="ibfa-income-budget-form-advanced"><div ref={remindersHeaderRef} className="ibfa-topanchor" />
      <h2  className="ibfa-header">Edit Expense Reminders</h2>
      {expensesArray.filter(expense => expense.name && expense.name.trim() !== "").length > 0 ? (
        <div className="ibfa-expense-list">
         {expensesArray
    .filter(expense => expense.name && expense.name.trim() !== "")
    .map((expense) => (
            <div key={expense.key} className="ibfa-expense-item">
              <div className="ibfa-expense-row1">
                <div className="ibfa-expense-name">{expense.name}</div>
                <div className="ibfa-expense-amount">{currencySymbol}{expense.amount}</div>
                
              </div>
              <div className="ibfa-expense-row2">
                <div className="ibfa-due-date">{getDueDateString(expense.dueDate)}</div>
                <div className="ibfa-autopay-checkbox-label">
                 
                  Autopay <input
                    className="ibfa-autopay-checkbox"
                    type="checkbox"
                    checked={expense.autopay}
                    onChange={() => handleToggleAutoPay(expense)}
                  />
                </div>
              </div>
              
              <div className="ibfa-expense-row">
                {editDueDateMode[expense.key] ? (
                  <>
                    Date due: <input
                      className="ibfa-due-date-input"
                      type="number"
                      min="1"
                      max="30"
                      value={dueDateInputValues[expense.key] || ""}
                      onChange={(e) => handleDueDateValueChange(e, expense)}
                      ref={specificDateRef}
                    />
                    <span className="ibfa-ordinal-suffix">{getOrdinalSuffix(dueDateInputValues[expense.key])}</span>
                    <button
                      className="ibfa-due-date-submit"
                      onClick={() => handleDueDateValueSubmit(expense)}
                      disabled={!dueDateInputValues[expense.key] || dueDateInputValues[expense.key] < 1 || dueDateInputValues[expense.key] > 30}
                    >
                      Save
                    </button>
                    <button className="ibfa-due-date-cancel" onClick={() => cancelEditMode(expense)}>
                      Cancel
                    </button>
                  </>
                ) : (
                  <select
                    className="ibfa-due-date-dropdown"
                    value={
                      dueDateTypes[expense.key] ||
                      (function () {
                        if (!expense.dueDate) return "Change due date";
                        if (expense.dueDate === "1") return "First of the month";
                        if (expense.dueDate === "End") return "End of the month";
                        return "Specific Day";
                      })()
                    }
                    onChange={(e) => handleDueDateTypeChange(e, expense)}
                  >
                    <option value="Change due date">Change due date</option>
                    <option value="First of the month">First of the month</option>
                    <option value="End of the month">End of the month</option>
                    <option value="Specific Day">Specific Day</option>
                  </select>
                )}
              </div>
              <div className="ibfa-expense-row">
         
              </div>
              
            </div>
          ))}
       <div className="ibfa-button-container">
                   <button onClick={handleCloseExpenseReminders}
                   
                               className="ibfa-close">
                               Done Editing
                               
                             </button></div>
        </div>
      ) : (
        <div><div style={{textAlign: "center"}} className="emptystate">No fixed expenses found!</div>  <div className="emptystate"><button className="expense-reminders-edit-reminders-button" onClick={handleBackToIncome}>Edit Fixed Expenses</button></div><div className="ibfa-button-container">
        <button onClick={handleCloseExpenseReminders}
        
                    className="ibfa-close">
                    Done
                    
                  </button></div></div>
      )}
      {expensesArray.filter(expense => expense.name && expense.name.trim() !== "").length > 0 && <div className="emptystate"><button className="expense-reminders-edit-reminders-button" onClick={handleBackToIncome}>Edit Fixed Expenses</button></div>}
    </div>
  );
  
};

export default IncomeBudgetFormAdvanced;
