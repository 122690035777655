import { useEffect, useRef, useState } from 'react';
import firebase from 'firebase/compat/app';
import './Groups.css';
import GroupDetail from './GroupDetail';
import budgetlogo from "./images/lillogo.svg";
import cebits2 from "./images/creditcards.svg";
import { IoIosArrowBack, IoIosClose } from "react-icons/io";
import GroupSpendingChart from './GroupSpendingChart'; // adjust the path as necessary
import 'firebase/compat/database';
import 'firebase/compat/storage';

const Groups = ({   emojiCategories, handleShowAnimation, currencySymbol, setShowGroupDeleteMessage, setShowInputWidget, setAddedPurchases }) => {
  const [groups, setGroups] = useState([]);
  const [newGroupName, setNewGroupName] = useState('');
  const [loading, setLoading] = useState(false);
  const [inviteEmail, setInviteEmail] = useState('');
  const [selectedGroup, setSelectedGroup] = useState('');
  const [selectedGroupDetail, setSelectedGroupDetail] = useState(null);
  const [purchases, setPurchases] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState('');
  const [groupTotals, setGroupTotals] = useState({}); 
  const [userEmail, setUserEmail] = useState('');
  const [showNewGroupForm, setShowNewGroupForm] = useState(false);

  const newGroupRef = useRef(null);

  
  const handleShowGroupForm = () => {
    setShowNewGroupForm(true);
    setShowInputWidget(false);
  };

  const handleCloseGroupForm = () => {
    setShowNewGroupForm(false);
    setShowInputWidget(true);
  };

  useEffect(() => {
    if (newGroupRef.current) {
      newGroupRef.current.focus();
    }
  }, [showNewGroupForm]);


  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        const userId = user.uid;
        const emailRef = firebase.database().ref(`users/${userId}/email`);
        const groupsRef = firebase.database().ref('groups');
        const purchasesRef = firebase.database().ref(`users/${userId}/purchases`);

        emailRef.on('value', (snapshot) => {
          const email = snapshot.val();
          setUserEmail(email);
        });


        groupsRef.on('value', (snapshot) => {
          const data = snapshot.val() || {};
          const groupsArray = Object.keys(data).map((key) => ({
            ...data[key],
            id: key,
          })).filter(group => group.members && group.members[userId]);

          setGroups(groupsArray);
          setLoading(false);
        });

        purchasesRef.on('value', (snapshot) => {
          const data = snapshot.val() || {};
          const purchasesArray = Object.keys(data).map((key) => ({
            ...data[key],
            id: key,
          }));

          setPurchases(purchasesArray);
        });

        // Remember to unsubscribe from the realtime database
        return () => {
          emailRef.off();
          groupsRef.off();
          purchasesRef.off();
          unsubscribe();
        };
      } else {
        // User is not logged in, handle the condition if needed
      }
    });
  }, []);



  const handleLeaveGroup = async (groupId) => {
    const userId = firebase.auth().currentUser.uid;
  
    const groupRef = firebase.database().ref(`groups/${groupId}`);
    const userPurchasesRef = firebase.database().ref(`users/${userId}/purchases`);
    const groupMembersRef = groupRef.child('members');
    const groupPurchasesRef = groupRef.child('purchases');
    
    const userEmail = firebase.auth().currentUser.email;
  
    try {
      // Retrieve the user's purchases
      const userPurchasesSnapshot = await userPurchasesRef.once('value');
      const userPurchases = userPurchasesSnapshot.val();
  
      // Filter the user's purchases to only those in the group
      const groupUserPurchases = Object.entries(userPurchases || {})
        .filter(([purchaseId, purchaseData]) => purchaseData.groupId === groupId);
  
      // Iterate over the groupUserPurchases and update their group and groupId to null
      for (const [purchaseId, purchaseData] of groupUserPurchases) {
        await userPurchasesRef.child(purchaseId).update({
          group: null,
          groupId: null
        });
      }
  
      // Retrieve the group's purchases
      const groupPurchasesSnapshot = await groupPurchasesRef.once('value');
      const groupPurchases = groupPurchasesSnapshot.val();
  
      // Iterate over the group's purchases and remove those added by the current user
      for (const [key, purchase] of Object.entries(groupPurchases || {})) {
        if (purchase.addedBy === userEmail) {
          await groupPurchasesRef.child(key).remove();
        }
      }
  
      // Remove the user from the group members
      await groupMembersRef.child(userId).remove();
  
      // Check if the group has any other members. If not, delete the group.
      const groupMembersSnapshot = await groupMembersRef.once('value');
      const groupMembers = groupMembersSnapshot.val();
  
      if (!groupMembers) {
        await groupRef.remove();
      }
      setSelectedGroupDetail(null); 
    } catch (error) {
      console.error('Error processing group leave:', error);
    }
  };


  useEffect(() => {
    // Calculate the group totals whenever the purchases or groups change
    calculateGroupTotals();
  }, [purchases, groups]);

  const calculateGroupTotals = () => {
    const totals = {};

    groups.forEach((group) => {
      const purchasesByUser = {};
      

      if (group.purchases) {
       

        Object.values(group.purchases).forEach((purchase) => {
          const { addedBy, amount } = purchase;

          // Ensure the amount is a number
          const numericAmount = parseFloat(amount);

          if (isNaN(numericAmount)) {
         
            return;  // Skip this purchase if the amount is not a valid number
          }

          if (purchasesByUser[addedBy]) {
            purchasesByUser[addedBy] += numericAmount;
          } else {
            purchasesByUser[addedBy] = numericAmount;
          }

          
        });

        const sortedMembers = Object.entries(purchasesByUser).sort((a, b) => b[1] - a[1]);
        totals[group.id] = sortedMembers.reduce((acc, [memberEmail, total]) => {
          acc[memberEmail] = total;
          return acc;
        }, {});

        
      }
    });

    
    setGroupTotals(totals);
};



  const handleAddGroup = () => {
    const userId = firebase.auth().currentUser.uid;
    const groupsRef = firebase.database().ref('groups');
    const newGroupRef = groupsRef.push(); // creates a new group node

    const newGroup = {
      name: newGroupName,
      members: {
        [userId]: true, // this user is a member of this group
      },
      purchases: {}, // this will hold the shared purchases
    };

    newGroupRef
      .set(newGroup)
      .then(() => {
        setNewGroupName(''); 
        setShowNewGroupForm(false);// clear the input field
        setShowInputWidget(true);
      })
      .catch((error) => {
        // Handle error if necessary
      });
  };


  const handleGroupButtonClick = (groupId) => {
    setSelectedGroupDetail(groupId);
  };

  const handleCloseGroupDetail = () => {
    setSelectedGroupDetail(null);
  };

  if (loading) {
    return <div>Loading...</div>;
  }



  return (
    <div className="groups-container">


{groups.length > 0 && !selectedGroupDetail && <div className="groupstilesheader">Squareup Groups</div>}



{groups.length === 0 && !selectedGroupDetail && 
  
      <div className="empty-state-container">
        <div className="nogroupsemptycontainer"><
          div className="nogroupstext1">Groups are a great way to square up with your friends, roommates or significant other.</div>
          <img className="emptygroupcebits" src={cebits2} />
          <div className="nogroupstext2">Simply create a group with as many people as you want, and if you make a purchase for the group, add it!
          <br></br><br></br>We'll keep track of who is spending the most, and make it easy for everyone to know who's got the next round. </div> 
          <button className="invite-button" onClick={handleShowGroupForm}>Create A New Group</button>
          </div>
      
       
      </div>



  }

 

      {Object.entries(groupTotals).map(([groupId, members]) => {
  const group = groups.find(group => group.id === groupId);
  const sortedMembers = Object.entries(members).sort((a, b) => b[1] - a[1]);
  const highestTotal = sortedMembers[0][1];
  const userTotal = parseFloat(members[userEmail]) || 0;
  const difference = Math.abs(parseFloat(highestTotal) - userTotal);
  const isUserAhead = userTotal === parseFloat(highestTotal);

 
  return (
    <div key={groupId} className="groupsmegamommy">




    {!selectedGroupDetail && <div
      key={groupId}
      className={`group-totals-group ${group.id === selectedGroup ? 'selected' : ''} ${isUserAhead ? 'ahead' : 'behind'}`}
      onClick={() => handleGroupButtonClick(group.id)}
    > 
    <div className="grouptotalsflexbox">
<div className="tilestatement">
      <h4 className={`group-totals-group-name ${isUserAhead ? 'ahead' : 'behind'}`}>
        {group?.name}
      </h4>
      {isUserAhead ? (
        <div className={`group-totals-statement ${isUserAhead ? 'ahead' : 'behind'}`}>You are this group's top spender</div>
      ) : (
        <div className={`group-totals-statement ${isUserAhead ? 'ahead' : 'behind'}`}>Behind the top spender by {currencySymbol}{difference.toFixed(0)}</div>
      )}</div>
<div className="barchartcontainer">


<GroupSpendingChart               
members={members}
userEmail={userEmail}
currencySymbol={currencySymbol}
isUserAhead={isUserAhead} />
</div></div>
    </div>}
    
    
    </div>
  );
})}

<div>
    {!selectedGroupDetail && (
      <div>
        {groups
          .filter((group) => !groupTotals[group.id])
          .map((group) => (
            <div key={group.id} className="no-purchases-group" onClick={() => handleGroupButtonClick(group.id)}>
              <h4 className="group-totals-group-name no-purchases">{group.name}</h4>
              <div className="no-purchases-statement">No purchases added to this group yet</div>
            </div>
          ))}
      </div>
    )}

    {groups.length > 0 && !selectedGroupDetail && <div className="newgroupbuttoncontainer"> <div className="createnewgroupbutton" onClick={handleShowGroupForm}>Create a New Group</div></div>}

      {selectedGroupDetail && (
        <><div className="backtogroupsbuttoncontain"><button className="backtogroupsbutton" onClick={handleCloseGroupDetail}>
        <IoIosArrowBack style={{paddingTop: "3px", boxSizing: "border-box"}} />Back to Summary
       </button></div>
          <GroupDetail   emojiCategories={emojiCategories} handleShowAnimation={handleShowAnimation} selectedGroup={selectedGroup} setAddedPurchases={setAddedPurchases} setShowInputWidget={setShowInputWidget} handleLeaveGroup={handleLeaveGroup} groupId={selectedGroupDetail} currencySymbol={currencySymbol} purchases={purchases} setShowGroupDeleteMessage={setShowGroupDeleteMessage} setSelectedGroupDetail={setSelectedGroupDetail} />
          {/* <div className="closebuttoncontain"><button className="close-button" onClick={handleCloseGroupDetail}>
           Close Group Details
          </button></div> */}
        </>
      )}
      
      </div>


      {!selectedGroupDetail && showNewGroupForm &&
      <div className="confirmation-message">
<div className="resetconfirmbottom">
    <div className="divaccent4"></div>
   <div className="createnewgroupformheader1">Create a New Group</div>
    <div className="buttons-container">
    {newGroupName.trim().length === 0 && (
      <p className="error-message-invite-dark">Group name cannot be blank</p>
    )}
       <input
       ref={newGroupRef}
        type="text"
        value={newGroupName}
        onChange={(e) => setNewGroupName(e.target.value)}
        placeholder="Enter your group name"
        className="group-input"
      />
      <div className="invitation-message">
    </div>
        {/* <div className="boxoballs2">
            <div className="ball1"></div>
            <div className="ball2"></div>
            <div className="ball3"></div>
            <div className="ball4"></div>
        </div> */}
      <div className="yesno">
        <button className="confirmation-okay-dark" onClick={handleAddGroup}
      disabled={newGroupName.trim().length === 0}>
          Create New Group
        </button>
            <button
                className="confirmation-no-dark"
                onClick={handleCloseGroupForm}
            >
                <IoIosClose style={{fontSize: "1.5rem"}}/>
            </button>
        </div>
    </div>
</div>
</div>}

<div className="budgetlogo">
                    <img
                        className="budgetlogoimg"
                        src={budgetlogo}
                        alt="Budgetlogo"
                    />
                </div>
                <div className="bottomanchor"/>
    </div>
  );
};

export default Groups;
