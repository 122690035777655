import React, { useState, useEffect, useRef } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import coffee1 from "./images/coffee1.svg";
import coffee2 from "./images/coffee2.svg";
import coffee3 from "./images/coffee3.svg";
import "./LoginAnimation.css";
import budgetlogo from "./images/roundedlogo.svg";
import sailboat from "./images/sailboat.svg";
import Onboarding from "./Onboarding";
import ValueProp from "./WhylilBudget";
import { FaCheck } from "react-icons/fa";
import { BiShow, BiHide, BiCloudRain, BiSun, BiRadio } from "react-icons/bi";
import { GiMeditation } from "react-icons/gi";
import Terms from "./Terms";
import PrivacyPolicy from "./PrivacyPolicy";
import Cookies from "js-cookie";
import ReactGA from "react-ga4";
import cityback2 from "./images/citybackground.svg";
import { v4 as uuidv4 } from 'uuid';

import { CSSTransition } from 'react-transition-group';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: "budget-app-d568a.firebaseapp.com",
    databaseURL: "https://budget-app-d568a-default-rtdb.firebaseio.com",
    projectId: "budget-app-d568a",
    storageBucket: "budget-app-d568a.appspot.com",
    messagingSenderId: "219321405159",
    appId: "1:219321405159:web:c4a51c20c9d13376629c42",
    measurementId: "G-6P5Y0TGT7T"
  };

firebase.initializeApp(firebaseConfig);

function LoginForm(props) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showRegistrationForm, setShowRegistrationForm] = useState(false);
    const [resetEmail, setResetEmail] = useState("");
    const [showResetPassword, setShowResetPassword] = useState(false);
    const [resetPasswordSuccess, setResetPasswordSuccess] = useState(false);
    const [resetPasswordError, setResetPasswordError] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showMainLogin, setShowMainLogin] = useState(true);
    const [loading, setLoading] = useState(false);
    const [rememberMe, setRememberMe] = useState(false);
    const [showOnboarding, setShowOnboarding] = useState(false);
    const [agreeToTerms, setAgreeToTerms] = useState(false);
    const [showTerms, setShowTerms] = useState(false);
    const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
    const [showInstall, setShowInstall] = useState(true);
    const [selectedTagline, setSelectedTagline] = useState("");
    const [showValueProp, setShowValueProp] = useState(false);
    const [isPasswordLengthValid, setIsPasswordLengthValid] = useState(false);
    const [isPasswordLetterValid, setIsPasswordLetterValid] = useState(false);
    const [isPasswordNumberValid, setIsPasswordNumberValid] = useState(false);
    const [isPasswordSpecialCharValid, setIsPasswordSpecialCharValid] = useState(false);
    const [isTypingPassword, setIsTypingPassword] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showCookieConsent, setShowCookieConsent] = useState(false);
    const [drops, setDrops] = useState([]);
    const [isRainSoundPlaying, setIsRainSoundPlaying] = useState(false);
    const [showRain, setShowRain] = useState(false);
    const [isZen, setIsZen] = useState(false);
    const [timeOfDay, setTimeOfDay] = useState(0.25);
    const [stars, setStars] = useState([]);
    const [quote, setQuote] = useState('');
    const [showQuote, setShowQuote] = useState(true);
    

    const audioRef = useRef(null);
    const sunRef = useRef(null);
    const skyRef = useRef(null);
    const starsRef = useRef(null);
    const cloudsRef = useRef(null);
    const sliderRef = useRef(null);
    const loginContainerRef = useRef(null);
    const loginContainerRef2 = useRef(null);
    const ppLinkRef = useRef(null);
    
    //AN ARRAY OF PASSIVE QUOTES THAT ARE MEANT TO BE SHOWN RANDOMLY WHEN THE COMPONENT LOADS___________________
    const taglines = [
        "We don't judge your spending, we just help you keep track of it.",
        "For when you want to spend money, but not all of it.",
        "Because spending money on avocado toast is important too.",
        "Spend smarter, not harder.",
        "The app for tracking your discretionary spending.",
        "Less guilt, more fun with your funds.",
        "Money management, but make it fashion.",
        "Track your spending, grow your savings.",
        "Don't want to manage a budget? Try 'intuitive spending'.",
        "We're like a best friend, but for your money.",
        "Make your parents proud of your financial choices.",
    ];

    const quotes = [
        "He who chases two stocks at the same time catches neither; diversify but with deliberation.",

        "A fool and his money are soon parted; a wise man and his money need a really good accountant.",
        
        "Beware of small expenses; a leaky faucet can empty the great barrel of wealth.",
        
        "In the valley of debts, even a penny is king; every little bit counts, except in Vegas.",
        
        "The miser who guards his coffers sleeps not, for the creak of a mouse becomes the footsteps of a thief; invest, don't hoard.",
        
        "He who sows in the fields of risk may reap in the season of regret; venture, but with prudence.",
        
        "The eagle knows not the worth of the pebble, as the rich man knows not the worth of a dime; respect all amounts, however small.",
        
        "The gold of the fool shines bright, but fades swiftly. The silver of the wise gleams gently, and for a lifetime.",
        
        "He who borrows sells tomorrow's peace for today's comfort; weigh well your debts.",
        
        "A single grain of gold can balance a scale, yet a mountain of debt can topple an empire; manage your liabilities wisely.",
        
        "When the river of wealth flows, build not a dam but a mill; let your money work for you.",
        
        "Trust not the jackal that promises riches; seek advice, but beware of false prophets.",
        
        "The tortoise grows not rich, but neither does he owe; slow and steady wins the wealth race.",
        
        "True wealth is like a well, constantly refilled; not a chest, that once emptied, is gone. Strive to create, not just accumulate.",
        
        "A single coin cannot buy the moon, but many coins can create a symphony of clinking.",
        
        "He who rides the camel of speculation might find himself trading fur for feathers in the market of the improbable.",
        
        "The wallet that is thinner than the shadow of a dime often dances with the wind of uncertainty.",
        
        "As the stone sinks in water, so does wealth in the sea of ignorance; but only if the fish have mastered commerce.",
        
        "If you seek prosperity, do not watch the pot; a watched pot never boils, but an unwatched stock never rises.",
        
        "The panda that contemplates the bamboo of investment often forgets the forest of expenditure around him.",
        
        "A coin in the hand might not sing, but a coin in the market might just recite poetry.",
        
        "The peacock who flaunts his wealth to the crows will find his coffers as colourful as a rainbow in the dark.",
        
        "He who counts his chickens before they've hatched, forgets that the rooster also has a say in the stock market.",
        
        "A butterfly cannot tip the scales, yet it may bankrupt the unwary bear in the market of the ephemeral.",
        
        "A wise man knows not to store his gold with his silver; separate your yeast from your baking soda for better reactions.",
        
        "Seek not to add more when less is enough; too much water makes a dough tough.",
        
        "Every grain of rice has its place in the pot, as every grain of salt does in the dough; season your baked goods appropriately.",
        
        "He who wishes to reap the rewards, must first sow the seeds; preheat your oven for optimum results.",
        
        "Investing is akin to baking; in both, precision is key. Measure your ingredients carefully for best results.",
        
        "Just as the river flows around the rock, so too should your investments be flexible; keep your steak tender by marinating before grilling.",
        
        "Rushing a journey will lead one astray; similarly, a slow simmer unlocks the depths of flavor in a stew.",
        
        "One cannot fill a cup that is already full; drain pasta before adding sauce to ensure the best absorption.",
        
        "A blind squirrel finds a nut once in a while, but an experienced chef tastes as they go; adjust your seasonings during cooking, not just at the end.",
        
        "As the peacock's display draws eyes, so should your portfolio attract interest; presentation is key, in finance and in plating.",
        
        "The wise hermit knows the value of isolation; eggs also, whisk best when they are room temperature.",
        
        "The strongest steel is forged in the hottest fire; for a perfect sear, your pan must be properly heated.",
        
        "Just as the mighty oak stands firm, a successful entrepreneur remains steadfast; like risotto, constant stirring leads to creaminess.",
        
        "As a ship in a storm seeks safe harbor, so too should an investor seek refuge in reliable assets; a sharp knife is a safe knife in the kitchen.",
        
        "Time heals all wounds and tenderizes tough meat; slow cooking on a low heat makes even the toughest cuts succulent.",

        "The wind does not discriminate between trees; ensure smoke alarms are functioning to protect all rooms.",

        "As the ant prepares in summer, so the man should in his youth; regularly save a part of your income for retirement.",

        "The wise bird builds a nest for tomorrow; always make your bed in the morning for a productive day.",

        "The bee visits many flowers before returning to the hive; comparison shop before making big purchases to get the best deal.",
    
    
    ];

    // function to get a random quote
    const getRandomQuote = () => {
        let randomIndex = Math.floor(Math.random() * quotes.length);
        if (quotes[randomIndex] !== quote) {
            setQuote(quotes[randomIndex]);
        } else {
            getRandomQuote();
        }
    }
    
    // set an interval to change the quote every 25 seconds (20 seconds show time + 5 seconds gap)
    useEffect(() => {
        getRandomQuote();
        const intervalId = setInterval(() => {
            setShowQuote(false);
            setTimeout(() => {
                getRandomQuote();
                setShowQuote(true);
            }, 5000); // this will cause a 5 second gap between quotes
        }, 25000); 
    
        // Clear interval on unmount
        return () => clearInterval(intervalId);
    }, []);

    const handlePasswordChange = (e) => {
        const value = e.target.value;
        setPassword(value);
        setIsPasswordLengthValid(value.length >= 8);
        setIsPasswordLetterValid(/[a-zA-Z]/.test(value));
        setIsPasswordNumberValid(/\d/.test(value));
        setIsPasswordSpecialCharValid(/[\W_]/.test(value));
      };

    const handleConfirmPasswordChange = (e) => {
        const value = e.target.value;
        setConfirmPassword(value);
    };

    const handleShowRain = (e) => {
        setShowRain(true);
        props.setBackgroundAnimation(false)
    };

    const handleStopRain = (e) => {
        setShowRain(false);
        // quietRainSound();
        // setIsRainSoundPlaying(false);
        setShowMainLogin(true);
        setShowRegistrationForm(false);
        setIsZen(false);
        props.setBackgroundAnimation(true)
    };

    const handleZenRain = (e) => {
        ppLinkRef.current.classList.add('fadeawaydiv'); 
        loginContainerRef.current.classList.add('fadeawaydiv'); 
        loginContainerRef2.current.classList.add('fadeawaydiv');// add the class
        setTimeout(() => {
          setShowMainLogin(false);
          setIsZen(true);
          ppLinkRef.current.classList.remove('fadeawaydiv'); 
          loginContainerRef.current.classList.remove('fadeawaydiv');
          loginContainerRef2.current.classList.remove('fadeawaydiv');  // remove the class
        }, 1500); // wait one second before executing this
   
    };

    const handleEndZenRain = (e) => {
        setShowMainLogin(true);
        setIsZen(false);
        setShowRegistrationForm(false);
        // toggleRainSound();
        // setIsRainSoundPlaying(false);
    };

    const handleZenRainClick = showMainLogin ? handleZenRain : handleEndZenRain;
    const zenClassName = showMainLogin ? "makeitrainbutton3" : "makeitrainbutton4";
    

    // const toggleRainSound = () => {
    //     const audio = audioRef.current;
    
    //     if (audio) {
    //       if (isRainSoundPlaying) {
    //         audio.pause();
    //         audio.currentTime = 0; // Reset audio to the beginning
    //         setIsRainSoundPlaying(false);
    //       } else {
    //         audio.play();
    //         setIsRainSoundPlaying(true);
    //       }
    //     }
    //   };

    //   const quietRainSound = () => {
    //     const audio = audioRef.current;
    //         audio.pause();
    //         audio.currentTime = 0; // Reset audio to the beginning
    //         setIsRainSoundPlaying(false);
    //     };
      
      
    //   const handleZenRadioClick = () => {
    //     if (!audioRef.current) {
    //       audioRef.current = new Audio(rainSound);
    //       audioRef.current.play();
    //       setIsRainSoundPlaying(true);
    //     } else {
    //       toggleRainSound();
    //     }
    //   };
    
    //   const radioClassName = isRainSoundPlaying ? "makeitrainbutton5" : "makeitrainbutton6";


    const toggleShowPassword = (e) => {
        e.preventDefault(); 
        setShowPassword(!showPassword);
      };

    //HOOK TO CHOOSE AND DISPLAY A RANDOM QUOTE FROM THE LIST___________________________________________________
    useEffect(() => {
        const randomIndex = Math.floor(Math.random() * 15);
        setSelectedTagline(taglines[randomIndex]);
    }, []);

    const handleShowValueProp = () => {
        setShowValueProp(true);
        setShowMainLogin(false);
    };

    //CLOSE THE VALUE PROP WINDOW
    const handleCloseValueProp = () => {
        setShowValueProp(false);
        setShowRegistrationForm(false);
        setShowMainLogin(true);
    };

    //SHOW THE TERMS AND CONDITIONS
    const handleTermsClick = () => {
        setShowTerms(true);
    };

      //SHOW THE PRIVACY POLICY
      const handlePrivacyPolicyClick = () => {
        setShowPrivacyPolicy(true);
    };

    //DISMISS THE 'INSTALL AS AN APP' POPUP
    const handleInstallClick = () => {
        setShowInstall(false);
    };

    //CLOSE THE SLIDESHOW
    const handleOnboardingClose = () => {
        setShowOnboarding(false);
        setShowMainLogin(true);
    };

    //SHOW A SLIDESHOW THAT TEACHES THE USER HOW TO INSTALL THE APP
    const handleShowOnboarding = () => {
        setShowOnboarding(true);
        setShowRegistrationForm(false);
    };

    //ALLOW USER TO BE REMEMBERED BASED ON BROWSER COOKIES____________________________________
    const handleRememberMe = () => {
        setShowCookieConsent(true);
    };
    useEffect(() => {
        const userEmail =
            Cookies.get("userEmail") || localStorage.getItem("userEmail");
        // const userPassword =
        //     Cookies.get("userPassword") || localStorage.getItem("userPassword");
        const remember =
            Cookies.get("rememberMe") || localStorage.getItem("rememberMe");
        if (userEmail && remember === 'true') {
            setEmail(userEmail);
            // setPassword(userPassword);
            setRememberMe(true);
        }
    }, []);
    
    const handleCookieConsent = () => {
        setRememberMe(true);
        setShowCookieConsent(false);
        Cookies.set("userEmail", email);
        // Cookies.set("userPassword", password);
        Cookies.set("rememberMe", 'true');
    };


    //HOOK TO CHECK FOR USER CREDENTIALS USING COOKIES________________________________________
    useEffect(() => {
        const userEmail =
            Cookies.get("userEmail") || localStorage.getItem("userEmail");
        const userPassword =
            Cookies.get("userPassword") || localStorage.getItem("userPassword");
        if (userEmail && userPassword) {
            setEmail(userEmail);
            setPassword(userPassword);
            setRememberMe(true);
        }
    }, []);

    //HANDLE EXISTING USER LOG IN_____________________________________________________________
    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
            await firebase.auth().signInWithEmailAndPassword(email, password);
            const user = firebase.auth().currentUser;
            const userRef = firebase.database().ref("users/" + user.uid);

            userRef.once("value", (snapshot) => {
                if (!snapshot.exists()) {
                    // Create a new node for the user in the database
                    userRef.set({
                        email: user.email,
                        currency: "$",
                        data: {},

                    });
                }
            });

            // Save user credentials to cookie or local storage based on "rememberMe" value
            if (rememberMe) {
                Cookies.set("userEmail", email);
                // Cookies.set("userPassword", password);
            } else {
                localStorage.removeItem("userEmail");
                // localStorage.removeItem("userPassword");
            }

            let loginStatusElement = document.querySelector(".loginstatus");
            if (loginStatusElement) {
                loginStatusElement.innerHTML = "Login Successful!";
            }
            props.onLogin(); // Call the onLogin function passed as a prop
            setTimeout(() => {
                props.onDismiss();
                if (loginStatusElement) {
                    loginStatusElement.innerHTML = "Logging in...";
                }
            }, 0);
        } catch (error) {
            console.error(error);

            let loginStatusElement = document.querySelector(".loginstatus");
            if (loginStatusElement) {
                loginStatusElement.innerHTML = "Login failed";
            }

            setTimeout(() => {
                if (loginStatusElement) {
                    loginStatusElement.innerHTML = "";
                }
            }, 4000);
        } finally {
            setLoading(false);
        }
    };

    //HANDLE NEW USER REGISTRATION____________________________________________________________
    const handleRegistration = async (e) => {
        e.preventDefault();
    
        // Password validation rules
        const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    
        if (password !== confirmPassword) {
            document.querySelector(".loginstatus").innerHTML =
                "Passwords do not match";
            return;
        }
    
        if (!password.match(passwordRegex)) {
            document.querySelector(".loginstatus").innerHTML =
                "Password must be at least 8 characters long and contain at least one letter, one number, and a special character.";
            return;
        }
    
        setLoading(true);
    
        try {
            const userCredential = await firebase
            .auth()
            .createUserWithEmailAndPassword(email, password);
    
            // Once the user is created, map the email to uid
            const snapshot = await firebase.database().ref('emailToUid').child(btoa(email)).once('value');
    
            if (!snapshot.exists() || snapshot.val() !== userCredential.user.uid) {
                await firebase.database().ref('emailToUid').child(btoa(email)).set(userCredential.user.uid);
                // console.log('uid set in the database')
            }

            // Create a new node for the user in the database
        const userRef = firebase.database().ref("users/" + userCredential.user.uid);
        userRef.set({
            email: userCredential.user.email,
            currency: "$",
            data: {},
        });
    
            document.querySelector(".loginstatus").innerHTML =
                "Registration successful! Logging you in...";
    
            // Call handleLogin after successful registration
            await handleLogin(e);
    
        } catch (error) {
            console.error(error);
            document.querySelector(".loginstatus").innerHTML =
                "Sorry, registration failed.";
            setLoading(false);
            setTimeout(() => {
                document.querySelector(".loginstatus").innerHTML = "";
            }, 5000);
            ReactGA.event({
                category: "Registration",
                action: "New Account Created",
                label: "User successfully created a new account",
            });
        }
    };
    

    //HANDLE FORGOT PASSWORD__________________________________________________________________
    const handleResetPassword = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            await firebase.auth().sendPasswordResetEmail(resetEmail);
            setResetEmail("");
            setResetPasswordSuccess(true);
            setTimeout(() => {
                setResetPasswordSuccess(false);
                setShowResetPassword(false);
                setShowMainLogin(true);
            }, 3000);
        } catch (error) {
            console.error(error);
            setResetPasswordError(true);
            setTimeout(() => {
                setResetPasswordError(false);
            }, 5000);
        } finally {
            setLoading(false);
        }
    };

    const birdDivs = document.querySelectorAll('.bird');

    birdDivs.forEach((birdDiv, index) => {
      birdDiv.style.setProperty('--delay', index);
    });

    useEffect(() => {
        const interval = setInterval(() => {
          // Generate a new drop, ripple, and splash
          const newDrop = {
            id: uuidv4(),
            left: Math.random() * window.innerWidth,
            bottom: Math.random() * 80,
            duration: Math.random() * 0.05 + 1,
            delay: Math.random() * 2,
            size: Math.random() * 5 + 10,
          };
    
          // Add the new drop to our list, and remove any drops if the list gets too long
          setDrops(drops => {
            const newDrops = [...drops, newDrop];
            if (newDrops.length > 50) {
              newDrops.shift();
            }
            return newDrops;
          });
        }, 800); // Change this value to create drops at a different rate
    
        // Clean up the interval when the component is unmounted
        return () => clearInterval(interval);
      }, []);

    //   useEffect(() => {
    //     // Create an array of stars
    //     const newStars = [];
    //     for (let i = 0; i < 100; i++) { // Adjust this number to add more or fewer stars
    //         newStars.push({
    //             id: i,
    //             left: Math.random() * 100,
    //             top: Math.random() * 100,
    //             size: Math.random() * 2,
    //             delay: Math.random() * 2,
    //         });
    //     }
    //     console.log(newStars);
    //     setStars(newStars);
    // }, []);

    //   function handleSliderChange(e) {
    //     const time = e.target.value;
    //     setTimeOfDay(time);

    //     // Move the sun and clouds
    //     sunRef.current.style.transform = `translateX(${time * 100}%)`;
    //     cloudsRef.current.style.transform = `translateX(${time * 100}%)`;

    //     // Fade the stars in and out
    //     const opacity = (time < 0.25 || time > 0.75) ? (1 - Math.abs(time - 0.5) * 2) : 0;
    //     starsRef.current.style.opacity = opacity;

    //     // Change the sky color
    //     const color = `hsl(${time * 360}, 80%, ${(1 - Math.abs(time - 0.5)) * 80 + 20}%)`;
    //     skyRef.current.style.background = color;
    // }

//     <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
//     <defs>
//         <clipPath id="wavePath" clipPathUnits="objectBoundingBox">
//             <path d="M0 1 C0.2 0.9 0.4 0.8 0.6 0.9 C0.8 1 1 1 1 1 L1 0 L0 0 Z"></path>
//         </clipPath>
//     </defs>
// </svg>

    //JSX_____________________________________________________________________________________
    return (
        <div className="logincontainer">
           
<BiCloudRain className="makeitrainbutton" onClick={handleShowRain}/>
            {showRain && 
            
<div className="animationboxcontainer">
{isZen && <CSSTransition in={showQuote} timeout={2000} classNames="quote-display" unmountOnExit>
            <div className="quote-display">
                {quote}
            </div>
        </CSSTransition>}
<div id="container">

<div className="suncontainer">
      <div id="sun" ref={sunRef}></div></div>
      <div class="birds-container">
  <div class="bird">
    <div class="wing"></div>
    <div className="birdbody"></div>
    <div class="wing2"></div>
  </div>
  <div class="bird2">
    <div class="wing"></div>
    <div className="birdbody"></div>
    <div class="wing2"></div>
  </div>
  <div class="bird3">
    <div class="wing"></div>
    <div className="birdbody"></div>
    <div class="wing2"></div>
  </div>
</div>
      <div className="wave0"></div>
      {drops.map(drop => (
        <React.Fragment key={drop.id}>
          <div
            className="rain"
            style={{
              left: drop.left + 'px',
              animationDuration: drop.duration + 's',
              animationDelay: drop.delay + 's',
            }}
          />
          <div
            className="ripple"
            style={{
              left: drop.left + 'px',
              bottom: drop.bottom + 'px',
              animationDuration: drop.duration + 's',
              animationDelay: drop.delay + 's',
              width: drop.size + 'px',
              height: drop.size * 0.4 + 'px',
            }}
          />
          <div
            className="splash"
            style={{
              left: drop.left + 40 + 'px', 
              bottom: drop.bottom + 20 + 'px',
            }}
          />
        </React.Fragment>
      ))}
     
         <div className="wave wave1"></div>
    <div className="wave wave2"></div>
    <div className="wave wave3"></div>
    <img className="sailboat" src={sailboat} alt="sailboat" />
 
    <div id="sky" ref={skyRef}>
 
                {/* <div id="stars" ref={starsRef}>
                    {stars.map(star => (
                        <div
                            key={star.id}
                            className="star"
                            style={{
                                left: star.left + '%',
                                top: star.top + '%',
                                width: star.size + 'px',
                                height: star.size + 'px',
                                animationDelay: star.delay + 's',
                            }}
                        />
                    ))}
                </div> */}
                {/* <div id="clouds" ref={cloudsRef}></div> */}
            </div>
            {/* <div id="stars" ref={starsRef}>
                    {stars.map(star => (
                        <div
                            key={star.id}
                            className="star"
                            style={{
                                left: star.left + '%',
                                top: star.top + '%',
                                width: star.size + 'px',
                                height: star.size + 'px',
                                animationDelay: star.delay + 's',
                            }}
                        />
                    ))}
                

                </div> */}
            {/* <input type="range" min="0" max="1" step="0.01" value={timeOfDay} onChange={handleSliderChange} ref={sliderRef} /> */}
            
          
            
            </div>
            
    <BiSun className="makeitrainbutton2" onClick={handleStopRain}/>
    <GiMeditation className={zenClassName} onClick={handleZenRainClick} />
    {/* {isZen && <BiRadio className={radioClassName} onClick={handleZenRadioClick} />} */}
    </div>}

            {showValueProp && (
                <div className="whylilbudgetcont">
                    <ValueProp onClose={handleCloseValueProp} />
                </div>
            )}
           {!isZen && <div className="whatslilbudget" ref={loginContainerRef2}>
                <button
                    className="showvaluepropbutton"
                    onClick={handleShowValueProp}
                >
                    What's lilBudget?
                </button>
            </div>}
            {showTerms && <Terms setShowTerms={setShowTerms} />}
            {showPrivacyPolicy && <PrivacyPolicy setShowPrivacyPolicy={setShowPrivacyPolicy} />}

            {showRegistrationForm && showInstall && (
                <div className="installasanapp">
                    <br></br>
                    <div className="installcta" onClick={handleShowOnboarding}>
                        Tap here to learn how to install lilBudget on your
                        mobile device.
                    </div>
                    <button
                        className="closeinstall"
                        onClick={handleInstallClick}
                    >
                        Dismiss
                    </button>
                </div>
            )}

            {showMainLogin && (
                <form ref={loginContainerRef} className="logincontainer2" onSubmit={handleLogin}>

                    <div className="logincontainer3">
                        
                        <p className="tagline">{selectedTagline}</p>
                        <div className="roundedlogo">
                            <img src={budgetlogo} alt="Budgetlogo" />
                            {/* <img
                                className="betalogo2"
                                src={betalogo}
                                alt="betalogo"
                            /> */}
                        </div>
                        <div className="logincont">
                            <input
                                className="loginemail"
                                type="email"
                                placeholder="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div className="logincont">
                        <div className="inputpw-container">
                            <input
                                className="loginpassword"
                                type={showPassword ? 'text' : 'password'}
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            /> 
                            <button type="button" className="hideorshowpassword2" onClick={toggleShowPassword}>
                            {showPassword ? <BiHide/> : <BiShow/>}</button>
                            </div>
                        </div>
                        <div className="logincont">
                            {loading ? (
                                <div className="loader2">
                                    <div className="boxoballs">
                                        <div className="ball1"></div>
                                        <div className="ball2"></div>
                                        <div className="ball3"></div>
                                        <div className="ball4"></div>
                                    </div>
                                </div>
                            ) : (
                                <button className="loginbutton" type="submit">
                                    Log In
                                </button>
                            )}
                        </div>
                        <div className="logincont">
                            <input
                                className="remembermecheckbox"
                                style={{border: "none"}}
                                type="checkbox"
                                id="rememberMe"
                                name="rememberMe"
                                checked={rememberMe}
                                onChange={(event) => {
                                    if (!rememberMe) {
                                      handleRememberMe(event);
                                    } else {
                                      setRememberMe(false);
                                    }
                                  }}
                            />
                            <label
                                className="remembermelabel"
                                htmlFor="rememberMe"
                            >
                                Remember me<span className="checkmark"></span>
                            </label>
                        </div>
                        <div className="loginstatus"></div>
                        <div className="logincont">
                            <button
                                className="regbuttonoutline"
                                type="button"
                                onClick={() => {
                                    setShowRegistrationForm(true);
                                    setShowMainLogin(false);
                                }}
                            >
                                Create a Free Account
                            </button>
                        </div>
                        <button
                            className="forgotpassword"
                            type="button"
                            onClick={() => {
                                setShowResetPassword(true);
                                setShowMainLogin(false);
                            }}
                        >
                            Forgot Password?
                        </button>


                        {showCookieConsent && (<div className="cookieconsentcontainer">
    <div className="cookie-consent-modal">
        <p><strong>We use cookies to remember your login details, and we need your consent.</strong><br></br><br/> Do you want these cookies?</p>
        <button className="cookiebutton" onClick={handleCookieConsent}>Yes, cookies please</button>
        <button className="cookiebutton2" onClick={() => setShowCookieConsent(false)}>No cookies</button>
    </div></div>
)}


                    </div>
                </form>

                
                
            )}

            {showRegistrationForm && (
                <form className="logincontainer2" onSubmit={handleRegistration}>
                    <div className="logincontainer7">

                        <div className="newuserregistrationheader">New User Registration</div>
                        {/* <p className="tagline">{selectedTagline}</p> */}
                        <div className="roundedlogosmaller">
                            <img src={budgetlogo} alt="Budgetlogo" />
                       
                        </div>
                        
                        <div className="logincont">
                            <input
                                className="loginemail"
                                type="email"
                                name="email"
                                placeholder="Enter email address"
                                required
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div className="logincont">
                        <div className="inputpw-container">
                            <input
                                className="loginpassword"
                                type={showPassword ? 'text' : 'password'}
                                name="password"
                                placeholder="Choose a password"
                                required
                                value={password}
                                onChange={handlePasswordChange}
                                onFocus={() => {
                                    setIsTypingPassword(true);
                                    document.querySelector(
                                        ".loginstatus"
                                    ).innerHTML = "";
                                }}
                                onBlur={() => setIsTypingPassword(false)}
                            /></div>
                        </div>
                        <div className="logincont">
                        <div className="inputpw-container">
                            <input
                                className="loginpassword"
                                type={showPassword ? 'text' : 'password'}
                                name="confirmPassword"
                                placeholder="Confirm your password"
                                required
                                value={confirmPassword}
                                onChange={handleConfirmPasswordChange}
                                onFocus={() => {
                                    setIsTypingPassword(true);
                                    document.querySelector(
                                        ".loginstatus"
                                    ).innerHTML = "";
                                }}
                                onBlur={() => setIsTypingPassword(false)}
                            />
                        </div></div>
                       
                        <button className="hideorshowpassword" onClick={toggleShowPassword}>
          {showPassword ? <BiHide /> : <BiShow />} 
        </button>

                        {isTypingPassword ? (
                            <div className="password-validation">
                                <div
                                    className={
                                        isPasswordLengthValid
                                            ? "valid"
                                            : "invalid"
                                    }
                                >
                                    {isPasswordLengthValid ? (
                                        <>
                                            <FaCheck /> Over 8
                                            characters
                                        </>
                                    ) : (
                                        "At least 8 characters"
                                    )}
                                </div>
                                <div
                                    className={
                                        isPasswordLetterValid
                                            ? "valid"
                                            : "invalid"
                                    }
                                >
                                    {isPasswordLetterValid ? (
                                        <>
                                            <FaCheck /> One
                                            letter
                                        </>
                                    ) : (
                                        "At least one letter"
                                    )}
                                </div>



                                <div
                                    className={
                                        isPasswordNumberValid
                                            ? "valid"
                                            : "invalid"
                                    }
                                >
                                    {isPasswordNumberValid ? (
                                        <>
                                            <FaCheck /> One
                                            number
                                        </>
                                    ) : (
                                        "At least one number"
                                    )}
                                </div>



                                <div
                                    className={
                                        isPasswordSpecialCharValid
                                            ? "valid"
                                            : "invalid"
                                    }
                                >
                                    {isPasswordSpecialCharValid ? (
                                        <>
                                            <FaCheck /> One
                                            special character
                                        </>
                                    ) : (
                                        "At least one special character"
                                    )}
                                </div>




                                {isPasswordLengthValid &&
                                    isPasswordLetterValid &&
                                    isPasswordNumberValid && 
                                    isPasswordSpecialCharValid && (
                                        <div
                                            className={
                                                password === confirmPassword
                                                    ? "valid"
                                                    : "invalid"
                                            }
                                        >
                                            {password === confirmPassword ? (
                                                <>
                                                    <FaCheck /> Passwords match
                                                </>
                                            ) : (
                                                "Passwords must match"
                                            )}
                                        </div>
                                    )}
                            </div>
                        ) : (
                            <div className="loginstatus"></div>
                        )}

                        <div className="logincont4">
                            <div className="termsstatement">
                                <input
                                    className="remembermecheckbox"
                                    style={{ marginRight: "5px", border: "none"}}
                                    type="checkbox"
                                    
                                    id="agreeToTerms"
                                    checked={agreeToTerms}
                                    onChange={() =>
                                        setAgreeToTerms(!agreeToTerms)
                                    }
                                />
                                I agree to the
                                <div
                                    className="tnclink"
                                    onClick={handleTermsClick}
                                >
                                    terms and conditions
                                </div>
                            </div>
                        </div>
                        <div className="logincont">
                            {loading ? (
                                <div className="loader">
                                    <div className="boxoballs">
                                        <div className="ball1"></div>
                                        <div className="ball2"></div>
                                        <div className="ball3"></div>
                                        <div className="ball4"></div>
                                    </div>
                                </div>
                            ) : (
                                <button
                                    className="regbutton"
                                    type="submit"
                                    disabled={!agreeToTerms}
                                    style={
                                        !agreeToTerms
                                            ? {
                                                  opacity: 0.5,
                                                  backgroundColor: "grey",
                                                  cursor: "not-allowed",
                                              }
                                            : {}
                                    }
                                >
                                    Register
                                </button>
                            )}
                        </div>
                        <div className="logincont">
                            <button
                                className="cancelregbutton"
                                type="button"
                                onClick={() => {
                                    setShowRegistrationForm(false);
                                    setShowMainLogin(true);
                                }}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </form>
            )}

            {showResetPassword && (
                <form
                    className="logincontainer2"
                    onSubmit={handleResetPassword}
                >
                    <div className="logincontainer3">
                        <div className="roundedlogosmaller">
                            <img src={budgetlogo} alt="Budgetlogo" />
                        </div>
                        <p
                            style={{
                                width: "300px",
                                color: "#2c2c2c",
                                fontSize: "0.7rem",
                                textAlign: "center",
                            }}
                        ><br></br>
                            Forgot your password? <br></br>
                            <br></br> Enter the email you used to sign up.{" "}
                            <br></br>We'll send you a link to set a new one.{" "}
                        </p>
                    
                        <div className="logincont">
                            <input
                                className="loginemail"
                                type="email"
                                name="resetEmail"
                                placeholder="Enter your email address"
                                required
                                value={resetEmail}
                                onChange={(e) => setResetEmail(e.target.value)}
                            />
                        </div>
                        <p   style={{
                                width: "300px",
                                color: "#2c2c2c",
                                fontSize: "0.7rem",
                                textAlign: "center",
                                fontWeight: "600"
                            }}>Remember to check your spam folder if you don't see the message.</p>
                        <div className="loginstatus">
                            {resetPasswordError && (
                                <div className="loginstatus">
                                    Error sending password reset email. Make
                                    sure you enter the email you signed up with.
                                </div>
                            )}

                            {resetPasswordSuccess && (
                                <div className="loginstatus">
                                    Password reset email sent!
                                </div>
                            )}
                        </div>
                        <div className="logincont">
                            {loading ? (
                                <div className="loader">
                                    <div className="boxoballs">
                                        <div className="ball1"></div>
                                        <div className="ball2"></div>
                                        <div className="ball3"></div>
                                        <div className="ball4"></div>
                                    </div>
                                </div>
                            ) : (
                                <button className="regbutton" type="submit">
                                    Reset Password
                                </button>
                            )}
                        </div>
                        <div className="logincont">
                            <button
                                className="cancelregbutton"
                                type="submit"
                                onClick={() => {
                                    setShowResetPassword(false);
                                    setShowMainLogin(true);
                                }}
                            >
                                Go back!
                            </button>
                        </div>{" "}
                        <div className="logincont">
                            <div className="spacer"></div>
                        </div>
                        <div className="logincont">
                            <div className="spacer"></div>
                        </div>
                        <div className="logincont">
                            <div className="spacer"></div>
                        </div>
                    </div>
                </form>
            )}

            {showOnboarding && <Onboarding onClose={handleOnboardingClose} />}
            
            {!isZen && !showOnboarding && <div ref={ppLinkRef}
                                    className="pplink"
                                    onClick={handlePrivacyPolicyClick}
                                >
                                    Privacy Policy
                                </div>}
        </div>
    );
}

export default LoginForm;
