import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import 'firebase/compat/database';
import 'firebase/compat/firestore';
import archivefolder from "./images/archivefolder.svg";
import "./Archive.css";
import { RiAlertFill } from "react-icons/ri";
import { saveAs } from 'file-saver';
import {  useNavigate, useLocation } from 'react-router-dom';

const db = firebase.database();


function ArchivePage({ currencySymbol, setActiveComponent }) {
  const [archivedPurchases, setArchivedPurchases] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState('date');
  const [editMode, setEditMode] = useState(false);
  const [showDeleteAll, setShowDeleteAll] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [inputColor, setInputColor] = useState('');
  const [deleteClicked, setDeleteClicked] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);

  const toggleEditMode = () => {
    setEditMode(!editMode);
    setShowDeleteAll(true);
    setInputColor('#FFFFFF');
    setDeleteClicked(false);
  };

  const handleFormSubmit = (event, purchaseId, newCategory) => {
    event.preventDefault();
    const userId = firebase.auth().currentUser.uid;
    const purchaseRef = db.ref(`users/${userId}/archivedpurchases/${purchaseId}`);
    purchaseRef.update({ category: newCategory }).then(() => {
      setInputColor('#d4edda');
    });
  };

  const navigate = useNavigate();
  const location = useLocation();
  const uniqueDates = Array.from(new Set(archivedPurchases.map(purchase => new Date(purchase.archiveDate).toDateString())));

  useEffect(() => {
    if (!editMode) {
      // Save the edited category data to the database
      const userId = firebase.auth().currentUser.uid;
      archivedPurchases.forEach((purchase) => {
        const purchaseRef = db.ref(`users/${userId}/archivedpurchases/${purchase.id}`);
        purchaseRef.update({ category: purchase.category });
      });
    }
  }, [editMode, archivedPurchases]);

  function calculateStatistics() {
    if (archivedPurchases.length === 0) {
      return { totalAmountSpent: 0, numberOfPurchases: 0, timeSpanInDays: 0 };
    }
  
    let totalAmountSpent = 0;
    let numberOfPurchases = archivedPurchases.length;
    let firstPurchaseDate = new Date(archivedPurchases[0].date);
    let lastPurchaseDate = new Date(archivedPurchases[archivedPurchases.length - 1].date);
    
    // Adjust dates to include current day
    firstPurchaseDate.setHours(0, 0, 0, 0);
    lastPurchaseDate.setHours(23, 59, 59, 999);
    
    let timeSpanInDays = Math.ceil((lastPurchaseDate - firstPurchaseDate) / (1000 * 60 * 60 * 24));
  
    for (let i = 0; i < archivedPurchases.length; i++) {
      totalAmountSpent += parseFloat(archivedPurchases[i].amount);
    }
  
    return { totalAmountSpent, numberOfPurchases, timeSpanInDays };
  }

  let stats = calculateStatistics();


  const downloadCsv = () => {
    const csvData = [
      ['Category', 'Amount', 'Date', 'Key'],
      ...archivedPurchases.map(purchase => [
        purchase.category,
        `${currencySymbol}${Number(purchase.amount).toFixed(2)}`,
        new Date(purchase.date).toLocaleDateString(),
        purchase.note,
      ]),
    ].map(row => row.join(','));

    const csvString = csvData.join('\n');
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, 'archived_purchases.csv');
  };


  const userId = firebase.auth().currentUser.uid;


  const handleDeleteAll = () => {
    setShowDeleteConfirmation(true);
  };



  useEffect(() => {
    const userId = firebase.auth().currentUser.uid;
    const purchaseRef = db.ref(`users/${userId}/archivedpurchases`);
  
    if (!editMode) {
      archivedPurchases.forEach((purchase) => {
        purchaseRef.child(purchase.id).update({ category: purchase.category });
      });
    }
  
    const listener = purchaseRef.orderByChild('date').on('value', (snapshot) => {
      const data = snapshot.val() || {};
      const archivedPurchasesArray = Object.keys(data).map((key) => ({
        ...data[key],
        id: key,
      }))
        .sort((a, b) => {
          if (sortColumn === 'category') {
            return sortDirection === 'asc'
              ? a.category.localeCompare(b.category)
              : b.category.localeCompare(a.category);
          } else if (sortColumn === 'amount') {
            return sortDirection === 'asc'
              ? a.amount - b.amount
              : b.amount - a.amount;
          } else if (sortColumn === 'date') {
            return sortDirection === 'asc'
              ? new Date(a.date) - new Date(b.date)
              : new Date(b.date) - new Date(a.date);
          } else if (sortColumn === 'key') {
            return sortDirection === 'asc'
              ? a.key.localeCompare(b.key)
              : b.key.localeCompare(a.key);
          } else {
            return 0;
          }
        });
      setArchivedPurchases(archivedPurchasesArray);
      setLoading(false);
    }, (error) => {
      setError(error.message);
      setLoading(false);
    });
  
    return () => {
      purchaseRef.off('value', listener);
    };
  }, [editMode, sortColumn, sortDirection]);

  const handleDateChange = (selectedOption) => {
    if (selectedOption === "") {
      setSelectedDate(null); // Clear the selected date to show all purchases
    } else {
      setSelectedDate(selectedOption);
    }
  };

  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  if (error) {
    return (
      <div className="purchases-container">
        <div className="error">{error}</div>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="purchases-container">
        <div className="loading">Loading...</div>
      </div>
    );
  }

  return (
    <div className="archivedpurchases-container">



   
{archivedPurchases.length > 0 && <div>
  {/* <div className="archive-header">Archive</div> */}
<div className="statistics-container">

      <div className="archive-stat-container">Total Spent: {currencySymbol}{stats.totalAmountSpent.toFixed(2)}</div>
      <div className="archive-stat-container">Purchase Count: {stats.numberOfPurchases}</div>
      <div className="archive-stat-container">Total History: {stats.timeSpanInDays} days</div>
    </div></div>}
{showDeleteConfirmation && (
  <div className="confirmation-message">
    <div style={{color: "#2c2c2c"}} className="resetconfirm">
      <div className="divaccent3"></div><br></br>
      <p style={{margin: "0px", fontSize: "3rem", color: "#852626"}}><RiAlertFill/></p><br></br> 
        This will delete all of your archived purchase data forever!<br></br>
        Are you sure you want to proceed? 
      <div className="buttons-container">
        <div className="boxoballs2">
          <div className="ball1"></div>
          <div className="ball2"></div>
          <div className="ball3"></div>
          <div className="ball4"></div>
        </div>
        <div className="yesno">
          <button style={{backgroundColor: "#852626"}} className="confirmation-okay" onClick={() => {
            
            const userId = firebase.auth().currentUser.uid;
          const archivedpurchasesRef = db.ref(`users/${userId}/archivedpurchases`);
          archivedpurchasesRef.remove().then(() => {
            
            setArchivedPurchases([]);
            setShowDeleteAll(false);
            
            setShowDeleteConfirmation(false);
          });
            
            }}>Delete Purchases Forever</button>
          <button className="confirmation-no" onClick={() => setShowDeleteConfirmation(false)}>Cancel</button>
        </div>
      </div>
    </div>
  </div>
)}

      <div className="">
      
        <div>
        <div className="archive-dropdown-header">Select Archive Date</div>
        <select className="archive-date-button" onChange={(e) => handleDateChange(e.target.value)}>
  <option value="">Show All</option>
  {uniqueDates.map(date => (
    <option key={date} value={date}>
      {date}
    </option>
  ))}
</select></div>


      {archivedPurchases.length > 0 &&  <div className="archive-table-container"> 
      
      <div className="archivebuttoncontainer">
<button 
  className={editMode ? "chartbycategory2 active" : "chartbycategory2"} 
  onClick={toggleEditMode}
>
  {editMode ? "Done" : "Modify"}
</button> 

{!editMode ? <button className="chartbycategory2" onClick={downloadCsv} >Export .csv</button> : <button style={{border: '1px solid #8a8a8a', backgroundColor: "#8a8a8a", color: "#c2c2c2"}} className="chartbycategory2" >Export .csv</button>}
</div>

{editMode ? <div style={{backgroundColor: "#852626"}} className="archivetableheaderlabel">- Modifying Archived Purchases -</div> : <div className="archivetableheaderlabel">- Archived Purchases -</div>}
          
          
          <table>
            
          <thead>
  <tr style={{ backgroundColor: '#ebebebf3', fontSize: '0.8rem'}}>
    <th style={{ width: editMode ? '50%' : '80%', fontSize: '0.8rem', textAlign: 'left', cursor: 'pointer' }} onClick={() => handleSort('category')}>
      Description {sortColumn === 'category' && (sortDirection === 'asc' ? <>&uarr;</> : <>&darr;</>)}
    </th>
    <th style={{ width: editMode ? '10%' : '10%', fontSize: '0.8rem', textAlign: 'center', cursor: 'pointer' }} onClick={() => handleSort('amount')}>
      Amount {sortColumn === 'amount' && (sortDirection === 'asc' ? <>&uarr;</> : <>&darr;</>)}
    </th>
    <th style={{ width: editMode ? '20%' : '10%', fontSize: '0.8rem', textAlign: 'center', cursor: 'pointer' }} onClick={() => handleSort('date')}>
      Date {sortColumn === 'date' && (sortDirection === 'asc' ? <>&uarr;</> : <>&darr;</>)}
    </th>
    {/* <th style={{ width: editMode ? '10%' : '40%', fontSize: '0.8rem', textAlign: 'left', paddingLeft: "10px", cursor: 'pointer' }} onClick={() => handleSort('key')}>
      Note {sortColumn === 'key' && (sortDirection === 'asc' ? <>&uarr;</> : <>&darr;</>)}
    </th> */}
    {editMode && (
      <th style={{ textAlign: 'right', width: "10%" }}>
        {showDeleteAll && (
          <button className="archivedeleteallbutton"
          onClick={handleDeleteAll}
          >
            Delete All
          </button>
        )}
      </th>
    )}
  </tr>
</thead>
<tbody>
{archivedPurchases
 .filter(purchase => !selectedDate || new Date(purchase.archiveDate).toDateString() === selectedDate)
.map((purchase) => (
    <tr key={purchase.id}>
      {editMode ? (
        <td style={{fontSize: "0.8rem", width: "100%"}}>
          <form
            onSubmit={(event) =>
              handleFormSubmit(
                event,
                purchase.id,
                event.target.category.value
              )
            }
          >
            <input className="editarchivecategory" defaultValue={purchase.category} name="category" style={{ backgroundColor: inputColor }} />
            <button className="savecategoryeditbutton" type="submit">✔</button>
          </form>
        </td>
      ) : (
        <td className="tableheader" style={{fontSize: '0.9rem' }}><div className="tablenumber9">{purchase.category}</div><div style={{fontWeight: "400"}} className="tablenumber7">{purchase.note}</div></td>
      )}
      <td className="tablenumber2" style={{ width: '20%', textAlign: 'center', fontSize: '0.8rem' }}>
        {`${currencySymbol}${Number(purchase.amount).toFixed(2)}`}
      </td>
      <td className="tablenumber2" style={{ textAlign: 'center', fontSize: '0.8rem'}}>
        {new Date(purchase.date).toLocaleDateString()}
      </td>
      {/* <td className="tablenumber2" style={{ textAlign: 'center' }}>
        
      </td> */}
      {editMode && (
        <td>








{!deleteClicked[purchase.id] ? (
          <button
            className="archivedeletebutton"
            onClick={() =>
              setDeleteClicked({ ...deleteClicked, [purchase.id]: true })
            }
          >
            Delete
          </button>
        ) : (
          <button
            className="archivedeletebuttonconfirm"
            onClick={() => {
              const userId = firebase.auth().currentUser.uid;
              const purchaseRef = db.ref(
                `users/${userId}/archivedpurchases/${purchase.id}`
              );
              purchaseRef.remove();
              setDeleteClicked({
                ...deleteClicked,
                [purchase.id]: false,
              });
            }}
          >
            Confirm Delete
          </button>
        )}



        </td>
      )}
    </tr>
  ))}
</tbody>
          </table>
          
     

        </div> }
        
        {archivedPurchases.length === 0 && (
  <div style={{paddingTop: "20px" }} className="emptychartstatearchive"><div className="archivefoldercontainer"><img className="archivefolderimage" src={archivefolder} /></div>Nothing archived yet!<br></br>This is where your purchases will go after you reset your budget.</div> 

  
)}


      </div>
     

<div className="close-archive-container">      
<button
                            className="close-archive-nav"
                            onClick={() => {
                               
                                if (location.pathname === "/archive") {
                                    navigate('/', { state: { fromExtension: true } });

                                    setActiveComponent("budgets");
                                } else {
                                    navigate("/archive");

                                    setActiveComponent("budgets");
                                }
                            }}
                        >
                            {location.pathname === "/archive"
                                ? "Close Archive"
                                : "View Archive"}
                        </button>
              </div>

      
    </div>
  );
}

export default ArchivePage;