import React, { useEffect, useRef } from "react";
import { GrClose } from "react-icons/gr";
import { IoLogoVenmo } from "react-icons/io5";
import { FaTshirt } from "react-icons/fa";

import { useNavigate, useLocation } from "react-router-dom";

function OptionsMenu(props) {
    const menuRef = useRef(null);

    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            props.closeMenu();
        }
    };

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div ref={menuRef}>
            <div className="optionsmenucontainer" onClick={() => {}}>
                <div
                    className="closeoptionsmenucontainer"
                    onClick={props.closeMenu}
                >
                    <button onClick={props.closeMenu} className="closeoptionsmenubutton">
                        <GrClose />
                    </button>
                </div>
                <div className="optionsoptions">
                    <div className="optionselections">
                        <button
                            className="menuoption"
                            onClick={() => {
                                props.closeMenu();
                                if (location.pathname === "/profile") {
                                    navigate('/', { state: { fromExtension: true } });

                                    props.setActiveComponent("budgets");
                                } else {
                                    navigate("/profile");

                                    props.setActiveComponent("budgets");
                                }
                            }}
                        >
                            {location.pathname === "/profile"
                                ? "Back to Budget"
                                : "Account"}
                        </button>

                        {/* <button
                            className="menuoption"
                            onClick={() => {
                                props.closeMenu();
                                if (location.pathname === "/review") {
                                    navigate('/', { state: { fromExtension: true } });

                                    props.setActiveComponent("budgets");
                                } else {
                                    navigate("/review");

                                    props.setActiveComponent("budgets");
                                }
                            }}
                        >
                            {location.pathname === "/review"
                                ? "Back to Budget"
                                : "Review Purchases"}
                        </button> */}




                        <button
                            className="menuoption"
                            onClick={() => {
                                props.closeMenu();
                                if (location.pathname === "/archive") {
                                    navigate('/', { state: { fromExtension: true } });

                                    props.setActiveComponent("budgets");
                                } else {
                                    navigate("/archive");

                                    props.setActiveComponent("budgets");
                                }
                            }}
                        >
                            {location.pathname === "/archive"
                                ? "Close Archive"
                                : "View Archive"}
                        </button>
                    
                        <button
                            className="menuoption"
                            onClick={() => {
                                props.closeMenu();
                                if (location.pathname === "/support") {
                                    navigate('/', { state: { fromExtension: true } });

                                    props.setActiveComponent("budgets");
                                } else {
                                    navigate("/support");

                                    props.setActiveComponent("budgets");
                                }
                            }}
                        >
                            {location.pathname === "/support"
                                ? "Back to Budget"
                                : "Support"}
                        </button>



                        <button
                            className="menuoption"
                            onClick={() => {
                                props.closeMenu();
                                if (location.pathname === "/about") {
                                    navigate('/', { state: { fromExtension: true } });

                                    props.setActiveComponent("budgets");
                                } else {
                                    navigate("/about");

                                    props.setActiveComponent("budgets");
                                }
                            }}
                        >
                            {location.pathname === "/about"
                                ? "Back to Budget"
                                : "About Us"}
                        </button>

                        <button
                            className="menuoption"
                            onClick={() => {
                                props.closeMenu();
                                props.setShowInputWidget(false);
                                props.setShowTutorial(true);}}
                        >
                        
                                Show Tutorial
                        </button>

                        <button
                            className="menuoption"
                            onClick={props.openLogoutConfirmation}
                        >
                            Log Out
                        </button>


                        <a
                            href="https://venmo.com/code?user_id=3795752434271502181"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <button className="menuoption">
                                <IoLogoVenmo
                                    style={{
                                        color: "#0074DE",
                                        fontSize: "0.9rem",
                                        paddingTop: "5px",
                                        boxSizing: "border-box",
                                    }}
                                />
                                Donate
                            </button>
                        </a>
                        <a
                            href="https://lilbudget.printify.me/products"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <button className="menuoptionlast">
                                <FaTshirt
                                    style={{
                                        color: "#6EEDAD",
                                        fontSize: "0.9rem",
                                        paddingTop: "5px",
                                        boxSizing: "border-box",
                                    }}
                                />
                                lilBudget Swag
                            </button>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OptionsMenu;
