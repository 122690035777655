import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import map from "./images/map.svg";
import budgetlogo from "./images/lillogo.svg";
import crowd from "./images/crowd.svg";
import citybackground5 from "./images/citybackground5.svg";
import {FaSpinner} from "react-icons/fa";
import "./AboutUs.css";

function AboutUs(fromExtension) {
  const navigate = useNavigate();
  const location = useLocation();

  const [displayWhoWeAre, setDisplayWhoWeAre] = useState(true);
  const [displayMissionValues, setDisplayMissionValues] = useState(false);
  const [displayFAQs, setDisplayFAQs] = useState(false);
  const [displayRoadmap, setDisplayRoadmap] = useState(false);

  const [isLoading, setIsLoading] = useState({
    map: true,
    crowd: true,
    citybackground5: true
  });

  useEffect(() => {
    const images = {
      map: map,
      crowd: crowd,
      citybackground5: citybackground5,
      budgetlogo: budgetlogo
    };

    Object.keys(images).forEach((key) => {
      const img = new Image();
      img.src = images[key];
      img.onload = () => setIsLoading(prevState => ({...prevState, [key]: false}));
      img.onerror = () => setIsLoading(prevState => ({...prevState, [key]: false}));
    });
  }, []);

  const handleClose = () => {
    navigate('/', { state: { fromExtension: true } });
  };

  const handleItemClick = (item) => {
    switch (item) {
      case 'whoWeAre':
        if (displayWhoWeAre) {
          setDisplayWhoWeAre(false);
        } else {
          setDisplayWhoWeAre(true);
          setDisplayMissionValues(false);
          setDisplayFAQs(false);
          setDisplayRoadmap(false);
        }
        break;
      case 'missionValues':
        if (displayMissionValues) {
          setDisplayMissionValues(false);
        } else {
          setDisplayWhoWeAre(false);
          setDisplayMissionValues(true);
          setDisplayFAQs(false);
          setDisplayRoadmap(false);
        }
        break;
      case 'faqs':
        if (displayFAQs) {
          setDisplayFAQs(false);
        } else {
          setDisplayWhoWeAre(false);
          setDisplayMissionValues(false);
          setDisplayFAQs(true);
          setDisplayRoadmap(false);
        }
        break;
      case 'roadmap':
        if (displayRoadmap) {
          setDisplayRoadmap(false);
        } else {
          setDisplayWhoWeAre(false);
          setDisplayMissionValues(false);
          setDisplayFAQs(false);
          setDisplayRoadmap(true);
        }
        break;
      default:
        break;
    }
  };
  

  return (
    <div className="aboutus-container">
     
    
      <ul className="aboutus-list">
      <div className="aboutus-header">About lilBudget</div>
        <li
          className={`aboutus-item ${displayWhoWeAre ? 'active' : ''}`}
          onClick={() => handleItemClick('whoWeAre')}
        >
             
          Who we are
        </li>
        {displayWhoWeAre && (
       <div className="aboutus-content" style={{ backgroundImage: `url(${map})` }}>
   {isLoading.map && <div className="full-loader"><FaSpinner className="spinner" /></div>}
          
          <div className="whowearecontainer">
            
            <div className="whowearewindow">
              
              <div className="aboutus-textheader">Thanks for taking an interest in us!</div>
              <div className="aboutus-textcontent"><br></br>lilBudget is an independently owned and operated LLC based out of South Burlington, Vermont.<br></br><br></br>We are not owned by any big data interests, financial institions, or otherwise. This app was born from a passion for design and creating useful products. lilBudget does not collect or sell data- we are funded entirely by donations and the merch store.<br></br><br></br>Our hope is that lilBudget makes your spending habits less of a black box, and gives you the tools to be proactive with your purchase decisions. All the while delivering it to you in a format that's a little less buttoned-up, and isn't run by a company who lobbies the government to make sure your taxes are as confusing as possible.<br></br><br></br>The small but mighty team at lilBudget is committed to listening to your feedback and constantly improving our app to serve you better. If you have an idea, question or run into any problems, please reach out to us at info@lilbudget.me.<br></br><br></br>Thanks for using lilBudget!</div>
              
              
              
              
              
              
              
              
              </div>
            
            </div>
        </div>
      )}
        <li
          className={`aboutus-item ${displayMissionValues ? 'active' : ''}`}
          onClick={() => handleItemClick('missionValues')}
        >
          Our Mission and Values
        </li>
        {displayMissionValues && (
        <div className="aboutus-contentmission" style={{ backgroundImage: `url(${citybackground5})` }}>
             {isLoading.citybackground5 && <div className="full-loader"><FaSpinner className="spinner" /></div>}
            <div className="whowearecontainer">
                <div className="whowearewindowmission">
                  
                  
                  
                <div className="aboutus-textheader2">Our Mission</div>
              <div className="aboutus-textcontent2">lilBudget strives to empower individuals with financial clarity and control. We provide user-friendly digital tools to simplify budgeting, enhance savings strategies, and streamline group expenses. Our goal is to make financial management accessible and efficient, facilitating informed decisions and promoting financial well-being for our users.</div>

              <div className="aboutus-textheader2">Our Values</div>

              <div className="aboutus-textheader3">Accessibility</div>
              <div className="aboutus-textcontent2">We're committed to providing tools that everyone can use, regardless of their financial background or experience. We aim to make financial management approachable and user-friendly.</div>
              <div className="aboutus-textheader3">Innovation</div>
              <div className="aboutus-textcontent2">We continuously strive to improve our services and stay ahead of the curve. Our passion for technology drives us to create better and smarter solutions for financial management.</div>
              <div className="aboutus-textheader3">User Focus</div>
              <div className="aboutus-textcontent2">Our users are at the heart of everything we do. We listen, learn, and adapt to their needs to deliver an enhanced user experience.</div>
              <div className="aboutus-textheader3">Transparency</div>
              <div className="aboutus-textcontent2">We believe in maintaining honesty in our interactions and clarity in our tools. We strive to provide clear, straightforward insights without hidden costs or complexities.</div>
              <div className="aboutus-textheader3">Community</div>
              <div className="aboutus-textcontent2">We see our users not just as customers, but as part of our community. We're committed to fostering a sense of unity and support among our user base.</div>
              <div className="aboutus-textheader3">Resilience</div>
              <div className="aboutus-textcontent2">We understand that challenges are part of the journey. We embrace these as opportunities to learn, grow, and improve our service.</div>
                  </div>
        </div></div>
      )}
        <li
          className={`aboutus-item ${displayFAQs ? 'active' : ''}`}
          onClick={() => handleItemClick('faqs')}
        >
          FAQs
        </li>
        {displayFAQs && (
        <div className="aboutus-contentfaqs" style={{ backgroundImage: `url(${crowd})` }}>
        {isLoading.crowd && <div className="full-loader"><FaSpinner className="spinner" /></div>}
        <div className="whowearecontainerfaqs">
            <div className="whowearewindowfaqs">

            <div className="aboutus-textheader4">Q: Why aren't you in the app store?</div>
              <div className="aboutus-textcontent3">A: lilBudget can be downloaded from the Google Play store or from your browser as a Progressive Web App. We aspire to soon introduce a native app for iOS, but we aren't there yet. For now, you can still add the lilBudget app to your iPhone's home screen in the Safari settings, and it will behave the same as a native app!</div>

              <div className="aboutus-textheader4">Q: Can I connect to my bank so that my purchases are added automatically?</div>
              <div className="aboutus-textcontent3">A: We've been approved to integrate with financial institutions using Plaid's secure APIs and SDK. We acknowledge that it's a pain to have to add purchases manually- automating it is an important feature that we want to add, but not until we can afford to do it right. That means hiring additional engineers that specialize in fintech integrations. When we do introduce it, it will have to have a cost associated with it. This is due to the fact that lilBudget must pay to access Plaid's transaction APIs. That said, we have built lilBudget with the intent to give users this option. Stay tuned for more on this feature.</div>

              <div className="aboutus-textheader4">Q: If there are no ads, and the app is free, are you selling my data? How do you make money?</div>
              <div className="aboutus-textcontent3">A: lilBudget makes very little money, and most of it is through selling hats and shirts. We do not collect or sell data, and have no plans to do so. Would it be nice to monetize this? Sure. But right now we're hoping to build an engaged user base and make the tools accessible to as many people as possible. There may come a day when paid features are on the table, but only if lilBudget has to pay someone else to enable it. </div>

              <div className="aboutus-textheader4">Q: What is lilBudgets position on data privacy?</div>
              <div className="aboutus-textcontent3">A: You can use lilBudget totally anonymously if you want to. We only ask for an email address so that we can send you a link if you forget your password, but you can use a burner email for this if you prefer. We aren't intereseted in identifying our users or doing anything with their data. Your data is yours, full stop.</div>

              <div className="aboutus-textheader4">Q: Is lilBudget secure? How do you protect my financial data?</div>
              <div className="aboutus-textcontent3">A: We use industry-standard security protocols to protect your data, and we are constantly striving to balance security with ease-of-use. Your data is encrypted both when it's moving to and from our servers, and when it's not. We take security seriously, and will continue to do our due diligence to keep your data safe.</div>


            </div>
    </div></div>
      )}

      </ul>

    

    

    

     

<button className="closesupportform" onClick={handleClose}>
        Close
      </button>

      <div className="budgetlogo">
                    <img
                        className="budgetlogoimg"
                        src={budgetlogo}
                        alt="Budgetlogo"
                    />
                </div>
                
    </div>
  );
}

export default AboutUs;